<script>
  import { onMount, onDestroy } from "svelte"
  import { route } from "@roxi/routify"
  import { convertResolvedValuesArrToObj } from "@/utils/applyResolvers"
  import { _ } from "@/i18n"
  import { mobileToolbarConfig, notifications } from "@/stores/stores"
  import { mcKessonClinicalNotesInfo } from "@/stores/healthRecordStores"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"
  import HealthRecordsHeader from "@/components/HealthRecordsHeader/HealthRecordsHeader.svelte"
  import HRCard from "@/components/HRCard/HRCard.svelte"
  import Timestamp from "@/components/HRCard/Timestamp.svelte"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"
  import { clientSidePagination } from "@/utils/clientSidePagination"
  import { sortByTimeStamp } from "@/utils/dateTime"

  // or operator for jest unit testing
  const currPageURL = $route?.shortPath || "/"
  let isLoading = true
  let results = []
  let visibleRowCount = 25
  let clinicalNoteNotifications = []

  const updateRowCount = (chunkSize) => {
    visibleRowCount += chunkSize
  }

  const breadcrumbLinks = [
    {
      title: $_("HR.HEALTH_RECORDS"),
      link: "/health-records",
      id: "health-records",
    },
    {
      title: $_("HR.CLINICAL_NOTES.TITLE"),
      active: true,
    },
  ]

  // not concerned about anything after the day in the comparison
  function isSameDate(d1, d2) {
    return d1?.slice(0, 10) === d2?.slice(0, 10)
  }

  const formatResolverDataToUI = (data) => {
    return data?.map(({ value }) => {
      return {
        ...convertResolvedValuesArrToObj(value),
      }
    })
  }
  /**
   * Set the mobileToolbarConfig options for HR
   */
  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: true, text: $_("HR.HEALTH_RECORDS") },
      center: { logo: false },
      customClass: "blue-bkg-sm-down",
      redirectPath: "/health-records",
    })
  })

  /**
   * Reset the mobileToolbarConfig
   */
  onDestroy(() => {
    mobileToolbarConfig.set({})
  })

  $: if ($mcKessonClinicalNotesInfo) {
    if (
      !$mcKessonClinicalNotesInfo.error &&
      $mcKessonClinicalNotesInfo.length > 0
    ) {
      results = sortByTimeStamp(
        formatResolverDataToUI($mcKessonClinicalNotesInfo),
        "DESC",
        "updatedAt",
      )
    }

    isLoading = false
  }

  $: {
    if ($notifications?.data) {
      clinicalNoteNotifications =
        notifications.get("HEALTH_RECORD", "CLINICAL_NOTE")?.notifications || []
    }
  }

  $: if (clinicalNoteNotifications.length && results.length) {
    results = results.map((result) => ({
      ...result,
      notificationId: clinicalNoteNotifications.find(
        ({ referenceId }) => referenceId === result?.id,
      )?.id,
    }))
  }

  /**
   * Generates an appropriate link URL based on the provided parameters.
   *
   * @param {Object} options - The options object containing parameters for URL generation.
   * @param {string} options.noteId - The record ID of the note.
   * @param {string} options.pageUrl - The base URL of the page.
   * @param {string} [options.externalURL] - An external URL to use if provided.
   * @param {string} [options.notificationId] - The record ID of the notification, if applicable.
   * @returns {string} The generated URL based on the provided parameters.
   */
  const createHref = ({ noteId, pageUrl, externalURL, notificationId }) => {
    if (externalURL) {
      return externalURL
    }
    return `${pageUrl}/${noteId}${
      notificationId ? `?nid=${notificationId}` : ""
    }`
  }
</script>

<section class="health-records-wrapper">
  <Breadcrumb {breadcrumbLinks} />
  <HealthRecordsHeader title={$_("HR.CLINICAL_NOTES.TITLE")} />
  <section class="health-records-container">
    {#if isLoading}
      <div class="spinner-wrapper">
        <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
      </div>
    {:else if results.length > 0}
      <div
        data-testid="clinical-notes"
        use:clientSidePagination={{
          totalRows: results.length,
          updater: updateRowCount,
          listId: "clinical-notes",
        }}
      >
        <div id="clinical-notes">
          {#each results.slice(0, visibleRowCount) as note, i (note.id)}
            <div class:no-margin-title={!note.createdAt && !note.updatedAt}>
              <HRCard
                title={note.title
                  ? note.title
                  : `${$_("HR.CLINICAL_NOTES.CLINICAL_NOTE")} (${i + 1})`}
                actionProps={{
                  text: $_("HR.CLINICAL_NOTES.VIEW_NOTE"),
                  href: createHref({
                    notificationId: note.notificationId,
                    noteId: note.id,
                    pageUrl: currPageURL,
                    externalURL: note.url,
                  }),
                  icon: {
                    position: "right",
                    src: "../../../assets/svg/records-icons/chevron.svg",
                    alt: "View note",
                  },
                  openInNewTab: note.url,
                  notificationId: note.notificationId,
                }}
              >
                {#if note.createdAt}
                  <Timestamp
                    prefix="Created on"
                    timestamp={note.createdAt}
                    by={note.createdBy}
                  />
                {/if}
                {#if note.updatedAt}
                  {#if !isSameDate(note.createdAt, note.updatedAt)}
                    <Timestamp
                      prefix="Last updated"
                      timestamp={note.updatedAt}
                      by={note.updatedBy}
                    />
                  {/if}
                {/if}
              </HRCard>
            </div>
          {/each}
        </div>
      </div>
    {:else}
      <div class="no-record">
        {$_("HR.CLINICAL_NOTES.NO_RECORDS")}
      </div>
    {/if}
  </section>
</section>

<style lang="scss" src="./index.scss">
</style>
