<script>
  import { onMount, onDestroy } from "svelte"
  import { writable } from "svelte/store"
  import { filter } from "lodash"
  import { _ } from "@/i18n"

  import {
    mobileToolbarConfig,
    notifications,
    viewedNotifications,
    isPageFullWidth,
  } from "@/stores/stores"

  import { labInfo } from "@/stores/healthRecordStores"
  import Table from "./_components/_table/Table.svelte"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"
  import HealthRecordsHeader from "@/components/HealthRecordsHeader/HealthRecordsHeader.svelte"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"
  import { convertComponentValueArrToObj } from "@/utils/convertComponentValueArrToObj"
  import LabAttachment from "./_components/LabAttachment.svelte"

  let breadcrumbLinks
  let labDetailsArr
  const labComponents = writable()
  let isLoading = true
  let labResultId
  let singleLabResultInterpretations
  let labResultsAttachmentsIds = []
  let testResultNotifications

  /**
   * Parse and destructure the lab results
   * @param {Array} details Lab details
   * @returns {Array} Parse and destructured data for name, date and components
   */
  const formatLabsDataArr = (details) => {
    return (
      details
        // format data into UI ready structure
        .map(({ value }) => {
          return {
            id: filter(value, { field: "id" })[0]?.value,
            name: filter(value, { field: "name" })[0]?.value,
            date: filter(value, { field: "date" })[0]?.value,
            provenance: filter(value, { field: "provenance" })[0]?.value,
            components: filter(value, { field: "components" }).map(
              (component) => convertComponentValueArrToObj(component.value),
            ),
          }
        })
    )
  }

  /**
   * Get last segment of the URL and filter the lab result id from lab results
   * @returns {Array} Parsed and destructured array of lab details
   */
  const getLabDetails = () => {
    labResultId = window.location.pathname.split("/").pop()
    const labDetail = filter($labInfo.results, {
      value: [{ value: labResultId }],
    })
    // Parse filtered lab result
    return formatLabsDataArr(labDetail)
  }

  /**
   * Prepare breadcrumb link with dynamic lab result name
   * @returns {Array} Breadcrumblinks
   */
  const generateBreadcrumbLinks = () => [
    {
      title: $_("HR.HEALTH_RECORDS"),
      link: "/health-records",
      id: "health-records",
    },
    {
      title: $_("HR.TEST_RESULTS.TITLE"),
      link: "/health-records/test-results",
      id: "test-results",
    },
    {
      title: labDetailsArr[0]?.name || "",
      active: true,
    },
  ]

  $: {
    if ($notifications?.data) {
      testResultNotifications =
        notifications.get("HEALTH_RECORD", "TEST_RESULT")?.notifications || []
    }
  }

  $: if ($labInfo && !$labInfo.isLoading) {
    isLoading = false
    labDetailsArr = getLabDetails()
    labComponents.set(labDetailsArr[0]?.components)
    breadcrumbLinks = generateBreadcrumbLinks()
  }

  $: if (labDetailsArr) {
    if (labDetailsArr[0].components.length === 1) {
      if (labDetailsArr[0].components[0].interpretations.length > 0) {
        singleLabResultInterpretations =
          labDetailsArr[0].components[0].interpretations
      }
    }

    labResultsAttachmentsIds = labDetailsArr[0].components
      .filter((labDetailComponent) => labDetailComponent.hasAttachment)
      .map((labDetailComponent) => labDetailComponent.id)
  }

  $: if (labDetailsArr && testResultNotifications) {
    const notificationQueue = new Set()

    if (labDetailsArr[0]?.components?.length > 0) {
      labDetailsArr[0]?.components?.forEach((component) => {
        const notificationId = testResultNotifications.find(
          ({ referenceId }) => referenceId === component.id,
        )?.id
        if (notificationId && !$viewedNotifications.includes(notificationId)) {
          notificationQueue.add(notificationId)
        }
      })
    }

    if (notificationQueue.size) {
      viewedNotifications.add([...notificationQueue])
    }
  }
  /**
   * Set the mobileToolbarConfig options for HR
   */
  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: true, text: $_("HR.TEST_RESULTS.TITLE") },
      center: { logo: false },
      customClass: "blue-bkg-sm-down",
      redirectPath: "/health-records/test-results",
    })
    isPageFullWidth.set(true)
  })

  /**
   * Reset the mobileToolbarConfig
   */
  onDestroy(() => {
    isPageFullWidth.set(false)
    mobileToolbarConfig.set({})
  })
</script>

<section class="health-records-wrapper test-result-details">
  <div class="page-layout">
    <Breadcrumb {breadcrumbLinks} />
  </div>
  {#if isLoading}
    <div class="spinner-wrapper">
      <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
    </div>
  {:else if labDetailsArr.length > 0}
    <div class="page-layout">
      <HealthRecordsHeader
        title={labDetailsArr[0]?.name}
        textAlignClass="text-left"
        subTitle={labDetailsArr[0]?.date}
      />
    </div>
    <section>
      <div class="section-container">
        {#if labDetailsArr[0].attachments || labDetailsArr[0].comments}
          <h3 class="section-header">Results</h3>
        {/if}
        {#if $labComponents}
          <Table
            {labComponents}
            panelName={labDetailsArr?.[0]?.name}
            {labResultId}
          />
        {/if}

        {#if labDetailsArr[0].provenance}
          <p class="provenance-info mb-0">
            <span class="field">Added to EHR: </span>
            <span>{labDetailsArr[0].provenance}</span>
          </p>
        {/if}
      </div>

      {#if labResultsAttachmentsIds.length}
        <div class="section-container attachments page-layout">
          <h3 class="section-header">Attachments</h3>

          {#each labResultsAttachmentsIds as labAttachmentId}
            <LabAttachment {labAttachmentId} />
          {/each}
        </div>
      {/if}
    </section>
  {:else}
    <div class="no-record">
      {$_("HR.TEST_RESULTS.NO_RECORD_FOUND")}
    </div>
  {/if}
</section>

<style lang="scss">
  @import "../index.scss";

  .attachments {
    padding: 0 16px;
  }

  @include media-breakpoint-up(md) {
    .page-layout {
      min-width: 544px;
      max-width: 666px;
      margin: 0 auto;
      width: calc(100% - 48px);
    }
  }
  @include media-breakpoint-down(sm) {
    .page-layout {
      max-width: 544px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @include media-breakpoint-down(xs) {
    .page-layout {
      max-width: 100%;
      width: 100%;
      margin: 0;
    }
  }

  @include media-breakpoint-down(sm) {
  }
</style>
