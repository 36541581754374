/* eslint-disable no-useless-escape */
/* eslint-disable quotes */
import { partialRight } from "lodash"
import { dateTransformer } from "../transformers"

const convertBase64ToString = (base64String) =>
  Buffer.from(base64String, "base64").toString()

const ClinicalNoteResolvers = [
  {
    field: "id",
    path: "entry[0].resource.id",
  },
  {
    field: "title",
    path: 'entry[0].resource.extension.|["\\"url\\":\\"nc:document:document_name\\""]|.valueString',
  },
  {
    field: "createdAt",
    path: 'entry[0].resource.extension.|["\\"url\\":\\"nc:document:created_at\\""]|.valueInstant',
    transform: partialRight(dateTransformer, {
      dateFormat: "MM/DD/YYYY",
    }),
  },
  {
    field: "createdBy",
    path: "entry[0].resource.author[0].display",
  },
  {
    field: "updatedAt",
    path: 'entry[0].resource.extension.|["\\"url\\":\\"nc:document:updated_at\\""]|.valueInstant',
    transform: partialRight(dateTransformer, {
      dateFormat: "MM/DD/YYYY",
    }),
  },
  {
    field: "attachment",
    path: "entry[0].resource.content[0].attachment.data",
    transformer: convertBase64ToString,
  },
]

export default ClinicalNoteResolvers
