/**
 * Dispatch event on click outside of node
 * @param {HTMLElement} node DOM element that action/listener will be attached to
 * @returns {Object} Add click event listener for the doument
 */
export function clickOutside(node) {
  const handleClick = (event) => {
    if (node && !node.contains(event.target) && !event.defaultPrevented) {
      node.dispatchEvent(new CustomEvent("clickOutside", node))
    }
  }

  document.addEventListener("click", handleClick, true)

  return {
    destroy() {
      document.removeEventListener("click", handleClick, true)
    },
  }
}
