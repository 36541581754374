<script>
  import Logo from "./logo.svelte"
</script>

<div class="auth-nav-wrapper">
  <nav class="navbar">
    <a class="navbar-brand" href="/auth/login" aria-label="navbar logo link">
      <Logo />
    </a>
  </nav>
</div>

<style lang="scss" src="./AuthNav.scss">
</style>
