<section class="about-us-container">
  <div class="heading-curve">
    <svg
      width="100%"
      height="24"
      viewBox="0 0 1152 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1152 21.8307V23H0V22.6678C163.727 8.40996 364.827 0 581.874 0C794.639 0 991.334 8.08137 1152 21.8307Z"
        fill="#0E446B"
      />
    </svg>
  </div>
  <div class="about-us">
    <div>
      <h2 class="heading">About Us</h2>
      <p class="mb-0">
        Navigating Care is a digital healthcare companion that helps you stay
        connected to your care team and manage your health during your treatment
        journey.
      </p>
      <div class="cards-container">
        <article class="card">
          <section class="card-icon">
            <img
              width="80"
              height="80"
              src="../assets/svg/organize-care-icon.svg"
              alt="organize-care"
            />
          </section>
          <section class="card-body">
            <h3 class="title mb-0">Organize Your Care</h3>
            <p>A single, secure place to track your health information.</p>
          </section>
        </article>
        <article class="card">
          <section class="card-icon">
            <img
              width="80"
              height="80"
              src="../assets/svg/stay-connected-icon.svg"
              alt="stay-connected"
            />
          </section>

          <section class="card-body">
            <h3 class="title mb-0">Stay Connected</h3>
            <p>
              Connect with your care team to get answers and report how you’re
              feeling.
            </p>
          </section>
        </article>
        <article class="card">
          <section class="card-icon">
            <img
              width="80"
              height="80"
              src="../assets/svg/informed-icon.svg"
              alt="be-informed"
            />
          </section>
          <section class="card-body">
            <h3 class="title mb-0">Be Informed</h3>
            <p>
              Get personalized education and resources to improve your care.
            </p>
          </section>
        </article>
      </div>
      <div class="learn-more">
        <p class="mb-0">
          Want to learn more? Visit our patient site at <a
            href="https://www.navigatingcare.com/"
            target="_blank">NavigatingCare.com</a
          >
        </p>
      </div>
    </div>
  </div>
</section>

<style lang="scss" src="./AboutUsBanner.scss"></style>
