import { hasIn, get, partialRight } from "lodash"
import applyResolvers from "@/utils/applyResolvers"
// import { sortByDate } from "@/utils/sortByDate"
import createProvenanceResolver from "./provenanceResolvers"
import { dateTransformer } from "./transformers"

const hasProblemsNameField = (entryObj) => hasIn(entryObj, "resource.code.text")
/**
 *
 * Comment out the sorting from the original problemsInfoResolvers.js
 * Since we will have to do sorting across encounterDiagnosis and problemsInfo
 */

/**
 * Sort data by status in order of Active, Complete
 * @param {Object} a Object of problems info
 * @param {Object} b Object of problems info
 * @returns {Boolean} return false if both string are equal and true if both string are not equal.
 */
// const sortByClinicalStatus = (a, b) =>
//   a.resource?.clinicalStatus?.text?.toLowerCase() >
//   b.resource?.clinicalStatus?.text?.toLowerCase()

/**
 * Sort data by status (Active, Complete) and then by date in descending order
 * @param {Object} a Object of problems info
 * @param {Object} b Object of problems info
 * @returns {Number} a number 0 OR positive or negative
 */
// const sortProblemsInfo = (a, b) =>
//   sortByClinicalStatus(a, b) ||
//   sortByDate(a.resource?.onsetDateTime, b.resource?.onsetDateTime)

const problemsInfoResolvers = [
  {
    field: "problems",
    path: (data) =>
      data?.entry
        ?.filter(hasProblemsNameField)
        // .sort(sortProblemsInfo)
        .map((obj) => ({
          text: get(obj?.resource, "code.text"),
          id: get(obj?.resource, "id"),
          fields: applyResolvers(obj, [
            {
              field: "Concern status",
              path: "resource.clinicalStatus.text",
            },
            {
              field: "Started",
              path: "resource.onsetDateTime",
              transform: partialRight(dateTransformer, {
                dateFormat: "M/D/YYYY",
              }),
            },
            {
              field: "Ended",
              path: "resource.abatementDateTime",
              transform: partialRight(dateTransformer, {
                dateFormat: "M/D/YYYY",
              }),
            },
            ...createProvenanceResolver(
              "resource.",
              "Added to EHR",
              "M/D/YYYY",
              ", hh:mma z",
            ),
          ]),
        })),
  },
]

export default problemsInfoResolvers
