<script>
  import Gauge from "@/components/Gauge/Gauge.svelte"

  export let range = ""
  export let result = ""
  const value = parseFloat(result).toString()
</script>

{#if range}
  <div class="reference-range-container">
    <p>{range}</p>
    <div class="gauge">
      <Gauge {value} {range} />
    </div>
  </div>
{/if}

<style type="scss">
  .reference-range-container {
    display: flex;
    align-items: center;
  }
  p {
    margin-right: 12px;
    margin-bottom: 0;
    text-wrap: nowrap;
    min-width: 65px;
  }
  .gauge {
    width: 150px;
  }
</style>
