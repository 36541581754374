<script>
  /**
   * @type {String} variants include "upcoming", "active", and "complete"
   */
  export let variant = "upcoming"
</script>

<span class="step step--{variant}" id="step" />

<style src="./StepIndicator.scss">
</style>
