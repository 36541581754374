import * as yup from "yup"
import { isEmpty, isNumber } from "lodash"
/**
 * Maps the errors returned from yup validation
 * @param {Object} err error object from yup
 * @returns {Object} mapped object more accesible for the UI
 */
export const extractErrors = (err) => {
  return err.inner.reduce((acc, error) => {
    return { ...acc, [error.path]: error.message }
  }, {})
}

export const loginSchemaWithEmail = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("AUTH.EMAIL_REQUIRED")
    .email("AUTH.INVALID_EMAIL"),
  password: yup.string().trim().required("AUTH.PASSWORD_REQUIRED"),
})

export const loginSchemaWithUsername = yup.object().shape({
  username: yup.string().trim().required("AUTH.USERNAME_REQUIRED"),
  password: yup.string().trim().required("AUTH.PASSWORD_REQUIRED"),
})

export const passwordResetSchemaWithEmail = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("AUTH.EMAIL_REQUIRED")
    .email("AUTH.INVALID_EMAIL"),
})

export const passwordSchema = yup.object().shape({
  password: yup
    .string()
    // eslint-disable-next-line func-names
    .test("passwordTests", function (password) {
      const specialCharOrNumRegex = new RegExp("(^.*(?=.*[!@#$%^&*_0-9]).*$)")
      const errorObj = {}

      if (!password || password.length < 8)
        errorObj.lengthErr = "error-text close-icon"
      if (!specialCharOrNumRegex.test(password))
        errorObj.specialCharOrNumErr = "error-text close-icon"

      return isEmpty(errorObj)
        ? true
        : this.createError({
            message: errorObj,
            path: "password",
          })
    }),
})

export const createPasswordSchema = passwordSchema.shape({
  confirmPassword: yup
    .string()
    // eslint-disable-next-line func-names
    .test("passwordTests", function (confirmPassword) {
      if (!confirmPassword || confirmPassword.length < 1)
        return this.createError({
          message: "AUTH.REENTER_CONFIRM_PASSWORD",
        })
      if (confirmPassword !== this.parent.password)
        return this.createError({
          message: "AUTH.PASSWORDS_MUST_MATCH",
        })
      return true
    }),
})

export const shareHealthRecordsSchema = yup.object().shape({
  showPasswordProtection: yup.string(),
  email: yup
    .string()
    .trim()
    .required("VDS.EMAIL_REQUIRED")
    .email("VDS.INVALID_EMAIL"),
  // eslint-disable-next-line func-names
  confirmEmail: yup.string().test("confirmEmail", function (confirmEmail) {
    if (!confirmEmail) {
      return this.createError({
        message: "VDS.CONFIRM_EMAIL_REQUIRED",
      })
    }

    if (confirmEmail !== this.parent.email) {
      return this.createError({
        message: "VDS.EMAIL_MISMATCH",
      })
    }
    return true
  }),
  password: yup
    .string()
    .trim()
    .when("showPasswordProtection", {
      is: (showPasswordProtection) => showPasswordProtection === "true",
      then: yup.string().trim().required("VDS.PASSWORD_REQUIRED"),
      otherwise: yup.string(),
    }),
})

export const DOBSchema = yup.object().shape({
  month: yup
    .number("REGISTRATION.INVALID_MONTH")
    .typeError("REGISTRATION.INVALID_MONTH")
    .required("REGISTRATION.MISSING_FIELD")
    .min(1, "REGISTRATION.INVALID_MONTH")
    .max(12, "REGISTRATION.INVALID_MONTH"),

  day: yup
    .number("REGISTRATION.INVALID_DAY")
    .typeError("REGISTRATION.INVALID_DAY")
    .required("REGISTRATION.MISSING_FIELD")
    .min(1, "REGISTRATION.INVALID_DAY")
    .max(31, "REGISTRATION.INVALID_DAY"),
  year: yup
    .number("REGISTRATION.INVALID_YEAR")
    .typeError("REGISTRATION.INVALID_YEAR")
    // eslint-disable-next-line func-names
    .test("test", function (val) {
      if (!val)
        return this.createError({
          message: "REGISTRATION.MISSING_FIELD",
        })
      if (!isNumber(val))
        return this.createError({
          message: "REGISTRATION.INVALID_YEAR",
        })
      if (val.toString().length !== 4)
        return this.createError({
          message: "REGISTRATION.INVALID_YEAR_CHAR_LENGTH",
        })
      if (val < 1900 || val > new Date().getFullYear())
        return this.createError({
          message: "REGISTRATION.INVALID_YEAR",
        })

      return true
    }),
})

export const personalRepresentativeSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required(
      "REGISTRATION.PERSONAL_REPRESENTATIVE_FORM_VALIDATION.FIRST_NAME",
    ),

  lastName: yup
    .string()
    .trim()
    .required("REGISTRATION.PERSONAL_REPRESENTATIVE_FORM_VALIDATION.LAST_NAME"),
  relationship: yup
    .string()
    .trim()
    .required(
      "REGISTRATION.PERSONAL_REPRESENTATIVE_FORM_VALIDATION.RELATIONSHIP",
    ),
  phone: yup
    .string()
    .trim()
    .length(
      14,
      "REGISTRATION.PERSONAL_REPRESENTATIVE_FORM_VALIDATION.PHONE_LENGTH",
    )
    .required(
      "REGISTRATION.PERSONAL_REPRESENTATIVE_FORM_VALIDATION.PHONE_REQUIRED",
    ),
  email: yup
    .string()
    .trim()
    .email(
      "REGISTRATION.PERSONAL_REPRESENTATIVE_FORM_VALIDATION.EMAIL_INVALID",
    ),
})
