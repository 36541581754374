<script>
  import { createEventDispatcher } from "svelte"
  import { _ } from "@/i18n"
  import { clickOutside } from "@/utils/clickOutside"
  import {
    enableKeyboardNavigation,
    setNodeFocusOnKeyboardSelect,
    focusBackHandler,
  } from "@/utils/keyboardNavigation"

  export let myClinicProviders
  export let myConnections
  export let invalid = false

  // Declare the dispatch
  const dispatch = createEventDispatcher()

  let isOpen = false
  let selectedProvider = []
  let selectedConnection = []
  let selectedRecipients = []

  /**
   * Toggle dropdown and dispatch the event to the application
   */
  const toggleDropdown = () => {
    isOpen = !isOpen
  }

  /**
   * Filter the selected providers or connection entries from the Recipient list.
   * Dispatch the event to application with selectedProvider flag and selected recipients data
   * @param {String} personName selected connection or provider name
   * @param {Keyboard Event} e Keyboard Event
   */
  const handleOptionSelect = (personName, e) => {
    if (e && e instanceof KeyboardEvent) {
      if (e.code !== "Space" && e.code !== "Enter") return
    }

    // Get selected connection list from myConnections array
    selectedConnection = myConnections.filter(
      (connection) => connection.name === personName,
    )

    // Get seleted providers list from myClinicProviders array
    selectedProvider = myClinicProviders.filter(
      (provider) => provider.name === personName,
    )

    // Check mark to skip duplicate selection
    if (
      selectedRecipients.filter((recipient) => recipient.name === personName)
        .length === 0
    ) {
      // Update seleted recipients array with newly selected option
      if (selectedConnection.length > 0) {
        selectedRecipients = [selectedConnection[0], ...selectedRecipients]
      }

      // Update seleted recipients array with newly selected option
      if (selectedProvider.length > 0) {
        selectedRecipients = [selectedProvider[0], ...selectedRecipients]
      }
    }

    // Dispatch the event with selectedProvider flag and selected recipients data
    dispatch("recipientSelected", {
      providerSelected: selectedProvider.length > 0,
      selectedRecipients,
      action: "recipient-added",
      isOpen,
    })

    // prevent execution if no event passed, therefore no keyboard event
    if (e) {
      // Close dropdown after selection
      toggleDropdown()
    }
  }

  /**
   * Remove the selected provider or connections from thie Chip selection area
   * Dispatch the event to application with selectedProvider flag and selected recipients data
   * @param {String} personName selected connection or provider name
   * @param {Keyboard Event} e Keyboard Event
   */
  const removeChipSelection = (personName, e) => {
    // check for keyboard selection
    if (e && e instanceof KeyboardEvent) {
      if (e.code !== "Space" && e.code !== "Enter") return
    }

    // Update selectedRecipients array while removing recipient from chips
    selectedRecipients = selectedRecipients.filter(
      (recipient) => recipient.name !== personName,
    )

    // Dispatch the event with selected Provider flag
    if (selectedRecipients.length === 0) {
      selectedProvider = []
      selectedConnection = []
    }

    dispatch("recipientSelected", {
      providerSelected: selectedProvider.length > 0,
      selectedRecipients,
      action: "recipient-removed",
      isOpen,
    })
  }

  $: displayRecipientLabels =
    myClinicProviders.length > 0 && myConnections.length > 0
</script>

<div
  class="dropdown"
  use:enableKeyboardNavigation={".chip-close-icon, .submenu:not(.disabled), .mainmenu:not(.disabled)"}
  on:keyboardNavigate={isOpen ? "" : toggleDropdown}
  on:keyboardUnfocus={isOpen ? toggleDropdown : ""}
  on:keyboardEscape={isOpen ? toggleDropdown : ""}
>
  <label for="topic" class="px-16"
    >{$_("SM.TO")}<span class="mobile-only">:</span></label
  >
  <button
    class="btn btn-primary dropdown-toggle {isOpen ? 'square-btm-borders' : ''}"
    type="button"
    data-toggle="dropdown"
    data-display="static"
    on:click={toggleDropdown}
    class:invalid
    id="recipient-dropdown"
    use:focusBackHandler
    on:focusBack={isOpen ? toggleDropdown : ""}
    use:clickOutside
    on:clickOutside={isOpen ? toggleDropdown : ""}
  >
    {#if selectedRecipients.length === 0}
      <span class="placeholder">{$_("SM.CHOOSE_MESSAGE_RECIPIENTS")}</span>
    {:else}
      <span class="chip-container">
        {#each selectedRecipients as recipient}
          <span class="chip">
            <div class="chip-block">
              <div class="chip-content">{recipient.name}</div>
            </div>
            <span
              class="chip-close-icon"
              use:setNodeFocusOnKeyboardSelect={"#recipient-dropdown"}
              on:click|stopPropagation={removeChipSelection(recipient.name)}
              on:keydown={(e) => removeChipSelection(recipient.name, e)}
            >
              <i class="fas fa-times-circle" aria-hidden="true" />
            </span>
          </span>
        {/each}
      </span>
    {/if}

    <span class="chevron-icon caret pr-16">
      <i
        class="fas {isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}"
        class:invalid
        aria-hidden="true"
      />
    </span>
  </button>
  <ul
    class="dropdown-menu mobile-full-height px-16"
    on:click={isOpen ? toggleDropdown : ""}
    class:show={isOpen}
    class:invalid
  >
    {#if myConnections.length === 0 && myClinicProviders.length === 0}
      <li class="no-recipient">
        {$_("SM.YOU_HAVE_NO_SECURE_MESSAGES_CONNECTIONS")}
      </li>
    {/if}

    {#if displayRecipientLabels}
      <li class="instruction">
        {$_("SM.MESSAGES_MAY_ONLY_BE_SENT_TO_YOUR_CLINIC_OR_YOUR_CONNECTIONS")}
      </li>
    {/if}

    {#if myClinicProviders.length > 0}
      {#if displayRecipientLabels}
        <li class="section-header">{$_("SM.MY_CLINIC")}</li>
      {/if}
      <li>
        <ul>
          {#each myClinicProviders as { name }, i}
            <li
              class="submenu {selectedConnection.length > 0 ? 'disabled' : ''}"
              use:setNodeFocusOnKeyboardSelect={"#recipient-dropdown"}
              on:click={selectedConnection.length === 0
                ? handleOptionSelect(name)
                : toggleDropdown}
              on:keydown={(e) => handleOptionSelect(name, e)}
            >
              {name}
            </li>
          {/each}
        </ul>
      </li>
    {:else}
      {#each myClinicProviders as { name }, i}
        <li
          class="mainmenu {selectedConnection.length > 0 ? 'disabled' : ''}"
          on:click={selectedConnection.length === 0
            ? handleOptionSelect(name)
            : toggleDropdown}
          on:keydown={(e) => handleOptionSelect(name, e)}
        >
          {name}
        </li>
      {/each}
    {/if}

    {#if myConnections.length > 0}
      {#if displayRecipientLabels}
        <li class="section-header">
          {$_("SM.MY_CONNECTIONS")}
        </li>
      {/if}
      <li>
        <ul>
          {#each myConnections as { name }, i}
            <li
              class="submenu {selectedProvider.length > 0 ? 'disabled' : ''}"
              use:setNodeFocusOnKeyboardSelect={"#recipient-dropdown"}
              on:click={selectedProvider.length === 0
                ? handleOptionSelect(name)
                : toggleDropdown}
              on:keydown={(e) => handleOptionSelect(name, e)}
            >
              {name}
            </li>
          {/each}
        </ul>
      </li>
    {/if}
  </ul>
</div>

<style lang="scss" src="./Recipient.scss">
</style>
