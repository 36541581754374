<section>
  <img src="../../assets/svg/curve.svg" class="icon" alt="Curve Icon" />
  <header>
    <img src="../../assets/svg/nc-logo.svg" alt="NC Logo" tabindex="0" />
    <h1>Your Digital Healthcare Companion</h1>
  </header>
  <div id="cover">
    <svg
      width="100%"
      height="23"
      viewBox="0 0 797 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M797 21.8307V23H0V22.6677C113.273 8.40995 252.402 0 402.564 0C549.763
        0 685.845 8.08138 797 21.8307Z"
        fill="#F0F2F5"
      />
    </svg>
  </div>
</section>

<style lang="scss" src="./LoginHeader.scss">
</style>
