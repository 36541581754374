/**
 * This is a much faster alternative to lodash omit
 * Note - This does not work on objects with flatted paths
 * @param {String} keyToOmit - key value to omit from the object
 * @param {Object} param1 - The object to omit the key from
 * @returns {Object} - cloned object without the property specified by keyToOmit
 */
export const omitObjProperty = (
  keyToOmit,
  { [keyToOmit]: _, ...omittedPropObj } = {},
) => omittedPropObj

export default omitObjProperty
