<script>
  import { Router } from "@roxi/routify"
  import { Datepicker } from "vanillajs-datepicker"
  import es from "vanillajs-datepicker/locales/es"
  import { setupI18n, _ } from "@/i18n"
  import { deleteCookie, setCookie } from "@/utils/cookie"
  import isPublicRoute from "@/utils/isPublicRoute"
  import {
    sessionData,
    bodyClassName,
    isLoggedIn,
    isUserClinicianOrAdmin,
  } from "@/stores/stores"
  import { getP1Host } from "@/utils/getP1Host"
  import { routes } from "../.routify/routes"
  import Navigation from "./components/Navigation/Navigation.svelte"
  import HomeHeader from "./components/HomeHeader/HomeHeader.svelte"
  import LoadingIndicator from "./components/LoadingIndicator/LoadingIndicator.svelte"

  $: document.body.className = $bodyClassName

  // eslint-disable-next-line no-undef
  const ENV = portalApp.env.ENV_NAME
  const TESTABLE_ENV = ["local", "dev", "acceptance"]

  // Get lang parameter from localstorage
  const locale = localStorage.getItem("locale") || "en"
  setupI18n({ withLocale: locale })
  const params = new URLSearchParams(window.location.search)

  // extending Datepicker with es locale for spanish support
  Object.assign(Datepicker.locales, es)
  Datepicker.locales.en.today = "Select today's date"

  /**
   * Check if timer param is set so the inactivity banner can be tested with other than the defaults values.
   */
  if (params.has("timer") && TESTABLE_ENV.includes(ENV)) {
    const timer = params.get("timer")
    localStorage.setItem("timer", timer)
  }

  const beforeUnload = () => {
    deleteCookie("providedEmail")
  }

  if (ENV === "local") {
    setCookie("nc-session", "943d91f3-4db1-41f8-9de8-33c3a04d10ff")
  }
</script>

<svelte:window on:beforeunload={beforeUnload} />
{#await sessionData.init()}
  <div class="spinner-wrapper">
    <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
  </div>
{:then}
  {#if !$isLoggedIn && !isPublicRoute() && !$isUserClinicianOrAdmin}
    <div class="redirect-container">
      <div class="nav-container">
        <Navigation />
      </div>
      <HomeHeader />
      <div class="spinner">
        <LoadingIndicator />
        <p>Redirecting...</p>
      </div>
    </div>
  {:else}
    <div class="app-container pl-0 pr-0">
      <Router {routes} />
    </div>
  {/if}
{:catch}
  {window.location.assign(getP1Host())}
{/await}

<style lang="scss" src="./css/global.scss" global></style>
