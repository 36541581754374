<script>
  import { _ } from "@/i18n"
  import HRCard from "@/components/HRCard/HRCard.svelte"
  import Timestamp from "@/components/HRCard/Timestamp.svelte"

  export let href
  export let id
  export let title = "File"
  export let fileName = "Filename"
  export let category
  export let description
  export let fileType
  export let size
  export let timestamp
  export let by
  export let prefix = "Uploaded on"
  export let notificationId = ""
</script>

<HRCard
  {title}
  actionProps={{
    id,
    category,
    text: $_("HR.HEALTH_DOCUMENTS.DOWNLOAD"),
    href,
    isDownload: true,
    icon: {
      position: "left",
      src: "../../../assets/svg/download.svg",
      alt: "Download icon",
    },
    isTitleCTA: true,
    notificationId,
  }}
>
  {#if description}
    <p>{description}</p>
  {/if}
  {#if fileName}
    <p>{fileName}</p>
  {/if}
  {#if fileType && size}
    <p>
      <span class="file-type">{fileType}</span>
      <span class="separator">|</span>
      <span>{size}</span>
    </p>
  {/if}
  <Timestamp {prefix} {timestamp} {by} />
</HRCard>

<style type="scss">
  .file-type {
    text-transform: uppercase;
  }
</style>
