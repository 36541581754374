<script>
  /**
   * @typedef CardConfigObject
   * @type {object}
   * @property {String} title - card title
   * @property {String} [subTitle] - card sub title
   * @property {String} description - card description
   * @property {String} url - card url
   * @property {String} actionText - card text
   * @property {Object} [image] { src: STRING, alt: STRING } - card image data
   */

  /**
   * @type {CardConfigObject}
   */
  export let config = {}
  let {
    title,
    subTitle,
    description,
    url,
    actionText,
    image,
  } = config

  $: ({
    title = "",
    subTitle = "",
    description = "",
    url,
    actionText,
    image = null,
  } = config)
</script>

<div class="card-container">
  {#if image}
    <div class="header-container">
      <img src={image.src} class="image" alt={image.alt} />
    </div> 
  {/if}
  <div class="description-container">
    <h1 class="title">{title}</h1>
    {#if subTitle}
      <p class="sub-title">{subTitle}</p>
    {/if}
    <p class="description">
      {description}
    </p>
  </div>
  <hr />
  <a href={url} class="link"> {actionText} </a>
</div>

<style lang="scss" src="./Card.scss">
</style>
