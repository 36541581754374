<script>
  import { onMount, onDestroy } from "svelte"
  import { goto, route, url } from "@roxi/routify"
  import { isEmpty } from "lodash"
  import dayjs from "dayjs"
  import {
    isUserClinicianOrAdmin,
    mobileToolbarConfig,
    sectionalAlertConfig,
  } from "@/stores/stores"
  import { _ } from "@/i18n"
  import { callAPI } from "@/utils/httpService"
  import { secondsTill14daysEOD, returnFormattedDate } from "@/utils/dateTime"
  import {
    shareHealthRecordsSchema,
    extractErrors,
  } from "@/utils/formValidations"
  import omitObjProperty from "@/utils/omitObjProperty"
  import { patientId } from "@/stores/healthRecordStores"
  import Toggle from "@/components/Toggle/Toggle.svelte"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"
  import HealthRecordsHeader from "@/components/HealthRecordsHeader/HealthRecordsHeader.svelte"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"

  let isLoading = false
  let showPasswordProtection = false
  let errors = {}
  const values = {}
  let hasError = false
  let fromDate
  let toDate
  const goBackUrl = $route?.last?.shortPath
    ? $url($route.last.shortPath, $route.last.params)
    : "/health-records/vds"

  /**
   * This function will validate the date format MM-DD-YYYY
   * @param {String} date date string to be validated
   * @returns {Boolean} true | false
   */
  const validateDateFormat = (date) => {
    const dateRegx = /^(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])-(19|20)\d{2}$/
    return dateRegx.test(date)
  }

  /**
   * This function will parse the date range from query params
   */
  const parseDateRangeFromQueryParams = () => {
    const params = new URLSearchParams(window.location.search)
    if (params.has("from") || params.has("to")) {
      const from = params.get("from")
      const to = params.get("to")
      if (validateDateFormat(from) && validateDateFormat(to)) {
        if (!dayjs(from).isAfter(dayjs(to))) {
          fromDate = returnFormattedDate(from, {
            dateFormat: "MM/DD/YY",
            strSeparator: true,
          })
          toDate = returnFormattedDate(to, {
            dateFormat: "MM/DD/YY",
            strSeporator: true,
          })
        }
      } else {
        $goto("/health-records/share")
      }
    }
  }

  /**
   * This function will validate the form input and call API to share health records
   */
  const shareHealthRecords = async () => {
    try {
      hasError = false
      values.showPasswordProtection = showPasswordProtection
      // Loading indicator handler.
      isLoading = true
      await shareHealthRecordsSchema.validate(values, { abortEarly: false })
      errors = {}
      const isEncrypted = !isEmpty(values.password)

      const payload = {
        usage: "transmit",
        dataFilters: {
          patientId: $patientId,
          startDate: fromDate
            ? returnFormattedDate(fromDate, { dateFormat: "YYYY-MM-DD" })
            : undefined,
          endDate: toDate
            ? returnFormattedDate(toDate, { dateFormat: "YYYY-MM-DD" })
            : undefined,
        },
        documents: {
          linkValiditySeconds: isEncrypted ? secondsTill14daysEOD() : 60 * 60,
          formats: ["pdf", "xml"],
          encryptionPassword: values.password,
        },
        emailRecipient: values.email,
      }

      const postResponse = await callAPI({
        url: "health-records/document-requests",
        method: "POST",
        payload,
      })

      isLoading = false

      let alertConfig
      if (isEncrypted) {
        alertConfig = {
          message: `${values.email} ${$_(
            "VDS.HEALTH_RECOREDS_SHARED_WITH_PASSWORD",
          )}`,
          title: $_("VDS.HEALTH_RECORDS_SHARED_TITLE"),
          contentType: "success",
          iconName: "check",
          stacked: true,
        }
      } else {
        alertConfig = {
          message: `${$_("VDS.HEALTH_RECOREDS_SHARED_WITHOUT_PASSWORD")} ${
            values.email
          }.`,
          contentType: "success",
          iconName: "check",
        }
      }

      if (postResponse.jobStatus === "submitted") {
        $sectionalAlertConfig.sentRecordsSuccess = alertConfig
        $goto("/health-records")
      }
    } catch (err) {
      errors = extractErrors(err)
      hasError = true
      isLoading = false
    }
  }

  /**
   * Toggle option to show/hide password protection block
   * @param {Boolean} param0.detail boolean value of toggle button
   */
  const toggleButton = ({ detail }) => {
    showPasswordProtection = detail === true
    values.password = ""
  }

  const breadcrumbLinks = [
    {
      title: $_("HR.HEALTH_RECORDS"),
      link: "/health-records",
      id: "health-records",
    },
    {
      title: $_("VDS.VIEW_DOWNLOAD_OR_SHARE"),
      link: goBackUrl,
      id: "vds",
    },
    {
      title: $_("VDS.SHARE"),
      active: true,
    },
  ]

  /**
   * Set the mobileToolbarConfig options for HR
   */
  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: false, text: $_("VDS.CANCEL") },
      center: { logo: false },
      customClass: "blue-bkg-sm-down",
      redirectPath: "/health-records/vds",
    })

    parseDateRangeFromQueryParams()
  })

  /**
   * Reset the mobileToolbarConfig
   */
  onDestroy(() => {
    mobileToolbarConfig.set({})
  })
</script>

<section class="health-records-wrapper">
  <Breadcrumb {breadcrumbLinks} />
  <HealthRecordsHeader
    customClass="custom-font-style"
    title={$_("VDS.SHARE_HEALTH_RECORDS_VIA_EMAIL")}
  />

  <section class="health-records-container">
    <div class="timeframe-container">
      <h2 id="timeframe-heading">
        {#if fromDate && toDate}
          {$_("VDS.SHARING_DATE_RANGE_HEALTH_RECORDS")}
        {:else}
          {$_("VDS.SHARING_ALL_HEALTH_RECORDS")}
        {/if}
      </h2>
      {#if fromDate && toDate}
        <p id="timeframe-range">{fromDate} - {toDate}</p>
      {/if}
      <p>
        <a href={goBackUrl} class="link">{$_("VDS.EDIT_TIMEFRAME")}</a>
      </p>
    </div>
    <form on:submit|preventDefault={shareHealthRecords}>
      <div class="heading">
        <h2>{$_("VDS.SHARE_HEADING")}</h2>
      </div>
      <div class="fields">
        <label for="email">{$_("VDS.EMAIL_ADDRESS")}</label>
        <input
          type="text"
          aria-label="email"
          id="email"
          name="transmit-email"
          class:error={errors.email}
          class="form-text-primary"
          bind:value={values.email}
          on:focus={() => (errors = omitObjProperty("email", errors))}
        />
        {#if errors.email}
          <span id="email-error" class="error-text">{$_(errors.email)}</span>
        {/if}
      </div>
      <div class="fields">
        <label for="confirmEmail">{$_("VDS.CONFIRM_EMAIL_ADDRESS")}</label>
        <input
          type="text"
          aria-label="confirmEmail"
          id="confirm-email"
          name="transmit-confirmEmail"
          class:error={errors.confirmEmail}
          class="form-text-primary"
          bind:value={values.confirmEmail}
          on:focus={() => (errors = omitObjProperty("confirmEmail", errors))}
        />
        {#if errors.confirmEmail}
          <span id="email-error" class="error-text"
            >{$_(errors.confirmEmail)}</span
          >
        {/if}
      </div>
      <div class="heading">
        <h2>{$_("VDS.ADD_PASSWORD_PROTECTION_RECOMMENDATION")}</h2>
      </div>
      <div class="password-protection">
        <div class="toggle-container">
          <label for="password-protection" class="mb-0"
            >{$_("VDS.PASSWORD_PROTECTION")}</label
          >
          <Toggle on:toggleButton={toggleButton} />
        </div>
        <input
          type="hidden"
          name="showPasswordProtection"
          bind:value={showPasswordProtection}
        />
        <div class="password-container">
          {#if showPasswordProtection}
            <h2>{$_("VDS.CREATE_A_PASSWORD")}</h2>
            <p class="suggestion-text">
              {$_("VDS.PASSWORD_SECURITY")}
            </p>
            <div class="fields pt-0">
              <label for="password">{$_("VDS.CREATE_PASSWORD")}</label>
              <input
                type="password"
                aria-label="password"
                id="password"
                name="transmit-password"
                autocomplete="off"
                class:error={errors.password}
                class="form-text-primary"
                bind:value={values.password}
                on:focus={() => (errors = omitObjProperty("password", errors))}
              />
              {#if errors.password}
                <span id="password-error" class="error-text"
                  >{$_(errors.password)}</span
                >
              {/if}
            </div>
            <span class="suggestion-text">
              {$_("VDS.REMEMBER_AND_SHARE_PASSWORD")}
            </span>
          {:else}
            <span class="suggestion-text">
              {$_("VDS.CAN_EXTEND_WITH_PASSWORD")}
            </span>
          {/if}
        </div>
      </div>
      {#if !isLoading}
        <div class="btn-container">
          <button
            id="share-health-records-btn"
            class="btn btn-primary {$isUserClinicianOrAdmin ? 'disabled' : ''}"
            disabled={$isUserClinicianOrAdmin}
            type="submit">{$_("VDS.SEND_HEALTH_RECORDS")}</button
          >
        </div>
        {#if showPasswordProtection}
          <p class="expire-in-days-text">
            {$_("VDS.THE_LINK_TO_DOWNLOAD_EXPIRE_IN_DAYS")}
          </p>
        {/if}
      {:else}
        <LoadingIndicator indicatorTitle={$_("COMMON.SENDING")} />
      {/if}
    </form>
  </section>
</section>

<style lang="scss" src="./index.scss"></style>
