import { languageTransformer, phoneTransformer } from "./transformers"
import applyResolvers from "@/utils/applyResolvers"
import { sortFieldToTop } from "@/utils/sortFieldToTop"
/* eslint-disable quotes */
/* eslint-disable no-useless-escape */

const singleAddressResolver = {
  path: [
    "line[0]",
    "line[1]",
    {
      join: ["city", "$, ", "state", "$ ", "postalCode"],
    },
  ],
}

/**
 * Creates name resolvers for at a name obj idx
 * @param {Number} nameIdx - index of the name object
 * @param {String} nameType - type of name (old or usual)
 * @returns {Array<Object>} - of resolved name resolvers for a name object idx
 */
const createNameResolvers = (nameIdx, nameType) => {
  if (nameType === "old") {
    return [
      {
        field: "Previous name",
        path: `name[${nameIdx}].text`,
      },
    ]
  } else {
    return [
      {
        field: "First name",
        path: `name[${nameIdx}].given[0]`,
      },
      {
        field: "Last name",
        path: `name[${nameIdx}].family`,
      },
      {
        field: "Middle name",
        path: `name[${nameIdx}].given[1]`,
      },
      {
        field: "Suffix",
        path: `name[${nameIdx}].suffix[0]`,
      },
    ]
  }
}

/**
 * Formats the name data based on the type and returns the resolved data
 * @param {Object} resource Name data
 * @returns {Array<Object>} - of resolved name data
 */
const resolveNameData = (data) => {
  if (!data?.name) return []

  const nameResolvers = data?.name
    .sort((a, b) => sortFieldToTop(a, b, "usual"))
    .flatMap((name, i) => {
      return createNameResolvers(i, name?.use)
    })
    // sort suffix to the bottom for UI display
    .sort((a, b) => {
      if (a.field === "Suffix") return 1
      if (b.field === "Suffix") return -1
      return b - a
    })

  // return in array to prevent applyResolvers from breaking up values
  return [applyResolvers(data, nameResolvers)]
}

/**
 * Formats the address data based on the type and returns the resolved data
 * @param {Object} resource Address data
 * @returns {Array<Object>} - of address resolved data
 */
const resolveAddressData = (data) => {
  return data.address
    .sort((a, b) => sortFieldToTop(a, b, "home"))
    .map((address) => {
      const addressResolver = [
        {
          ...singleAddressResolver,
          field:
            address.use === "home" ? "Current Address" : "Previous Address",
        },
      ]
      return applyResolvers(address, addressResolver)
    })
}

/**
 * Returns the value of ethnicity or race based on the available data.
 * @param {Object} resource  data
 * @returns {String} - ethnicity or race value
 */
const resolveEthnicityOrRace = (data, stringValue) => {
  const knownURL = `http://hl7.org/fhir/us/core/StructureDefinition/us-core-${stringValue}`
  if (!data || !data?.extension) return undefined

  const knownObject = data?.extension.filter(
    (value) => value.url === knownURL,
  )[0]

  const knownValue = knownObject?.extension[0]?.valueCoding?.display

  if (knownValue) {
    return knownValue
  } else {
    const unknownValue = knownObject?.extension[0]?.valueString
    return unknownValue
  }
}

const patientInfoResolvers = [
  {
    field: "ID",
    path: "id",
    hide: true,
  },
  {
    field: "Name",
    path: (data) => resolveNameData(data),
  },
  {
    field: "Birth sex",
    path: "gender",
  },
  {
    field: "Date of birth",
    path: "birthDate",
  },
  {
    field: "Race",
    path: (data) => resolveEthnicityOrRace(data, "race"),
  },
  {
    field: "Ethnicity",

    path: (data) => resolveEthnicityOrRace(data, "ethnicity"),
  },
  {
    field: "Preferred language",
    path: 'communication.|{ "preferred": true }|.language.text',
    transform: languageTransformer,
  },
  {
    field: "Addresses",
    path: (data) => resolveAddressData(data),
  },
  /*  {
    field: "Addresses",
    path: (data) => data.map((obj) => resolveAddressData(obj)),
  }, */

  {
    field: "Phone number",
    path: 'telecom.|{ "system": "phone" }|.value',
    groupWith: "Phone number type",
    transform: phoneTransformer,
  },
  {
    field: "Phone number type",
    path: 'telecom.|{ "system": "phone" }|.use',
  },
  {
    field: "Email address",
    path: 'telecom.|{ "system": "email" }|.value',
  },
]

export default patientInfoResolvers
