<script>
  import { onMount, onDestroy } from "svelte"
  import { _ } from "@/i18n"
  import { mobileToolbarConfig, notifications } from "@/stores/stores"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"
  import HealthRecordsHeader from "@/components/HealthRecordsHeader/HealthRecordsHeader.svelte"

  export let activeClassLabel = ""

  /**
   * Set the mobileToolbarConfig options for HR
   */
  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: true, text: $_("HR.HEALTH_RECORDS") },
      center: { logo: false },
      customClass: "blue-bkg-sm-down",
      redirectPath: "/health-records",
    })
  })

  /**
   * Reset the mobileToolbarConfig
   */
  onDestroy(() => {
    mobileToolbarConfig.set({})
  })

  const breadcrumbLinks = [
    {
      title: $_("HR.HEALTH_RECORDS"),
      link: "/health-records",
      id: "health-records",
    },
    {
      title: $_("HR.HEALTH_CONCERNS&DIAGNOSES"),
      active: true,
    },
  ]

  let navTabs = [
    {
      href: "/health-records/health-concerns",
      label: $_("HR.HEALTH_CONCERNS"),
      notificationCount: null,
    },
    {
      href: "/health-records/diagnoses",
      label: $_("HR.DIAGNOSES"),
      notificationCount: null,
    },
  ]
  $: if ($notifications?.data) {
    navTabs = [
      {
        ...navTabs[0],
        notificationCount: notifications.get("HEALTH_RECORD", "HEALTH_CONCERN")
          ?.counts?.[0]?.count,
      },
      {
        ...navTabs[1],
        notificationCount: notifications.get("HEALTH_RECORD", "DIAGNOSIS")
          ?.counts?.[0]?.count,
      },
    ]
  }
</script>

<section class="health-records-wrapper">
  <Breadcrumb {breadcrumbLinks} />
  <HealthRecordsHeader
    title={$_("HR.HEALTH_CONCERNS&DIAGNOSES")}
    {navTabs}
    {activeClassLabel}
  />
  <section class="health-records-container">
    <slot />
  </section>
</section>

<style lang="scss">
  @import "../index.scss";
</style>
