<script>
  import { onMount } from "svelte"
  import { isEmpty } from "lodash"
  import { goto } from "@roxi/routify"
  import AuthButtonGroup from "@/components/AuthButtonGroup/AuthButtonGroup.svelte"
  import PasswordInputWithToggle from "@/components/PasswordInputWithToggle/PasswordInputWithToggle.svelte"
  import {
    passwordSchema,
    createPasswordSchema,
    extractErrors,
  } from "@/utils/formValidations"
  import { _, locale } from "@/i18n"

  const values = {}
  let passwordErrors = {}
  let showPasswordErrors = false
  let confirmPasswordErrors = {}
  let showConfirmPasswordErrors = false

  const validatePassword = async (showErrors) => {
    try {
      showPasswordErrors = false
      await passwordSchema.validate(values, { abortEarly: false })
      passwordErrors = {}
    } catch (err) {
      passwordErrors = extractErrors(err)
      if (showErrors) showPasswordErrors = true
    }
  }

  const validateConfirmPassword = async (showErrors) => {
    try {
      await createPasswordSchema.validate(values, { abortEarly: false })
      confirmPasswordErrors = {}
    } catch (err) {
      confirmPasswordErrors = extractErrors(err)
      if (showErrors) showConfirmPasswordErrors = true
    }
  }

  const preventInputBlurEvents = (evt) => {
    evt.preventDefault()
  }

  const handleCreatePassword = async () => {
    await validatePassword(true)
    await validateConfirmPassword(true)

    if (isEmpty(confirmPasswordErrors) && isEmpty(passwordErrors)) {
      // TODO: form submission handling
      $goto("/auth/login")
    }
  }

  const handleInput = (e, id) => {
    values[id] = e.target.value

    if (id === "password") validatePassword()
  }

  onMount(() => {
    // TODO: Validate token before entering the page.
    /**
     * Validate reset password token <- MOCK ONLY
     */
    const validateResetPasswordToken = (token) => {
      // Set token in localstorage
      if (token.includes("expire")) {
        localStorage.setItem("tokenValid", false)
        $goto("/auth/login")
      } else {
        localStorage.setItem("tokenValid", true)
      }
    }

    const params = new URLSearchParams(window.location.search)

    if (params.get("reset_password_token")) {
      const resetPasswordToken = params.get("reset_password_token")
      validateResetPasswordToken(resetPasswordToken)
    } else {
      $goto("/auth/login")
    }
  })
</script>

<section class="auth-container">
  <AuthButtonGroup
    breadcrumbBtn={{ text: $_("AUTH.SIGN_IN"), href: "/auth/login" }}
  />
  <div class="heading">
    <h1>{$_("AUTH.CREATE_NEW_PASSWORD")}</h1>
  </div>
  <div class="sub-heading">
    <p>
      {$_("AUTH.CREATE_PASSWORD_DESCRIPTION")}
    </p>
  </div>
  <section class="login-form">
    <form on:submit|preventDefault={handleCreatePassword} class="auth-form">
      <div class="fields fields--with-requirements">
        <label for="password">{$_("AUTH.NEW_PASSWORD")}</label>
        <PasswordInputWithToggle
          hasRequirements
          {passwordErrors}
          value={values.password}
          error={showPasswordErrors && !isEmpty(passwordErrors)}
          iconOnly={$locale === "es"}
          on:blur={() => validatePassword(true)}
          on:input={(e) => handleInput(e, "password")}
        />
      </div>

      <div class="fields">
        <label for="confirmPassword">{$_("AUTH.CONFIRM_NEW_PASSWORD")}</label>
        <PasswordInputWithToggle
          id="confirmPassword"
          name="confirmPassword"
          ariaLabel="confirmPassword"
          error={showConfirmPasswordErrors && !isEmpty(confirmPasswordErrors)}
          iconOnly={$locale === "es"}
          on:input={(e) => handleInput(e, "confirmPassword")}
          on:blur={() => {
            validatePassword(true)
            validateConfirmPassword(true)
          }}
        />

        {#if confirmPasswordErrors.confirmPassword}
          <span id="confirm-password-error" class="error-text"
            >{$_(confirmPasswordErrors.confirmPassword)}</span
          >
        {/if}
      </div>
      <div class="btn-div">
        <button
          on:mousedown={preventInputBlurEvents}
          type="button"
          id="create-password-submit"
          class="btn btn-primary"
          on:click|preventDefault={() => handleCreatePassword()}
          >{$_("AUTH.RESET_PASSWORD")}</button
        >
      </div>
    </form>
  </section>
  <div class="hr" />
  <div class="contactus-text">
    <p>
      {@html $_("AUTH.ADDITIONAL_HELP")}
    </p>
  </div>
</section>

<style src="./index.scss"></style>
