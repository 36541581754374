<script>
  import { orderBy } from "lodash"
  import { allergyInfo } from "@/stores/healthRecordStores"
  import { notifications, viewedNotifications } from "@/stores/stores"
  import { _ } from "@/i18n"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"
  import HealthRecordsHeader from "@/components/HealthRecordsHeader/HealthRecordsHeader.svelte"
  import { clientSidePagination } from "@/utils/clientSidePagination.js"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"
  import NotificationDot from "@/components/Notifications/NotificationDot.svelte"

  let isLoading = true
  let results = []
  let resultNotifications = []
  let visibleRowCount = 25
  const updateRowCount = (chunkSize) => {
    visibleRowCount += chunkSize
  }
  const breadcrumbLinks = [
    {
      title: $_("HR.HEALTH_RECORDS"),
      link: "/health-records",
      id: "health-records",
    },
    {
      title: $_("HR.ALLERGIES"),
      active: true,
    },
  ]
  /**
   * This function will format the resolver response and sort by allergen name alphabetically
   * @param {Array} resolverResponse response received from resolver
   */
  const formatAndSortAllergyData = (resolverResponse) => {
    const formattedData = []
    resolverResponse.forEach(({ value }) => {
      const { fields = [] } = value || {}
      formattedData.push(...fields)
    })
    results = orderBy(formattedData, ["title"], ["asc"])
  }

  $: if ($allergyInfo) {
    if ($allergyInfo.length > 0) {
      formatAndSortAllergyData($allergyInfo)
    }
    isLoading = false
  }

  $: if ($notifications?.data) {
    resultNotifications =
      notifications.get("HEALTH_RECORD", "ALLERGY")?.notifications || []
  }

  $: if (resultNotifications.length && results.length) {
    const notificationQueue = new Set() // set to avoid duplicates
    results = results.map(({ title, id, fields }) => {
      const notificationId = resultNotifications.find(
        ({ referenceId }) => referenceId === id,
      )?.id
      if (notificationId && !$viewedNotifications.includes(notificationId))
        notificationQueue.add(notificationId)
      return {
        fields,
        notificationId,
        title,
      }
    })
    if (notificationQueue.size) {
      viewedNotifications.add([...notificationQueue])
    }
  }
</script>

<section class="health-records-wrapper">
  <Breadcrumb {breadcrumbLinks} />
  <HealthRecordsHeader title={$_("HR.ALLERGIES")} />
  <section class="health-records-container">
    {#if isLoading}
      <div class="spinner-wrapper">
        <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
      </div>
    {:else if results.length > 0}
      <div
        data-testid="allergies"
        use:clientSidePagination={{
          totalRows: results.length,
          updater: updateRowCount,
          listId: "allergies",
        }}
      >
        <div id="allergies">
          {#each results.slice(0, visibleRowCount) as { title, fields, notificationId }}
            <article class="card">
              <section class="card-header">
                <header>
                  <h3
                    class="title mb-0"
                    class:has-notification={notificationId}
                  >
                    {#if notificationId}
                      <NotificationDot />
                    {/if}
                    {title}
                  </h3>
                </header>
              </section>
              <section class="card-body">
                <ul class="card-text">
                  {#each fields as { field, value }}
                    {#if field !== "Allergy" && field !== "provenance" && value}
                      <li>
                        <div class="label">
                          {field}:
                        </div>
                        <div class="value">{value}</div>
                      </li>
                    {/if}
                  {/each}
                </ul>
              </section>
            </article>
          {/each}
        </div>
      </div>
    {:else}
      <div class="no-record">
        {$_("HR.NO_RECORD_FOUND_FOR_ALLERGIES")}
      </div>
    {/if}
  </section>
</section>

<style lang="scss">
  @import "../index.scss";
  :global(.card-header .title .dot) {
    margin-right: 2px !important;
  }
</style>
