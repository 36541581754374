/**
 * Validate the form by iterating each form elements and return the true if form is valid
 * @param {String} formClassName className of HTML element 
 * @returns {Boolean} true if form is valid and false if form is invalid
 */
export const validateForm = (formClassName) => {
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  const forms = document.getElementsByClassName(formClassName)

  // Loop over them and prevent submission
  const validationStatus = Array.prototype.filter.call(forms, (form) => {
    form.classList.add("was-validated")
    if (form.checkValidity() === false) {
      return false
    }      
    return true      
  })

  return validationStatus
}
