<script>
  import { onMount } from "svelte"
  import { goto } from "@roxi/routify"
  import { _ } from "@/i18n"
  import {
    passwordResetSchemaWithEmail,
    extractErrors,
  } from "@/utils/formValidations"
  import omitObjProperty from "@/utils/omitObjProperty"
  import AuthButtonGroup from "@/components/AuthButtonGroup/AuthButtonGroup.svelte"

  const values = {}
  let errors = {}

  const breadcrumbBtn = {
    text: $_("AUTH.BACK"),
    href: "https://login.navigatingcare.com/patient",
    callback: null,
  }
  const handlePasswordReset = async () => {
    try {
      await passwordResetSchemaWithEmail.validate(values, { abortEarly: false })
      errors = {}
      $goto(`/auth/check-email/?email=${values.email}`)
    } catch (err) {
      errors = extractErrors(err)
    }
  }

  onMount(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.has("email")) {
      values.email = params.get("email")
    }
  })
</script>

<section class="auth-container">
  <AuthButtonGroup {breadcrumbBtn} />
  <header class="heading">
    <h1 class="title">{$_("AUTH.RESET_YOUR_PASSWORD")}</h1>
  </header>
  <div class="sub-heading">
    <p>
      {$_("AUTH.PASSWORD_RESET_DESCRIPTION")}
    </p>
  </div>
  <form on:submit|preventDefault={handlePasswordReset} class="auth-form">
    <div class="fields">
      <label for="email">{$_("AUTH.EMAIL")}</label>
      <input
        type="text"
        aria-label="email"
        id="email"
        name="email"
        class="form-text-primary"
        class:error={errors.email}
        bind:value={values.email}
        on:focus={() => (errors = omitObjProperty("email", errors))}
      />
      {#if errors.email}
        <span id="email-error" class="error-text">{$_(errors.email)}</span>
      {/if}
    </div>
    <div class="btn-div">
      <button
        type="button"
        id="password-reset"
        class="btn btn-primary"
        on:click={() => handlePasswordReset()}
        >{$_("AUTH.SEND_PASSWORD_RESET_LINK")}</button
      >
    </div>
  </form>
  <div class="hr" />
  <div class="contactus-text">
    <p>
      {@html $_("AUTH.HAVING_TROUBLE_IN_SIGNING_IN")}
    </p>
  </div>
</section>

<style type="text/scss">
  @import "../index.scss";
</style>
