import { returnFormattedDate } from "@/utils/dateTime"

/**
 * Check If array has data or not.
 * @param {Object} medicationProperties Object of medication properties
 * @return {Boolean} true if medication properties are exist else false after omit the fields
 */
export const hasData = (medicationProperties) => {
  if (Object.entries(medicationProperties || [])?.length <= 0) return false

  let hasPropertyValue = false

  const deepFilterOfObjectProperties = (data) => {
    const keys = Object.keys(data)

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < keys.length; index++) {
      // Get each element by key.
      const element = data[keys[index]]

      // If one of properties has value. Then, It should be true.
      // Here, except the specific case such like property value can be 0 (It's value)
      // Ex: dose: [0, undefined]
      if (typeof element === "string" || typeof element === "number") {
        if (element || element === 0) {
          hasPropertyValue = true
          break
        }
      }

      if (Array.isArray(element) || (typeof element === "object" && element)) {
        deepFilterOfObjectProperties(element)
      }
    }
  }

  deepFilterOfObjectProperties(medicationProperties)

  return hasPropertyValue
}

/**
 * Formats the started date of the given records
 */
export const formatRecordsDate = (record) => {
  return {
    ...record,
    value: {
      ...record.value,
      started: returnFormattedDate(record.value.started),
    },
  }
}
