import { get, hasIn } from "lodash"
import applyResolvers from "@/utils/applyResolvers"
import createProvenanceResolver from "./provenanceResolvers"
import { isNotFutureDate } from "@/utils/dateTime"

// used to filter out entries that have no medication name and can't be displayed in UI
const hasMedicationName = (entryObj) =>
  hasIn(entryObj, "resource.medicationCodeableConcept.text")

const isPatientAdministered = (patientObj) => {
  const subject = get(patientObj, "resource.subject.reference")
  const actor = get(patientObj, "resource.performer[0].actor.reference")
  return subject === actor
}

const medicationInfoResolvers = [
  {
    field: "Medications",
    path: (data) =>
      data?.entry
        ?.filter(hasMedicationName)
        .filter((entry) => isNotFutureDate(entry?.resource?.effectiveDateTime))
        .map((obj) => ({
          medicationName: get(obj, "resource.medicationCodeableConcept.text"),
          dosage: [
            get(obj, "resource.dosage.rateQuantity.value"),
            get(obj, "resource.dosage.rateQuantity.unit"),
          ],
          frequency: get(obj, "resource.dosage.text"),
          dose: [
            get(obj, "resource.dosage.dose.value"),
            get(obj, "resource.dosage.dose.unit"),
          ],
          isPatientAdministered: isPatientAdministered(obj),
          route: get(obj, "resource.dosage.route.coding[0].display"),
          status: get(obj, "resource.status"),
          started: get(obj, "resource.effectiveDateTime"),
          "Added to EHR": applyResolvers(
            obj,
            createProvenanceResolver(
              "resource.",
              "Added to EHR",
              "M/D/YYYY",
              ", hh:mma z",
            ),
          )[0]?.value,
          id: get(obj, "resource.id"),
        })),
  },
]

export default medicationInfoResolvers
