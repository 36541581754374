<script>
  /** eslint-disable quotes */
  import { onMount } from "svelte"
  import DOMPurify from "dompurify"
  import SimpleModal from "@/components/Modal/SimpleModal.svelte"
  import FullModal from "@/components/Modal/FullModal.svelte"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"

  export let loincCode
  export let closeModalsCallback

  let modalState = "loading"
  let content = ""
  let title = ""

  onMount(() => {
    fetch(
      `https://connect.medlineplus.gov/service?mainSearchCriteria.v.cs=2.16.840.1.113883.6.1&mainSearchCriteria.v.c=${loincCode}&knowledgeResponseType=application/json`,
      {
        method: "GET",
      },
    )
      // eslint-disable-next-line no-unused-vars
      .then((data) => data.json())
      // eslint-disable-next-line consistent-return
      .then((data) => {
        const entry = data?.feed?.entry?.[0]
        if (!entry) {
          return (modalState = "unavailable")
        }
        // eslint-disable-next-line no-underscore-dangle
        content = entry?.summary?._value
        // eslint-disable-next-line no-underscore-dangle
        title = entry?.title?._value
        modalState = "success"
      })
      .catch(() => {
        return (modalState = "unavailable")
      })
  })
</script>

{#if modalState === "loading"}
  <SimpleModal on:close={closeModalsCallback}>
    <div slot="content" class="content">
      <div class="spin text-center">
        <LoadingIndicator />
      </div>
      <p class="title text-center">Please wait ...</p>
      <p class="description text-center">
        Loading information from the National Institute of Health
      </p>
    </div>
  </SimpleModal>
{/if}
{#if modalState === "unavailable"}
  <SimpleModal on:close={closeModalsCallback}>
    <div slot="content" class="content ">
      <p class="title text-center">Information unavailable</p>
      <p class="description text-center">
        We're not able to find information for this test. For trustworthy health
        information, try searching the National Institute of Health database on <a
          class="medline-link"
          href="https://medlineplus.gov/lab-tests/">Medline Plus</a
        >.
      </p>
    </div>
  </SimpleModal>
{/if}
{#if modalState === "success"}
  <div class="success-modal">
    <FullModal on:close={closeModalsCallback}>
      <div slot="content" class="content">
        <h2 class="component-title">{title}</h2>
        <div class="medline-content">
          {@html DOMPurify.sanitize(content)}
        </div>
      </div>
    </FullModal>
  </div>
{/if}

<style type="scss">
  @import "../../../../../css/sass-imports.scss";

  .content {
    p {
      margin-bottom: 0;
      text-align: left;
    }
    .spin {
      margin-bottom: 32px;
      display: flex;
      justify-content: center;
    }
    :global(.medline-content h2) {
      @include heading;
    }
    :global(.medline-content) {
      word-wrap: break-word;
    }
    .medline-link {
      @include body-inline-link;
    }
    .title {
      @include subheading;
      margin-bottom: 6px;
    }
    .component-title {
      @include section-title;
      margin-bottom: 24px;
    }
    .description {
      @include body-regular;
      color: $text-secondary;
    }
  }
</style>
