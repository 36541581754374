<script>
  import { createEventDispatcher } from "svelte"
  import { _ } from "@/i18n"
  import { handleKeyboardEvents } from "@/utils/keyboardNavigation"
  import ThreadCard from "../ThreadCard/ThreadCard.svelte"

  export let unreadThreads = []
  export let readThreads = []

  // Declare the dispatch
  const dispatch = createEventDispatcher()

  /**
   * Dispatch event with thread details to navigate to the thread detail page
   * @param {Object} thread
   */
  const navigateToThreadDetail = (thread) => {
    dispatch("navigateToThreadDetail", thread)
  }
</script>

{#if unreadThreads.length > 0}
  <div class="unread-messages">
    {#if readThreads.length > 0}
      <h2 class="messages-group-label">
        {$_("SM.UNREAD")} ({unreadThreads.length})
      </h2>
    {/if}
    {#each unreadThreads as thread}
      <div
        use:handleKeyboardEvents
        on:keyboardSelect={() => navigateToThreadDetail(thread)}
        on:click={navigateToThreadDetail(thread)}
        class="message-card clickable-card"
      >
        <ThreadCard messageThread={thread} type="unread" />
      </div>
    {/each}
  </div>
{/if}

{#if readThreads.length > 0}
  <div class="read-messages">
    {#if unreadThreads.length > 0}
      <h2 class="messages-group-label">
        {$_("SM.READ")} ({readThreads.length})
      </h2>
    {/if}

    {#each readThreads as thread}
      <div
        use:handleKeyboardEvents
        on:keyboardSelect={() => navigateToThreadDetail(thread)}
        on:click={navigateToThreadDetail(thread)}
        class="message-card clickable-card"
      >
        <ThreadCard messageThread={thread} type="read" />
      </div>
    {/each}
  </div>
{/if}

<style lang="scss" src="./Inbox.scss">
</style>
