<script>
  import { Subscribe, Render } from "svelte-headless-table"

  /**
   * The modelView object created by the svelte-headless-table createTable function.
   * @type {object}
   */
  export let modelView
  /**
   * Object specifying the maximum width override for individual columns.
   * The key represents the id/accessor of the column, and the value represents the max width as a number.
   * @type {object}
   */
  export let columnMaxWidthOverride = {}

  const defaultMaxWidth = 175

  $: ({ headerRows, rows, tableAttrs, tableBodyAttrs, pluginStates } =
    modelView)
</script>

<div class="table-wrapper">
  <div class="scrollable">
    <table {...$tableAttrs} tabindex="0">
      <thead>
        {#each $headerRows as headerRow (headerRow.id)}
          <Subscribe rowAttrs={headerRow.attrs()} let:rowAttrs>
            <tr {...rowAttrs}>
              {#each headerRow.cells as cell, i (cell.id)}
                <Subscribe
                  attrs={cell.attrs()}
                  let:attrs
                  props={cell.props()}
                  let:props
                >
                  <th
                    {...attrs}
                    style="max-width: {columnMaxWidthOverride?.[cell.id]
                      ? `${columnMaxWidthOverride?.[cell.id]}px`
                      : `${defaultMaxWidth}px`}"
                  >
                    <Render of={cell.render()} />
                  </th>
                </Subscribe>
              {/each}
            </tr>
          </Subscribe>
        {/each}
      </thead>
      <tbody {...$tableBodyAttrs}>
        {#each $rows as row, i (row.id)}
          <Subscribe
            rowAttrs={row.attrs()}
            let:rowAttrs
            rowProps={row.props()}
            let:rowProps
          >
            <tr>
              {#each row.cells as cell, j (cell.id)}
                <Subscribe
                  attrs={cell.attrs()}
                  let:attrs
                  props={cell.props()}
                  let:props
                >
                  <td
                    {...attrs}
                    style="max-width: {columnMaxWidthOverride?.[cell.id]
                      ? `${columnMaxWidthOverride?.[cell.id]}px`
                      : `${defaultMaxWidth}px`}"
                  >
                    <Render of={cell.render()} />
                  </td>
                </Subscribe>
              {/each}
            </tr>
          </Subscribe>
        {/each}
      </tbody>
    </table>
  </div>
</div>

<style lang="scss">
  .table-wrapper {
    :global(p) {
      margin-bottom: 0;
    }
    display: flex;
    justify-content: center;
  }

  .scrollable {
    border-radius: 8px;
    overflow-x: auto;
    display: inline-block;
    max-width: 100%;
    box-sizing: content-box;
  }

  table {
    background-color: #ffffff;
    border-bottom: 0;
    border-collapse: separate;
    border-spacing: 0;
    width: max-content;
    table-layout: fixed;
    white-space: nowrap;
    display: inline-block;
    border: 1px solid #dddddd;
    border-radius: 8px;
  }

  th,
  td {
    margin: 0;
    border-color: #dddddd;
    border-style: solid;
    border-width: 1px;
    text-align: left;
    padding: 12px;
    vertical-align: middle;
    max-width: 175px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: #242627;
  }

  th {
    background-color: #f0f2f5;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    border-right: 3px solid #dddddd;
  }

  tr {
    height: auto;
  }

  tr > * {
    white-space: normal;
    height: auto;
    min-height: 40px; /* Adjust as needed */
  }

  tr td {
    background-color: #ffffff;
  }
</style>
