<script>
  import { isArray } from "lodash"
  import { convertDateTime } from "@/utils/dateTime"

  export let messageThread
  export let type

  let recentMessage
  let numMessages
  let authorDisplay
  $: {
    if (isArray(messageThread?.messages)) {
      // eslint-disable-next-line prefer-destructuring
      recentMessage = messageThread.messages[0]
      numMessages = messageThread.message_count || 0

      const { author } = recentMessage
      authorDisplay = author.on_behalf_of || author.name || ""
    }
  }
</script>

<article tabindex="0" class="card {type}">
  <div class="row ml-0 mr-0">
    <div class="col-11 col-md-11 pr-8">
      <section class="card-header pt-0 pb-0">
        <div class="card-first-row">
          {#if type === "unread"}
            <div class="card-unread-icon" />
          {/if}
          <header>
            <h3 class="card-title mb-0">
              {authorDisplay}
              {#if numMessages > 1}
                <span class="total-messages">
                  {numMessages}
                </span>
              {/if}
            </h3>
          </header>
        </div>
        <div class="d-flex">
          <time class="card-subtitle message-timestamp mt-0 mb-0">
            {recentMessage ? convertDateTime(recentMessage.created_at) : ""}
          </time>
          {#if messageThread.has_attachments}
            <span class="paperclip" id="paperclip">
              <i class="far fa-paperclip" aria-hidden="true" />
            </span>
          {/if}
        </div>
      </section>
      <section class="card-body pt-0 pb-0">
        <h4 class="card-title mb-0 message-subject">
          {messageThread.subject}
        </h4>
        <p class="card-text">
          {recentMessage ? recentMessage.body.replace(/(<([^>]+)>)/gi, "") : ""}
        </p>
      </section>
    </div>
    <div class="col-1 col-md-1 right-arrow">
      <i class="fas fa-chevron-right" aria-hidden="true" />
    </div>
  </div>
</article>

<style lang="scss" src="./ThreadCard.scss">
</style>
