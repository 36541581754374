<script>
  import { onDestroy, onMount } from "svelte"
  import DOMPurify from "dompurify"
  import { bodyClassName } from "@/stores/stores"
  import { _ } from "@/i18n"
  import Step from "@/components/StepIndicator/StepIndicator.svelte"
  import CurvedBanner from "@/components/CurvedBanner/CurvedBanner.svelte"

  const formStepIndicatorDots = ["complete", "complete", "active"]
  const isPersonalRepresentative = sessionStorage.getItem(
    "IS_PERSONAL_REPRESENTATIVE",
  )
  let viewPortWidth
  /**
   * TODO: this is temporary until we have a way to get the clinic name value
   */
  const clinicName = "Clinic Name"

  /**
   * Set white bodyClassName for xs-down view ports
   */
  onMount(() => {
    bodyClassName.add("white-bkg-xs-down")
  })

  /**
   * Reset the bodyClassName
   */
  onDestroy(() => {
    bodyClassName.remove("white-bkg-xs-down")
  })

  // $: errorsValueArr = Object.values(errors)
</script>

<svelte:window bind:innerWidth={viewPortWidth} />

<div class="registration">
  <CurvedBanner
    config={{
      title: $_("REGISTRATION.CONSENT.TITLE", { values: { clinicName } }),
      iconSrc: "../../assets/svg/informed-no-bg.svg",
      bannerColor: "#0E446B",
      circleBorderColor: "#91DD44",
      disableAuthButtonGroup: true,
    }}
  />
  <div class="form-section-indicator">
    {#each formStepIndicatorDots as variant}
      <Step {variant} />
    {/each}
  </div>
  <section class="auth-container">
    <p class="mb-0">
      {$_("REGISTRATION.CONSENT.SECTION_1", { values: { clinicName } })}
    </p>
    {@html DOMPurify.sanitize($_("REGISTRATION.CONSENT.RESOURCE_BULLETS"))}
    {@html DOMPurify.sanitize($_("REGISTRATION.CONSENT.SECTION_2"))}
    <div class="consent-scroller" tabindex="0">
      {@html DOMPurify.sanitize(
        $_("REGISTRATION.CONSENT.CONSENT_DESCRIPTION", {
          values: { clinicName },
        }),
      )}
    </div>
    <div class="btn-div">
      {#if viewPortWidth < 768}
        <button class="btn btn-primary consent-btn"
          >{isPersonalRepresentative === "true"
            ? $_("REGISTRATION.CONSENT.REP_CONSENT_BTN_AGREE")
            : $_("REGISTRATION.CONSENT.CONSENT_BTN_AGREE")}</button
        >
        <button class="btn btn-tertiary decline-btn"
          >{isPersonalRepresentative === "true"
            ? $_("REGISTRATION.CONSENT.REP_CONSENT_BTN_DECLINE")
            : $_("REGISTRATION.CONSENT.CONSENT_BTN_DECLINE")}</button
        >
      {:else}
        <button class="btn btn-tertiary decline-btn"
          >{isPersonalRepresentative === "true"
            ? $_("REGISTRATION.CONSENT.REP_CONSENT_BTN_DECLINE")
            : $_("REGISTRATION.CONSENT.CONSENT_BTN_DECLINE")}</button
        >
        <button class="btn btn-primary consent-btn"
          >{isPersonalRepresentative === "true"
            ? $_("REGISTRATION.CONSENT.REP_CONSENT_BTN_AGREE")
            : $_("REGISTRATION.CONSENT.CONSENT_BTN_AGREE")}</button
        >
      {/if}
    </div>
  </section>
</div>

<style src="./index.scss">
</style>
