import { startsWith } from "lodash"
import { callAPI } from "@/utils/httpService"

// supports local json files beginning with "../"
export const getData = async (url, throwError = false) => {
  if (startsWith(url, "../")) {
    const res = await fetch(url.replace("..", window.location.origin))
    if (!res.ok) throw new Error(res.statusText)
    return res.json()
  } else {
    const res = await callAPI({ url, method: "GET", throwError })
    return res
  }
}
