<script>
  import { isOutOfRange } from "@/utils/isOutOfRange"

  export let data
  export let componentIdx
  export let testId
  const {
    result,
    referenceRange,
    interpretationConcept,
    valueNumber,
    valueUnit,
    referenceRangeUnit,
  } = data

  const maxResultChars = 250
  const isLong = result?.length >= maxResultChars

  let resultText

  if (!result) {
    resultText = "No data"
  } else if (isLong) {
    resultText = `${result.substring(0, maxResultChars)}...`
  } else {
    resultText = result
  }

  $: isHighlighted = isOutOfRange({
    resultText,
    referenceRange,
    interpretationConcept,
    valueUnit,
    valueNumber,
    referenceRangeUnit,
  })
</script>

<p class="results indicator" class:highlighted={isHighlighted}>
  {resultText}
</p>
{#if isLong}
  <div class="link-text-content">
    <a
      class="link-text"
      href="/health-records/test-results/{testId}/detail/{componentIdx}"
      >See full result</a
    >
  </div>
{/if}

<style type="scss">
  @import "../../../../../../css/sass-imports.scss";

  .indicator {
    display: inline;
    &.highlighted {
      border-radius: 20px;
      padding: 2px 8px;
      background-color: $severity-mild;
    }
  }
  .link-text-content {
    font-weight: 600;
    padding-top: 10px;
  }
</style>
