<script>
  import { isArray } from "lodash"

  export let medicationProperties

  /**
   * Filter falsy values from array and check if value exist in array or not undefined
   * @param {Array} value array of values
   * @returns {Boolean} return true if value is not undefined or array is not empty after filter falsy values from array
   */
  const validateEmptyValue = (value) => {
    // Except the case If value is 0.
    return isArray(value) ? !!value.filter((v) => Boolean(v) || v === 0).length : !!(value || value === 0)
  }
</script>

<section class="card-body">
  <ul>
    {#each Object.entries(medicationProperties) as [field, value]}
      {#if validateEmptyValue(value)}
        <li>
          <div class="label">
            {field}:
          </div>
          <div class="value">
            {isArray(value) ? value.join(" ") : value}
          </div>
        </li>
      {/if}
    {/each}
  </ul>
</section>

<style lang="scss">
  .card-body li:last-child {
    margin-bottom: 0;
  }

  .label {
    text-transform: capitalize;
  }
</style>
