/**
 * Stringifies an object and sets it to localstorage
 * @param {String} key - for localstorage
 * @param {Object} value - for localstorage
 * @returns {Undefined}
 */
export const serializeObjectToStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

/**
 * Gets a JSON item from local storage and returns a parsed object
 * @param {String} key for the local storage value
 * @returns {Object}
 */
export const getStoredJsonAsObject = (key) => {
  const value = localStorage.getItem(key)
  return value && JSON.parse(value)
}
