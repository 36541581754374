<script>
  /**
   * @type {string} words before timestamp
   */
  export let prefix

  /**
   * @type {string} - formatted timestamp
   */
  export let timestamp
  /**
   * @type {?string} - entity that triggered timestamp 
   */
  export let by = ""
</script>

<p>
  {`${prefix} ${timestamp} ${by ? `by ${by}` : ""}`}
</p>
