export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date()
  let expires = ""
  if (exdays) {
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    expires = `expires=${d.toUTCString()}`
  }

  document.cookie = `${cname}=${cvalue};${expires};path=/`
}

export const deleteCookie = (cname) => {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"`
}

export const getCookie = (cname) => {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(";")
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}
