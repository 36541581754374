<script>
  import { startsWith } from "lodash"
  import { page } from "@roxi/routify"
  import AppLayout from "@/layouts/AppLayout.svelte"
  import AuthLayout from "@/layouts/AuthLayout.svelte"

  let currentLayout = "default"
  function switchLayout(node) {
    if (startsWith(node.parent.path, "/auth")) {
      currentLayout = "auth"
    } else {
      currentLayout = "default"
    }
  }

  $: switchLayout($page)
</script>

{#if currentLayout === "auth"}
  <AuthLayout>
    <slot />
  </AuthLayout>
{:else}
  <AppLayout>
    <slot />
  </AppLayout>
{/if}
