<script>
  /** eslint-disable prefer-const */
  import Alert from "../Alert.svelte"
  import { globalAlertConfig } from "@/stores/stores.js"

  let isRendered = false

  $: if ($globalAlertConfig) {
    isRendered = true
  } else {
    isRendered = false
  }

  const dismissAlert = () => {
    globalAlertConfig.set(null)
  }
</script>

{#if isRendered}
  <!-- the component needs to be conditionally rendered depending on existence of slot content since the slot itself can't be wrapped in a conditional -->
  {#if $$slots.message}
    <Alert global on:dismissAlert={dismissAlert} config={$globalAlertConfig}>
      <!-- passes slot content down to the alert component -->
      <span slot="message">
        <slot name="message" />
      </span>
    </Alert>
  {:else}
    <Alert global on:dismissAlert={dismissAlert} config={$globalAlertConfig} />
  {/if}
{/if}

<style lang="scss" src="../Alert.scss"></style>
