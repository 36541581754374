<script>
  import { createEventDispatcher } from "svelte"
  import { merge } from "lodash"
  import { DateRangePicker } from "vanillajs-datepicker"
  import { chevronRightSVGString } from "../chevronRightSVGString"
  import "@/../node_modules/vanillajs-datepicker/dist/css/datepicker-bs4.min.css"

  /**
   * @type {Arr<String>} selectedDateRange - string format of mm/dd/yyyy
   */
  export let selectedDateRange
  /**
   * @type {Object} datePickerOptions - Overrides for default datepicker options with the exception of orientation which is handled separately
   */
  export let datePickerOptions = {}
  export let includeTodayBtn = true
  export let orientation = "bottom" // bottom or top
  export let startInputLabel = "Start date"
  export let endInputLabel = "End date"
  export let nextBtnAltText = "Next"
  export let prevBtnAltText = "Previous"

  const defaultDatePickerOptions = {
    todayBtnMode: 1,
    clear: true,
    todayHighlight: true,
    autoclose: true,
    toggleActive: true,
    maxDate: new Date(),
    buttonClass: "btn",
    todayBtn: includeTodayBtn,
    nextArrow: `${chevronRightSVGString} <span class="sr-only">${nextBtnAltText}</span>`,
    prevArrow: `${chevronRightSVGString} <span class="sr-only">${prevBtnAltText}</span>`,
  }

  const dispatch = createEventDispatcher()

  function initDateRangePicker(node, dates) {
    const dateRangePicker = new DateRangePicker(
      node,
      merge(defaultDatePickerOptions, datePickerOptions),
    )

    // set orientation for the individual datepickers within range
    dateRangePicker.datepickers.forEach((picker, i) => {
      if (i === 0) {
        picker.setOptions({
          orientation: `${orientation} left`,
        })
      } else {
        picker.setOptions({
          orientation: `${orientation} right`,
        })
      }
    })

    if (dates) {
      dateRangePicker.setDates(...dates)
    }

    return {
      update(newDates) {
        dateRangePicker.setDates(...newDates)
      },
      destroy() {
        dateRangePicker.destroy()
      },
    }
  }
</script>

<div
  use:initDateRangePicker={selectedDateRange}
  class="input-daterange"
  id="datepicker"
>
  <div class="input-wrapper">
    <label for="startDate">{startInputLabel}</label>
    <div class="svg-placement">
      <input
        type="text"
        class="input-sm "
        name="start"
        id="startDate"
        placeholder="mm/dd/yyyy"
        on:click
        on:blur
        on:changeDate={(e) => {
          dispatch(
            "updateDateRange",
            e.detail.datepicker.rangepicker.getDates("mm/dd/yyyy"),
          )
        }}
      />
    </div>
  </div>
  <div class="input-wrapper">
    <label for="endDate">{endInputLabel}</label>
    <div class="svg-placement">
      <input
        on:click
        on:blur
        on:changeDate={(e) => {
          dispatch(
            "updateDateRange",
            e.detail.datepicker.rangepicker.getDates("mm/dd/yyyy"),
          )
        }}
        placeholder="mm/dd/yyyy"
        type="text"
        class="input-sm "
        name="end"
        id="endDate"
      />
    </div>
  </div>
</div>

<style lang="scss" src="./DateRangePicker.scss"></style>
