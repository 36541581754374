<script>
  /** eslint-disable prefer-const */
  import Alert from "../Alert.svelte"
  import { sectionalAlertConfig } from "@/stores/stores.js"

  export let key

  let isRendered = false

  $: isRendered =
    $sectionalAlertConfig[key] && $sectionalAlertConfig[key] !== "dismissed"

  const dismissAlert = () => {
    if ($sectionalAlertConfig[key]?.onDismiss) {
      $sectionalAlertConfig[key].onDismiss(key)
    }
    // differentiate undefined (not set yet) and dismissed alerts
    $sectionalAlertConfig[key] = "dismissed"
  }
</script>

{#if isRendered}
  <!-- the component needs to be conditionally rendered depending on existence of slot content since the slot itself can't be wrapped in a conditional -->
  {#if $$slots.message}
    <Alert on:dismissAlert={dismissAlert} config={$sectionalAlertConfig[key]}>
      <!-- passes slot content down to the alert component -->
      <span slot="message">
        <slot name="message" />
      </span>
    </Alert>
  {:else}
    <Alert on:dismissAlert={dismissAlert} config={$sectionalAlertConfig[key]} />
  {/if}
{/if}

<style lang="scss" src="../Alert.scss"></style>
