<script>
  import { route } from "@roxi/routify"
  import { reject, map, get, uniqBy } from "lodash"
  import { _ } from "@/i18n"
  import { convertDateTime } from "@/utils/dateTime"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"

  export let threadDetails
  export let accountIdContext

  const gobackUrl =
    $route && $route.last && $route.last.shortPath
      ? $route.last.shortPath
      : "/messages/inbox"

  /**
   * Find the thread author details from the thread
   * @param {Object} details thread details
   * @returns {String} thread author name and on behalf of details if exists
   */
  const getThreadAuthor = (details) => {
    if (!details.messages) {
      return ""
    }

    if (details.participants.length === 2) {
      // Fetch uniq senders from messages author array
      const uniqSender = uniqBy(details.messages, "author.nc_account_id")
      if (uniqSender.length > 1) {
        // Filter out self user and other sender user
        const otherUser = reject(uniqSender, {
          author: { nc_account_id: accountIdContext.toString() },
        })

        let senderName
        if (otherUser.length > 1) {
          // If other user sent message on behalf of provider and then provider has responded on it
          senderName = map(otherUser, "author.name").join(", ")
        } else {
          senderName = otherUser[0].author.name
          // If message sent from on behalf of someone
          if (otherUser[0].author.on_behalf_of) {
            senderName += ` ${$_("SM.ON_BEHALF_OF")} ${
              otherUser[0].author.on_behalf_of
            }`
          }
        }

        return `${$_("SM.CONVERSATION_WITH")}: ${senderName}`
      } else if (
        String(accountIdContext) ===
        String(get(details, "messages[0].author.nc_account_id"))
      ) {
        // If all messages sent from one user only, it will display To to the logged in user
        const otherUser = reject(details.participants, {
          nc_account_id: accountIdContext.toString(),
        })

        return `${$_("SM.TO")}: ${otherUser[0].name || $_("SM.UNKNOWN_USER")}`
      } else {
        // If all message sent from one user only, it will display From for the logged in user
        let senderName = details.messages[0].author.name
        if (details.messages[0].author.on_behalf_of) {
          senderName += ` ${$_("SM.ON_BEHALF_OF")} ${
            details.messages[0].author.on_behalf_of
          }`
        }

        return `${$_("SM.FROM")}: ${senderName}`
      }
    } else {
      const authors = [
        ...new Set(
          reject(details.participants, {
            nc_account_id: accountIdContext.toString(),
          }),
        ),
      ]
      return `${$_("SM.CONVERSATION_WITH")}: ${map(authors, "name").join(", ")}`
    }
  }

  /**
   * Prepare the thread subject
   * @param {Object} details thread details
   * @returns {String} thread subject
   */
  const getThreadSubject = (details) => {
    if (!details.messages) {
      return ""
    }

    const prefix = details.messages.length > 1 ? "RE: " : ""
    return prefix + details.subject
  }

  /**
   * Prepare thread received date and time
   * @param {Object} details thread details
   * @returns {String} thread date and time details
   */
  const getThreadDateTime = (details) =>
    convertDateTime(details.messages[0].created_at)

  const breadcrumbLinks = [
    {
      title: $_("SM.MESSAGES_INBOX"),
      link: gobackUrl,
      id: gobackUrl === "/messages/inbox" ? "messages-inbox" : "messages-sent",
    },
    {
      title: $_("SM.MESSAGE_DETAIL"),
      active: true,
    },
  ]
</script>

<section class="message-header">
  <Breadcrumb {breadcrumbLinks} />

  <div class="subject-section">
    <header>
      <h5 class="mb-0">
        {getThreadSubject(threadDetails)}
      </h5>
    </header>
    {#if threadDetails.messages && threadDetails.messages.length <= 1}
      <div class="date-section">{getThreadDateTime(threadDetails)}</div>
    {/if}
  </div>
  {#if threadDetails.category}
    <div class="category-section">
      {$_("SM.TOPIC")}: {threadDetails.category.name}
    </div>
  {/if}
  <div class="author-section">{getThreadAuthor(threadDetails)}</div>
</section>

<style lang="scss" src="./ThreadHeader.scss">
</style>
