/* eslint-disable no-useless-escape */
/* eslint-disable quotes */
import { partialRight } from "lodash"
import { dateTransformer } from "../transformers"
import applyResolvers from "@/utils/applyResolvers"
import { isNotFutureDate } from "@/utils/dateTime"

const singleClinicalNoteResolver = [
  {
    field: "id",
    path: "resource.id",
  },
  {
    field: "title",
    path: 'resource.extension.|["\\"url\\":\\"nc:document:document_name\\""]|.valueString',
  },
  {
    field: "createdAt",
    path: 'resource.extension.|["\\"url\\":\\"nc:document:created_at\\""]|.valueInstant',
    transform: partialRight(dateTransformer, {
      dateFormat: "MM/DD/YYYY",
    }),
  },
  {
    field: "createdBy",
    path: "resource.author[0].display",
  },
  {
    field: "updatedAt",
    path: 'resource.extension.|["\\"url\\":\\"nc:document:updated_at\\""]|.valueInstant',
    transform: partialRight(dateTransformer, {
      dateFormat: "MM/DD/YYYY",
    }),
  },
  {
    field: "Type",
    path: "resource.type.text",
  },
  {
    field: "url", // will typically get this when there's no inline content
    path: "resource.content[0].attachment.url",
  },
]

const ClinicalNotesResolvers = [
  {
    field: "ClinicalNotes",
    path: (data) =>
      data?.entry
        ?.filter((entry) =>
          isNotFutureDate(
            entry?.resource?.extension?.find(
              (extension) => extension?.url === "nc:document:created_at",
            )?.valueInstant,
          ),
        )
        .map((obj) => applyResolvers(obj, singleClinicalNoteResolver)),
  },
]

export default ClinicalNotesResolvers
