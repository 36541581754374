import { hasIn } from "lodash"
import applyResolvers from "@/utils/applyResolvers"
import createProvenanceResolver from "./provenanceResolvers"
import { isNotFutureDate } from "@/utils/dateTime"

const singleGoalResolver = [
  {
    field: "Goal",
    path: "resource.description.text",
  },
]

// used to filter out entries that have no display name and can't be displayed in UI
const hasDisplayName = (entryObj) =>
  hasIn(entryObj, "resource.description.text")

const sortEntriesByDate = (a, b) => {
  const dateA = a.resource?.startDate
  const dateB = b.resource?.startDate

  // if either property is missing a date field, sort down
  if (!dateA) return 1
  if (!dateB) return -1

  return new Date(dateB).getTime() - new Date(dateA).getTime()
}

const goalInfoResolvers = [
  {
    field: "Goals",
    path: (data) =>
      data?.entry
        ?.filter(hasDisplayName)
        .filter((entry) =>
          isNotFutureDate(entry?.resource?.contained?.[0]?.startDate),
        )
        .sort(sortEntriesByDate)
        .map((obj) => ({
          id: obj?.resource?.id,
          fields: [
            ...applyResolvers(obj, singleGoalResolver),
            ...applyResolvers(obj, createProvenanceResolver("resource.")),
          ],
        })),
  },
]

export default goalInfoResolvers
