// eslint-disable-next-line no-undef
const subDomain = portalApp.env.ENV_NAME === "staging" ? "staging" : "www"

// eslint-disable-next-line no-undef
const authApiUrl = portalApp.env.AUTH_API_URL

export const errorMapping = {
  403: {
    errorText: "Sorry, this account isn’t authorized to access this page",
    errorInstruction: `Please check your log-in credentials or <br/> <a href=${authApiUrl}redirect_login> log in again</a>`,
    errorIcon: "error_illustration_authentication.svg",
    errorCode: 403,
  },
  404: {
    errorText: "Sorry, we can't seem to find that page",
    errorInstruction:
      "Try double-checking the spelling in the URL, otherwise you can go to your <a href='../home'>Homepage</a>",
    errorIcon: "error_illustration_computer.svg",
    errorCode: 404,
  },

  500: {
    errorText: "Sorry, we're experiencing some technical issues right now",
    errorInstruction:
      "Please wait a few moments then<br/> <a href='javascript:window.location.reload()'>refresh the page</a>",
    errorIcon: "error_illustration_computer.svg",
    errorCode: 500,
  },

  502: {
    errorText: "Sorry, we're having issues reaching that page right now",
    errorInstruction:
      "Please wait a few moments then<br/> <a href='javascript:window.location.reload()'>refresh the page</a>",
    errorIcon: "error_illustration_computer.svg",
    errorCode: 502,
  },

  503: {
    errorText: "Sorry, we're having issues reaching that page right now",
    errorInstruction:
      "Please wait a few moments then<br/> <a href='javascript:window.location.reload()'>refresh the page</a>",
    errorIcon: "error_illustration_computer.svg",
    errorCode: 503,
  },

  "no clinic ID": {
    errorText:
      "Sorry, admins currently aren't authorized to access the new secure messaging experience",
    errorInstruction: `To view this experience, please<br/> <a href=https://${subDomain}.navigatingcare.com/account/login>log in using a patient account</a>`,
    errorIcon: "error_illustration_authentication.svg",
    errorCode: "",
    error: "no clinic ID",
  },

  "no CPL ID": {
    errorText:
      "Sorry, health records access is currently limited to patients only.",
    errorInstruction: `To view this experience, please<br/> <a href=https://${subDomain}.navigatingcare.com/account/login>log in using a patient account</a>`,
    errorIcon: "error_illustration_authentication.svg",
    errorCode: "",
    error: "no clinic patient link ID",
  },

  "no CDM patient ID": {
    errorText:
      "Sorry, health records access is currently limited to patients only.",
    errorInstruction: `To view this experience, please<br/> <a href=https://${subDomain}.navigatingcare.com/account/login>log in using a patient account</a>`,
    errorIcon: "error_illustration_authentication.svg",
    errorCode: "",
    error: "no CDM patient ID",
  },

  "no GC user ID": {
    errorText: "Sorry, we can't seem to find that page",
    errorInstruction:
      "Try double-checking the spelling in the URL, otherwise you can go to your <a href='../messages/inbox'>Messages inbox</a>",
    errorIcon: "error_illustration_computer.svg",
    errorCode: "",
    error: "no GC user ID",
  },

  connection_issues: {
    errorText: "Sorry, we're having issues reaching that page right now",
    errorInstruction:
      "Please wait a few moments then<br/> <a href='javascript:window.location.reload()'>refresh the page</a>",
    errorIcon: "error_illustration_computer.svg",
    errorCode: "",
    error: "connection issues",
  },
}
