<script>
  /** eslint-disable prefer-const */
  import { onDestroy } from "svelte"
  import SectionalAlert from "../SectionalAlert/SectionalAlert.svelte"
  import { sectionalAlertConfig } from "@/stores/stores.js"

  export let key

  const dismissAlert = () => {
    if ($sectionalAlertConfig[key]?.onDismiss) {
      $sectionalAlertConfig[key].onDismiss(key)
    }
    // differentiate undefined (not set yet) and dismissed alerts
    $sectionalAlertConfig[key] = "dismissed"
  }

  onDestroy(() => {
    if ($sectionalAlertConfig[key]) dismissAlert()
  })
</script>

<SectionalAlert {...$$props} />
