<script>
  import { createEventDispatcher } from "svelte"

  // Declare the dispatch
  const dispatch = createEventDispatcher()

  const toggleCheckbox = (e) => {
    dispatch("toggleButton", e.target.checked)
  }
</script>

<label class="switch">
  <input type="checkbox" aria-label="toggle" on:change={toggleCheckbox} />
  <span class="slider round" />
  <span class="checkbox-text" />
  <span class="sr-only">Toggle</span>
</label>

<style lang="scss" src="./Toggle.scss"></style>
