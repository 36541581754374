<script>
  export let count
</script>

<div class="circle notification-count">{count}</div>

<style type="scss">
  @import "../../css/mixins.scss";
  .circle {
    @include body-emphasis;
    line-height: normal;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #b836b5;
    width: 26px;
    height: 26px;
    font-family: Mukta;
  }
</style>
