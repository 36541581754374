<script>
  import { onMount, onDestroy } from "svelte"
  import { isEmpty } from "lodash"
  import { params, goto, url, afterPageLoad } from "@roxi/routify"
  import dayjs from "dayjs"
  import { _ } from "@/i18n"
  import { callAPI } from "@/utils/httpService"
  import {
    mobileToolbarConfig,
    bodyClassName,
    patientContext,
    sessionData,
    accountPatientsInfo,
  } from "@/stores/stores"
  import ThreadHeader from "./_components/ThreadHeader/ThreadHeader.svelte"
  import ThreadBody from "./_components/ThreadBody/ThreadBody.svelte"
  import ThreadReply from "./_components/ThreadReply/ThreadReply.svelte"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"

  let showLoader = false
  let threadDetails = {}
  let replyFlag = false

  $: if (replyFlag === true) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  /**
   * Get thread details from the API
   */
  const getThreadDetails = async () => {
    const { ncPatientId } = $patientContext
    showLoader = true
    threadDetails = await callAPI({
      url: `patients/${ncPatientId}/secure-messages/threads/${$params.id}`,
      method: "GET",
    })
    showLoader = false
  }

  /**
   * Update the thread and mark it as read
   */
  const markAsRead = () => {
    const { ncPatientId } = $patientContext
    const payload = { read_at: dayjs().toISOString() }
    callAPI({
      url: `patients/${ncPatientId}/secure-messages/threads/${$params.id}`,
      method: "PUT",
      payload,
    })
  }

  /**
   * Show reply screen
   */
  const showReply = () => $goto(`${$url()}#reply`)

  /**
   * Redirect to the thread detail page
   */
  const gotoThreadDetail = () => $goto($url())

  /**
   * Send reply message to the providers or connections
   * @param {Object} event event emitted from the child (ThreadReply) component with thread details
   */
  const sendReply = async (event) => {
    const { ncPatientId } = $patientContext
    showLoader = true

    const payload = event.detail
    const response = await callAPI({
      url: `patients/${ncPatientId}/secure-messages/threads/${$params.id}`,
      method: "POST",
      payload,
    })

    if (response) {
      $goto("/messages/thankyou")
    }

    showLoader = false
  }

  $afterPageLoad(() => {
    if (window.location.hash.slice(1) === "reply") {
      replyFlag = true

      mobileToolbarConfig.set({
        left: { arrow: false, text: $_("GLOBAL_NAV.CANCEL") },
        center: { logo: false },
      })
    } else {
      replyFlag = false

      mobileToolbarConfig.set({
        left: { arrow: true, text: $_("GLOBAL_NAV.INBOX") },
        center: { logo: false },
      })
    }
  })

  /**
   * download links are active only for 15 minutes so we refresh thread detail every 14 minutes
   */
  const setupAutoPageReload = () => {
    setTimeout(() => {
      window.location.reload()
    }, 840000)
  }

  // Get thread details and mark that thread as read
  $: if (isEmpty($patientContext) === false) {
    getThreadDetails()
    const isPatientOrRep =
      $sessionData.account_types.includes("patient") ||
      $sessionData.account_types.includes("authorized_rep")

    if (isPatientOrRep) markAsRead()
  }

  /**
   * Get thread details and update the thread as read
   * Set mobileToolbarConfig to display button positions
   * Set white bodyClassName for xs-down view ports
   * Setup Auto page reload after 14 minutes
   */
  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: true, text: $_("GLOBAL_NAV.INBOX") },
      center: { logo: false },
    })
    bodyClassName.add("white-bkg-xs-down")

    setupAutoPageReload()
  })

  /**
   * Reset the mobileToolbarConfig and bodyClassName
   */
  onDestroy(() => {
    mobileToolbarConfig.set({})
    bodyClassName.remove("white-bkg-xs-down")
  })
</script>

{#if showLoader}
  <div class="spinner-wrapper">
    <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
  </div>
{:else if threadDetails}
  <ThreadHeader
    {threadDetails}
    accountIdContext={$accountPatientsInfo?.accountId}
  />
  {#if replyFlag}
    <ThreadReply
      patientContext={$patientContext}
      on:sendReply={sendReply}
      on:gotoThreadDetail={gotoThreadDetail}
    />
  {/if}
  <ThreadBody {replyFlag} {threadDetails} on:showReply={showReply} />
{/if}
