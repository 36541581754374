<script>
  import { getRanges, isOutOfRanges } from "@/utils/isOutOfRange"
  /**
   * This component represents a gauge with a pin that indicates a value within a given range.
   *
   * @module Gauge
   * @param {string} value - The current value to be displayed on the gauge.
   * @param {string} range - The range of values within which the gauge is considered in-range.
   */

  const PIN_PATH =
    "M5.17578 -4.52481e-07C6.64063 -3.2442e-07 7.91016 0.488281 8.88672 1.66016C9.86328 2.73437 10.3516 4.10156 10.3516 5.76172C10.3516 7.51953 8.88672 10.7422 5.85938 15.2344C5.66406 15.4297 5.46875 15.625 5.17578 15.625C4.98047 15.625 4.6875 15.4297 4.49219 15.2344C1.5625 10.7422 7.08603e-07 7.51953 8.62275e-07 5.76172C1.00741e-06 4.10156 0.585939 2.73437 1.5625 1.66016C2.53906 0.488281 3.8086 -5.72004e-07 5.17578 -4.52481e-07Z"

  export let value = ""
  export let range = ""

  /**
   * Determines whether the current value is out of the specified range.
   * @type {boolean}
   */
  const isOutRange = isOutOfRanges(value, range)

  /**
   * The color of the pin on the gauge.
   * If the value is out of range, the color is set to "#B2511A"; otherwise, it is set to "#1150A2".
   * @type {string}
   */
  const pinColor = isOutRange ? "#B2511A" : "#1150A2"

  /**
   * Retrieves the low and high values from the range.
   * @type {array}
   */
  const [low, high] = getRanges(range)

  /** The parsed numerical value of the input value.
   * @type {number}
   */
  const parsedValue = parseFloat(value)

  /** The parsed numerical value of the low range.
   * @type {number}
   */
  const parsedLow = parseFloat(low)

  /** The parsed numerical value of the high range.
   * @type {number}
   */
  const parsedHigh = parseFloat(high)

  /** Determines if the value is below the specified range.
   * @type {boolean}
   */
  const isBelowTheRange = parsedValue < parsedLow

  /** Determines if the value is above the specified range. *
   * @type {boolean}
   */
  const isAboveTheRange = parsedValue > parsedHigh

  /** The width of the gauge for values below the range.
   * @type {number}
   */
  let belowRangeGaugeWidth = 0

  /** The width of the gauge for values above the range.
   * @type {number}
   */
  let aboveRangeGaugeWidth = 0

  /** The width of the gauge for values within the range.
   * @type {number}
   */
  let inRangeGaugeWidth = 0

  /** The full value range used for calculating percentages.
   * @type {number}
   */
  let fullValue = parsedValue

  /** The calculated percentage value to get the width of the in-range gauge.
   * @type {number}
   */
  let percentageValue = 0

  /** Validating if the value is out of range to calculate the other gauges */
  if (isOutRange) {
    if (isBelowTheRange) {
      fullValue = parsedLow - parsedValue + parsedHigh
      percentageValue = (parsedHigh * 100) / fullValue
      belowRangeGaugeWidth = 100 - percentageValue
    }
    if (isAboveTheRange) {
      fullValue = parsedValue - parsedHigh + parsedHigh
      percentageValue = (parsedHigh * 100) / fullValue
      aboveRangeGaugeWidth = 100 - percentageValue
    }
  }

  /** The width of the gauge for values within the range. */
  inRangeGaugeWidth = isOutRange ? percentageValue : 100

  /** The position of the pin on the gauge in percentage. */
  const pinPosition =
    ((fullValue - parsedLow) / (parsedHigh - parsedLow)) * 100 - 0.5
</script>

<div class="gauge-container">
  {#if isBelowTheRange}
    <div class="inner-container" style="width:{belowRangeGaugeWidth}%">
      <div class="gauge out-range" />
      <div class="pin-container out-range">
        <svg
          width="11"
          height="16"
          viewBox="0 0 11 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={PIN_PATH} fill={pinColor} />
        </svg>
      </div>
    </div>
  {/if}

  <div class="inner-container" style="width:{inRangeGaugeWidth}%">
    <div class="gauge" />
    {#if !isOutRange}
      <div class="pin-container" style="left:{pinPosition}%">
        <svg
          width="11"
          height="16"
          viewBox="0 0 11 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={PIN_PATH} fill={pinColor} />
        </svg>
      </div>
    {/if}
  </div>

  {#if isAboveTheRange}
    <div class="inner-container" style="width:{aboveRangeGaugeWidth}%">
      <div class="gauge out-range" />
      <div class="pin-container out-range">
        <svg
          width="11"
          height="16"
          viewBox="0 0 11 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={PIN_PATH} fill={pinColor} />
        </svg>
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  .gauge-container {
    position: relative;
    display: flex;
    .gauge {
      width: 100%;
      height: 15px;
      background-color: #93e4ec;
      border-radius: 10px;
      &.out-range {
        background-color: #c3c7cc;
      }
    }
    .inner-container {
      position: relative;
      width: 100%;
      min-width: 6px;
    }
    .pin-container {
      position: absolute;
      top: -15px;
      &.out-range {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
</style>
