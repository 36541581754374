<script>
  import { onMount, onDestroy } from "svelte"
  import { _ } from "@/i18n"
  import { mobileToolbarConfig } from "@/stores/stores"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"

  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: true, text: $_("MORE.ABOUT_US") },
      center: { logo: false },
      customClass: "blue-bkg-sm-down",
      redirectPath: "/about-us",
    })
  })

  onDestroy(() => {
    mobileToolbarConfig.set({})
  })

  const breadcrumbLinks = [
    {
      title: $_("MORE.ABOUT_US"),
      link: "/about-us",
      id: "about-us",
    },
    {
      title: $_("MORE.PRIVACY_POLICY"),
      active: true,
    },
  ]
</script>

<div class="breadcrumb-wrapper desktop-only">
  <Breadcrumb {breadcrumbLinks} />
</div>

<article class="content-wrapper">
  <h1 class="page-title">{$_("MORE.PRIVACY_POLICY")}</h1>
  <p class="section-title-small">Updated January 26, 2022</p>
  <section>
    <p>
      This online privacy policy describes the privacy practices for Navigating
      Cancer, Inc. (“Navigating Cancer” “our,” “we,” or “us”). Navigating Cancer
      considers the privacy and security of user information an important
      component of the Navigating Care service, including services offered on
      our websites, navigatingcare.com and nav.care (the “Sites”), email and
      text communications. Navigating Cancer is committed to the privacy and
      confidentiality of your personal information. The following information
      explains how Navigating Cancer collects and uses information obtained from
      its users in connection with our services (collectively, the “Services”).
    </p>
    <p>
      By accessing the Sites or using the Services, you agree to the terms of
      this Privacy Policy. If you do not agree to this Privacy Policy, please do
      not use the Sites or Services.
    </p>
  </section>

  <section>
    <h2 class="section-title">What this Privacy Policy Covers</h2>
    <p>
      This Privacy Policy covers the personal information collected from you
      when you are on the Sites and when you use the Services and Navigating
      Cancer's treatment of such personal information. Also, this Privacy Policy
      covers Navigating Cancer's treatment of personal information that
      Navigating Cancer's business partners and healthcare providers share with
      Navigating Cancer. This Privacy Policy does not apply to the practices of
      companies that Navigating Cancer does not own or control, or to
      individuals that Navigating Cancer does not employ or manage.
    </p>
  </section>
  <section>
    <h2 class="section-title">HIPAA Notice of Privacy Practices</h2>
    <p>
      To access your healthcare provider's HIPAA Notice of Privacy Practices
      please visit your healthcare provider's website.
    </p>
  </section>

  <section>
    <h2 class="section-title">
      Personal Information Collected and How We Use It
    </h2>
    <p>
      In order to provide users with a friendly, customized, and efficient
      experience, we collect personal information of several types. We also
      collect personal information received from your healthcare provider in
      order to provide Services to you and to your healthcare provider including
      but not limited to care management, health tracker, and patient portal
      solutions. We collect personal information from your device or browser,
      and directly from you. We may use personal information we collect in order
      to provide you with our Services, to address your questions, comments or
      requests, to develop and analyze our products and Services, to personalize
      your experience with our Services, comply with out legal and contractual
      obligations, and protect our rights, property, and safety (or the rights,
      property and safety of others). We have collected these categories of
      information within the last twelve months.
    </p>
    <h3 class="subsection-title">A) Information You Provide to Us:</h3>
    <p>
      We may receive and store information you enter on the Sites or otherwise
      provide to us through the Services, including information that we receive
      as a result of our back-office integration with your healthcare provider's
      clinical systems.
    </p>
    <ol>
      <li>
        Registration. In order for you to use certain features of the Services,
        you may be required to complete a registration form which may ask for
        personal information.
      </li>
      <li>
        Health Records. We work with your healthcare providers to provide you
        the Services. Your healthcare providers share personal information about
        you and your treatment with us, in order for us to provide you with the
        Services, and we share with your healthcare provider information that
        you input into the Sites (e.g., secure messaging). We recognize the
        sensitivity of your personal information and we will protect any
        information provided by your healthcare providers to us as required by
        HIPAA. As a user of Navigating Care, you can access and manage your
        health records and monitor and record your daily health information and
        disease symptoms and/or side effects. All of the health record pages on
        Navigating Care use Secure Sockets Layer (SSL) encryption to protect
        your personal information. Examples of the types of information stored
        in your Navigating Care health records include (this is not an exclusive
        list):
      </li>
      <ol type="a">
        <li>
          General demographic personal information including, but not limited
          to, your name, address, date of birth, gender and contact information
        </li>
        <li>Diagnosis</li>
        <li>
          Treatments including surgeries, radiation treatments, chemotherapy,
          complementary or alternative medicines
        </li>
        <li>Lab results and clinical notes</li>
        <li>
          Prescription medications, dietary supplements, herbal remedies, or
          other medications
        </li>
        <li>
          Healthcare Provider team member's contact information such as your
          doctor's name, specialty, address, email and phone numbers
        </li>
        <li>
          Clinic facility information, such as names, addresses and phone
          numbers
        </li>
        <li>
          Daily Health Tracker information, including self-reported details
          about your general health and wellbeing, as well as self-reported
          disease symptoms and/or side effects of treatment
        </li>
        <li>Medical appointment dates, times and physician information</li>
        <li>Secure messaging with your healthcare providers</li>
      </ol>
      <li>
        Your Communications with Us. We collect personal information from you
        such as email address, date of birth, zip code, phone number, or mailing
        address when you request information about our Services, sign up for
        communications, request customer or technical support, apply for a job
        or otherwise communicate with us.
      </li>
      <li>
        Surveys. We may contact you to participate in surveys. If you decide to
        participate, you may be asked to provide certain information which may
        include personal information.
      </li>
    </ol>
    <h3 class="subsection-title">B) Automatic Information:</h3>
    <p>
      We may receive and store certain types of information whenever you
      interact with the Sites or Services. Navigating Cancer may automatically
      receive and record certain "traffic data" on our server logs from your
      browser including your Internet Protocol (IP) address, geo-location,
      search history, browning history, the uniform resource locator (URL), the
      website you linked from, Navigating Cancer cookie information, and the
      page you requested.
    </p>
    <p>
      In addition, we may automatically collect data regarding your use of our
      Services, such as the types of content you interact with and the frequency
      and duration of your activities. We may combine your information with
      information that other people provide when they use our Services,
      including information about you when they tag you. We may also use Google
      Analytics and other service providers to collect information regarding
      visitor behavior and visitor demographics on our Services.
    </p>
    <h3 class="subsection-title">C) E-mail and SMS Communications:</h3>
    <p>
      We may communicate with you via email and text messaging (SMS), for
      example for registration purposes, to provide educational materials, and
      to notify you when your clinic has updated your patient portal. We may
      also send out emails with news or promotions. If you do not want to
      receive e-mail or other mail from us, please notify us by e-mail at <a
        href="mailto:unsubscribe@navigatingcare.com"
        >unsubscribe@navigatingcare.com</a
      > and include sufficient information for us to identify your account.
    </p>
    <h3 class="subsection-title">D) Information from Other Sources:</h3>
    <p>
      In order for us to develop a more personalized service offering, we may
      receive information about you from other sources and add it to our account
      information.
    </p>
  </section>
  <section>
    <h2 class="section-title">Sharing and Use of Information</h2>
    <p>
      We do not sell any personally identifiable information to anyone without
      your consent, and within the last twelve months we have shared your
      information consistent with the statements below. We will not share your
      personal information (described above) except in the following
      circumstances:
    </p>
    <h3 class="subsection-title">A) Consent:</h3>
    <p>
      Navigating Cancer may disclose personal information when you have provided
      consent to do so.
    </p>
    <h3 class="subsection-title">B) Consistent with HIPAA:</h3>
    <p>
      Navigating Cancer may use and disclose personal information if it is
      required to do so by law, when it is permitted to do so consistent with
      HIPAA, or if Navigating Cancer has a good faith belief that such action is
      necessary to conform to applicable laws or comply with any legal,
      regulatory or similar requirement or investigation, to protect or defend
      the rights or property of Navigating Cancer or another user or to enforce
      Navigating Cancer's Terms of Use.
    </p>
    <h3 class="subsection-title">C) Business Transfers:</h3>
    <p>
      Navigating Cancer may transfer your personal information to any successor
      to all or substantially all of its business or assets that concern the
      Services.
    </p>
    <h3 class="subsection-title">D) Government Entities/Security:</h3>
    <p>
      We may share personal information in response to a court order, subpoena,
      search warrant, law or regulation. We may also use personal information to
      comply with our legal, regulatory, security and risk management
      obligations, including defending legal claims. We may cooperate with law
      enforcement authorities in investigating and prosecuting activities that
      are illegal, violate our rules, or may be harmful to other visitors. We
      may share personal information, to address fraud, security or other
      technical issues. We may also share personal information to protect
      against harm to Navigating Cancer or other third parties.
    </p>
    <h3 class="subsection-title">E) Service Providers:</h3>
    <p>
      We may share any personal information we collect about you with our
      third-party service providers. The categories of service providers to whom
      we entrust personal information include IT and related services;
      information and services; services such as Google Analytics, customer
      service providers; and vendors to support the provision of the Services.
    </p>
    <h3 class="subsection-title">F) International Data Transfers:</h3>
    <p>
      You agree that all information processed by us may be transferred,
      processed, and stored anywhere in the world, including but not limited to,
      the United States or other countries, which may have data protection laws
      that are different from the laws where you live. We have taken appropriate
      safeguards to require that your personal information will remain protected
      and require our third-party service providers and partners to have
      appropriate safeguards as well.
    </p>
    <h3 class="subsection-title">
      G) Information You Share with Third Parties:
    </h3>
    <p>
      You should be aware that by providing third parties with your name (User
      ID) and password, you may inadvertently enable unauthorized persons to
      review, modify or delete your health records. If you provide a third party
      with your user credentials, Navigating Cancer cannot monitor their use of
      the Site or Services.
    </p>
    <h3 class="subsection-title">H) Information You Share with the Public:</h3>
    <p>
      The Sites will allow you to post information into a public forum. If you
      post personal information into a public forum, the public will have access
      to such information. Please note, once you make a public posting through
      the Sites, you may not be able to change or remove it.
    </p>
    <p>
      THIS PRIVACY POLICY DOES NOT PROTECT YOU WHEN YOU USE OUR PUBLIC FORUMS OR
      PROVIDE INFORMATION (INCLUDING PERSONALLY IDENTIFIABLE INFORMATION) ABOUT
      YOURSELF THROUGH THESE PUBLIC FORUMS.
    </p>
    <p>
      You should be aware that any information shared in a public forum such as
      a message board, posting, group, bulletin board, review posting or through
      Navigating Cancer public forums is public information and may be seen or
      collected by third parties that do not adhere to our Privacy Policy. You
      should think carefully before disclosing any information in any public
      forum. For example, you should not select a username that includes
      personally identifiable information.
    </p>
  </section>
  <section>
    <h2 class="section-title">
      Aggregation of De-identified Information and Statistics
    </h2>
    <p>
      Navigating Cancer may de-identify your personal information, including
      your healthcare information. Navigating Cancer and its partners may use
      and disclose de-identified and or aggregated information gathered from
      your profile and your usage of the Sites and related statistics. If we are
      de-identifying personal healthcare information then, such
      de-identification will be in compliance with HIPAA requirements and such
      de-identified information will not include personally identifiable
      information relating to users such as name, email address, or address, but
      may include gender, height, weight, postal code, and medical information
      such as diagnosis and stage of illness. We will not attempt to re-identify
      the de-identified information, except we may attempt to re-identify the
      information solely for the purpose of determining whether our
      de-identification processes satisfy legal requirements. Aggregated
      information is information from multiple users that cannot be
      “re-identified,” or associated with any individual user or account. This
      de-identified data may also include data extracted on an anonymous basis
      from the health care information we receive from your physician or other
      care providers once you have agreed to the use of our Services. We will
      require any of our partners that receive de-identified data from us to
      agree to not re-identify the data.
    </p>
  </section>
  <section>
    <h2 class="section-title">Security</h2>
    <p>
      Your Navigating Care account information is protected by a password for
      your privacy and security. You are responsible to protect against
      unauthorized access to your account and to your computer by logging off
      once you have finished using a shared computer, and to your mobile phone
      by enabling your device's security features.
    </p>
    <p>
      It is our policy to protect the confidentiality of Social Security numbers
      ("SSNs”) that we receive or collect in the course of business. We secure
      the confidentiality of SSNs through various means, including physical,
      technical, and administrative safeguards that are designed to protect
      against unauthorized access. It is our policy to limit access to SSNs to
      that which is lawful, and to prohibit unlawful disclosure of SSNs.
    </p>
    <p>
      “Do Not Track.” Do Not Track (“DNT”) is a preference you can set in your
      web browser to let the websites you visit know that you do not want them
      collecting data about you. At this time, this Site does not respond to DNT
      or similar signals.
    </p>
  </section>
  <section>
    <h2 class="section-title">Third Party Sites</h2>
    <p>
      The Services may contain links to other websites, and other websites may
      reference or link to our website or other Services. These other websites
      are not controlled by us. We encourage our users to read the privacy
      policies of each website and application with which they interact. We do
      not endorse, screen or approve and are not responsible for the privacy
      practices or content of such other websites or applications. Visiting
      these other websites or applications is at your own risk.
    </p>
  </section>
  <section>
    <h2 class="section-title">Cookies</h2>
    <p>
      Cookies are alphanumeric identifiers that we may transfer to your
      computer's hard drive through your Web browser to enable our systems to
      recognize your browser and tell us how and when pages in our Sites are
      visited and by how many people. Navigating Care cookies do not collect
      personal information, and we do not combine information collected through
      cookies with other personal information to tell us who you are or what
      your email address is.
    </p>
    <p>
      Your internet browser may direct you on how to prevent your browser from
      accepting new cookies, how to command the browser to tell you when you
      receive a new cookie, or how to fully disable cookies. We strongly
      recommend that you leave the cookies activated, because cookies enable you
      to take advantage of some of the Services' most attractive features.
    </p>
  </section>
  <section>
    <h2 class="section-title">Minors</h2>
    <p>
      The Services are not directed to children under 18, and we do not
      knowingly collect or sell personal information from children. However, if
      requested by your healthcare provider, minor patients, parents or
      guardians may elect to establish a portal account, and, in doing so,
      expressly consent to Navigating Cancer utilizing such information as set
      forth in this Privacy Policy and the Terms of Use. If you learn that your
      child has provided us with personal information without your consent, you
      may contact us as set forth below. If we learn that we have collected any
      personal information in violation of applicable law, we will promptly take
      steps to delete such information and terminate the child's account.
    </p>
  </section>
  <section>
    <h2 class="section-title">Changes</h2>
    <p>
      Navigating Cancer reserves the right to change or update this Privacy
      Policy at any time by posting a notice at the Sites. Changes to the
      Privacy Policy will take effect immediately unless otherwise noted. Your
      continued access of the Services constitutes your agreement to this
      Privacy Policy and any updates or revisions.
    </p>
  </section>
  <section>
    <h2 class="section-title">Contact</h2>
    <p>
      If you would like to make changes to your personal health information,
      please contact your healthcare provider directly. If you have any
      questions regarding this Privacy Policy or your dealings at this Sites,
      please contact Navigating Cancer at:
    </p>
    <p>
      Attn: Privacy Officer <br />
      Re: Privacy Policy <br />
      1008 Western Avenue Suite 206 <br />
      Seattle, Washington 98104 <br />
      <a href="mailto:support@navigatingcare.com">support@navigatingcare.com</a>
    </p>
  </section>
  <section>
    <h2 class="section-title">Special Notice for California Residents:</h2>
    <p class="mb-4">
      In addition to the Privacy Policy above, California residents may be
      entitled to the following privacy rights under California law:
    </p>
    <ol type="a">
      <li>
        The right to know about personal information collected, disclosed or
        sold. You have the right to request that we disclose what personal
        information we collect, use, disclose and sell. Please note that we
        describe these practices above in our Privacy Policy.
      </li>
      <li>
        The right to request deletion of personal information. You, or your
        authorized agent, have the right to request that we delete the Personal
        Information that we have collected about you. Subject to HIPAA, other
        laws, and our legal obligations (including contractual obligations) we
        may deny your request. There may be exceptions under California law that
        may allow Navigating Cancer to maintain and use your personal
        information notwithstanding your request for deletion. We will inform
        you of our reasons for denying your request.
      </li>
      <li>
        The right to opt-out of the sale of personal information. Under
        California law, you have the right to opt-out of the sale of personal
        information; however, Navigating Cancer does not sell your personal
        information.
      </li>
      <li>
        The right to non-discrimintation for the exercise of a consumer's
        privacy rights. You have the right to not receive discriminatory
        treatment by Navigating Cancer for the exercise of your privacy rights
        conferred by California law. Please note that if you exercise certain
        rights you may not be able to use or access all of Navigating Cancer's
        Services.
      </li>
      <li>
        Submission of requests. You may submit requests by contacting
        <a href="mailto:support@navigatingcancer.com"
          >support@navigatingcancer.com</a
        >. We may request identity verification before processing your requests
        (e.g. ensuring that your information matches the identifying information
        that we have within our system) and we will not process your request
        without sufficient information to reasonably verify your identity, or
        the identity of your authorized agent and their authority to submit this
        request.
      </li>
      <li>
        The California “Shine the Light” law permits users who are California
        residents to request and obtain from us once a year, free of charge, a
        list of the third parties to whom we have disclosed your personal
        information (if any) for their direct marketing purposes in the prior
        calendar year, as well as the type of personal information disclosed to
        those parties.
      </li>
    </ol>
  </section>
</article>

<style lang="scss" src="./index.scss">
</style>
