<script>
  import SimpleModal from "@/components/Modal/SimpleModal.svelte"

  export let isModalOpen

  const closeModal = () => {
    isModalOpen = false
  }
</script>

{#if isModalOpen}
  <div class="accounts-modal">
    <SimpleModal on:close={closeModal}>
      <div slot="content" class="content">
        <h1 class="title">Switch accounts to send a secure message</h1>
        <p class="description">
          Secure messages are disabled for this account. To send a message, go
          to
          <strong>My Clinics</strong> in the top navigation, select the
          <strong>Active</strong> clinic, and navigate back to your Messages page.
        </p>
        <button class="btn btn-primary" on:click={closeModal}>Okay</button>
      </div>
    </SimpleModal>
  </div>
{/if}

<style type="scss">
  @import "../../../../css/sass-imports.scss";
  .title {
    @include heading;
    text-align: center;
  }
  .description {
    @include body-regular;
    text-align: center;
  }
  .accounts-modal :global(.modal-wrapper) {
    max-width: 300px !important;
  }
  button {
    width: 100%;
  }
</style>
