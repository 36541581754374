<script>
  import { onMount, afterUpdate, createEventDispatcher } from "svelte"
  import { _, locale } from "@/i18n"
  import { sectionalAlertConfig } from "@/stores/stores"
  import { getCookie, setCookie } from "@/utils/cookie"
  import {
    getStoredJsonAsObject,
    serializeObjectToStorage,
  } from "@/utils/localStorage"
  import {
    loginSchemaWithEmail,
    loginSchemaWithUsername,
    extractErrors,
  } from "@/utils/formValidations"
  import LoginAttemptTracker from "@/utils/loginAttemptTracker"
  import { callAPI } from "@/utils/httpService"
  import omitObjProperty from "@/utils/omitObjProperty"
  import SectionalAlert from "@/components/Alert/SectionalAlert/SectionalAlert.svelte"
  import PasswordInputWithToggle from "@/components/PasswordInputWithToggle/PasswordInputWithToggle.svelte"
  import LanguageButton from "@/components/LanguageButton/LanguageButton.svelte"

  const defaultCookieExpiryDay = 180
  let signInWithEmail = true
  let errors = {}
  let values = {}
  let hasError = false
  let toggleSignInActionByUser = false
  let loginAttemptTracker
  let timerValue
  let isLoginDisabled = false
  let isTokenValid
  let inputPasswordType = "password"
  let rememberUser = false

  const onCountdown = (timerVal) => {
    timerValue = timerVal
  }

  const onLoginDisable = () => (isLoginDisabled = true)

  const onLoginEnable = () => (isLoginDisabled = false)

  const dispatch = createEventDispatcher()

  const handleSignIn = async () => {
    if (isLoginDisabled) return

    // Update the input type to password while clicking on Sign In button
    inputPasswordType = "password"
    try {
      hasError = false
      if (signInWithEmail) {
        await loginSchemaWithEmail.validate(values, { abortEarly: false })
      } else {
        await loginSchemaWithUsername.validate(values, { abortEarly: false })
      }

      errors = {}
      const payload = {
        username: signInWithEmail
          ? values.email.trim()
          : values.username.trim(),
        password: values.password,
      }

      const response = await callAPI({
        url: "sessions",
        method: "POST",
        payload,
      })
      if (response?.status === 401) {
        hasError = true
        loginAttemptTracker.handleBadLoginAttempt()
      }

      // successful login - No error message in response
      if (response?.status === 200) {
        // TODO: Remove ncSessionCookie implementation once proxy and auth service can both be run locally
        // eslint-disable-next-line no-undef
        const ncSessionCookie = portalApp.env.NC_SESSION_COOKIE
        if (ncSessionCookie) {
          setCookie("nc-session", ncSessionCookie, {
            expires: defaultCookieExpiryDay,
          })
        }
        loginAttemptTracker.resetAttempts()
        if (rememberUser) {
          if (signInWithEmail) {
            const { email } = values
            serializeObjectToStorage("rememberUser", { email })
          } else {
            const { username } = values
            serializeObjectToStorage("rememberUser", { username })
          }
        } else {
          localStorage.removeItem("rememberUser")
        }
        dispatch("redirectToHome", {})
      }
    } catch (err) {
      errors = extractErrors(err)
      hasError = true
    }
  }
  /**
   * Toggle sign in form with email and username, set signInWith option in cookies
   * @param {Boolean} toggleEventByUser toggle signIn event performed by user
   */
  const toggleSignIn = (toggleEventByUser = false) => {
    signInWithEmail = !signInWithEmail
    errors = {}
    hasError = false
    if (toggleEventByUser) {
      toggleSignInActionByUser = true

      setCookie(
        "signInWith",
        signInWithEmail ? "email" : "username",
        defaultCookieExpiryDay,
      )
    }
  }

  const handleInput = (e) => (values.password = e.target.value)

  /**
   * This function will set the focus on the particular field
   * @param {String} field field name to set the focus
   */
  const setFocus = (field) => {
    document.getElementsByName(field)[0].focus()
  }

  // Check signInWith cookie and toggle signIn option
  if (getCookie("signInWith") === "username") {
    toggleSignIn()
  }

  afterUpdate(() => {
    // Set focus on email or username field after user click event and dom update and reset the toggleSignInActionByUser flag to false
    if (toggleSignInActionByUser) {
      if (signInWithEmail) {
        setFocus("email")
      } else {
        setFocus("username")
      }

      toggleSignInActionByUser = false
    }
  })

  const closeCallback = () => {
    isTokenValid = false
    localStorage.removeItem("tokenValid")
  }

  const resetPasswordAlertConfig = {
    message: $_("AUTH.RESET_LINK_EXPIRED"),
    type: "sectional",
    contentType: "fail",
    iconName: "alert",
    actionLink: "/auth/password-reset",
    actionText: "Request a new link",
    closeCallback,
  }

  onMount(() => {
    isTokenValid = localStorage.getItem("tokenValid")
    loginAttemptTracker = new LoginAttemptTracker({
      onCountdown,
      onLoginDisable,
      onLoginEnable,
    })
    const savedUser = getStoredJsonAsObject("rememberUser")
    if (savedUser) {
      values = {
        ...values,
        ...savedUser,
      }
      rememberUser = true
    }
    // Set autofocus on email or username field
    if (signInWithEmail) {
      setFocus("email")
    } else {
      setFocus("username")
    }
  })

  $: if (isTokenValid === false) {
    $sectionalAlertConfig.loginErr = resetPasswordAlertConfig
  }
  $: if (hasError && loginAttemptTracker.badLoginAttempts < 6) {
    $sectionalAlertConfig.loginErr = {
      contentType: "fail",
      iconName: "alert",
    }
  }
  $: if (isLoginDisabled) {
    $sectionalAlertConfig.loginErr = {
      contentType: "fail",
      iconName: "alert",
      title: $_("AUTH.ACCOUNT_LOCKED"),
      stacked: true,
    }
  }
</script>

<section class="login-form">
  <LanguageButton />
  <div class="heading">
    <h1>{$_("AUTH.SIGN_IN_HEADING")}</h1>
  </div>
  {#if isTokenValid === "false"}
    <SectionalAlert key="loginErr" />
  {/if}
  {#if hasError && loginAttemptTracker.badLoginAttempts < 6}
    <SectionalAlert key="loginErr">
      <div slot="message">
        <p class="description-text">{$_("AUTH.INCORRECT_PASSWORD")}</p>
        <a class="lockout-help-link" href="/auth/sign-in-help"
          >{$_("AUTH.SIGN_IN_HELP_LINK")}</a
        >
      </div>
    </SectionalAlert>
  {/if}
  {#if isLoginDisabled}
    <SectionalAlert key="loginErr">
      <div slot="message">
        <p class="description-text">
          {$_("AUTH.ACCOUNT_LOCKED_DESCRIPTION")}
          <span class="lockout-timer">
            {timerValue}
          </span>
        </p>
        <a class="lockout-help-link" href="/auth/sign-in-help"
          >{$_("AUTH.SIGN_IN_HELP_LINK")}</a
        >
      </div>
    </SectionalAlert>
  {/if}
  <form autocomplete="off" on:submit|preventDefault={handleSignIn}>
    {#if signInWithEmail}
      <div class="fields">
        <label for="email">{$_("AUTH.EMAIL")}</label>
        <input
          type="text"
          aria-label="email"
          id="email"
          name="email"
          class:error={errors.email}
          class="form-text-primary"
          on:focus={() => (errors = omitObjProperty("email", errors))}
          bind:value={values.email}
        />
        {#if errors.email}
          <span id="email-error" class="error-text">{$_(errors.email)}</span>
        {/if}
      </div>
    {:else}
      <div class="fields">
        <label for="username">{$_("AUTH.USERNAME")}</label>
        <input
          type="text"
          aria-label="username"
          id="username"
          name="username"
          class:error={errors.username}
          class="form-text-primary"
          on:focus={() => (errors = omitObjProperty("username", errors))}
          bind:value={values.username}
        />
        {#if errors.username}
          <span id="username-error" class="error-text"
            >{$_(errors.username)}</span
          >
        {/if}
      </div>
    {/if}
    <div class="fields">
      <label for="password">{$_("AUTH.PASSWORD")}</label>
      <PasswordInputWithToggle
        {inputPasswordType}
        on:input={handleInput}
        on:focus={() => (errors = omitObjProperty("password", errors))}
        iconOnly={$locale === "es"}
        error={errors.password}
      />

      {#if errors.password}
        <span id="password-error" class="error-text">{$_(errors.password)}</span
        >
      {/if}
    </div>
    <div>
      <a href="/auth/password-reset" class="link"
        >{$_("AUTH.FORGOT_PASSWORD")}</a
      >
    </div>
    <div class="btn-div">
      <button id="sign-in-btn" type="submit" class="btn btn-primary w-100"
        >{$_("AUTH.SIGN_IN_BTN")}</button
      >
    </div>
    <div class="form-text-primary d-flex">
      <input
        aria-label="remember_me"
        type="checkbox"
        name="remember_me"
        bind:checked={rememberUser}
      />
      <p for="remember_me" class="pl-12">
        {$_("AUTH.REMEMBER_ME")}
      </p>
    </div>
  </form>
  <div class="hr" />
  <div class="text-center">
    <!-- svelte-ignore a11y-invalid-attribute -->
    <a
      href="javascript:void(0)"
      on:click={() => toggleSignIn(true)}
      class="link toggle-signin"
    >
      {signInWithEmail
        ? $_("AUTH.SIGN_IN_WITH_USERNAME")
        : $_("AUTH.SIGN_IN_WITH_EMAIL")}
    </a>
  </div>
  <!-- TODO: Implement later -->
  <!-- <div class="new-regi-block text-center">
    <span class="form-text-primary">{$_("AUTH.NEW_TO_NAVIGATING_CARE")}</span>
    <a href="/#" class="link">{$_("AUTH.START_HERE")}</a>
  </div> -->
</section>

<style lang="scss" src="./LoginForm.scss"></style>
