<script>
  /** eslint-disable no-unreachable */
  import { isEmpty } from "lodash"
  import { goto, afterPageLoad, params, isChangingPage } from "@roxi/routify"
  import { _ } from "@/i18n"
  import {
    pageBannerConfig,
    error,
    isUserClinicianOrAdmin,
    isPageFullWidth,
    sessionData,
    patientContext,
    loginViaNewAuth,
    mobileToolbarConfig,
    patientIdUrlContext,
    accountIdUrlContext,
    notifications,
    viewedNotifications,
  } from "@/stores/stores"
  import { patientId } from "@/stores/healthRecordStores"
  import { getSessionData } from "@/getSessionData"
  import { setupDataDog } from "@/utils/datadog"

  import Navigation from "@/components/Navigation/Navigation.svelte"
  import PageBanner from "@/components/PageBanner/PageBanner.svelte"
  import GlobalAlert from "@/components/Alert/GlobalAlert/GlobalAlert.svelte"
  import { checkSession, redirectToUniversalLogin } from "@/utils/authService"

  /* Page title variables */
  const DEFAULT_PAGE_TITLE = $_("META.DEFAULT_PAGE_TITLE")
  const pageTitle = DEFAULT_PAGE_TITLE

  $afterPageLoad(() => {
    error.set(null)
  })

  $: if ($params.accountId) {
    accountIdUrlContext.set($params.accountId)
  }

  $: if ($params.patientId) {
    patientIdUrlContext.set($params.patientId)
  }

  /**
   * Logout by navigating to portal 1 or portal 2 logout url based on role
   * This allows the user to see the appropriate login mode after logging out
   */
  const logout = async () => {
    // eslint-disable-next-line no-undef
    const { PORTAL_1_URL, AUTH_API_URL } = portalApp.env
    if ($isUserClinicianOrAdmin) {
      window.location.assign(PORTAL_1_URL.replace("login", "logout"))
    } else {
      window.location.assign(`${AUTH_API_URL}logout_url`)
    }
  }

  // Make getSssionData call only if user will login via new px Auth by entering username and password
  if ($loginViaNewAuth) {
    getSessionData().then((response) => {
      if (response) {
        sessionData.set(response)
      }
    })
  }

  // Setup datadog information when session data is available
  $: if (isEmpty($sessionData) === false) setupDataDog()
  // setup datadog again once patient context available to add patient specific attributes
  $: if (isEmpty($patientContext) === false) setupDataDog()

  const handleHeartbeat = () => {
    checkSession(logout)
  }

  const handleTimeout = () => {
    redirectToUniversalLogin()
  }
  // mark any seen notifications as read on refresh
  const beforeUnload = () => {
    if ($viewedNotifications.length) {
      viewedNotifications.markAsRead($viewedNotifications)
    }
  }

  $: if ($error) {
    $goto("/error")
  }

  // check when patient id first becomes available
  $: if ($patientId) {
    notifications.refresh($patientId)
  }

  // prevents multiple executions of isChangingPage
  let isExecuted = false

  $: {
    if ($isChangingPage && $patientId && !isExecuted) {
      if ($viewedNotifications.length) {
        viewedNotifications
          .markAsRead($viewedNotifications)
          .then(() => notifications.refresh($patientId))
      } else {
        notifications.refresh($patientId)
      }
      isExecuted = true
    } else if (!$isChangingPage) {
      isExecuted = false
    }
  }
</script>

<svelte:head>
  <title>{pageTitle}</title>
</svelte:head>
<svelte:window on:beforeunload={beforeUnload} />
<inactivity-modal
  is_logged_in={true}
  on:heartbeat={handleHeartbeat}
  on:timeout={handleTimeout}
  on:logout={logout}
/>
<Navigation handleLogout={logout} />
<main
  class={`${
    !$mobileToolbarConfig || isEmpty($mobileToolbarConfig)
      ? "page-wrapper"
      : "page-wrapper page-contains-titlebar"
  }`}
>
  <GlobalAlert />

  {#if $pageBannerConfig}
    <PageBanner config={$pageBannerConfig} />
  {/if}
  <div
    class="page-container {$isPageFullWidth ? 'page-container--fullWidth' : ''}"
  >
    <slot />
  </div>
</main>
