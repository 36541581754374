import { dictionary, locale, _, json } from "svelte-i18n"
import en from "@/lang/en.json"
import es from "@/lang/es.json"

function setupI18n({ withLocale: _locale } = { withLocale: "en" }) {
  dictionary.set({
    en,
    es
  })
  locale.set(_locale)
  localStorage.setItem("locale", _locale)
}

function setLocale(_locale) {
  localStorage.setItem("locale", _locale)
  locale.set(_locale)
}

export { _, locale, setupI18n, setLocale, json }
