import applyResolvers from "@/utils/applyResolvers"
import createProvenanceResolver from "./provenanceResolvers"
import { isNotFutureDate } from "@/utils/dateTime"

const allergyDetailsResolver = [
  {
    field: "Reaction",
    path: "description",
  },
  {
    field: "Severity",
    path: "severity",
  },
]

const resourceResolver = [
  {
    field: "Status",
    path: "resource.clinicalStatus.coding[0].display",
  },
]

const allergyInfoResolvers = [
  {
    field: "Allergies",
    path: (data) =>
      data?.entry
        ?.filter((entry) => isNotFutureDate(entry?.resource?.recordedDate))
        .map((obj) => ({
          fields: obj?.resource?.reaction?.map((reaction) => ({
            id: obj?.resource?.id,
            title:
              reaction?.substance.coding[0].display || "Allergen unavailable",
            fields: [
              ...applyResolvers(reaction, allergyDetailsResolver),
              ...applyResolvers(obj, resourceResolver),
              ...applyResolvers(
                obj,
                createProvenanceResolver(
                  "resource.",
                  "Added to EHR",
                  "M/D/YYYY",
                  ", hh:mma z",
                ),
              ),
            ],
          })),
        })),
  },
]

export default allergyInfoResolvers
