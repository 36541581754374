import { hasIn, get, partialRight } from "lodash"
import applyResolvers from "@/utils/applyResolvers"
import createProvenanceResolver from "./provenanceResolvers"
import { dateTransformer } from "./transformers"

const hasDiagnosisNameField = (entryObj) =>
  hasIn(entryObj, "resource.code.text")

export const filterStartDatesTransformer = (value) => {
  return value === "1900-01-01" || value === "1800-01-01" ? "" : value
}

export const filterEndDatesTransformer = (value) => {
  return value === "3000-01-01" ? "" : value
}

const encounterDiagnosisResolver = [
  {
    field: "encounterDiagnosis",
    path: (data) =>
      data?.entry?.filter(hasDiagnosisNameField).map((obj) => ({
        text: get(obj?.resource, "code.text"),
        id: get(obj?.resource, "id"),
        fields: applyResolvers(obj, [
          {
            field: "Started",
            path: "resource.onsetDateTime",
            transform: [
              filterStartDatesTransformer,
              partialRight(dateTransformer, {
                dateFormat: "M/D/YYYY",
              }),
            ],
          },
          {
            field: "Ended",
            path: "resource.abatementDateTime",
            transform: [
              filterEndDatesTransformer,
              partialRight(dateTransformer, {
                dateFormat: "M/D/YYYY",
              }),
            ],
          },
          ...createProvenanceResolver(
            "resource.",
            "Added to EHR",
            "M/D/YYYY",
            ", hh:mma z",
          ),
        ]),
      })),
  },
]

export default encounterDiagnosisResolver
