/**
Attaches a given node to the document body.
@param {HTMLElement} node - The node to attach to the body.
@param {boolean} disabled - Determines if the attachment should be disabled.
@returns {void}
*/
export function attachNodeToBody(node, disabled) {
  if (disabled) return
  const teleportContainer = document.body
  teleportContainer?.appendChild(node)
  teleportContainer.focus() // optional
}
