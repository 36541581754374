import dayjs from "dayjs"
import { forIn } from "lodash"
import { returnFormattedDate } from "@/utils/dateTime"

const prettifyUnits = (unit) => {
  switch (unit) {
    case "mm[Hg]":
      return " mm HG"
    case "[in_us]":
      return " in"
    case "{beats}/min":
      return " bpm"
    case "[lb_av]":
      return " lbs"
    case "[degF]":
      return " °F"
    case "%": // This is to omit the leading space which is added to the rest including in the default case
      return "%"
    default:
      return ` ${unit}`
  }
}

/**
 * Omits value if date does not have a reading for that header and merges
 * values if date has multiple values for a given header
 *
 * @param vitals - {fieldName: [values]}
 * @param header - a fieldName
 * @return string
 */
const formatCellValue = (vitals, header) => {
  if (!(header in vitals)) return ""
  return vitals[header]
    .map((value) => {
      const [reading, unit] = value.split(" ")
      return `${reading}${prettifyUnits(unit)}`
    })
    .join(", ")
}

/**
 * Creates an intermediate data object for clarity and simplicity.
 * TODO: This could potentially happen in the resolver which is tracked by PE-5557
 *
 * @param data - data from vitals resolver
 * @return {table, uniqueTableHeaders}
 * uniqueTableHeaders:  array of observation types used for table headers
 * table:  {
 *            date: {
 *              fieldName: [
 *                values
 *              ]
 *            }
 *          }
 */
export const dateOrganizedTableInfo = (data) => {
  const table = {}
  const uniqueTableHeaders = new Set(["Date"])

  const setTableValue = (observation, fieldName) => {
    const reading = observation[0]
    const date = returnFormattedDate(observation[1], {
      dateFormat: "M/D/YY",
    })
    if (date in table && fieldName in table[date]) {
      if (!table[date][fieldName].includes(reading))
        table[date][fieldName].push(reading)
    } else if (date in table) {
      table[date][fieldName] = [reading]
    } else {
      table[date] = { [fieldName]: [reading] }
    }
  }

  data.forEach((entry) => {
    const fieldName = entry?.value?.field
    uniqueTableHeaders.add(fieldName)
    const cellValue = entry?.value?.value
    // this value can be an array of arrays or an array of strings
    if (Array.isArray(cellValue?.[0])) {
      entry?.value?.value?.forEach((observation) => {
        setTableValue(observation, fieldName)
      })
    } else {
      setTableValue(cellValue, fieldName)
    }
  })

  return { table, uniqueTableHeaders }
}

/**
 * parses table data structure from dateOrganizedTableInfo into 2d array for Table component
 * @param table - intermediate data object with shape {
 *                                                      date: {
 *                                                        fieldName: [
 *                                                          values
 *                                                        ]
 *                                                      }
 *                                                    }
 * @param uniqueTableHeaders
 */
export const formatCells = (table, uniqueTableHeaders) => {
  const cells = []
  forIn(table, (vitals, date) => {
    const row = [date]
    uniqueTableHeaders.forEach((header) => {
      if (header === "Date") return
      const cellValue = formatCellValue(vitals, header)
      row.push(cellValue)
    })

    cells.push(row)
  })

  return cells.sort((a, b) => (dayjs(a[0]).isBefore(dayjs(b[0])) ? 1 : -1))
}
