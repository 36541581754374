<script>
  import { goto } from "@roxi/routify"
  import { isEmpty } from "lodash"
  import { sessionData } from "@/stores/stores"

  // If not empty session data, then redirect to the home page
  $: if (!isEmpty($sessionData)) {
    $goto("/home")
  }
</script>

<slot />
