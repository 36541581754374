import dayjs from "dayjs"

/**
 * Hide or show attachment option based on FF configured in environment variables
 * @param {Number} clinicId clinicId
 * @returns {Boolean} true if the FF available date is today or in the future otherwise false
 */
export const attachmentFF = (clinicId) => {
  // eslint-disable-next-line no-undef
  const portalAppEnv = portalApp.env
  const currentDate = dayjs(new Date()).format("YYYY-MM-DD")
  const dateDiff = dayjs(portalAppEnv.FF_ATTACHMENTS_AVAILABLE).diff(
    dayjs(currentDate),
  )

  // Show attachment option if FF Available date is today or in the future
  if (dateDiff >= 0) {
    // Check clinicId from environment variables to show attachment option)
    if (portalAppEnv.CLINIC_IDS_FF_EXEMPT?.split(",").includes(clinicId)) {
      return true
    }
  } else if (
    // Show attachment option if FF Available date is not defined or in the past
    portalAppEnv.FF_ATTACHMENTS_AVAILABLE === "undefined" ||
    dateDiff < 0
  ) {
    return true
  }

  return false
}
