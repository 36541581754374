import { partialRight } from "lodash"
import applyResolvers from "@/utils/applyResolvers"
import { dateTransformer } from "./transformers"

/**
 * Finds correct sub-object for an author within a provenance agent object
 * @param {Array<Object>} agent - provenence agent object representing authors for an entry
 * @param {String} authorType - for filtering agent sub-objects. Usually "Practitioner" or "Organization"
 * @returns {String} - of the display name for that author type
 */
const parseProvenanceAuthor = (...[agent, , , authorType]) => {
  const authorObj = agent.find(({ who }) => who.reference.includes(authorType))
  return authorObj?.who?.display
}

/**
 * Concatenated resolved provenance values into a single string for display
 * @param {Array<Object>} provenanceData - of individual resolved provenance fields
 * @returns {String} - concatenated provenance values
 */
const concatProvenanceValues = (provenanceData) => {
  const resolvedDataAsObj = provenanceData.reduce((acc, { field, value }) => {
    return {
      ...acc,
      [field]: value,
    }
  }, {})

  const { authorName, authorOrganization, timestamp } = resolvedDataAsObj
  let provenanceString = ""

  if (timestamp) provenanceString += `${timestamp} `
  if (authorName || authorOrganization) {
    provenanceString += "by "

    if (authorName) provenanceString += authorName
    if (authorName && authorOrganization) provenanceString += ", "
    if (authorOrganization) provenanceString += authorOrganization
  }

  return provenanceString
}
/**
 * resolves provenance data
 * @param {String} entryPathFragment - path to the contained object array
 * @param {String} [fieldName="provenance"] - optional resolver field name
 * @param {String} [dateFormat="M/D/YY, hh:mma z"] - optional, represents the dayjs date format for the date field
 * @param {String} [timeFormat=", hh:mma z"] - optional, represents the dayjs time format for the date field
 * @returns {Array<Object>} - representing a provenance resolver
 */
const createProvenanceResolver = (
  entryPathFragment,
  fieldName = "provenance",
  dateFormat = "M/D/YY",
  timeFormat = ", hh:mma z",
) => {
  const provenanceResolverStructure = [
    {
      field: "authorName",
      path: `${entryPathFragment}contained[0].agent`,
      transform: partialRight(parseProvenanceAuthor, "Practitioner"),
    },
    {
      field: "authorOrganization",
      path: `${entryPathFragment}contained[0].agent`,
      transform: partialRight(parseProvenanceAuthor, "Organization"),
    },
    {
      field: "timestamp",
      path: `${entryPathFragment}contained[0].recorded`,
      transform: partialRight(dateTransformer, { dateFormat, timeFormat }),
    },
  ]
  return [
    {
      field: fieldName,
      path: (data) => {
        const provenanceData = applyResolvers(data, provenanceResolverStructure)
        return provenanceData && provenanceData.length > 0
          ? concatProvenanceValues(provenanceData)
          : undefined
      },
    },
  ]
}

export default createProvenanceResolver
