<script>
  /**
   * @typedef PillConfigObject
   * @type {object}
   * @property {String} title - pill title
   * @property {String} url - pill url
   * @property {String} imageSrc - pill image src
   * @property {String} imageAlt - pill image alt
   * @property {Function} callback - pill callback
   */

  /**
   * @type {PillConfigObject}
   */
  export let config = {}

  const {
    title = "Description",
    url,
    imageSrc = "../../assets/svg/pills/ask.svg",
    imageAlt = "Pill Icon",
    callback = null,
  } = config

  const handleClick = () => {
    if (callback) {
      callback()
    } else {
      window.location.href = url
    }
  }
</script>

<div class="pill" on:click={handleClick}>
  <div class="icon-container">
    <img src={imageSrc} class="icon" alt={imageAlt} />
  </div>
  <div class="description-container"><p>{title}</p></div>
</div>

<style lang="scss" src="./Pill.scss">
</style>
