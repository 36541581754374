<script>
  import { createTable, createRender } from "svelte-headless-table"
  import {
    addSortBy,
    addColumnOrder,
    addResizedColumns,
    addHiddenColumns,
  } from "svelte-headless-table/plugins"
  import LabInterpretations from "@/components/LabInterpretations/LabInterpretations.svelte"
  import SimpleTable from "@/components/SimpleTable/SimpleTable.svelte"
  import { isValidReferenceRange } from "../../_utils/isValidReferenceRange.js"
  import Title from "./Title.svelte"
  import Result from "./Result.svelte"
  import ReferenceRange from "./ReferenceRange.svelte"

  /**
    A Svelte store that contains an array of objects defining lab components.
    @type {import('svelte/store').Writable<Array<Object>>}
    @name labComponents
  */
  export let labComponents
  export let panelName = ""
  export let labResultId

  const table = createTable(labComponents, {
    sort: addSortBy({ disableMultiSort: true }),
    colOrder: addColumnOrder(),
    resize: addResizedColumns(),
    addHiddenColumns: addHiddenColumns(),
  })

  const columns = table.createColumns([
    table.column({
      header: "Test",
      id: "test",
      accessor: (component) => ({
        title: component.text,
        panelName: panelName || "",
        loincCode: component.loincCode,
        id: labResultId,
      }),
      cell: ({ value }) =>
        createRender(Title, {
          data: value,
        }),
    }),
    table.column({
      header: "Result",
      id: "result",
      accessor: ({
        low,
        high,
        referenceRange,
        referenceRangeLowUnit,
        referenceRangeHighUnit,
        valueString,
        valueNumber,
        valueUnit,
        interpretationConcept,
      }) => ({
        referenceRange: isValidReferenceRange({
          low,
          high,
          referenceRange,
          referenceRangeLowUnit,
          referenceRangeHighUnit,
        })
          ? referenceRange[0]
          : undefined,
        result: valueNumber || valueString,
        valueUnit,
        valueNumber,
        referenceRangeUnit: referenceRangeLowUnit,
        interpretationConcept,
      }),
      cell: ({ value, row }) =>
        createRender(Result, {
          data: value,
          componentIdx: row?.dataId,
          testId: labResultId,
        }),
    }),
    table.column({
      header: "Unit",
      accessor: "valueUnit",
      cell: ({ value }) => value || "",
    }),
    table.column({
      header: "Flag",
      accessor: "interpretationConcept",
      cell: ({ value }) => value || "",
    }),
    table.column({
      header: "Reference Range",
      id: "referenceRange",
      accessor: ({
        referenceRange,
        low,
        high,
        referenceRangeLowUnit,
        referenceRangeHighUnit,
        valueString,
        valueNumber,
      }) =>
        isValidReferenceRange({
          low,
          high,
          referenceRange,
          referenceRangeLowUnit,
          referenceRangeHighUnit,
        })
          ? {
              result: valueNumber || valueString,
              range: referenceRange[0],
            }
          : undefined,

      cell: ({ value }) =>
        createRender(ReferenceRange, {
          result: value?.result,
          range: value?.range,
        }),
    }),
    table.column({
      header: "Comments",
      id: "interpretations",
      accessor: ({ interpretations }) =>
        interpretations?.length ? interpretations : undefined,
      cell: ({ value }) =>
        createRender(LabInterpretations, {
          interpretations: value,
          truncateLength: 250,
        }),
    }),
  ])

  const modelView = table.createViewModel(columns)
  const { headerRows, rows, pluginStates } = modelView
  const { hiddenColumnIds } = pluginStates.addHiddenColumns

  $: if ($headerRows && $rows) {
    // find the ids of columns that have all empty data and add to hiddenColumnIds store
    $hiddenColumnIds = $headerRows?.[0].cells
      .map(({ id }) => id)
      .filter((key) =>
        $rows?.every(
          (row) => row.cells.find(({ id }) => id === key)?.value === undefined,
        ),
      )
  }
</script>

{#if modelView}
  <div class="render-table">
    <SimpleTable
      {modelView}
      columnMaxWidthOverride={{ result: 275, referenceRange: 275 }}
    />
  </div>
{/if}
