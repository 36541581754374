import { partialRight } from "lodash"
import applyResolvers from "@/utils/applyResolvers"
import { dateTransformer, formatBytes } from "./transformers"
import { isNotFutureDate } from "@/utils/dateTime"

/**
 * As an array map method callback, getFileMetadataFromPath recieves 3 initial arguments (map method passes 3 params and
 * only first arg is utilized) and format can be bound via a partialRight
 * @param {String} url - url that metadata will be parsed from
 * @param {Boolean} [fileExtension=false] - get the file extension rather than full name
 * @returns {String} - of either file name or extension
 */
const getFileMetadataFromPath = (...[url, , , fileExtension = false]) => {
  try {
    const fileName = new URL(url).pathname.split("/").pop()
    if (fileExtension) return fileName.split(".").pop()
    return fileName
  } catch (e) {
    return url
  }
}

const singleHealthDocumentResolver = [
  {
    field: "id",
    path: "resource.id",
  },
  {
    field: "title",
    path: "resource.content[0].attachment.title",
  },
  {
    field: "fileName",
    path: "resource.content[0].attachment.url",
    transform: getFileMetadataFromPath,
  },
  {
    field: "description",
    path: "resource.description",
  },
  {
    field: "fileType",
    path: "resource.content[0].attachment.url",
    transform: partialRight(getFileMetadataFromPath, true),
  },
  {
    field: "size",
    path: "resource.content[0].attachment.size",
    transform: formatBytes,
  },

  {
    field: "url",
    path: "resource.content[0].attachment.url",
  },
  {
    field: "timestamp",
    path: "resource.date",
    transform: partialRight(dateTransformer, {
      dateFormat: "MM/DD/YYYY",
    }),
  },
]

const healthDocumentsResolvers = [
  {
    field: "healthDocuments",
    path: (data) =>
      data?.entry
        ?.filter((entry) => isNotFutureDate(entry?.resource?.date))
        .map((obj) => ({
          fields: [...applyResolvers(obj, singleHealthDocumentResolver)],
        })),
  },
]

export default healthDocumentsResolvers
