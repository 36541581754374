/* global portalApp */
const AUTH_FAIL = "auth-fail"
const AUTH_SUCCESS = "auth-success"
const authApiUrl = portalApp.env.AUTH_API_URL
const redirectUrl = `${authApiUrl}redirect_login`
const appDomain = window.location.origin
const isLocalhost = appDomain.startsWith("http://localhost:5000")
let timeoutId

// TODO: this meta tag may not be neccessary anymore now that portal 2 has been moved off *.nav.care
const ncDomains =
  "https://*.nc-acceptance.com https://*.nav.care https://*.navigatingcare.com"

const injectCspMetaTag = () => {
  const meta = document.createElement("meta")
  meta.httpEquiv = "Content-Security-Policy"
  meta.content = `frame-src ${redirectUrl} ${ncDomains}`
  document.head.appendChild(meta)
}

injectCspMetaTag()

const redirectToUniversalLogin = () => {
  if (window.location.hostname === "localhost") return
  const returnToUrl = `${redirectUrl}?returnUrl=${encodeURIComponent(
    window.location.href,
  )}`
  window.location.assign(returnToUrl)
}

const generateIframe = () => {
  const iframe = document.createElement("iframe")
  iframe.style.display = "none"
  iframe.id = "auth_iframe"
  document.body.appendChild(iframe)
  if (!isLocalhost) {
    iframe.onload = () => {
      // HACK: the assumption here is that the final landing page of redirects with valid cookies will have
      // the same domain as the parent window. If not the corsCheck will throw due to the browser blocking cross-origin frame access
      // TODO: review if refinements need to be done considering the hack
      try {
        /* eslint-disable-next-line no-unused-vars */
        const corsCheck = iframe.contentWindow.location.href
        window.parent.postMessage(AUTH_SUCCESS)
      } catch {
        window.parent.postMessage(AUTH_FAIL)
      }
    }
  }
  return iframe
}
const checkSession = async (logoutHandler, timeoutMS = 5000) => {
  const iframe = generateIframe()

  /* eslint-disable-next-line no-shadow */
  function cleanup(status, handler) {
    clearTimeout(timeoutId)
    iframe?.parentNode?.removeChild(iframe)
    window.removeEventListener("message", handler)
    if (status === AUTH_FAIL) {
      // TBD on when to handle error case but it is not needed at this time
      // logoutHandler()
    }
  }

  function handleMessage(e) {
    // when iframe redirect fails at auth0 login page and it is cross-origin no message is emitted and timeout handler handles cleanup
    if (
      (isLocalhost && e.origin === "https://patient-dev.nc-acceptance.com") ||
      e.data === AUTH_SUCCESS ||
      e.data === AUTH_FAIL
    ) {
      cleanup(e.data, handleMessage)
    }
  }

  timeoutId = setTimeout(() => {
    cleanup(AUTH_FAIL, handleMessage)
  }, timeoutMS)

  iframe.src = redirectUrl

  window.addEventListener("message", handleMessage)
}

export { redirectToUniversalLogin, checkSession }
