<script>
  import { onMount } from "svelte"
  import { errorMapping } from "@/utils/errorMapping"
  import { error } from "@/stores/stores"

  let errorDetail
  const err = $error
  const MAX_RETRIES = 1
  if (errorMapping[err?.errorCode]) {
    errorDetail = errorMapping[err?.errorCode]
  } else {
    errorDetail = errorMapping.connection_issues
  }

  // handles trying to redirect to previous page on refresh and finally to '/' after some amount of retries
  const handleErrorRedirect = () => {
    const historyLength = parseInt(
      window.sessionStorage.getItem("historyLength"), 10
    )
    const errorRetries =
      parseInt(window.sessionStorage.getItem("errorRetries"), 10) || 0
    if (errorRetries > MAX_RETRIES) {
      window.sessionStorage.removeItem("errorRetries")
      window.sessionStorage.removeItem("historyLength")
      window.location.assign("/")
      return
    }
    if (historyLength === window.history.length) {
      window.sessionStorage.setItem("errorRetries", errorRetries + 1)
      // history.length does not change on refresh or history.back()
      // need to reset this value after every refresh otherwise
      // there is a potential infinite loop from page_x <-> error_page
      window.sessionStorage.removeItem("historyLength")
      window.history.back()
      return
    }
    window.sessionStorage.setItem("historyLength", window.history.length)
  }

  onMount(() => {
    handleErrorRedirect()
  })
</script>

<section class="error-container">
  <div class="error-icon">
    <img
      src="{window.location.origin}/assets/svg/{errorDetail?.errorIcon}"
      alt="error icon"
    />
  </div>
  <p class="error-content">{errorDetail?.errorText}</p>
  <p class="error-detail">
    {@html errorDetail?.errorInstruction}
  </p>
  <p class="error-code">
    {#if errorDetail.errorCode}
      Error code: {errorDetail?.errorCode}
    {:else if errorDetail?.error}
      Error: {errorDetail?.error}
    {/if}
  </p>
</section>

<style lang="scss" src="./error.scss">
</style>
