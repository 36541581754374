/* eslint-disable class-methods-use-this */
import { msToHMS } from "@/utils/dateTime"

export default class LoginAttemptTracker {
  constructor(args) {
    Object.assign(
      this,
      {
        onLoginDisable: () => {},
        onLoginEnable: () => {},
        onCountdown: () => {},
      },
      args,
    )

    this.badLoginAttempts = parseInt(localStorage.badLoginAttempts, 10) || 0
    this.endTime = parseInt(localStorage.endTime, 10) || null

    this.init()
  }

  /**
   * Check on instantiation if there is still a timer in effect
   * Reinit timed lockout if there is
   */
  init() {
    if (localStorage.endTime) {
      if (Date.now() < this.endTime) {
        this.initializeTimer()
      } else {
        localStorage.removeItem("endTime")
      }
    }
  }

  /**
   * Clear badLoginAttempts property and localStorage values
   */
  resetAttempts() {
    localStorage.removeItem("badLoginAttempts")
    this.badLoginAttempts = 0
  }

  /**
   * Increment bad login attempts and init lockout if attempts exceed 5
   */
  handleBadLoginAttempt() {
    // conditional prevents timers longer than 15min
    if (this.badLoginAttempts <= 15) this.badLoginAttempts += 1
    localStorage.setItem("badLoginAttempts", this.badLoginAttempts)
    if (this.badLoginAttempts > 5) {
      this.calcAndStoreEndTime()
      this.initializeTimer()
    }
  }

  /**
   * Calculate timer endDate and store in localStorage
   */
  calcAndStoreEndTime() {
    this.endTime = Date.now() + 2 ** (this.badLoginAttempts - 6) * 1000
    localStorage.setItem("endTime", this.endTime)
  }

  /**
   * Emit login disable event
   * Create setInterval countdown and emit onCountdown event every second
   * On timer end, emit login enable event and remove endTime from localStorage
   */
  initializeTimer() {
    const initialCountdownVal = msToHMS(this.endTime - Date.now())
    this.onCountdown(initialCountdownVal)
    this.onLoginDisable()

    const timer = setInterval(() => {
      const remaining = this.endTime - Date.now()
      if (remaining >= 0) {
        this.onCountdown(msToHMS(remaining))
      } else {
        this.onLoginEnable()
        this.endTime = null
        localStorage.removeItem("endTime")
        clearInterval(timer)
      }
    }, 1000)
  }
}
