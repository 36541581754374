/**
 * Extract the host part from portal URL as defined in env file
 * @returns url
 */
export const getP1Host = () => {
  // eslint-disable-next-line no-undef
  const { PORTAL_1_URL } = portalApp.env
  const parts = PORTAL_1_URL.split("/")
  return parts.slice(0, parts.length - 2).join("/")
}
