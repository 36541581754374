<script>
  import SimpleModal from "./SimpleModal.svelte"
</script>

<div class="full-modal">
  <SimpleModal {...$$restProps} on:close>
    <slot name="content" slot="content" />
  </SimpleModal>
</div>

<style type="scss">
  @import "../../css/sass-imports.scss";
  @include media-breakpoint-down(sm) {
    .full-modal {
      :global {
        .modal {
          padding: 0;
        }
        .modal-wrapper {
          margin: 0;
          height: 100% !important;
        }
        .modal .modal-wrapper {
          max-height: 100%;
          border-radius: 0;
        }
      }
    }
  }
  .full-modal :global(.modal-wrapper) {
    max-width: 100% !important;
  }
</style>
