<script>
  import { onDestroy, onMount } from "svelte"
  import { isEmpty, omitBy, isNil } from "lodash"
  import { bodyClassName } from "@/stores/stores"
  import { _ } from "@/i18n"
  import omitObjProperty from "@/utils/omitObjProperty"
  import { DOBSchema, extractErrors } from "@/utils/formValidations"
  import Step from "@/components/StepIndicator/StepIndicator.svelte"
  import CurvedBanner from "@/components/CurvedBanner/CurvedBanner.svelte"

  const values = {}
  const formStepIndicatorDots = ["active", "upcoming", "upcoming"]
  let errors = {}

  const handleSubmit = async () => {
    try {
      await DOBSchema.validate(values, { abortEarly: false })
      errors = {}

      // TODO: Implement API and redirect to next step
      // fail

      // success
      sessionStorage.setItem("DOB", values)
    } catch (err) {
      errors = extractErrors(err)
    }
  }
  /**
   * validates a single field based on the element ID
   * @param {String} id DOM element ID
   * @returns {Undefined}
   */
  const inlineValidator = async (id) => {
    try {
      await DOBSchema.validateAt(id, omitBy(values, isNil), {
        abortEarly: false,
      })
    } catch (err) {
      errors = { ...errors, ...extractErrors(err) }
    }
  }

  /**
   * Prevents input if the character is not a number
   * @param {Object} evt DOM Event
   * @returns {Boolean|Undefined} true if the character is a number
   */
  function onlyAllowNumbers(evt) {
    const charCode = evt.which ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57)) evt.preventDefault()
    return true
  }

  /**
   * Checks if every error is for a missing field
   * @param {Array<String>} errorsArr - of errors
   * @returns {Boolean} true if the character is a number or a dot
   */

  const areAllErrorsEmptyField = (errorsArr) => {
    return errorsArr.every((error) => {
      return error === "REGISTRATION.MISSING_FIELD"
    })
  }

  /**
   * Set white bodyClassName for xs-down view ports
   */
  onMount(() => {
    bodyClassName.add("white-bkg-xs-down")
  })

  /**
   * Reset the bodyClassName
   */
  onDestroy(() => {
    bodyClassName.remove("white-bkg-xs-down")
  })

  $: errorsValueArr = Object.values(errors)
</script>

<div class="registration">
  <CurvedBanner
    config={{
      title: $_("REGISTRATION.WELCOME_NAV_CARE"),
      iconSrc: "../../assets/svg/nc-icon.svg",
      bannerColor: "#0E446B",
      circleBorderColor: "#91DD44",
      disableAuthButtonGroup: true,
    }}
  />
  <div class="form-section-indicator">
    {#each formStepIndicatorDots as variant}
      <Step {variant} />
    {/each}
  </div>
  <section class="auth-container">
    <div class="registration-content-wrapper">
      <h2 class="subheading">{$_("REGISTRATION.FIRST_STEP")}</h2>
      <p class="description">{$_("REGISTRATION.VERIFY_DOB_DESCRIPTION")}</p>

      <form class="auth-form" on:submit={handleSubmit}>
        <div class="inputs">
          <div class="fields month-input">
            <label for="month">{$_("REGISTRATION.MONTH")}</label>
            <input
              type="text"
              aria-label="month"
              id="month"
              name="month"
              maxlength="2"
              class="form-text-primary"
              placeholder="MM"
              class:error={errors.month}
              on:keypress={onlyAllowNumbers}
              on:focus={() => (errors = omitObjProperty("month", errors))}
              bind:value={values.month}
              on:blur={() => inlineValidator("month")}
            />
            {#if !isEmpty(errors)}
              {#if errorsValueArr.length > 1}
                <!-- check if all the validation errors are for empty fields -->
                {#if areAllErrorsEmptyField(errorsValueArr)}
                  <span id="dob-error" class="error-text"
                    >{$_("REGISTRATION.MISSING_FIELD")}</span
                  >
                {:else}
                  <!-- multiple non empty field errors are present -->
                  <span id="dob-error" class="error-text"
                    >{$_("REGISTRATION.INVALID_DATE")}</span
                  >
                {/if}
              {:else}
                <!-- single error is present -->
                <span id="dob-error" class="error-text"
                  >{$_(Object.values(errors)[0])}</span
                >
              {/if}
            {/if}
          </div>
          <div class="fields day-input">
            <label for="day">{$_("REGISTRATION.DAY")}</label>
            <input
              type="text"
              aria-label="day"
              id="day"
              name="day"
              class="form-text-primary"
              maxlength="2"
              placeholder="DD"
              class:error={errors.day}
              on:keypress={onlyAllowNumbers}
              on:focus={() => (errors = omitObjProperty("day", errors))}
              bind:value={values.day}
              on:blur={() => inlineValidator("day")}
            />
          </div>
          <div class="fields year-input">
            <label for="year">{$_("REGISTRATION.YEAR")}</label>
            <input
              type="text"
              aria-label="year"
              id="year"
              name="year"
              class="form-text-primary"
              maxlength="4"
              class:error={errors.year}
              placeholder="YYYY"
              on:keypress={onlyAllowNumbers}
              bind:value={values.year}
              on:focus={() => (errors = omitObjProperty("year", errors))}
              on:blur={() => inlineValidator("year")}
            />
          </div>
        </div>
        <div class="btn-div">
          <button type="button" id="validate-dob" class="btn btn-primary"
            >{$_("COMMON.NEXT")}
            <span class="next-icon">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5112 11.1239C14.6917 10.9771 14.8 10.7569 14.8 10.5C14.8 10.2798 14.6917 10.0596 14.5112 9.87615L7.50973 2.75688C7.32927 2.61009 7.11273 2.5 6.89619 2.5C6.64356 2.5 6.42702 2.61009 6.28266 2.75688L5.48867 3.56422C5.30822 3.74771 5.19995 3.96789 5.19995 4.18807C5.19995 4.44495 5.27213 4.66514 5.45258 4.81193L11.0466 10.5L5.45258 16.1881C5.27213 16.3716 5.19995 16.5917 5.19995 16.8119C5.19995 17.0688 5.30822 17.289 5.48867 17.4358L6.28266 18.2431C6.42702 18.4266 6.64356 18.5 6.89619 18.5C7.11273 18.5 7.32927 18.4266 7.50973 18.2431L14.5112 11.1239Z"
                  fill="white"
                />
              </svg>
            </span>
          </button>
        </div>
      </form>
    </div>
  </section>
</div>

<style src="./index.scss">
</style>
