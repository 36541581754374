import { isNil } from "lodash"

const { isNaN } = Number

/**
 *
 * @param {String} low lab value lower standard
 * @param {String} high - lab value upper standard
 * @param {Array<String>} referenceRange - lab value range
 * @param {String} referenceRangeLowUnit - lab value lower unit
 * @param {String} referenceRangeHighUnit - lab value upper unit
 * @returns {Boolean} indicating if the standard range is valid
 */
export const isValidReferenceRange = ({
  low,
  high,
  referenceRange,
  referenceRangeLowUnit,
  referenceRangeHighUnit,
}) =>
  referenceRange &&
  referenceRangeLowUnit === referenceRangeHighUnit &&
  !isNaN(parseFloat(low)) &&
  !isNil(low) &&
  !isNaN(parseFloat(high)) &&
  !isNil(high) &&
  referenceRange[0] !== "0 - 0"
