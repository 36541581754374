<script>
  import { partialRight } from "lodash"
  import { _ } from "@/i18n"
  import applyResolvers from "@/utils/applyResolvers"
  import { patientId } from "@/stores/healthRecordStores"
  import labAttachmentMetadataResolvers from "@/stores/resolvers/McKesson/labAttachmentMetadataResolvers"
  import HDCard from "@/components/HDCard/HDCard.svelte"
  import { convertComponentValueArrToObj } from "@/utils/convertComponentValueArrToObj"
  import { getData } from "@/utils/getData"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"

  export let labAttachmentId

  let formattedAttachmentData
  let attachmentState = "loading"

  /**
   * Get lab attachment
   * @param {String} attachmentId - labAttachment Id
   * @returns {Object} Resolved labAttachment
   */
  const getLabAttachment = (attachmentId) =>
    getData(
      `patients/${$patientId}/health-documents?category=LabAttachment&health_document_identifier=${attachmentId}`, // "../../../../sample-mckesson-lab-metadata.json"
      true,
    )

  /**
   * resolves lab attachment data
   * @param {String} attachmentData - from api
   * @returns {Object} Resolved labAttachment
   */
  const resolveLabAttachment = (attachmentData) => {
    const resolveData = partialRight(
      applyResolvers,
      labAttachmentMetadataResolvers,
    )

    return resolveData(attachmentData)[0]
  }

  const formatLabAttachment = (resolvedAttachment) =>
    convertComponentValueArrToObj(resolvedAttachment.value)

  $: if (labAttachmentId) {
    getLabAttachment(labAttachmentId)
      .then(resolveLabAttachment)
      .then(formatLabAttachment)
      .then((data) => {
        formattedAttachmentData = data
        attachmentState = "loaded"
      })
      .catch(() => {
        // TODO: handle error states
        attachmentState = "error"
      })
  }
  $: ({ title, documentId, date } = formattedAttachmentData || {})
</script>

{#if attachmentState === "loading"}
  <div class="loading-card">
    <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
  </div>
{/if}

{#if attachmentState === "loaded" && formattedAttachmentData}
  <HDCard
    {title}
    category="LabAttachment"
    id={documentId}
    timestamp={date}
    prefix="Published on"
  />
{/if}

<style lang="scss">
  @import "./src/css/sass-imports.scss";
  .loading-card {
    height: 135px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
  }
  :global(.sk-container) {
    align-items: baseline;
  }
  @include media-breakpoint-up(md) {
    .loading-card {
      height: 80px;
    }
  }
</style>
