<script>
  import { goto } from "@roxi/routify"
  import InfoSVG from "@/assets/svg/info.svg"
  import HistorySVG from "@/assets/svg/history.svg"
  import { enableInteractive } from "@/actions/enableInteractive"
  import { attachNodeToBody } from "@/actions/attachNodeToBody"
  import TestResultDefinition from "@/pages/health-records/test-results/[id]/_components/TestResultDefinition.svelte"

  export let data = {}
  $: ({ loincCode, title, id, panelName } = data)

  let isResultDefinitionOpen = false
</script>

{#if data}
  {#if data?.loincCode}
    <div class="has-loinc lab-result-title-container ">
      <span
        class="history-link-wrapper"
        use:enableInteractive={() =>
          $goto(
            `/health-records/test-results/${id}/history/${loincCode}?panelName=${panelName}&panelComponentName=${title}`,
          )}
      >
        <span class="history-icon">
          <HistorySVG />
        </span>
        <p class="lab-result-title">{title}</p>
      </span>
      <span
        class="info-link-wrapper"
        use:enableInteractive={() => (isResultDefinitionOpen = true)}
      >
        <InfoSVG />
      </span>
    </div>
    {#if isResultDefinitionOpen}
      <div use:attachNodeToBody>
        <TestResultDefinition
          {loincCode}
          closeModalsCallback={() => (isResultDefinitionOpen = false)}
        />
      </div>
    {/if}
  {:else}
    <div class="lab-result-title-container">
      <p class="lab-result-title">{title}</p>
    </div>
  {/if}
{/if}

<style type="scss">
  @import "../../../../../../css/sass-imports.scss";
  .lab-result-title {
    overflow-wrap: anywhere;
  }
  .has-loinc {
    display: flex;
    color: $ui-primary;
    cursor: pointer;
  }
  .info-link-wrapper {
    margin-left: 12px;
  }
  .history-link-wrapper {
    display: flex;
  }
  .history-icon {
    padding-right: 4px;
  }
</style>
