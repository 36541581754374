<script>
  import { onMount, onDestroy } from "svelte"

  import { _ } from "@/i18n"
  import { mobileToolbarConfig } from "@/stores/stores"
  import {
    activeComponentLoincCode,
    labComponentHistoryInfo,
  } from "@/stores/healthRecordStores"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"
  import HealthRecordsHeader from "@/components/HealthRecordsHeader/HealthRecordsHeader.svelte"
  import { formatLabsHistory, getLabResultValue } from "@/utils/formatLabsData"
  import { isValidReferenceRange } from "../_utils/isValidReferenceRange"
  import { isOutOfRange } from "@/utils/isOutOfRange"

  let breadcrumbLinks
  let panelName
  let panelComponentName
  let isLoading = true
  let results = []
  let loincCode
  let labResultId

  const getURLParams = () => {
    const queryString = window.location.search
    const pathParams = window.location.pathname.split("/").slice(-3)
    const urlParams = new URLSearchParams(queryString)
    panelName = urlParams.get("panelName")
    panelComponentName = urlParams.get("panelComponentName")
    // eslint-disable-next-line prefer-destructuring
    labResultId = pathParams[0]
    // eslint-disable-next-line prefer-destructuring
    loincCode = pathParams[2]
    activeComponentLoincCode.set(loincCode)
  }

  /**
   * Prepare breadcrumb link with dynamic lab result name
   * @returns {Array} Breadcrumblinks
   */
  const generateBreadcrumbLinks = () => [
    {
      title: $_("HR.HEALTH_RECORDS"),
      link: "/health-records",
      id: "health-records",
    },
    {
      title: $_("HR.TEST_RESULTS.TITLE"),
      link: "/health-records/test-results",
      id: "test-results",
    },
    {
      title: panelName || "",
      link: `/health-records/test-results/${labResultId}`,
      id: "test-results-id",
    },
    {
      title: `${panelComponentName} History`,
      active: true,
    },
  ]

  $: if ($labComponentHistoryInfo) {
    isLoading = false
    results = formatLabsHistory($labComponentHistoryInfo)
    breadcrumbLinks = generateBreadcrumbLinks()
  }

  /**
   * Get the URL Params
   * Note: Running here cause onMount only runs on page refresh
   */
  getURLParams()

  /**
   * Set the mobileToolbarConfig options for HR
   */
  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: true, text: panelName || "" },
      center: { logo: false },
      customClass: "blue-bkg-sm-down",
      redirectPath: `/health-records/test-results/${labResultId}`,
    })
  })

  /**
   * Reset the mobileToolbarConfig
   */
  onDestroy(() => {
    mobileToolbarConfig.set({})
    activeComponentLoincCode.set("")
  })
</script>

<section class="health-records-wrapper">
  <Breadcrumb {breadcrumbLinks} />
  {#if isLoading}
    <div class="spinner-wrapper">
      <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
    </div>
  {:else if results.length > 0}
    <HealthRecordsHeader
      title={`${panelComponentName} History`}
      textAlignClass="text-left"
    />
    <section class="health-records-container">
      <div class="test-history-table">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Result</th>
              <th scope="col">Reference Range</th>
            </tr>
          </thead>
          <tbody>
            {#each results as { low, high, displayDate, valueQuantity, referenceRangeUnits="", valueString, referenceRange=[], valueUnits="", interpretationConcept="", valueUnit, valueNumber, referenceRangeUnit, referenceRangeLowUnit, referenceRangeHighUnit }}
              <tr>
                <td class="date-row"
                  ><p class="date">
                    {displayDate}
                  </p></td
                >
                <td class="results-row">
                  <p>
                    <span
                      class="indicator"
                      class:highlighted={isOutOfRange({
                        resultText: getLabResultValue(
                          valueString,
                          valueQuantity,
                        ),
                        referenceRange: isValidReferenceRange({
                          low,
                          high,
                          referenceRange,
                          referenceRangeLowUnit,
                          referenceRangeHighUnit,
                        })
                          ? referenceRange[0]
                          : null,
                        interpretationConcept,
                        valueUnit,
                        valueNumber,
                        referenceRangeUnit: referenceRangeLowUnit,
                      })}
                    >
                      {valueQuantity.length ? valueQuantity[0] : valueString}
                    </span>
                    <span>{interpretationConcept}</span>
                  </p>
                </td>
                <td>
                  <p>
                    {isValidReferenceRange({
                      low,
                      high,
                      referenceRange,
                      referenceRangeLowUnit,
                      referenceRangeHighUnit,
                    })
                      ? `${referenceRange[0]} ${
                          referenceRangeUnits || valueUnits
                        }`
                      : ""}
                  </p>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    </section>
  {:else}
    <div class="no-record">
      {$_("HR.TEST_RESULTS.NO_HISTORY")}
    </div>
  {/if}
</section>

<style lang="scss" src="./index.scss"></style>
