import { datadogRum } from "@datadog/browser-rum"
import { patientContext as patientContextStore } from "@/stores/stores"
/**
 * Set default file types, filesize, initialize progress and defining different upload actions
 * @class
 * @classdesc This class is used to upload the file to the server. It will capture the progress, error and completed event and returned it to the application to take the dicision on that.
 */
export default class FileUploaderClass {
  constructor(args) {
    Object.assign(
      this,
      {
        // default args
        allowedFileTypes: [
          "doc",
          "docx",
          "rtf",
          "odt",
          "txt",
          "pdf",
          "png",
          "jpg",
          "jpeg",
          "bmp",
          "csv",
          "xls",
          "xlsx",
          "ppt",
          "pptx",
          "heic",
          "zip",
        ],
        maxFileSize: 26214400,
        onCompleted: () => {},
        onError: () => {},
        onProgress: () => {},
        progress: 0,
      },
      args,
    )
  }

  fileUpload() {
    // Initialize progress to zero
    this.progress = 0

    const formData = new FormData()
    formData.append("document", this.fileMetadata)

    // Check file size and file format before actual upload event start
    if (!this.validateUpload()) {
      return false
    }

    // Attachment api endpoint

    // Get the patient context
    let patientContext
    patientContextStore.subscribe((value) => {
      patientContext = value
    })

    const { ncPatientId } = patientContext

    // eslint-disable-next-line prefer-template, no-undef
    const URL = `${portalApp.env.API_URL}patients/${ncPatientId}/secure-messages/attachments`

    const xhr = new XMLHttpRequest()
    xhr.withCredentials = true
    xhr.open("POST", URL, true)

    // xhr progress event
    xhr.upload.addEventListener(
      "progress",
      (e) => {
        if (e.lengthComputable) {
          this.progress = e.loaded / e.total
          this.onProgress({
            progress: this.progress,
            fileName: this.fileMetadata.name,
            abort: () => xhr.abort(), // attached abort event while uploading in progress
            retry: () => this.fileUpload(),
          })
        }
      },
      false,
    )

    // xhr load event
    xhr.addEventListener("load", () => {
      if (xhr.status === 200 && xhr.readyState === 4) {
        // Trigger upload completed event after xhr.status = 200 and xhr.readyState = 4
        this.uploadCompleted(xhr.response)
      } else {
        this.error(xhr.response)
      }
    })

    // abort event
    xhr.addEventListener("abort", () => {
      this.recordFileUploadAction("cancelled")
      return false
    })

    // xhr error event
    xhr.addEventListener("error", () => this.error(xhr.response))

    // send form data
    xhr.send(formData)

    return true
  }

  /**
   * Prepare error object after failed operation and record the file upload action in datadog.
   * @param {Object} xhrResponse XHRResponse received from server after failed operation
   */
  error(xhrResponse) {
    const response = xhrResponse ? JSON.parse(xhrResponse) : {}

    this.onError({
      title: "Retry upload?",
      body: "The file upload was interrupted. You can try again or stop the upload. Stopping will remove the file from your message.",
      status: response ? response.status : "",
      error: response ? response.error : "",
      message: response ? response.message : "",
      retry: () => this.fileUpload(),
    })
    this.recordFileUploadAction(
      `failed-error-${response ? response.status : ""}`,
    )
  }

  /**
   * Validate file type and file size
   * @returns {Boolean} true if file size and file type validation pass otherwise false
   */
  validateUpload() {
    // Check for file size
    if (this.fileMetadata.size > this.maxFileSize) {
      this.onError({
        title: "File is too large",
        body: `Total size of attachments cannot exceed ${
          this.maxFileSize / (1024 * 1024)
        } MB per message.`,
      })

      this.recordFileUploadAction("failed-too-large")

      return false
    }

    // Check for file types
    if (
      !this.allowedFileTypes.includes(
        this.getFileExtension(this.fileMetadata).toLowerCase(),
      )
    ) {
      this.onError({
        title: "Unable to upload that type of file",
        body: `Files must be in one of the following formats: ${this.allowedFileTypes.join(
          ", ",
        )}.`,
      })

      this.recordFileUploadAction("failed-unsupported-file-type")

      return false
    }

    return true
  }

  /**
   * Add custom RUM action to Datadog for tracking of file upload success/errors
   * @param {String} uploadResult file upload result and status
   */
  recordFileUploadAction(uploadResult) {
    datadogRum.addAction("fileUpload", {
      file: {
        bytes: this.fileMetadata.size,
        ext: this.getFileExtension(),
      },
      result: uploadResult,
    })
  }

  /**
   * Get the file extension from filename
   * @returns {String} file extension
   */
  // Check file extension
  getFileExtension() {
    const filenameParts = this.fileMetadata.name.split(".")
    return filenameParts[filenameParts.length - 1].toLowerCase()
  }

  /**
   * Successful file uploading and record the file uploading action to datadog
   * @param {Object} xhrResponse response received from server after successful uploads
   */
  uploadCompleted(xhrResponse) {
    // Set progress to zero
    this.progress = 0

    // Dispatch completed event
    this.onCompleted({
      progress: this.progress,
      xhrResponse,
    })

    // Record success event
    this.recordFileUploadAction("success")
  }
}
