/* eslint-disable consistent-return */

const interpretationConcepts = [
  "ph",
  "aa",
  "pi",
  "hh",
  "c",
  "cx",
  "pl",
  "veryabnormal",
  "llx",
  "cr",
  "#*",
  "l^",
  "<",
  "l",
  "rh",
  ">",
  "lx",
  "h",
  "ll",
  "*l",
  "ch",
  "h",
  "ll",
  "hx",
  "low",
  "criticalhigh",
  "criticallow",
  "hc",
  "hi",
  "hhx",
  "abnormal",
  "ha",
  "low",
  "la",
  "#h",
  "c",
  "a",
  "<x",
  "il",
  "ih",
  "high",
  "l",
  "cl",
  "*h",
  "high",
  "hh",
  "*",
  "rl",
  "lo",
]

export const getRanges = (referenceRange) => {
  return referenceRange.split(" - ")
}

export const isOutOfRanges = (resultText, referenceRange) => {
  // Validate if the value is out of range.
  const [min, max] = referenceRange.split(" - ").map(parseFloat)
  // convert the result text value to a number
  const value = parseFloat(resultText)
  // check if the value is within the range
  return value < min || value > max
}

export const isOutOfRange = ({
  resultText,
  referenceRange,
  interpretationConcept,
  valueNumber,
  valueUnit,
  referenceRangeUnit,
}) => {
  if (!resultText) return

  // First we review it the interpretation concept exist and if it does validate if is included on the the list
  if (interpretationConcept) {
    return interpretationConcepts.includes(
      interpretationConcept.replace(/\s+/g, "").toLowerCase(),
    )
  }

  // The unit is the easiest way to check if is valueQuantity or valueString
  if (!valueNumber) {
    return false
  }

  // Validate if reference range is not defined
  if (!referenceRange) {
    return false
  }

  // Check if the range unit and the unit is the same
  if (valueUnit !== referenceRangeUnit) {
    return false
  }

  return isOutOfRanges(resultText, referenceRange)
}
