import { hasIn } from "lodash"
import applyResolvers from "@/utils/applyResolvers"
import { sortByDate } from "@/utils/sortByDate"
import { dateTransformer } from "./transformers"
import createProvenanceResolver from "./provenanceResolvers"
import { isNotFutureDate } from "@/utils/dateTime"

const hasProcedureNameField = (entryObj) =>
  hasIn(entryObj, "resource.code.text")

const procedureInfoResolvers = [
  {
    field: "Procedures",
    path: (data) =>
      data?.entry
        ?.filter(hasProcedureNameField)
        .filter((entry) => isNotFutureDate(entry?.resource?.performedDateTime))
        .sort((a, b) =>
          sortByDate(
            a.resource?.performedDateTime,
            b.resource?.performedDateTime,
          ),
        )
        .map((obj) => ({
          title: obj?.resource?.code?.text,
          id: obj?.resource?.id,
          // apply resolvers on sub-objects to allow for complex resolver methods(transform, find, join, ...)
          fields: applyResolvers(obj, [
            {
              field: "Date",
              path: "resource.performedDateTime",
              transform: dateTransformer,
            },

            ...createProvenanceResolver(
              "resource.",
              "Added to\nEHR",
              "M/D/YYYY",
              ", hh:mma z",
            ),
          ]),
        })),
  },
]

export default procedureInfoResolvers
