<!-- Modal.svelte -->
<script>
  // TODO: Ensure content(slots, props) can be updated without needing to destroy and rebuild
  import { onMount, createEventDispatcher, onDestroy } from "svelte"
  import { _ } from "@/i18n"
  import { trapKeyboardFocus } from "@/utils/keyboardNavigation"
  import { bodyClassName } from "../../stores/stores"

  const dispatch = createEventDispatcher()

  export let closeIcon = true
  export let closeModalOnBackDropClick = true
  export let maxWidth = 400

  let modalEl

  const focusableElementsString =
    // eslint-disable-next-line quotes
    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'

  // eslint-disable-next-line consistent-return
  onMount(() => {
    // prevent background scrolling
    bodyClassName.add("modal-open")

    // Listen for escape key press to close modal
    if (closeModalOnBackDropClick) {
      const handleKeyDown = (event) => {
        if (event.key === "Escape") {
          emitCloseEvt()
        }
      }

      window.addEventListener("keydown", handleKeyDown)

      return () => {
        window.removeEventListener("keydown", handleKeyDown)
      }
    }
  })

  onDestroy(() => {
    bodyClassName.remove("modal-open")
  })

  const emitCloseEvt = () => {
    dispatch("close")
  }
</script>

<div
  class="modal"
  bind:this={modalEl}
  use:trapKeyboardFocus={{
    querySelectorString: focusableElementsString,
    returnFocusOnDestroy: true,
  }}
  on:click|stopPropagation={(e) => {
    if (closeModalOnBackDropClick && e.target === modalEl) {
      emitCloseEvt()
    }
  }}
>
  <div class="modal-wrapper" style="--modal-size: {maxWidth}px">
    {#if closeIcon}
      <div class="close-modal">
        <button id="close-btn" class="close-btn" on:click={emitCloseEvt}>
          <span class="sr-only">{$_("GLOBAL_NAV.CLOSE")}</span>
        </button>
      </div>
    {/if}
    <div class="modal-body">
      {#if $$slots.content}
        <slot name="content" />
      {/if}
    </div>
  </div>
</div>

<style type="scss">
  @import "../../css/sass-imports.scss";
  .modal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10%;
  }
  .modal-wrapper {
    max-width: var(--modal-size);
    position: relative;
    padding-left: 24px;
    z-index: 1;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05),
      0px 10px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 8px;
    max-height: calc(100% - 48px);
    height: auto;
    flex-grow: 1;
    display: flex;
    /* for Firefox */
    min-height: 0;
  }
  // necessary for border radius in firefox
  @supports (-moz-appearance: none) {
    .modal-wrapper {
      padding-bottom: 5px;
    }
  }
  .modal-body {
    margin-top: 40px;
    padding: 0 24px 24px 0;
    overflow-y: auto;
    z-index: 1;
    text-align: initial;
    height: auto;
    max-height: calc(100% - 48px);
    flex-grow: 1;
    overflow: auto;
    /* for Firefox */
    min-height: 0;
  }

  .close-btn {
    border: 0;
    background-image: url("/assets/svg/close.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-color: white;
    width: 12px;
    height: 12px;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 13px;
  }

  ::-webkit-scrollbar {
    width: 18px;
    height: 18px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $neutral-white;
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: $neutral-300;
    border: 5px solid $neutral-white;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $neutral-300;
  }
</style>
