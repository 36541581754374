<script>
  import { truncate } from "lodash"
  import { convertFilesize } from "@/utils/convertFilesize"

  export let attachment

  const fileType = attachment.filename.split(".").pop()
</script>

<section class="attachments-container">
  <div class="position-relative">
    <div class="filetype">
      {fileType}
    </div>
    <svg
      width="37"
      height="50"
      viewBox="0 0 37 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5473 1.05513L35.9499 10.5848C36.2902 11.0223 36.5003 11.4923 36.5003 11.9141V12H25.667C25.3908 12 25.167 11.7761 25.167 11.5V0.5H25.2451C25.6549 0.5 26.1155 0.710116 26.5473 1.05513Z"
        fill="#F9FBFC"
        stroke="#C3C7CC"
      />
      <path
        d="M36.5 16.125V47.6562C36.5 48.1972 36.2709 48.6599 35.9696 48.9652C35.591 49.349 35.1455 49.5 34.6875 49.5H2.3125C1.73233 49.5 1.32631 49.3352 1.06695 49.0066L1.03253 48.963L0.98938 48.928C0.664921 48.665 0.5 48.2499 0.5 47.6562V2.34375C0.5 1.87382 0.651249 1.41904 1.0304 1.03477C1.33172 0.729368 1.78521 0.5 2.3125 0.5H21.0833V13.2812C21.0833 14.0373 21.294 14.7774 21.9002 15.2943C22.4106 15.9092 23.1443 16.125 23.8958 16.125H36.5Z"
        fill="#F0F2F5"
        stroke="#C3C7CC"
      />
    </svg>
  </div>
  <div class="d-block attachment-info">
    <div>
      <a class="attachment-title" href={attachment.url}>
        {#if attachment.filename.length <= 24}
          {attachment.filename}
        {:else}
          {truncate(attachment.filename, {
            length: 22,
          })}{`.${fileType}`}
        {/if}
      </a>
    </div>
    <div class="attachment-details">
      {`${fileType} | ${convertFilesize(attachment.size)}`}
    </div>
  </div>
</section>

<style lang="scss" src="./Attachment.scss">
</style>
