<script>
  import { createEventDispatcher } from "svelte"
  import { _ } from "@/i18n"
  import { clickOutside } from "@/utils/clickOutside"
  import {
    enableKeyboardNavigation,
    setNodeFocusOnKeyboardSelect,
    focusBackHandler,
  } from "@/utils/keyboardNavigation"

  // TODO: Is gong to be refactor on another ticket so it's fully reusable

  // Declare the dispatch
  const dispatch = createEventDispatcher()
  /**
   * @type {String} - id of the dropdown
   */
  export let id = "custom-dropdown"
  /**
   * @type {String} - default selected option
   */
  export let selectedOption = ""
  /**
   * @type {Boolean} - modifies the style, set to true if used inside a composed component e.g. MaskedInputWithDropdown
   */
  export let grouped = false
  /**
   * @type {String} - path of the icon to use
   */
  export let iconSrc = "../../assets/svg/arrow.svg"
  /**
   * @type {String} - placeholder text
   */
  export let placeholder = $_("SM.SELECT_ONE")
  /**
   * @type {Array<String>} - options to display
   */
  export let options = [
    "Spouse/Partner",
    "Parent",
    "Legal Guardian",
    "Power of Attorney",
    "Sibling",
    "Child",
    "Other",
  ]

  const isFormDirty = false
  let isOpen = false

  /**
   * Toggle dropdown and dispatch the event to the application
   */
  const toggleDropdown = () => {
    isOpen = !isOpen
    dispatch("menuOpened", {
      isOpen,
    })
  }

  /**
   * Select option
   * @param {String | Object} option option selected
   * @param {KeyboardEvent} e Keyboard event
   */
  const handleSelectOption = (option, e) => {
    // check for keyboard enter and space events as well as click
    if (e && e instanceof KeyboardEvent) {
      if (e.code !== "Space" && e.code !== "Enter") return
    }
    selectedOption = option

    // Dispatch the event with selectedProvider flag and selected recipients data
    dispatch("optionSelected", {
      option,
    })
  }
</script>

<div
  class="dropdown-container {selectedOption ? 'border-bottom-0' : ''}"
  use:enableKeyboardNavigation={`#${id} .mainmenu`}
  on:keyboardNavigate={isOpen ? "" : toggleDropdown}
  on:keyboardEscape={isOpen ? toggleDropdown : ""}
  on:keyboardUnfocus={isOpen ? toggleDropdown : ""}
  class:invalid={isFormDirty && selectedOption.length === 0}
  class:grouped
>
  <div class="dropdown">
    <button
      {id}
      class={`btn dropdown-toggle ${id} ${isOpen ? "square-btm-borders" : ""}`}
      class:invalid={isFormDirty && selectedOption.length === 0}
      class:menuOpened={isOpen}
      data-display="static"
      type="button"
      data-toggle="dropdown"
      use:clickOutside
      use:focusBackHandler
      on:focusBack={isOpen ? toggleDropdown : ""}
      on:clickOutside={isOpen ? toggleDropdown : ""}
      on:click={toggleDropdown}
    >
      {#if selectedOption}
        <span class="category-title">{selectedOption}</span>
      {:else}
        <span class="placeholder">{placeholder}</span>
      {/if}
      <span class="chevron-icon caret">
        <img src={iconSrc} alt="Arrow icon" />
      </span>
    </button>
    <ul
      {id}
      class="dropdown-menu mobile-full-height"
      class:show={isOpen}
      class:invalid={isFormDirty && selectedOption.length === 0}
    >
      {#each options as option}
        <li
          use:setNodeFocusOnKeyboardSelect={`.${id}`}
          on:click={() => handleSelectOption(option)}
          on:keydown={(e) => handleSelectOption(option, e)}
          class="mainmenu"
        >
          {option}
        </li>
      {/each}
    </ul>
  </div>
</div>

<style lang="scss" src="./Dropdown.scss">
</style>
