<script>
  import { onDestroy, onMount } from "svelte"
  import { pageBannerConfig } from "@/stores/stores"

  let windowWidth

  function setWindowWidth() {
    windowWidth = window.innerWidth
  }

  $: if (windowWidth) {
    if (windowWidth < 575) {
      pageBannerConfig.set({
        title: "About Us",
        iconSrc: "../assets/svg/nc-icon.svg",
        circleBorderColor: "#2d9b11",
        coverColor: "#ffffff",
        rightValue: "30px",
      })
    } else {
      pageBannerConfig.set({
        title: "About Us",
        iconSrc: "../assets/svg/nc-icon.svg",
        circleBorderColor: "#2d9b11",
        coverColor: "#f1f2f5",
        rightValue: "30px",
      })
    }
  }

  onMount(() => {
    windowWidth = window.innerWidth
    window.addEventListener("resize", setWindowWidth)
    return () => {
      window.removeEventListener("resize", setWindowWidth)
    }
  })

  onDestroy(() => {
    pageBannerConfig.set(null)
  })
</script>

<section class="about-us">
  <div class="container">
    <p class="mb-0">
      Navigating Care is a digital healthcare companion that helps you stay
      connected to your care team and manage your health during your treatment
      journey.
    </p>
    <div class="cards-container">
      <article class="card">
        <section class="card-icon">
          <img
            width="80"
            height="80"
            src="../assets/svg/organize-care-icon.svg"
            alt="organize-care"
          />
        </section>
        <section class="card-body">
          <h3 class="title mb-0">Organize Your Care</h3>
          <p>A single, secure place to track your health information.</p>
        </section>
      </article>
      <article class="card">
        <section class="card-icon">
          <img
            width="80"
            height="80"
            src="../assets/svg/stay-connected-icon.svg"
            alt="stay-connected"
          />
        </section>

        <section class="card-body">
          <h3 class="title mb-0">Stay Connected</h3>
          <p>
            Connect with your care team to get answers and report how you’re
            feeling.
          </p>
        </section>
      </article>
      <article class="card">
        <section class="card-icon">
          <img
            width="80"
            height="80"
            src="../assets/svg/informed-icon.svg"
            alt="be-informed"
          />
        </section>
        <section class="card-body">
          <h3 class="title mb-0">Be Informed</h3>
          <p>Get personalized education and resources to improve your care.</p>
        </section>
      </article>
    </div>
    <div class="learn-more">
      <p class="mb-0">
        Want to learn more? Visit our patient site at <a
          href="https://www.navigatingcancer.com/"
          target="_blank">NavigatingCare.com</a
        >
      </p>
    </div>
  </div>
  <div class="links-container">
    <div class="links">
      <a href="/about-us/terms-of-use"> Terms of Use </a>
      <a href="/about-us/privacy-policy"> Privacy Policy </a>
      <a
        href="https://navigatingcancer.com/requirements-incentives"
        target="_blank"
      >
        Certification
      </a>
      <a
        href="https://www.navigatingcare.com/patient/contact-us.html"
        target="_blank"
      >
        Help
      </a>
    </div>
    <p class="copyright">© 2022 Navigating Cancer, Inc v7.0</p>
  </div>
</section>

<style lang="scss" src="./index.scss"></style>
