<script>
  import { problemsInfo } from "@/stores/healthRecordStores"
  import { notifications, viewedNotifications } from "@/stores/stores"
  import { _ } from "@/i18n"
  import HealthConcernsDiagnoses from "../health-concerns-diagnoses/index.svelte"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"
  import NotificationDot from "@/components/Notifications/NotificationDot.svelte"

  let isLoading = true
  let diagnoses = []
  let diagnosisNotifications = []
  $: if ($problemsInfo) {
    isLoading = false
    diagnoses = $problemsInfo
  }
  $: if ($notifications?.data) {
    diagnosisNotifications =
      notifications.get("HEALTH_RECORD", "DIAGNOSIS")?.notifications || []
  }

  $: if (diagnosisNotifications.length && diagnoses.length) {
    const notificationQueue = new Set() // set to avoid duplicates
    diagnoses = diagnoses.map((result) => {
      const notificationId = diagnosisNotifications.find(
        ({ referenceId }) => referenceId === result?.value?.id,
      )?.id

      if (notificationId && !$viewedNotifications.includes(notificationId))
        notificationQueue.add(notificationId)

      return {
        ...result,
        notificationId,
      }
    })

    if (notificationQueue.size) {
      viewedNotifications.add([...notificationQueue])
    }
  }
</script>

<HealthConcernsDiagnoses activeClassLabel={$_("HR.DIAGNOSES")}>
  {#if isLoading}
    <div class="spinner-wrapper">
      <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
    </div>
  {:else if diagnoses.length > 0}
    {#each diagnoses as { notificationId, value: { text, fields } }}
      <article class="card">
        <section class="card-header">
          <header>
            {#if notificationId}
              <span>
                <NotificationDot />
              </span>
            {/if}
            <h3 class:has-notification={!!notificationId} class="title mb-0 ">
              {text}
            </h3>
          </header>
        </section>
        <section class="card-body">
          {#if fields.length > 0}
            <ul class="card-text" class:mt-0={!text}>
              {#each fields as { field, value }}
                {#if value}
                  <li>
                    <div class="label">
                      {field}:
                    </div>
                    <div class="value">{value}</div>
                  </li>
                {/if}
              {/each}
            </ul>
          {/if}
        </section>
      </article>
    {/each}
  {:else}
    <div class="no-record">
      {$_("HR.NO_RECORD_FOUND_FOR_HEALTH_CONCERNS&PROBLEMS")}
    </div>
  {/if}
</HealthConcernsDiagnoses>

<style lang="scss" src="./index.scss">
</style>
