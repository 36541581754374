<script>
  import { onMount, onDestroy } from "svelte"
  import { filter } from "lodash"
  import { _ } from "@/i18n"
  import { mobileToolbarConfig } from "@/stores/stores"
  import { labInfo } from "@/stores/healthRecordStores"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"
  import HealthRecordsHeader from "@/components/HealthRecordsHeader/HealthRecordsHeader.svelte"
  import { formatLabsDataArr } from "@/utils/formatLabsData"

  let breadcrumbLinks
  let labDetailsArr
  let isLoading = true
  let labResultId
  let componentResultID

  /**
   * Get last segment of the URL and filter the lab result id from lab results
   * @returns {Array} Parsed and destructured array of lab details
   */
  const getLabDetails = () => {
    const pathParams = window.location.pathname.split("/").slice(-3)
    // eslint-disable-next-line prefer-destructuring
    labResultId = pathParams[0]
    // eslint-disable-next-line prefer-destructuring
    componentResultID = pathParams[2]
    const labDetail = filter($labInfo.results, {
      value: [{ value: labResultId }],
    })
    return formatLabsDataArr(labDetail)
  }

  /**
   * Prepare breadcrumb link with dynamic lab result name
   * @returns {Array} Breadcrumblinks
   */
  const generateBreadcrumbLinks = () => [
    {
      title: $_("HR.HEALTH_RECORDS"),
      link: "/health-records",
      id: "health-records",
    },
    {
      title: $_("HR.TEST_RESULTS.TITLE"),
      link: "/health-records/test-results",
      id: "test-results",
    },
    {
      title: labDetailsArr[0]?.name || "",
      link: `/health-records/test-results/${labResultId}`,
      id: "test-results-id",
    },
    {
      title: labDetailsArr[0]?.components[componentResultID]?.text,
      active: true,
    },
  ]

  $: if ($labInfo && !$labInfo?.isLoading && $labInfo?.totalResults > 0) {
    isLoading = false
    labDetailsArr = getLabDetails()
    breadcrumbLinks = generateBreadcrumbLinks()
  }

  /**
   * Set the mobileToolbarConfig options for HR
   */
  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: true, text: $_("HR.TEST_RESULTS.TITLE") },
      center: { logo: false },
      customClass: "blue-bkg-sm-down",
      redirectPath: "/health-records/test-results",
    })
  })

  /**
   * Reset the mobileToolbarConfig
   */
  onDestroy(() => {
    mobileToolbarConfig.set({})
  })
</script>

<section class="health-records-wrapper">
  <Breadcrumb {breadcrumbLinks} />
  {#if isLoading}
    <div class="spinner-wrapper">
      <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
    </div>
  {:else if labDetailsArr.length > 0}
    <HealthRecordsHeader
      title={labDetailsArr[0]?.components[componentResultID]?.text}
      textAlignClass="text-left"
    />
    <section class="health-records-container">
      <div class="results-detail-container">
        <p class="result-detail">
          {labDetailsArr[0]?.components[componentResultID]?.valueString}
        </p>
      </div>
    </section>
  {:else}
    <div class="no-record">
      {$_("HR.TEST_RESULTS.NO_RECORD")}
    </div>
  {/if}
</section>

<style lang="scss" src="./index.scss"></style>
