import { isEmpty } from "lodash"
import applyResolvers from "@/utils/applyResolvers"
import createProvenanceResolver from "./provenanceResolvers"
import { phoneTransformer } from "./transformers"

const emptyParticipantObj = {
  field: "",
  value: [],
}

const clinicInfoResolver = [
  {
    field: "Name",
    path: "resource.name",
  },
  {
    field: "Phone",
    path: "resource.telecom[0].value",
    transform: phoneTransformer,
  },
  {
    field: "Address",
    path: [
      "resource.address[0].line[0]",
      "resource.address[0].line[1]",
      {
        join: [
          "resource.address[0].city",
          "$, ",
          "resource.address[0].state",
          "$ ",
          "resource.address[0].postalCode",
        ],
      },
    ],
  },
]

const careTeamResourceResolver = [
  {
    field: "Name",
    path: "member.display",
  },
  {
    field: "Role",
    path: "role[0].text",
  },
  {
    field: "Phone",
    path: "resource.telecom[0].value",
    transform: phoneTransformer,
  },
]

/**
 * find the participant reference in the JSON data and return the matched element.
 * @param {String} reference Participant reference
 * @param {Array} data JSON data received from api
 * @returns {Array} return matched participant from the JSON data.
 */
const getParticipantDetails = (reference, data) => {
  return data.entry.filter(
    (entryObj) =>
      `${entryObj.resource.resourceType}/${entryObj.resource.id}` === reference,
  )
}

/**
 * Custom resolver for care team
 * @param {String} participantRef Participant reference
 * @param {Array} data JSON data received from api
 * @returns {Array} return the resolver data
 */
const careTeamResolver = (participantRef, data) => {
  const participant = getParticipantDetails(
    participantRef?.member?.reference,
    data,
  )

  // Return provider name and role if no reference value is not present for that provider
  if (participant.length === 0) {
    return {
      field: "CareTeam",
      value: applyResolvers(participantRef, careTeamResourceResolver),
    }
  }

  // Merge matched participant and its reference into single array
  const participantInfo = Object.assign(participant[0], participantRef)

  // Resource type = Organization will be considered for Clinics and rest all resource type will be considered for Care Team
  if (participant?.[0]?.resource?.resourceType === "Organization") {
    if (!participant?.[0]?.resource?.name) return emptyParticipantObj
    return {
      field: "Clinics",
      value: applyResolvers(participant[0], clinicInfoResolver),
    }
  } else {
    if (!participant?.[0]?.resource?.name?.[0]?.text) return emptyParticipantObj
    return {
      field: "CareTeam",
      value: applyResolvers(participantInfo, careTeamResourceResolver),
    }
  }
}

/**
 * Filter the Care Team object
 * @param {Object} entryObj
 * @returns {Object|Boolean} return CareTeam object if matched else false
 */
const filterCareTeamResourceType = (entryObj) => {
  return entryObj?.resource?.resourceType === "CareTeam" ? entryObj : false
}

const careTeamInfoResolvers = [
  {
    field: "CareTeam",
    path: (data) => {
      const careTeamResolverData = data?.entry
        ?.filter(filterCareTeamResourceType)
        .map((obj) => {
          return obj?.resource?.participant?.map((participant) =>
            careTeamResolver(participant, data),
          )
        })

      if (!isEmpty(careTeamResolverData)) {
        const provenanceData = applyResolvers(
          data,
          createProvenanceResolver(
            "entry[0].resource.",
            "Added to EHR",
            "M/D/YYYY",
            ", hh:mma z",
          ),
        )[0]?.value

        careTeamResolverData[0].push({
          field: "Provenance",
          value: provenanceData,
        })
      }

      return careTeamResolverData
    },
  },
]

export default careTeamInfoResolvers
