<script>
  import { createEventDispatcher } from "svelte"
  import { map } from "lodash"
  import { v4 as uuidv4 } from "uuid"
  import { _ } from "@/i18n"
  import { attachmentFF } from "@/utils/featureFlag"
  import FileUploader from "@/components/FileUploader/FileUploader.svelte"
  import TextArea from "@/components/FormComponents/TextArea/TextArea.svelte"
  import { isUserClinicianOrAdmin } from "@/stores/stores"

  export let patientContext

  let message = ""
  let fileAttachments = []
  let areAttachments = false
  let pendingAttachments = false
  let isSendButtonDisabled = true
  let isAddAttachmentsButtonDisabled = false

  $: areAttachments = fileAttachments.length > 0
  $: pendingAttachments =
    areAttachments &&
    fileAttachments.some(({ isUploaded }) => isUploaded === false)
  $: {
    if ($isUserClinicianOrAdmin) {
      isSendButtonDisabled = true
      isAddAttachmentsButtonDisabled = true
    }
    // if there are pending attachments, disabled
    // if the message is empty and there is no completed attachments, disabled
    else if (areAttachments) {
      if (pendingAttachments) {
        isSendButtonDisabled = true
        isAddAttachmentsButtonDisabled = true
      } else {
        if (message.trim().length > 0) {
          isSendButtonDisabled = false
        } else {
          isSendButtonDisabled = true
        }
        isAddAttachmentsButtonDisabled = false
      }
    } else if (message.trim().length > 0) {
      isSendButtonDisabled = false
      isAddAttachmentsButtonDisabled = false
    } else {
      isSendButtonDisabled = true
      isAddAttachmentsButtonDisabled = false
    }
  }

  // Declare the dispatch
  const dispatch = createEventDispatcher()

  /**
   * Send a reply to connections or providers
   */
  const sendReply = () => {
    if ($isUserClinicianOrAdmin) return
    const payload = { message }

    // Add file attachment ids in thread payload
    if (fileAttachments.length > 0) {
      payload.attachment_ids = [
        ...new Set(map(fileAttachments, "uploadResponse.id")),
      ]

      fileAttachments = []
    }
    dispatch("sendReply", payload)
  }

  /**
   * Redirect to the thread detail page
   */
  const gotoThreadDetail = () => {
    dispatch("gotoThreadDetail", true)
  }

  /**
   * Filter cancelled attachment detail and remove it from the DOM
   * @param {Object} param0 attachment detail
   */
  const cancelUpload = ({ detail: attachment }) =>
    (fileAttachments = fileAttachments.filter(
      (file) => file.uuid !== attachment.uuid,
    ))

  /**
   * Update the isUploaded flag after successful attachment uploads and re-render the DOM
   * @param {Object} param0 attachment detail
   */
  const uploadSuccess = ({ detail: attachment }) =>
    (fileAttachments = fileAttachments.map((file) =>
      attachment.uuid === file.uuid
        ? { ...file, uploadResponse: attachment, isUploaded: true }
        : { ...file },
    ))

  /**
   * Trigger upload event for any attachment
   */
  const uploadEvent = () => {
    if ($isUserClinicianOrAdmin) return
    const inputFileElement = document.querySelector("#customInput")
    /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
    const newFileAttachments = [...inputFileElement.files].map((file) => {
      return {
        // use unique id for keyed each loop on components
        uuid: uuidv4(),
        fileMetadata: file,
        isUploaded: false,
      }
    })

    fileAttachments = [...fileAttachments, ...newFileAttachments]

    inputFileElement.value = ""
  }
</script>

<section class="message-reply-container">
  <div class="message-reply">
    <div class="reply-form">
      <TextArea
        label={$_("SM.MESSAGE")}
        placeholder={$_("SM.COMPOSE_MESSAGE_PLACEHOLDER")}
        bind:value={message}
      />
    </div>
    {#if fileAttachments.length > 0}
      <div class="pl-16 attachments-container">
        {#each fileAttachments as { uuid, fileMetadata } (uuid)}
          <FileUploader
            {uuid}
            bind:fileMetadata
            on:uploadSuccess={uploadSuccess}
            on:cancelUpload={cancelUpload}
          />
        {/each}
      </div>
    {/if}
    <div class="btn-control">
      <button
        type="button"
        class="btn btn-link discard-btn desktop-only px-0"
        on:click={gotoThreadDetail}
        id="btn-discard"
      >
        <i class="fas fa-trash-alt" />
        {$_("SM.DISCARD")}
      </button>
      <div class="sub-btns">
        {#if attachmentFF(patientContext.clinicId)}
          <div class="custom-file">
            <input
              type="file"
              name="document"
              class="custom-file-input"
              class:disabled={isAddAttachmentsButtonDisabled}
              disabled={isAddAttachmentsButtonDisabled}
              id="customInput"
              on:change={uploadEvent}
            />
            <label
              class="custom-file-label"
              for="customInput"
              class:disabled={isAddAttachmentsButtonDisabled}
              id="file-input"
            >
              <i class="fas fa-paperclip" />
              Add a photo or file
            </label>
          </div>
        {/if}
        <div>
          <button
            type="button"
            class="btn btn-primary {message.trim().length === 0
              ? 'disabled'
              : ''}"
            id={message.trim().length === 0
              ? "btn-send-disabled"
              : "btn-send-enabled"}
            class:disabled={isSendButtonDisabled}
            on:click={sendReply}
          >
            <i class="fas fa-paper-plane" />
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<style lang="scss" src="./ThreadReply.scss">
</style>
