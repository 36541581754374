<script>
  import { onMount, onDestroy } from "svelte"
  import { _ } from "@/i18n"
  import { mobileToolbarConfig } from "@/stores/stores"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"

  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: true, text: $_("MORE.ABOUT_US") },
      center: { logo: false },
      customClass: "blue-bkg-sm-down",
      redirectPath: "/about-us",
    })
  })

  onDestroy(() => {
    mobileToolbarConfig.set({})
  })

  const breadcrumbLinks = [
    {
      title: $_("MORE.ABOUT_US"),
      link: "/about-us",
      id: "about-us",
    },
    {
      title: $_("MORE.TERMS_OF_USE"),
      active: true,
    },
  ]
</script>

<div class="breadcrumb-wrapper desktop-only">
  <Breadcrumb {breadcrumbLinks} />
</div>

<article class="content-wrapper">
  <h1 class="page-title">{$_("MORE.TERMS_OF_USE")}</h1>
  <section>
    <h2 class="section-title">Acceptance of Terms</h2>
    <p>
      The following terms and conditions govern all use of the Navigating Care™
      service, including the navigatingcare.com, navigatingcancer.com, and
      nav.care websites (the "Sites"), and the services available at the Sites
      and from Navigating Care (the "Service"). The Service is owned and
      operated by Navigating Cancer, Inc. ("Navigating Cancer"). The Service is
      offered subject to acceptance without modification of all of the terms and
      conditions contained herein, and all other operating rules, policies, and
      procedures that may be published from time to time on the Sites by
      Navigating Cancer (collectively, the "Terms of Use").
    </p>
    <p>
      The Service is available only to individuals who are at least 18 years
      old. If you do not so qualify, do not attempt to register for or use the
      Service. Navigating Cancer may refuse to offer the Service to any person
      or entity and may change its eligibility criteria, at any time, in its
      sole discretion.
    </p>
    <p>
      Please take a few minutes to read the Terms of Use carefully. The
      information provided by the Service is designed to support, not replace,
      the relationship between patient/site visitors and their healthcare
      providers.
    </p>
    <p>
      IF YOU DO NOT AGREE TO ALL OF THESE TERMS OF USE, THEN DO NOT ACCESS OR
      USE THE SERVICE. BY VIEWING OR USING ALL OR ANY PART OF THE SERVICE,
      DOWNLOADING OR ACCESSING ANY MATERIALS OR BY COMPLETING THE REGISTRATION
      PROCESS, YOU AGREE TO BE BOUND BY ALL OF THESE TERMS OF USE.
    </p>
  </section>
  <section>
    <h2 class="section-title">Changes</h2>
    <p>
      Navigating Cancer reserves the right, at its sole discretion, to modify or
      replace any of these Terms of Use at any time without notice to you. All
      changes are effective immediately when we post them. It is your
      responsibility to check the Terms of Use periodically for changes. Your
      continued use of the Service following the posting of any changes to the
      Terms of Use constitutes acceptance of those changes.
    </p>
  </section>
  <section>
    <h2 class="section-title">Registration</h2>
    <p>
      As a condition to using the Service, you may be required to register with
      Navigating Care and select a password and name (User ID). You shall
      provide Navigating Care with accurate, complete, and updated registration
      information. Failure to do so shall constitute a breach of the Terms of
      Use, which may result in immediate termination of your account. You must
      ensure that your account with Navigating Care contains (at all times)
      either a valid email address and/or a valid mobile phone number. In
      addition, if you elect to create a User ID and password for accessing the
      Sites:
    </p>

    <ul>
      <li>
        you shall not (i) select or use as a User ID a name of another person
        with the intent to impersonate that person; (ii) use as a User ID a name
        subject to any rights of a person other than you without appropriate
        authorization; or (iii) use as a User ID a name that is otherwise
        offensive, vulgar, or obscene;
      </li>
      <li>
        Navigating Cancer reserves the right to refuse registration of or cancel
        a User ID in its sole discretion;
      </li>
      <li>
        you are solely responsible for activity that occurs on your account and
        shall be responsible for maintaining the confidentiality of your
        password;
      </li>
      <li>
        you shall never use another user's account without such other user's
        express permission, unless you are a designated personal representative
        of the user;
      </li>
      <li>
        you shall never transfer or assign your account to any third party or
        provide a third party with your login and password;
      </li>
      <li>
        you will immediately notify Navigating Cancer in writing of any
        unauthorized use of your account, or other account related security
        breach of which you are aware;
      </li>
      <li>
        you shall only have one (1) account with the Service (which, for
        clarity, means that you will not register (or attempt to register) for
        more than one (1) account); provided that, within such personal account
        with the Service you may be able to open sub-accounts for another user
        when acting as their personal representative.
      </li>
    </ul>
  </section>

  <section>
    <h2 class="section-title">Privacy</h2>
    <p>
      Our policies pertaining to the usage of our Service are contained in our
      <a href="/about-us/privacy-policy"> Privacy Policy </a> and are incorporated
      into these Terms of Use by this reference.
    </p>
  </section>

  <section>
    <h2 class="section-title">No Medical Advice</h2>
    <p>This Service Does Not Provide Medical Advice.</p>
    <p>
      All of the material provided by the Service, such as text, treatments,
      dosages, outcomes, charts, member profiles, graphics, images, advice,
      messages, forum postings, and any other material provided by the Service
      are for informational and educational purposes only and are not a
      substitute for professional medical advice, treatment, or your own
      judgment. We encourage you to be careful when using medical information,
      and to always seek the advice of your physician or other qualified health
      provider with any questions you may have regarding your health and based
      on your particular circumstances. Content provided on the Sites may not
      have been reviewed by your physician or other healthcare provider; you
      should always consult with your provider for an understanding and
      interpretation of any test results, clinical notes or other material that
      may be included on the Sites. Never disregard professional medical advice
      or delay in seeking it because of something you have read from the
      Service. We do not guarantee or take responsibility for the medical
      accuracy of the content provided on the Sites, nor can we assume any
      liability for the content of web sites linked to our site.
    </p>
    <p>
      Neither Navigating Cancer nor its affiliates, directors, officers,
      employees, or contractors (collectively, the "Affiliates") recommends or
      endorses any specific tests, physicians, products, procedures, opinions,
      or other information that may be mentioned by the Service. Reliance on any
      information provided by Navigating Cancer or its Affiliates, by persons
      appearing on the Sites at the invitation of Navigating Cancer, or by other
      members is solely at your own risk.
    </p>
  </section>
  <section>
    <h2 class="section-title">Not for Emergencies</h2>
    <p>
      If you think you may have a medical emergency, call your physician or 911
      immediately. The Service is for non-emergency purposes only. Do not
      attempt to access emergency care through the Service or the Sites. The
      Service is not intended to support emergency or time-critical calls or
      communications to any type of hospital, medical care facility, law
      enforcement agency or any other kind of emergency or time-critical care
      service.
    </p>
    <p>
      Navigating Cancer, its affiliates or staff, are in no way liable for such
      emergency calls or communications. You may not rely on the electronic
      communications for assistance with your urgent medical care needs. Any
      email or information submission provided on the Sites is not designed to
      facilitate medical care or emergency medical services.
    </p>
  </section>

  <section>
    <h2 class="section-title">Rules and Conduct</h2>
    <p>
      The Service (including without limitation, any content, documents, or
      other information made available via the Service) is provided only for
      your own informational purposes. Any unauthorized use of the Service,
      including without limitation, any commercial use (such as, for example,
      resale by you or your representatives to third parties), is expressly
      prohibited.
    </p>
    <p>
      As a condition of use, you promise not to use the Service for any purpose
      that is unlawful or prohibited by these Terms of Use, or any other purpose
      not reasonably intended by Navigating Cancer. You further agree to abide
      by all applicable local, state, national, and international laws and
      regulations. Use of the Service outside of the United States shall be in
      compliance with the laws of the jurisdiction from which you access the
      Service.
    </p>
    <p>
      By way of example, and not as a limitation, you agree not to (a) take any
      action or (b) upload, post, submit, or otherwise distribute or facilitate
      distribution of any content (including text, communications, software,
      images, sounds, data, or other information) using any communications
      service or other service available on or through the Service, that:
    </p>

    <ul>
      <li>
        is unlawful, threatening, abusive, harassing, defamatory, libelous,
        deceptive, fraudulent, invasive of another's privacy, tortious, obscene,
        or pornographic, profane, or which otherwise violates the Terms of Use;
      </li>
      <li>
        infringes any patent, trademark, trade secret, copyright, right of
        publicity, or other right of any party;
      </li>
      <li>
        constitutes unauthorized or unsolicited advertising, junk, or bulk
        e-mail ("spamming");
      </li>
      <li>
        imposes an unreasonable or disproportionately large load on Navigating
        Cancer's computing, storage, or communications infrastructure, or
        attempts to gain unauthorized access to the Service, other accounts,
        computer systems, or networks connected to the Service, through password
        mining or otherwise;
      </li>
      <li>
        contains software viruses or any other computer codes, files, or
        programs that are designed or intended to disrupt, damage, limit, or
        interfere with the proper function of any software, hardware, or
        telecommunications equipment or to damage or obtain unauthorized access
        to any system, data, or other information of Navigating Cancer or any
        third party; or
      </li>
      <li>harvests or collects any information from the Service; or</li>
      <li>
        impersonates any person or entity, including Navigating Cancer, or any
        employee or representative of Navigating Cancer.
      </li>
      <li>
        Navigating Cancer may, at its sole discretion, immediately terminate
        your access to the Service should your conduct fail to conform strictly
        with any provision of this section.
      </li>
    </ul>
  </section>

  <section>
    <h2 class="section-title">Content</h2>
    <p>
      Navigating Cancer has no obligation to monitor the Service, Sites, or any
      use thereof. However, Navigating Cancer reserves the right at all times
      and without notice to monitor, review, retain, or disclose any information
      as necessary to satisfy any applicable law, regulation, legal process, or
      governmental (including law enforcement) request.
    </p>
  </section>

  <section>
    <h2 class="section-title">Third-Party Sites</h2>
    <p>
      The Service may permit you to link to other websites on the Internet, and
      other websites may contain links to the Sites. These other websites are
      not under Navigating Cancer's control, and you acknowledge that Navigating
      Cancer is not responsible for the accuracy, legality, appropriateness, or
      any other aspect of the content or function of such websites. The
      inclusion of any such link does not imply endorsement by Navigating Cancer
      or any association with its operators.
    </p>
  </section>
  <section>
    <h2 class="section-title">Third-Party Content</h2>
    <p>
      The Service makes information of third parties available, including
      articles, news reports, calculation tools, marketing materials from
      pharmaceutical manufacturers, and company information and data about
      cancer care, including "patient monographs" and other data specific to the
      treatment of patients whose identity has not been divulged (the
      "Third-Party Content"). You acknowledge and agree that the Third-Party
      Content is not created or endorsed by Navigating Cancer, nor any business
      offering products or services through it. The provision of Third-Party
      Content is for general informational purposes only and does not constitute
      a recommendation or inducement for the referral of patients or the
      purchase or order of any item or service. In addition, the Third-Party
      Content is not intended to constitute or provide medical advice. You
      acknowledge that the Third-Party Content provided to Navigating Cancer is
      obtained from sources believed to be reliable and that no guarantees are
      made by the Service or the providers of the Third-Party Content as to its
      accuracy, completeness, or timeliness. You agree not to hold Navigating
      Cancer, its Affiliates, the Service, any business offering products or
      services through the Service, or any provider of Third Party Content
      liable for any medical treatment decision or other transaction you may
      make based on your reliance on or use of such data, or any liability that
      may arise due to delays or interruptions in the delivery of the Third
      Party Content for any reason. If you decide to access any of the
      Third-Party Content, then you do so at your own risk and subject to the
      terms and conditions of use such content. You further agree to comply
      fully with all applicable laws in your dealings with respect to
      Third-Party Content.
    </p>
  </section>

  <section>
    <h2 class="section-title">Clinic Integration</h2>
    <p class="fix-me-anchor-missing">
      If you are using this Service in conjunction with your physician's
      practice or clinic, you acknowledge and agree to permit certain
      information including, but not limited to your medical diagnosis,
      treatments, medications, and schedules, to be integrated with your account
      on the Service (the "Clinic Content"). The delivery to you of the Clinic
      Content is intended to provide you with accurate, timely, and relevant
      information. All Clinic Content is provided to you on an "as is" and on an
      "as available" basis and Navigating Cancer makes no assurance to you that
      the Clinic Content is accurate and correct. Like the remainder of the
      information available from the Service, the Clinic Content is subject to
      the terms of the Privacy Policy referenced above and available at
      http://www.navigatingcancer.com/privacy-policy.
    </p>
    <p>
      THERE IS NO WARRANTY OF MERCHANTABILITY, NO WARRANTY OF FITNESS FOR A
      PARTICULAR USE, NO WARRANTY OF TITLE, AND NO WARRANTY OF NON-INFRINGEMENT.
      THERE IS NO WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, REGARDING THIRD
      PARTY CONTENT AND CLINIC CONTENT.
    </p>
  </section>

  <section>
    <h2 class="section-title">Third-Party Trademarks and Copyrights</h2>
    <p>
      Third-Party Content is the property of its respective provider or its
      licensor and is protected by applicable copyright law as set forth below.
      You agree not to reproduce, retransmit, disseminate, sell, distribute,
      publish, broadcast, circulate, or commercially exploit the Third-Party
      Content in any manner without the express written consent of the
      Third-Party Content Provider.
    </p>
  </section>

  <section>
    <h2 class="section-title">Proprietary Rights</h2>
    <p>
      You agree that all content and materials delivered via the Service or
      otherwise made available by Navigating Cancer at the Sites including
      without limitation, all information, software, site design, text,
      displays, graphics, logos, buttons, icons, images, audio or video clips,
      service marks brand names, trade dress and trade names and the design,
      selection, and arrangement thereof (hereafter "Navigating Cancer
      Content"), are protected by United States and international copyrights,
      trademarks, service marks, patents, trade secrets, or other proprietary
      rights and laws. Navigating Cancer retains all right, title and interest
      in the Navigating Cancer Content and grants you no right, title or
      interest in any intellectual property owned or licensed by Navigating
      Cancer, including service marks, logos, brand names, trade dress and trade
      names ("Trademarks"). Except as expressly authorized by Navigating Cancer
      in writing, you agree not to sell, license, rent, modify, distribute,
      copy, reproduce, transmit, publicly display, publicly perform, publish,
      adapt, edit, or create derivative works from Navigating Cancer Content and
      Trademarks. However, you may print or download a reasonable number of
      copies of the materials or content from the Service for your own
      informational purposes, provided that you retain all copyright and other
      proprietary notices contained therein. Systematic retrieval of data or
      other content from the Service to create or compile, directly or
      indirectly, a collection, database, or directory without written
      permission from Navigating Cancer is prohibited.
    </p>
    <p>
      You have no rights in or to such Navigating Cancer Content or Trademarks.
      Reproducing, displaying, copying, or distributing any Navigating Cancer
      Content or Trademarks from the Service for any other purpose is strictly
      prohibited without the express prior written permission of Navigating
      Cancer. Use of the Navigating Cancer Content or Trademarks for any purpose
      not expressly permitted in these Terms of Use is prohibited. Allowing
      anyone else to use the Navigating Cancer Content or Trademarks for any
      purpose not expressly permitted in these Terms of Use is prohibited. Use
      of Navigating Cancer Content or Trademarks in connection with any product
      or service that does not belong to Navigating Cancer, in any manner that
      is likely to cause confusion, or in any manner that disparages or
      discredits Navigating Cancer is strictly prohibited without express prior
      written permission of Navigating Cancer. Any rights not expressly granted
      herein are reserved.
    </p>
  </section>
  <section>
    <h2 class="section-title">License to Navigating Care</h2>
    <p>
      Navigating Cancer has a separate arrangement with your medical provider in
      relation to the Clinic Content. The following relates to content other
      than the Clinic Content ("Non-Clinic Content"), such as the content that
      you provide directly to the Sites or the Service.
    </p>
    <p>
      By uploading, submitting, or otherwise disclosing or distributing content
      of any kind at the Sites or otherwise through the Service, you:
    </p>

    <ul>
      <li>
        grant to Navigating Cancer, its affiliates and their assignees a
        worldwide, perpetual, irrevocable, non-exclusive, transferable
        royalty-free license to access, use, reproduce, display, perform, adapt,
        modify, distribute, make derivative works of, and otherwise exploit such
        Non-Clinic Content in any form and for any purpose, including without
        limitation, any concepts, ideas, or know-how embodied therein;
      </li>
      <li>
        represent and warrant to Navigating Cancer that you own or otherwise
        control all rights to such Non-Clinic Content and that disclosure and
        use of such content by Navigating Cancer (including without limitation,
        publishing content through the Service) will not infringe or violate the
        rights of any third party; and
      </li>
      <li>
        acknowledge that the Non-Clinic Content may not be treated
        confidentially.
      </li>
    </ul>

    <p>
      Some areas of the Sites or Service may allow you to submit information and
      other content relating to your health or otherwise. While some such areas
      are monitored periodically for topicality, Navigating Cancer has no
      obligation to prescreen postings and is not responsible for their content.
      Navigating Cancer encourages you to notify us of inappropriate or illegal
      content, and Navigating Cancer reserves the right to remove postings for
      any reason.
    </p>
    <p>
      You agree not to provide Navigating Cancer with any confidential or
      proprietary information that you desire or are required to keep secret.
    </p>
    <p>
      THIS SECTION SHALL NOT APPLY TO THE EXTENT IT CONFLICTS WITH THE PRIVACY
      POLICY.
    </p>
  </section>

  <section>
    <h2 class="section-title">Termination</h2>
    <p>
      If you wish to terminate your account, you may simply discontinue using
      the Service. Navigating Cancer may terminate your access to all or any
      part of the Service at any time, with or without cause, with or without
      notice, effective immediately. Without limiting the foregoing, the
      following may lead to a termination by Navigating Cancer of your
      membership and use of the Service: (a) breaches or violations of this
      Agreement or other incorporated agreements or guidelines, (b) requests by
      law enforcement or other government agencies, (c) a request by you
      (self-initiated membership cancellation), (d) unexpected technical issues
      or problems, and (e) extended periods of inactivity. Navigating Cancer has
      no obligation to maintain, store, or transfer to you, information or data
      that you have posted on or uploaded to the Service. Navigating Cancer will
      not be liable to you or any third party for termination of your access to
      the Service.
    </p>
  </section>

  <section>
    <h2 class="section-title">Disclaimer of Warranties</h2>
    <p>
      THE SERVICE (AND ALL MATERIALS, INFORMATION, PRODUCTS AND SERVICES
      INCLUDED IN OR AVAILABLE THROUGH THE SITES (COLLECTIVELY, THE CONTENT))
      ARE PROVIDED "AS IS" AND "AS AVAILABLE". THE SERVICE AND CONTENT ARE
      PROVIDED WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT
      NOT LIMITED TO, THE IMPLIED WARRANTIES OF NON-INFRINGEMENT, TITLE,
      MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES
      IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE
      EXPRESSLY DISCLAIMED. THIS DOES NOT AFFECT THOSE WARRANTIES THAT ARE NOT
      SUBJECT TO EXCLUSION, RESTRICTION, OR MODIFICATION UNDER THE LAWS
      APPLICABLE TO THE TERMS OF USE.
    </p>
    <p>
      NAVIGATING CANCER, AND ITS AFFILIATES, LICENSORS, AND SUPPLIERS DO NOT
      WARRANT THAT: (A) THE CONTENT IS TIMELY, ACCURATE, ADEQUATE, COMPLETE,
      RELIABLE, USEFUL OR CORRECT; (B) THE SERVICE WILL BE SECURE OR AVAILABLE
      AT ANY PARTICULAR TIME OR LOCATION; (C) ANY DEFECTS, ERRORS, OR OMISSIONS
      WILL BE CORRECTED; (D) THE CONTENT IS FREE OF VIRUSES, WORMS, OR OTHER
      HARMFUL COMPONENTS; OR (E) THE RESULTS OF USING THE SERVICE WILL MEET YOUR
      REQUIREMENTS.
    </p>
    <p>
      YOUR USE OF THE SERVICE IS SOLELY AT YOUR OWN RISK. THE CONTENT IS MADE
      AVAILABLE FOR EDUCATIONAL PURPOSES ONLY AND DOES NOT CONSTITUTE
      PROFESSIONAL ADVICE. PROFESSIONAL MEDICAL AND HEALTHCARE CONSULTATION
      DEPENDS ON THE PRECISE FACTS OF EACH AND EVERY INDIVIDUAL CASE. THE
      CONTENT IS IN NO WAY A SUBSTITUTE FOR ANY SUCH PROFESSIONAL CONSULTATION.
      ALWAYS CONSULT YOUR PHYSICIAN OR OTHER RELEVANT AND QUALIFIED HEALTHCARE
      PROVIDERS FOR DIAGNOSTIC AND TREATMENT PURPOSES. WE DO NOT ENDORSE, OR
      RECOMMEND, OR IN ANY MANNER ASSUME RESPONSIBILITY FOR, ANY CONTENT.
    </p>
    <p>
      SOME STATES DO NOT ALLOW CERTAIN WARRANTY LIMITATIONS, SO THE FOREGOING
      LIMITATIONS MAY NOT APPLY TO YOU.
    </p>
  </section>

  <section>
    <h2 class="section-title">Limitation of Liability</h2>
    <p>
      IN NO EVENT SHALL NAVIGATING CANCER (OR ITS AFFILIATES, LICENSORS, OR
      SUPPLIERS) BE LIABLE TO YOU OR ANY OTHER PARTY FOR ANY INDIRECT, SPECIAL,
      CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES
      FOR LOST PROFITS, BUSINESS INTERRUPTION, GOODWILL, OR OTHER INTANGIBLE
      LOSSES OF ANY KIND ARISING OUT OF OR CONCERNING THE SUBJECT MATTER OF THIS
      AGREEMENT, REGARDLESS OF THE FORM OF ANY CLAIM OR ACTION (WHETHER IN
      CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE), FOR (A) YOUR USE OF
      OR INABILITY TO USE THE SERVICE OR THE CONTENT CONTAINED THEREIN, (B) ANY
      MATTER BEYOND ITS REASONABLE CONTROL, OR (C) ANY LOSS OR INACCURACY OF
      DATA, ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, DEFECT, DELAY IN
      OPERATION OR TRANSMISSION, LOSS OR INTERRUPTION OF USE, COMPUTER VIRUS OR
      LINE OR SYSTEM FAILURE, OR COST OF PROCURING SUBSTITUTE CONTENT OR
      SERVICES, EVEN IF NAVIGATING CANCER HAS BEEN ADVISED OF THE POSSIBILITY OF
      SUCH DAMAGES. THESE LIMITATIONS ARE INDEPENDENT FROM ALL OTHER PROVISIONS
      OF THIS AGREEMENT AND SHALL APPLY NOTWITHSTANDING THE FAILURE OF ANY
      REMEDY PROVIDED HEREIN. SOME STATES DO NOT ALLOW THE LIMITATION OF CERTAIN
      DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
    </p>
  </section>

  <section>
    <h2 class="section-title">Indemnification</h2>
    <p>
      You agree to defend, indemnify, and hold harmless Navigating Cancer, its
      affiliates and their employees, contractors, officers, directors, and
      representatives from all liabilities, claims, and expenses, including
      attorneys' fees, that arise from your use or misuse of the Service or your
      violation of these Terms of Use or other contract between you and
      Navigating Cancer. Navigating Cancer reserves the right, at its own
      expense, to assume the exclusive defense and control of any matter
      otherwise subject to indemnification by you, in which event you will
      assist and cooperate with Navigating Cancer in asserting any available
      defenses.
    </p>
  </section>

  <section>
    <h2 class="section-title">International Use</h2>
    <p>
      Navigating Cancer makes no representation that the Content is appropriate
      or available for use in locations outside the United States, and accessing
      the Service is prohibited from territories where such Content is illegal.
      If you access the Service from other locations, you do so at your own
      initiative and are responsible for compliance with local laws.
    </p>
  </section>

  <section>
    <h2 class="section-title">Dispute Resolution</h2>
    <p>
      A printed version of these Terms of Use and of any notice given in
      electronic form shall be admissible in judicial or administrative
      proceedings based upon or relating to this agreement to the same extent
      and subject to the same conditions as other business documents and records
      originally generated and maintained in printed form. You and Navigating
      Cancer agree that any cause of action arising out of or related to the
      Service must commence within one (1) year after the cause of action arose;
      otherwise, such cause of action is permanently barred. You agree that you
      will notify Navigating Cancer in writing of any claim or dispute
      concerning or relating to your use of the Sites or Service and give
      Navigating Cancer a reasonable period of time to address it before
      bringing any legal action, either individually or as a class member
      against Navigating Cancer.
    </p>
    <p>
      These Terms of Use shall be governed by and construed in accordance with
      the laws of the State of Washington, without regard to its conflicts of
      law rules, and the United States of America. You expressly agree that the
      exclusive jurisdiction for any claim or action arising out of or relating
      to these Terms of Use or your use of the Sites or Service shall be filed
      only in the state or federal courts located in Washington, USA, and you
      further agree and submit to the exercise of personal jurisdiction of such
      courts for the purpose of litigating any such claim or action. Use of the
      Service is not authorized in any jurisdiction that does not give effect to
      all provisions of these Terms of Use, including without limitation, this
      section.
    </p>
  </section>

  <section>
    <h2 class="section-title">Integration and Severability</h2>
    <p>
      These Terms of Use are the entire agreement between you and Navigating
      Cancer with respect to the Service and use of the Sites, and supersede all
      prior or contemporaneous communications and proposals (whether oral,
      written, or electronic) between you and Navigating Cancer with respect to
      the Service (but excluding the use of any software which may be subject to
      a separate end-user license agreement). If any provision of the Terms of
      Use is found to be unenforceable or invalid, that provision will be
      limited or eliminated to the minimum extent necessary so that the Terms of
      Use will otherwise remain in full force and effect and enforceable.
    </p>
  </section>

  <section>
    <h2 class="section-title">Miscellaneous</h2>
    <p>
      These Terms of Use and the Service contemplated hereunder are personal to
      you, and are not assignable, transferable, or sublicensable by you except
      with Navigating Cancer's prior written consent. Navigating Cancer may
      assign, transfer, or delegate any of its rights and obligations hereunder
      without your consent. All waivers and modifications must be in writing
      signed by both parties, except as otherwise provided herein. No agency,
      partnership, joint venture, or employment relationship is created as a
      result of this Agreement, and neither party has any authority of any kind
      to bind the other in any respect. In any action or proceeding to enforce
      rights under this Agreement, the prevailing party will be entitled to
      recover costs and attorneys' fees. All notices under this Agreement will
      be in writing and will be deemed to have been duly given when received, if
      personally delivered or sent by certified or registered mail, return
      receipt requested; when receipt is electronically confirmed, if
      transmitted by facsimile or e-mail; or the day after it is sent, if sent
      for next day delivery by recognized overnight delivery service.
    </p>
  </section>

  <section>
    <h2 class="section-title">Copyright and Trademark Notices</h2>
    <p>
      Unless otherwise indicated, these Terms of Use and all Content provided by
      Navigating Cancer are copyright © 2008-2021 Navigating Cancer.com, Inc.
      All rights reserved.
    </p>
    <p>
      "Navigating Cancer," "Navigating Care," as well as page headers, custom
      graphics, buttons, images, and other content on the Sites, are subject to
      trademark, service mark, trade dress, copyright, and or other proprietary
      or intellectual property rights or licenses held by Navigating Cancer,
      Inc. or its licensors, supplier, or partners. Other trademarks, product
      names, and company names or logos used on the Sites are the property of
      their respective owners. Except as expressly authorized, the use or misuse
      of any trademarks, trade names, logos, images, graphics, or content from
      the Sites is strictly prohibited.
    </p>
  </section>

  <section>
    <h2 class="section-title">Contact</h2>
    <p>
      You may contact Navigating Cancer at the following address:
      <a href="mailto:info@navigatingcancer.com">info@navigatingcancer.com</a>
    </p>
    <p>Last Modified: October 15, 2020.</p>
  </section>
</article>

<style lang="scss" src="./index.scss">
</style>
