/**
 * emit a custom DOM event based on a keyboard input
 * @param {HTMLElement} node - DOM element that action/listener will be attached to
 * @param {String} keyCode - the keybord key code
 * @param {Boolean} shiftKey - true if shift key is pressed
 * returns {Undefined}
 */
const emitKeyboardEvent = (node, keyCode, shiftKey) => {
  // eslint-disable-next-line default-case
  switch (true) {
    case keyCode === "Escape":
      node.dispatchEvent(new CustomEvent("keyboardEscape", {}))
      break
    case keyCode === "Tab" && shiftKey:
      node.dispatchEvent(new CustomEvent("focusBack", {}))
      break
    case keyCode === "Tab":
      node.dispatchEvent(new CustomEvent("keyboardUnfocus", {}))
      break
    case keyCode === "Enter" || keyCode === "Space":
      node.dispatchEvent(new CustomEvent("keyboardSelect", {}))
      break
    case keyCode === "ArrowUp" || keyCode === "ArrowDown":
      node.dispatchEvent(new CustomEvent("keyboardNavigate", {}))
      break
  }
}
/**
 * listens for keyboard events on a DOM element
 * @param {HTMLElement} node - DOM element that action/listener will be attached to
 * returns {Undefined}
 */
export const handleKeyboardEvents = (node) => {
  const handleKeydown = ({ code: keyCode, shiftKey }) => {
    emitKeyboardEvent(node, keyCode, shiftKey)
  }

  node.addEventListener("keydown", handleKeydown)
  return {
    destroy() {
      node.removeEventListener("keydown", handleKeydown)
    },
  }
}

/**
 * Allow keyboard navigation on subset of nodes within a parent node
 * @param {HTMLElement} parentNode - DOM element that action/listener will be attached to
 * @param {String} querySelectorString - query for items that need to be keyboard navigable
 * @returns undefined
 */
export const enableKeyboardNavigation = (parentNode, querySelectorString) => {
  const handleKeydown = ({ code: keyCode, shiftKey }) => {
    // handle event dispatchers before navigation logic
    // eslint-disable-next-line default-case
    emitKeyboardEvent(parentNode, keyCode, shiftKey)

    // navigation movement specific logic
    if (keyCode !== "ArrowUp" && keyCode !== "ArrowDown") return

    const htmlCollection = parentNode.querySelectorAll(querySelectorString)

    // prevent further logic if there's no available nodes
    if (htmlCollection.length === 0) return

    // enables manual setting of focous on elements without being able to tab focus on them
    htmlCollection.forEach((el) => el.setAttribute("tabindex", "-1"))

    const items = [...htmlCollection]
    const current = document.activeElement
    const currentIndex = items.indexOf(current)
    let newIndex

    // if the currently focused element was NOT a list item, then default to focusing the first item in the list (index 0)
    if (currentIndex === -1) {
      newIndex = 0
      // otherwise, the currently focused element is an item in our list
    } else if (keyCode === "ArrowUp") {
      const decrementedIndex = currentIndex - 1
      // if the decremented index is less than the 0 index of items, loop the index to that of the last item in the array
      if (decrementedIndex < 0) {
        newIndex = items.length - 1
      } else {
        newIndex = decrementedIndex
      }
      // if the DOWN key has been pressed
    } else {
      const incrementedIndex = currentIndex + 1
      // if the incremented index is greater than the last index of the array, loop the index back to that of the first item in the array
      if (incrementedIndex > items.length - 1) {
        newIndex = 0
      } else {
        newIndex = incrementedIndex
      }
    }

    items[newIndex].focus()
  }

  parentNode.addEventListener("keydown", handleKeydown)

  return {
    destroy() {
      parentNode.removeEventListener("keydown", handleKeydown)
    },
  }
}

/**
 * On enter or space, of a node, set focus to another node
 * @param {HTMLElement} node - DOM element that action/listener will be attached to
 * @param {String} querySelectorString - query for items that need to be keyboard navigable
 * @returns undefined
 */
export const setNodeFocusOnKeyboardSelect = (node, querySelectorString) => {
  const setFocusOnNode = ({ code: keyCode }) => {
    if (keyCode !== "Enter" && keyCode !== "Space") return

    const nodeToFocus = document.querySelector(querySelectorString)
    nodeToFocus.focus()
  }

  node.addEventListener("keydown", setFocusOnNode)

  return {
    destroy() {
      node.removeEventListener("keydown", setFocusOnNode)
    },
  }
}

/**
 * Dispatch event on shift tab on node
 * @param {HTMLElement} node DOM element that action/listener will be attached to
 * @returns {Object} Add keydown event listener for the doument
 */
export const focusBackHandler = (node) => {
  const handleKeydown = ({ code: keyCode, shiftKey }) => {
    if (keyCode !== "Tab" || !shiftKey) return
    node.dispatchEvent(new CustomEvent("focusBack", {}))
  }

  node.addEventListener("keydown", handleKeydown)

  return {
    destroy() {
      node.removeEventListener("keydown", handleKeydown)
    },
  }
}

/**
 * Trap the focusable elements only to the selected node.
 * @param {HTMLElement} node DOM element that action/listener will be attached to
 * @param {Object} options - for the focus trap
 * @param {String} options.querySelectorString - query for items that need to be focusable
 * @param {String} options.enabled - query for items that need to be focusable\
 * @param {Boolean} options.returnFocusOnDestroy - return focus to item focused before trap
 * @returns {Object} Add keydown event listener for the document
 */
export const trapKeyboardFocus = (
  node,
  { querySelectorString, enabled = true, returnFocusOnDestroy },
) => {
  const focusedElementBeforeTrap = document.activeElement

  function trapKeyboardNavigation(e) {
    const isTabPressed = e.key === "Tab" || e.keyCode === 9
    if (!isTabPressed) {
      return
    }

    const focusableContent = node.querySelectorAll(querySelectorString)
    if (!focusableContent.length) return
    const firstFocusableElement = focusableContent[0] // get first element to be focused inside node

    const lastFocusableElement = focusableContent[focusableContent.length - 1] // get last element to be focused inside node

    const { activeElement } = document

    if (e.shiftKey) {
      // if shift key pressed for shift + tab combination
      if (activeElement === firstFocusableElement) {
        lastFocusableElement?.focus() // add focus for the last focusable element
        e.preventDefault()
      }
    } else if (activeElement === lastFocusableElement) {
      // if focused has reached to last focusable element then focus first focusable element after pressing tab
      firstFocusableElement?.focus() // add focus for the first focusable element
      e.preventDefault()
    }
  }

  const initFocus = () => {
    const initialFocusableContent = node.querySelectorAll(querySelectorString)
    const initialFocusableElement = initialFocusableContent[0]
    initialFocusableElement?.focus()
    node.addEventListener("keydown", trapKeyboardNavigation)
  }

  if (enabled) {
    initFocus()
  }
  return {
    update(options) {
      // simulates a component being destroyed and recreated
      node.removeEventListener("keydown", trapKeyboardNavigation)
      if (options.enabled) {
        initFocus()
      }
    },
    destroy() {
      node.removeEventListener("keydown", trapKeyboardNavigation)
      if (returnFocusOnDestroy) {
        focusedElementBeforeTrap.focus()
      }
    },
  }
}

// TODO: utilize this in the compose screen once svelte version is updated
/**
 * Sets focus when tabbing for the first time on a specific node
 * @param {*} node -listening parent node (usually document body)
 * @param {*} querySelectorString - single element for first tab focus
 * @returns {Object} with destroy method
 */
export const setFirstTabbedElement = (node, querySelectorString) => {
  const handleTab = (e) => {
    const recipientDropdown = document.querySelector(querySelectorString)
    if (
      e.keyCode === 9 &&
      recipientDropdown &&
      document.activeElement === document.body
    ) {
      e.preventDefault()
      recipientDropdown.focus()
    }
  }

  node.addEventListener("keydown", handleTab, true)

  return {
    destroy() {
      document.removeEventListener("click", handleTab, true)
    },
  }
}
