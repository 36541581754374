import { get } from "svelte/store"
import { _ } from "@/i18n"

export function clientSidePagination(
  node,
  { totalRows, updater, listId, chunkSize = 25 },
) {
  const rowContainerEl = document.getElementById(listId)
  const initialRowCount = rowContainerEl.children.length
  if (initialRowCount < totalRows) {
    const loadBtn = document.createElement("button")
    loadBtn.appendChild(document.createTextNode(get(_)("HR.LOAD_MORE")))
    loadBtn.className = "btn btn-secondary load-more-btn"
    loadBtn.addEventListener("click", () => {
      const rowCount = rowContainerEl.children.length
      if (rowCount < totalRows) {
        updater(chunkSize)
      }

      if (rowCount >= totalRows || rowCount + chunkSize >= totalRows) {
        loadBtn.disabled = true
        loadBtn.style.display = "none"
      }
    })

    node.classList.add("client-side-pagination-container")
    node.appendChild(loadBtn)
  }

  return {
    destroy() {
      return null
    },
  }
}
