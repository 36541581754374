<script>
  import { procedureInfo } from "@/stores/healthRecordStores"
  import { notifications, viewedNotifications } from "@/stores/stores"
  import { _ } from "@/i18n"
  import ProceduresImmunizations from "../procedures-immunizations/index.svelte"
  import { clientSidePagination } from "@/utils/clientSidePagination"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"
  import NotificationDot from "@/components/Notifications/NotificationDot.svelte"

  let isLoading = true
  let results = []
  let resultNotifications = []
  let visibleRowCount = 25

  const updateRowCount = (chunkSize) => {
    visibleRowCount += chunkSize
  }
  /**
   * Formats the procedure resolver response
   * @param {Array<Object>} resolverResponse response received from resolver
   * @returns {Array<Object>} return formatted data
   */
  const formatProcedureData = (resolverResponse) => {
    return resolverResponse.map(({ value }) => {
      const { title, id, fields } = value
      return {
        title,
        id,
        fields,
      }
    })
  }

  $: if ($procedureInfo) {
    isLoading = false
    results = formatProcedureData($procedureInfo)
  }

  $: if ($notifications?.data) {
    resultNotifications =
      notifications.get("HEALTH_RECORD", "PROCEDURE")?.notifications || []
  }

  $: if (resultNotifications.length && results.length) {
    const notificationQueue = new Set() // set to avoid duplicates
    results = results.map(({ id, ...result }) => {
      const notificationId = resultNotifications.find(
        ({ referenceId }) => referenceId === id,
      )?.id

      if (notificationId && !$viewedNotifications.includes(notificationId))
        notificationQueue.add(notificationId)

      return {
        ...result,
        notificationId,
      }
    })

    if (notificationQueue.size) {
      viewedNotifications.add([...notificationQueue])
    }
  }
</script>

<ProceduresImmunizations activeClassLabel="Procedures">
  {#if isLoading}
    <div class="spinner-wrapper">
      <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
    </div>
  {:else if results.length > 0}
    <div
      data-testid="procedures"
      use:clientSidePagination={{
        totalRows: results.length,
        updater: updateRowCount,
        listId: "procedures",
      }}
    >
      <div id="procedures">
        {#each results.slice(0, visibleRowCount) as { title, notificationId, fields }}
          <article class="card">
            <section class="card-header">
              <header>
                {#if notificationId}
                  <NotificationDot />
                {/if}
                <h3 class="title mb-0 " class:has-notification={notificationId}>
                  {title}
                </h3>
              </header>
            </section>
            <section class="card-body mt-0">
              <ul class="card-text">
                {#each fields as { field, value }}
                  {#if field.toLowerCase() !== "id"}
                    <li>
                      <div
                        class="label"
                        class:multiline={field === "Added to\nEHR"}
                      >
                        {field}:
                      </div>
                      <div class="value">
                        {value}
                      </div>
                    </li>
                  {/if}
                {/each}
              </ul>
            </section>
          </article>
        {/each}
      </div>
    </div>
  {:else}
    <div class="no-record">
      {$_("HR.NO_RECORD_FOUND_FOR_PROCEDURES_&_IMMUNIZATIONS")}
    </div>
  {/if}
</ProceduresImmunizations>

<style lang="scss">
  @import "../index.scss";
  .card-body li {
    @include body-regular;
    .label {
      margin-bottom: 0;
      min-width: auto;
    }
    .value {
      margin-left: 6px;
    }
  }
</style>
