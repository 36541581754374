<script>
  import { onDestroy, onMount } from "svelte"
  import { isEmpty } from "lodash"
  import { bodyClassName } from "@/stores/stores"
  import { _ } from "@/i18n"
  import {
    passwordSchema,
    personalRepresentativeSchema,
    extractErrors,
  } from "@/utils/formValidations"
  import Step from "@/components/StepIndicator/StepIndicator.svelte"
  import PasswordInputWithToggle from "@/components/PasswordInputWithToggle/PasswordInputWithToggle.svelte"
  import MaskedInputWithDropdown from "@/components/MaskedInputWithDropdown/MaskedInputWithDropdown.svelte"
  import Dropdown from "@/components/Dropdown/Dropdown.svelte"
  import CurvedBanner from "@/components/CurvedBanner/CurvedBanner.svelte"

  const values = {}
  let errors = {}
  let passwordErrors = {}
  let showPasswordErrors = false
  let isPersonalRepresentative = false
  const phoneOptions = ["Mobile", "Home", "Work"]
  const phoneMaskOptions = {
    mask: "(000) 000-0000",
  }
  const formStepIndicatorDots = ["complete", "active", "upcoming"]

  const validatePassword = async (showErrors) => {
    try {
      showPasswordErrors = false
      await passwordSchema.validate(values, { abortEarly: false })
      passwordErrors = {}
    } catch (err) {
      passwordErrors = extractErrors(err)
      if (showErrors) showPasswordErrors = true
    }
  }

  const handleSubmit = async () => {
    try {
      await validatePassword(true)
      if (isPersonalRepresentative) {
        await personalRepresentativeSchema.validate(values, {
          abortEarly: false,
        })
      }
      errors = {}
      sessionStorage.setItem("SECURE_ACCOUNT", values)
      sessionStorage.setItem(
        "IS_PERSONAL_REPRESENTATIVE",
        isPersonalRepresentative,
      )
    } catch (err) {
      errors = extractErrors(err)
    }
  }

  const handleInput = (e, id) => {
    values[id] = e.target.value

    if (id === "password") validatePassword()
  }

  const handleRelationshipSelected = ({ detail }) => {
    values.relationship = detail.option
  }

  const handlePhoneSelected = ({ detail }) => {
    values.phoneType = detail.option
  }
  /**
   * Set white bodyClassName for xs-down view ports
   */
  onMount(() => {
    bodyClassName.add("white-bkg-xs-down")
  })

  /**
   * Reset the bodyClassName
   */
  onDestroy(() => {
    bodyClassName.remove("white-bkg-xs-down")
  })
</script>

<div class="registration">
  <CurvedBanner
    config={{
      title: $_("REGISTRATION.SECURE_ACCOUNT"),
      iconSrc: "../../assets/svg/auth-icons/locked.svg",
      bannerColor: "#0E446B",
      circleBorderColor: "#91DD44",
      disableAuthButtonGroup: true,
    }}
  />
  <div class="form-section-indicator">
    {#each formStepIndicatorDots as variant}
      <Step {variant} />
    {/each}
  </div>
  <section class="auth-container">
    <form class="auth-form">
      <div class="fields fields--with-requirements">
        <label for="password">{$_("REGISTRATION.CREATE_PASSWORD")}</label>
        <PasswordInputWithToggle
          hasRequirements
          {showPasswordErrors}
          {passwordErrors}
          value={values.password}
          error={showPasswordErrors && !isEmpty(passwordErrors)}
          on:blur={() => validatePassword(true)}
          on:input={(e) => handleInput(e, "password")}
        />
      </div>
    </form>

    <form on:submit|preventDefault={handleSubmit}>
      <div class="personal-representative">
        <div class="toggle-container">
          <div class="form-text-primary d-flex">
            <input
              aria-label="isPersonalRepresentative"
              type="checkbox"
              name="isPersonalRepresentative"
              bind:checked={isPersonalRepresentative}
            />

            <label for="personal-representative" class="mb-0"
              >{$_("REGISTRATION.PERSONAL_REPRESENTATIVE_DISCLOSURE")}</label
            >
          </div>
        </div>
        <input
          type="hidden"
          name="isPersonalRepresentative"
          bind:value={isPersonalRepresentative}
        />
        {#if isPersonalRepresentative}
          <div class="personal-representative-form">
            <h2>{$_("REGISTRATION.PERSONAL_REPRESENTATIVE_FORM.HEADER")}</h2>
            <div class="fields pt-0">
              <label for="firstName"
                >{$_(
                  "REGISTRATION.PERSONAL_REPRESENTATIVE_FORM.FIRST_NAME",
                )}</label
              >
              <input
                type="text"
                aria-label="firstName"
                id="firstName"
                name="firstName"
                autocomplete="off"
                class:error={errors.firstName}
                class="form-text-primary"
                bind:value={values.firstName}
              />
              {#if errors.firstName}
                <span id="firstName-error" class="error-text"
                  >{$_(errors.firstName)}</span
                >
              {/if}
            </div>
            <div class="fields pt-0">
              <label for="lastName"
                >{$_(
                  "REGISTRATION.PERSONAL_REPRESENTATIVE_FORM.LAST_NAME",
                )}</label
              >
              <input
                type="text"
                aria-label="lastName"
                id="lastName"
                name="lastName"
                autocomplete="off"
                class:error={errors.lastName}
                class="form-text-primary"
                bind:value={values.lastName}
              />
              {#if errors.lastName}
                <span id="lastName-error" class="error-text"
                  >{$_(errors.lastName)}</span
                >
              {/if}
            </div>
            <div class="fields pt-0">
              <label for="relationship"
                >{$_(
                  "REGISTRATION.PERSONAL_REPRESENTATIVE_FORM.RELATIONSHIP",
                )}</label
              >
              <Dropdown on:optionSelected={handleRelationshipSelected} />
            </div>

            {#if values.relationship === "Other"}
              <div class="fields pt-0">
                <label for="specifyRelationship"
                  >{$_(
                    "REGISTRATION.PERSONAL_REPRESENTATIVE_FORM.SPECIFY_RELATIONSHIP",
                  )}</label
                >
                <input
                  type="text"
                  aria-label="specifyRelationship"
                  id="specifyRelationship"
                  name="specifyRelationship"
                  autocomplete="off"
                  class:error={errors.specifyRelationship}
                  class="form-text-primary"
                  bind:value={values.specifyRelationship}
                />
                {#if errors.specifyRelationship}
                  <span id="specifyRelationship-error" class="error-text"
                    >{$_(errors.specifyRelationship)}</span
                  >
                {/if}
              </div>
            {/if}
            <div class="fields pt-0">
              <label for="phone"
                >{$_("REGISTRATION.PERSONAL_REPRESENTATIVE_FORM.PHONE")}</label
              >
              <MaskedInputWithDropdown
                ariaLabel="phone"
                id="phone"
                name="phone"
                error={errors.phone}
                maskOptions={phoneMaskOptions}
                options={phoneOptions}
                bind:value={values.phone}
                on:optionSelected={handlePhoneSelected}
              />
            </div>
            <div class="fields pt-0">
              <label for="email"
                >{$_("REGISTRATION.PERSONAL_REPRESENTATIVE_FORM.EMAIL")}</label
              >
              <input
                type="text"
                aria-label="email"
                id="email"
                name="email"
                autocomplete="off"
                class:error={errors.email}
                class="form-text-primary"
                bind:value={values.email}
              />
              {#if errors.email}
                <span id="email-error" class="error-text"
                  >{$_(errors.email)}</span
                >
              {/if}
            </div>
          </div>
        {/if}
      </div>
    </form>
    <div class="disclousure">
      {@html $_("REGISTRATION.ACTIVATE_ACCOUNT_DISCLOSURE")}
    </div>
    <div class="btn-div">
      <button
        type="button"
        id="activate-account"
        on:click={handleSubmit}
        class="btn btn-primary"
        >{$_("REGISTRATION.ACTIVATE_ACCOUNT")}
        <span class="next-icon">
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5112 11.1239C14.6917 10.9771 14.8 10.7569 14.8 10.5C14.8 10.2798 14.6917 10.0596 14.5112 9.87615L7.50973 2.75688C7.32927 2.61009 7.11273 2.5 6.89619 2.5C6.64356 2.5 6.42702 2.61009 6.28266 2.75688L5.48867 3.56422C5.30822 3.74771 5.19995 3.96789 5.19995 4.18807C5.19995 4.44495 5.27213 4.66514 5.45258 4.81193L11.0466 10.5L5.45258 16.1881C5.27213 16.3716 5.19995 16.5917 5.19995 16.8119C5.19995 17.0688 5.30822 17.289 5.48867 17.4358L6.28266 18.2431C6.42702 18.4266 6.64356 18.5 6.89619 18.5C7.11273 18.5 7.32927 18.4266 7.50973 18.2431L14.5112 11.1239Z"
              fill="white"
            />
          </svg>
        </span>
      </button>
    </div>
  </section>
</div>

<style src="./index.scss">
</style>
