/* eslint-disable no-param-reassign */
/**
 * Add resize event listener to the DOM input element
 * @param {Object} event.target resize event listener
 */
function resize({ target }) {
	target.style.height = "88px"
	target.style.height = `${target.scrollHeight}px`
}

export function textareaResize(el) {
	resize({ target: el })
	el.style.overflow = "hidden"
	el.addEventListener("input", resize)

	return {
		destroy: () => el.removeEventListener("input", resize)
	}
}
