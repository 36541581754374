<script>
  import { onMount, onDestroy } from "svelte"
  import { goto } from "@roxi/routify"
  import { isArray, orderBy, reject, isEmpty } from "lodash"
  import { _ } from "@/i18n"
  import { pageBannerConfig, patientContext } from "@/stores/stores"
  import { callAPI } from "@/utils/httpService"
  import Inbox from "../Inbox/Inbox.svelte"
  import Sent from "../Sent/Sent.svelte"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"
  import SwitchAccountsModal from "../SwitchAccountsModal/SwitchAccountsModal.svelte"

  export let activeClassLabel

  let showLoader = false
  let inbox = {}
  let sent = {}
  let isSwitchAccountsModalOpen = false
  /**
   * Get all threads and prepare data for display in Inbox and Sent tabs
   */
  const getThreads = async () => {
    showLoader = true

    inbox = { readThreads: [], unreadThreads: [] }
    sent = []

    // get all read & unread threads for inbox first
    const { ncPatientId } = $patientContext
    const newInbox = await callAPI({
      url: `patients/${ncPatientId}/secure-messages/threads?unread=false&per_page=250`,
      method: "GET",
    })
    if (!isArray(newInbox)) return

    // Separate read and unread messages
    newInbox.forEach((messageThread) => {
      if (messageThread.read_at) {
        inbox.readThreads.push(messageThread)
      } else {
        inbox.unreadThreads.push(messageThread)
      }
    })

    inbox.unreadThreads = orderBy(
      inbox.unreadThreads,
      "messages[0].created_at",
      "desc",
    )

    // then get sent threads and additionally combine them with inbox read section
    sent = await callAPI({
      url: `patients/${ncPatientId}/secure-messages/threads?sent=true&unread=false&per_page=250`,
      method: "GET",
    })
    sent = orderBy(sent, "messages[0].created_at", "desc")

    const respondedSentThreads = reject(sent, { message_count: 1 })
    inbox.readThreads = orderBy(
      [...inbox.readThreads, ...respondedSentThreads],
      "messages[0].created_at",
      "desc",
    )
    inbox = inbox

    showLoader = false
  }

  // Get Threads when patientContext is available
  $: if (isEmpty($patientContext) === false) getThreads()

  /**
   * While clicking on particular thread navigate to the thread detail page
   * @param {Object} thread
   */
  const navigateToThreadDetail = (thread) => {
    $goto("/messages/thread/:id", {
      id: thread.detail.id,
    })
  }

  /**
   * Navigate to the compose message screen
   */
  const navigateToCompose = () => {
    if ($patientContext?.mergedInto) {
      isSwitchAccountsModalOpen = true
    } else {
      $goto("/messages/compose")
    }
  }

  /**
   * Set pageBanner config to display on the Inbox and Sent pages
   */
  onMount(() => {
    pageBannerConfig.set({
      title: $_("SM.MESSAGES"),
      iconSrc: "../assets/svg/messages-icon.svg",
    })
  })

  /**
   * Reset the pageBanner config to hide that from other pages.
   */
  onDestroy(() => {
    pageBannerConfig.set(null)
  })
</script>

<div class="secure-messages-wrapper">
  <div class="message-controls">
    <button
      id="btn-compose"
      type="button"
      class="btn btn-primary compose-btn"
      on:click={navigateToCompose}
    >
      <i class="fas fa-plus" aria-hidden="true" />
      {$_("SM.COMPOSE")}
    </button>
  </div>
  <nav>
    <ul class="nav nav-tabs">
      <li
        class="nav-item pt-0 pl-0 {activeClassLabel === 'inbox'
          ? 'active'
          : ''}"
        id="inbox"
      >
        <a class="nav-link d-flex" href="/messages/inbox">
          {$_("SM.INBOX")}
          {#if inbox.unreadThreads?.length}
            <div class="circular-badge">{inbox.unreadThreads.length}</div>
          {/if}
        </a>
      </li>
      <li
        class="nav-item pt-0 pl-0 {activeClassLabel === 'sent' ? 'active' : ''}"
        id="sent"
      >
        <a class="nav-link" href="/messages/sent">{$_("SM.SENT")}</a>
      </li>
    </ul>
  </nav>
  {#if showLoader}
    <div class="spinner-wrapper">
      <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
    </div>
  {:else}
    <div class="message-container">
      {#if activeClassLabel === "sent"}
        <Sent
          threads={sent}
          on:navigateToThreadDetail={navigateToThreadDetail}
        />
      {:else}
        <Inbox
          unreadThreads={inbox.unreadThreads}
          readThreads={inbox.readThreads}
          on:navigateToThreadDetail={navigateToThreadDetail}
        />
      {/if}
    </div>
  {/if}
  <SwitchAccountsModal bind:isModalOpen={isSwitchAccountsModalOpen} />
</div>

<style lang="scss" src="./SecureMessages.scss">
</style>
