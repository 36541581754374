/* eslint-disable no-undef */
import _ from "lodash"
import { datadogRum } from "@datadog/browser-rum"
import { get } from "svelte/store"
import { v4 as uuidv4 } from "uuid"
import { error, sessionData } from "@/stores/stores"
import { addCustomError } from "@/utils/datadog"
import { statusMapping } from "@/utils/httpStatusMapping"
import { redirectToUniversalLogin } from "@/utils/authService.js"

/**
 * Set error code into store variable to display error page.
 * @param {(Number|String)} errorCode http status code OR custom error code
 * @returns {Boolean} false
 */
const showErrorPage = (errorCode) => {
  error.set({
    errorCode,
  })

  return false
}

// TODO: add jsDoc and clarify & document return values of failure cases
/**
 * Function is used to call the REST API.
 * @param {String} url  API endpoint
 * @param {String} method API method
 * @param {Object} payload API payload for POST/PUT
 * @param {Object} signal to abort the http request
 * @returns TODO: After refactoring this function we would need to update returns
 */
export const callAPI = ({
  url,
  method,
  payload,
  signal = null,
  suppressErrorPage = false,
  throwError = false,
  keepAlive = false,
}) => {
  // Get session data from store
  const sessionInfo = get(sessionData)

  // complete full URL, support loading JSON files for mock data usage
  const URL = _.startsWith(url, "../")
    ? url.replace("..", window.location.origin)
    : portalApp.env.API_URL + url

  // eslint-disable-next-line camelcase
  const nc_request_id = uuidv4()

  return fetch(URL, {
    method,
    signal,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      nc_request_id,
    },
    credentials: "include",
    body: !_.isEmpty(payload) ? JSON.stringify(payload) : undefined,
    keepalive: keepAlive,
  })
    .then((response) => {
      if (response.status === 401) {
        return redirectToUniversalLogin()
      }

      if (response.status === 200) {
        return _.startsWith(url, "sessions") ? response : response.json()
      }

      if (throwError) {
        throw response.status
      }

      // Set error details to datadog
      if (suppressErrorPage) return null

      const errorTitle = response.statusText || statusMapping[response?.status]
      const errorObj = {
        message: errorTitle,
        status: response.status,
        statusText: errorTitle,
        apiUrl: URL,
        pageUrl: window.location.href,
        nc_request_id,
      }
      addCustomError(errorObj, errorTitle)

      return showErrorPage(response.status)
    })
    .catch((e) => {
      if (throwError) {
        throw e
      }
      // Set error details to datadog
      const errorAttributes = {
        message: e?.message,
        apiUrl: URL,
        pageUrl: window.location.href,
        nc_request_id,
      }

      // If request aborted by user then does not show error page
      if (signal?.aborted === true) {
        Object.assign(errorObj, {
          aborted: true,
          abortedBy: `${sessionInfo.first_name} ${sessionInfo.last_name}`,
        })

        return errorObj
      } else {
        datadogRum.addError(e, errorAttributes)
        return showErrorPage("connection_issues")
      }
    })
}

/**
 * Removes a specified url param from the url and replaces the window history state
 * @param {String} param name of url param
 * @returns {Undefined}
 */
export const removeUrlParamByName = (param) => {
  // Get the current URL and parse it
  const currentUrl = window.location.href
  const url = new URL(currentUrl)

  // Check if the parameter is present in the query string
  if (!url.searchParams.has(param)) {
    return
  }

  // Remove the parameter from the query string
  url.searchParams.delete(param)

  // Update the URL without refreshing the page
  const updatedUrl = url.toString()
  window.history.replaceState(null, "", updatedUrl)
}
