<script>
  import { onMount, onDestroy } from "svelte"
  import { _ } from "@/i18n"
  import { mobileToolbarConfig, notifications } from "@/stores/stores"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"
  import HealthRecordsHeader from "@/components/HealthRecordsHeader/HealthRecordsHeader.svelte"
  import { healthDocumentsInfo } from "@/stores/healthRecordStores"
  // TODO: Uncomment when server side pagination is working
  // import { labInfo, healthDocumentsInfo } from "@/stores/healthRecordStores"
  import HDCard from "@/components/HDCard/HDCard.svelte"
  import { clientSidePagination } from "@/utils/clientSidePagination"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"
  import { sortByTimeStamp } from "@/utils/dateTime"

  let isLoading = true
  let results = []
  let visibleRowCount = 25
  let notificationsIds

  const updateRowCount = (chunkSize) => {
    visibleRowCount += chunkSize
  }

  const formatHDResolverData = (data) => {
    // mapping each document object from resolver
    return data.map(({ value: document }) => {
      let notificationId = ""
      // reduce object array of fields+values from resolver response into simple object for UI
      return document.fields.reduce((documentAsObj, { field, value }) => {
        if (field === "id") {
          const matchingNotification = notificationsIds.find(
            (notification) => notification.referenceId === value,
          )
          notificationId = matchingNotification
            ? matchingNotification.notificationId
            : ""
        }

        return {
          ...documentAsObj,
          notificationId,
          [field]: value,
        }
      }, {})
    })
  }

  const breadcrumbLinks = [
    {
      title: $_("HR.HEALTH_RECORDS"),
      link: "/health-records",
      id: "health-records",
    },
    {
      title: $_("HR.HEALTH_DOCUMENTS.TITLE"),
      active: true,
    },
  ]

  $: {
    if ($notifications?.data) {
      notificationsIds = notifications
        .get("HEALTH_RECORD", "HEALTH_DOCUMENT")
        .notifications.map((notification) => {
          return {
            referenceId: notification.referenceId,
            notificationId: notification.id,
          }
        })
    }
  }

  $: if ($healthDocumentsInfo && notificationsIds) {
    if (!$healthDocumentsInfo.error && $healthDocumentsInfo.length > 0) {
      results = sortByTimeStamp(
        formatHDResolverData($healthDocumentsInfo, notificationsIds),
        "DESC",
        "timestamp",
      )
    }

    isLoading = false
  }

  /**
   * Set the mobileToolbarConfig options for HR
   */
  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: true, text: $_("HR.HEALTH_RECORDS") },
      center: { logo: false },
      customClass: "blue-bkg-sm-down",
      redirectPath: "/health-records",
    })
  })

  /**
   * Reset the mobileToolbarConfig
   */
  onDestroy(() => {
    mobileToolbarConfig.set({})
  })

  $: if ($healthDocumentsInfo) {
    isLoading = false
  }
</script>

<section class="health-records-wrapper">
  <Breadcrumb {breadcrumbLinks} />
  <HealthRecordsHeader title={$_("HR.HEALTH_DOCUMENTS.TITLE")} />
  <section class="health-records-container">
    {#if isLoading}
      <div class="spinner-wrapper">
        <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
      </div>
    {:else if results.length > 0}
      <div
        data-testid="health-documents"
        use:clientSidePagination={{
          totalRows: results.length,
          updater: updateRowCount,
          listId: "health-documents",
        }}
      >
        <div id="health-documents">
          {#each results.slice(0, visibleRowCount) as { id, title, description, fileName, fileType, size, url, by, timestamp, notificationId }}
            <HDCard
              href={url}
              {...{
                id,
                title,
                description,
                fileName,
                fileType,
                size,
                timestamp,
                by,
                notificationId,
              }}
            />
          {/each}
        </div>
      </div>
    {:else}
      <div class="no-health-documents">
        <img
          width="50"
          src="../assets/svg/health-document.svg"
          alt="health-documents"
        />
        <h3>There are no Health Documents to display</h3>
        <p>Documents shared between you and your clinic will show up here.</p>
      </div>
    {/if}
  </section>
</section>

<style lang="scss" src="./index.scss">
</style>
