<script>
  import { onMount, afterUpdate } from "svelte"
  import NotificationDot from "../Notifications/NotificationDot.svelte"

  export let data
  /**
   * @property notificationDates {Array} - must be in format "M/D/YYYY" used for first column dates
   */
  export let notificationDates = []

  let tableHeader = []
  let tableData = []
  let tableWrapperWidth = "100%"
  let firstColumnHeights = []

  $: {
    if (data.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      tableHeader = data[0]
      tableData = data.slice(1)
    }
  }
  /**
   * Set first column table cell height if any of the column contents splitted into multiple rows
   */
  const setFirstColumnCellHeight = () => {
    const trEle = document.querySelectorAll("tr")
    firstColumnHeights = []
    trEle.forEach((ele) =>
      firstColumnHeights.push(`${ele.children[1].clientHeight + 1}px`),
    )
  }

  /**
   * Set table width for different view ports
   */
  const setTableWidth = () => {
    const documentWidth = document.documentElement.clientWidth
    tableWrapperWidth = `${documentWidth}px`
  }

  /**
   * Set table width & cellHeight on onMount and afterUpdate action
   */
  onMount(() => {
    setTableWidth()
    setFirstColumnCellHeight()
  })
  afterUpdate(setFirstColumnCellHeight)

  // Window resize event
  window.addEventListener("resize", setTableWidth)
  window.addEventListener("resize", setFirstColumnCellHeight)
</script>

<div class="table-wrapper" style="width:{tableWrapperWidth}">
  <div class="scrollable">
    <table tabindex="0">
      <tr>
        {#each tableHeader as header, i}
          <th
            style="height: {i === 0 && firstColumnHeights[0]
              ? firstColumnHeights[0]
              : 'auto'}">{header}</th
          >
        {/each}
      </tr>
      {#each tableData as row, i}
        <tr>
          {#each row as cell, j}
            <td
              style="height: {j === 0 && firstColumnHeights[i + 1]
                ? firstColumnHeights[i + 1]
                : 'auto'}"
            >
              {#if j === 0 && notificationDates.includes(cell)}
                <NotificationDot />
              {/if}
              {cell}
            </td>
          {/each}
        </tr>
      {/each}
    </table>
  </div>
</div>

<style lang="scss">
  .table-wrapper {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-bottom: 0;
    position: relative;
  }
  tr:first-child th {
    color: #242627;
  }
  td,
  th {
    border-color: #dddddd;
    border-style: solid;
    border-width: 0 1px 1px 0;
    text-align: left;
    padding: 12px;
    white-space: nowrap;
    color: #555860;
    vertical-align: top;
  }

  th:first-child,
  td:first-child {
    position: absolute;
    left: 0;
    font-weight: bold;
    font-size: 16px;
    align-items: center;
    color: #242627;
    padding: 12px 12px 12px 16px;
    width: 99px;
    max-width: 99px;
    border-right-width: 2px;
    :global(.dot) {
      vertical-align: middle;
    }
  }

  .scrollable {
    overflow-x: auto;
    margin-left: 99px;
  }

  th:last-child,
  td:last-child {
    min-width: 140px;
    white-space: normal;
  }

  @media (min-width: 576px) {
    th:last-child,
    td:last-child {
      width: 100%;
      max-width: 100%;
      white-space: nowrap;
    }
  }
</style>
