<script>
  import { _ } from "@/i18n"

  export let ariaLabel = "password"
  export let id = "password"
  export let name = "password"
  export let error = false
  export let inputPasswordType = "text"
  export let allowAutocomplete = false
  export let iconOnly = false

  let toggleIcon = "eye-slash.svg"
  let toggleText = "Hide"

  /**
   * To enable the requirements block the following props are needed to enable the requirements validation
   * @type {Boolean} enables the requirements block.
   */
  export let hasRequirements = false
  /**
   * @type {String} Tha value of the input
   */
  export let value
  /**
   * @type {Object} The validations errors,
   */
  export let passwordErrors = {}

  $: {
    toggleIcon = inputPasswordType === "text" ? "eye-slash.svg" : "eye.svg"
    toggleText = inputPasswordType === "text" ? "Hide" : "Show"
  }

  /**
   * Toggle the icon, password input type and hide/show text
   */
  const togglePasswordField = () => {
    inputPasswordType = inputPasswordType === "text" ? "password" : "text"
  }

  /**
   * Update the toggle contents on focus event of input and input type is password
   * @param {Object} event Input event object
   */
  const onInputFocus = (event) => {
    if (event?.srcElement?.type === "password") {
      toggleIcon = "eye.svg"
      toggleText = "Show"
      inputPasswordType = "password"
    }
  }
</script>

<span class="password-wrapper">
  <input
    type={inputPasswordType}
    aria-label={ariaLabel}
    {id}
    {name}
    class:error
    class="form-text-primary password-input"
    autocomplete={allowAutocomplete ? "" : "new-password"}
    on:input
    on:blur
    on:focus
    on:focus={onInputFocus}
  />
  <button class="toggle-icon" class:icon-only={iconOnly} type="button" on:click={togglePasswordField}>
    <img
      src="{window.location.origin}/assets/svg/{toggleIcon}"
      alt="{toggleText}_password"
    />
    {#if !iconOnly}
      <span class="toggle-text">{toggleText}</span>
    {/if}
  </button>
</span>

{#if hasRequirements}
  <div class="password-requirements">
    <p class="mb-0">{$_("AUTH.PASSWORD_REQUIREMENTS")}</p>
    <ul>
      <li
        class:check={value?.length && !passwordErrors?.password?.lengthErr}
        class={error ? passwordErrors?.password?.lengthErr : ""}
      >
        {$_("AUTH.MIN_CHARS")}
      </li>
      <li
        class:check={value?.length &&
          !passwordErrors?.password?.specialCharOrNumErr}
        class={error ? passwordErrors?.password?.specialCharOrNumErr : ""}
      >
        {$_("AUTH.NEEDS_NUMBER_OR_SPECIAL_CHAR")}
      </li>
    </ul>
  </div>
{/if}

<style src="./PasswordInputWithToggle.scss"></style>
