/* eslint-disable quotes */
import { groupBy, partialRight, orderBy } from "lodash"
import applyResolvers from "@/utils/applyResolvers"
import { dateTransformer } from "./transformers"
import createProvenanceResolver from "./provenanceResolvers"
import { isNotFutureDate } from "@/utils/dateTime"

/**
 * Returns an object with only component data
 * @param {Array<Object>} entry - lab record entry
 * @param {Object} entry.resource - usable lab data
 * @returns {Object} - of component data
 */
const mapLabComponentData = ({ resource }) => {
  const {
    id,
    code,
    valueQuantity,
    valueString,
    valueUnit,
    valueNumber,
    interpretation,
    note,
    extension,
    referenceRange,
    referenceRangeLowUnit,
    referenceRangeHighUnit,
  } = resource
  return {
    id,
    code,
    valueQuantity,
    valueString,
    valueUnit,
    valueNumber,
    interpretation,
    note,
    extension,
    referenceRange,
    referenceRangeLowUnit,
    referenceRangeHighUnit,
  }
}

/**
 * Casts the lab start date string to a JS date object
 * @param {Object} labRecord - already grouped lab data
 * @returns {Date}
 */
const parsedEntryDate = (labRecord) => {
  const { effectiveDateTime } = labRecord.resource
  return new Date(effectiveDateTime).getTime()
}

/**
 * Format object returned grouped labs into a flat array of objects
 * @param {Object} labGroups - grouped labs with "{text}-{date}" as keys
 * @returns {Array<Object>} a flat array of objects
 */
const formatLabResultsIntoArray = (labGroups) => {
  return Object.values(labGroups).map((groupedArr) => {
    // everything besides the lab component is redundant between the group entries
    // so only need non component data from the first arr entry
    const {
      code,
      valueQuantity,
      referenceRange,
      valueString,
      interpretation,
      note,
      extension,
      ...groupProps
    } = groupedArr[0].resource

    const mergedComponentArr = groupedArr.map(mapLabComponentData)
    const filteredComponents = mergedComponentArr.filter((component) => {
      // eslint-disable-next-line no-shadow
      const { valueString, interpretation, note, extension, valueQuantity } =
        component
      const hasAttachment = extension?.[0]?.valueBoolean

      if (hasAttachment || note || interpretation) {
        return true
      } else if (valueString || valueQuantity) {
        return true
      } else {
        return false
      }
    })
    return {
      ...groupProps,
      components: filteredComponents,
    }
  })
}

/**
 * Used to group by both text and date with Lodash's groupBy method
 * @param {Array<Object>} entry - lab record entry
 * @returns {String} - of format "{text}-{date}"
 */
const generateLabDateKey = (entry) => {
  const { resource } = entry
  const { text } = resource.category[0]
  const date = resource.effectiveDateTime
  return `${text}-${date}`
}

/**
 *
 * @param {Object} component - FIHR object entry
 * @returns {String} - representing panel name
 */
const getPanelTitle = (component) => {
  const panelName = component?.resource?.category?.[0].text
  // return the component name instead of panel if the panel name doesn't map to a USCDI defined vital (shows as Vital Signs)
  if (panelName === "Vital Signs") {
    return component?.resource?.code?.text || "Lab Result"
  }
  return panelName || "Lab Result"
}

/**
 * Groups lab components, sorts, and formats into a flat array of objects
 * @param {Array<Object>} componentEntries -
 * @returns {Array<Object>} a flat array of objects
 */
const groupComponentsByLab = (componentEntries) => {
  if (!componentEntries || componentEntries.length === 0) return []
  // clone component data while adding a category text prop if missing
  const componentsWithLabName = componentEntries.map((component) => ({
    ...component,
    resource: {
      ...component.resource,
      category: [
        {
          ...component.resource.category[0],
          text: getPanelTitle(component),
        },
      ],
    },
  }))

  const sortedComponents = orderBy(
    componentsWithLabName,
    [parsedEntryDate, "resource.category[0].text"],
    ["desc", "asc"],
  )

  const labGroups = groupBy(sortedComponents, generateLabDateKey)
  const formattedResults = formatLabResultsIntoArray(labGroups)
  const filteredResults = formattedResults.filter(filterEmptyResults)
  return filteredResults
}

/**
 * Filter Empty Results
 */
const filterEmptyResults = (result) => {
  const { components } = result
  if (components.length === 0) return false
  return true
}

/**
 * Remove lab components that have no attachments but still have value of "See attached"
 * @param {Array<Object>} entry - lab records entries
 * @returns {Array} - entry array without false attachements
 */
const filterLegitAttachments = (entry) =>
  entry.filter(({ resource }) => {
    const value = resource?.valueString
    const hasAttachment = resource?.extension?.[0]?.valueBoolean
    if (value === "See attached" && !hasAttachment) {
      return false
    }
    return true
  })

const componentResolvers = [
  {
    field: "id",
    path: "id",
  },
  {
    field: "text",
    path: "code.text",
  },
  {
    field: "hasAttachment",
    path: "extension[0].valueBoolean",
  },
  {
    field: "valueQuantity",
    path: [
      {
        join: ["valueQuantity.value", "$ ", "valueQuantity.code"],
      },
    ],
  },
  {
    field: "valueUnit",
    path: "valueQuantity.unit",
  },
  {
    field: "referenceRangeLowUnit",
    path: "referenceRange[0].low.unit",
  },
  {
    field: "referenceRangeHighUnit",
    path: "referenceRange[0].high.unit",
  },
  {
    field: "referenceRange",
    path: [
      {
        join: [
          "referenceRange[0].low.value",
          "$ - ",
          "referenceRange[0].high.value",
        ],
      },
    ],
  },
  {
    field: "valueNumber",
    path: "valueQuantity.value",
  },
  {
    field: "valueString",
    path: "valueString",
  },
  {
    field: "low",
    path: "referenceRange[0].low.value",
  },
  {
    field: "high",
    path: "referenceRange[0].high.value",
  },
  {
    field: "interpretationConcept",
    path: 'interpretation.|{"coding": [{ "system": "http://hl7.org/fhir/ValueSet/observation-interpretation" }]}|.text',
  },
  { field: "loincCode", path: "code.coding[0].code" },
  {
    field: "interpretations",
    path: (component) => [
      applyResolvers(component, [
        {
          field: "findings",
          path: 'interpretation.|{"coding": [{ "system": "interpretation:finding" }]}|.text',
        },
        {
          field: "comments",
          path: 'interpretation.|{"coding": [{ "system": "interpretation:comment" }]}|.text',
        },
        {
          field: "labComments",
          path: "note.|[]|.text",
        },
      ]),
    ],
  },
]

const labResolvers = [
  {
    field: "name",
    path: "category[0].text",
  },
  {
    field: "date",
    path: "effectiveDateTime",
    transform: partialRight(dateTransformer, { dateFormat: "MMM D YYYY" }),
  },
  {
    field: "id",
    path: "id",
  },
  {
    field: "components",
    path: ({ components }) =>
      components.map((component) =>
        applyResolvers(component, componentResolvers),
      ),
  },
]

const labInfoResolvers = [
  {
    field: "Labs",
    path: (data) => {
      const { entry = [] } = data
      // eslint-disable-next-line no-shadow
      const labsWithoutFutureDate = entry?.filter((entry) =>
        isNotFutureDate(entry?.resource?.effectiveDateTime),
      )
      const labsWithLegitAttachments = filterLegitAttachments(
        labsWithoutFutureDate,
      )
      return groupComponentsByLab(labsWithLegitAttachments).map((obj) => {
        return [
          ...applyResolvers(obj, labResolvers),
          ...applyResolvers(
            obj,
            createProvenanceResolver(
              "",
              "provenance",
              "M/D/YYYY",
              ", hh:mma z",
            ),
          ),
        ]
      })
    },
  },
]

export default labInfoResolvers
