<script>
  import { onMount } from "svelte"
  import { goto } from "@roxi/routify"
  import LoginHeader from "@/components/LoginHeader/LoginHeader.svelte"
  import LoginForm from "@/components/LoginForm/LoginForm.svelte"
  import AboutUsBanner from "@/components/AboutUsBanner/AboutUsBanner.svelte"
  import Footer from "@/components/Footer/Footer.svelte"
  import { redirectToUniversalLogin } from "@/utils/authService"
  import { loginViaNewAuth } from "@/stores/stores"

  const redirectToHome = () => {
    // Set newAuth flag true in localStorage TODO: Need to confirm if this is useful for future purpose
    localStorage.setItem("newAuth", true)

    // Set loginViaNewAuth flag in svelte store
    loginViaNewAuth.set(true)

    // Redirect to secure messages inbox
    $goto("/home")
  }

  onMount(async () => {
    redirectToUniversalLogin()
  })
</script>

<div class="login-container">
  <LoginHeader />
  <LoginForm on:redirectToHome={redirectToHome} />
  <AboutUsBanner />
  <Footer />
</div>

<style lang="scss">
  .login-container {
    display: block;
    width: 100%;
  }
</style>
