<script>
  import { textareaResize } from "@/utils/autoResize"

  export let label = ""
  export let placeholder = ""
  export let value = ""
</script>

<label for="message" class="desktop-only"
  >{label}<span class="mobile-only">:</span></label
>
<textarea
  use:textareaResize
  name="message"
  aria-label="compose-message"
  rows="4"
  {placeholder}
  bind:value
  id="message"
/>
