<script>
  import { isEmpty } from "lodash"
  import { _ } from "@/i18n"
  import { notifications, viewedNotifications } from "@/stores/stores"
  import { healthConcernsInfo } from "@/stores/healthRecordStores"
  import HealthConcernsDiagnoses from "../health-concerns-diagnoses/index.svelte"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"
  import NotificationDot from "@/components/Notifications/NotificationDot.svelte"

  let isLoading = true
  let healthConcerns = []
  let healthConcernNotifications = []
  $: if ($healthConcernsInfo) {
    isLoading = false
    healthConcerns = $healthConcernsInfo
  }

  $: if ($notifications?.data) {
    healthConcernNotifications =
      notifications.get("HEALTH_RECORD", "HEALTH_CONCERN")?.notifications || []
  }

  $: if (healthConcernNotifications.length && healthConcerns.length) {
    const notificationQueue = new Set() // set to avoid duplicates
    healthConcerns = healthConcerns.map((result) => {
      const notificationId = healthConcernNotifications.find(
        ({ referenceId }) => referenceId === result?.value?.id,
      )?.id

      if (notificationId && !$viewedNotifications.includes(notificationId))
        notificationQueue.add(notificationId)

      return {
        ...result,
        notificationId,
      }
    })

    if (notificationQueue.size) {
      viewedNotifications.add([...notificationQueue])
    }
  }
</script>

<HealthConcernsDiagnoses activeClassLabel={$_("HR.HEALTH_CONCERNS")}>
  {#if isLoading}
    <div class="spinner-wrapper">
      <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
    </div>
  {:else if healthConcerns.length > 0}
    {#each healthConcerns as { notificationId, value: { text, statusCode, clinicalFacts, provenance } }}
      <article class="card">
        <section class="card-header">
          <header>
            {#if notificationId}
              <span>
                <NotificationDot />
              </span>
            {/if}
            <h3 class:has-notification={!!notificationId} class="title mb-0 ">
              {text}
            </h3>
          </header>
        </section>
        <section class="card-body">
          {#if !isEmpty(clinicalFacts) || provenance}
            <ul class="card-text" class:mt-0={!text}>
              {#if !isEmpty(clinicalFacts)}
                {#each clinicalFacts as { fact }}
                  <li>
                    <div class="label">Clinical fact:</div>
                    <div class="value">
                      {fact}
                    </div>
                  </li>
                {/each}
              {/if}
              {#if provenance}
                <li>
                  <div class="label">Added to EHR:</div>
                  <div class="value">{provenance}</div>
                </li>
              {/if}
              {#if statusCode}
                <li>
                  <div class="label">Status:</div>
                  <div class="value">{statusCode}</div>
                </li>
              {/if}
            </ul>
          {/if}
        </section>
      </article>
    {/each}
  {:else}
    <div class="no-record">
      {$_("HR.NO_RECORD_FOUND_FOR_HEALTH_CONCERNS&PROBLEMS")}
    </div>
  {/if}
</HealthConcernsDiagnoses>

<style lang="scss">
  @import "../index.scss";
</style>
