<script>
  import { onMount, onDestroy } from "svelte"
  import { _ } from "@/i18n"
  import { goalInfo } from "@/stores/healthRecordStores"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"
  import {
    mobileToolbarConfig,
    notifications,
    viewedNotifications,
  } from "@/stores/stores"
  import HealthRecordsHeader from "@/components/HealthRecordsHeader/HealthRecordsHeader.svelte"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"

  import NotificationDot from "@/components/Notifications/NotificationDot.svelte"

  let isLoading = true
  let results = []
  let resultNotifications = []

  const breadcrumbLinks = [
    {
      title: $_("HR.HEALTH_RECORDS"),
      link: "/health-records",
      id: "health-records",
    },
    {
      title: $_("HR.GOALS"),
      active: true,
    },
  ]

  /**
   * Set the mobileToolbarConfig options for HR
   */
  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: true, text: $_("HR.HEALTH_RECORDS") },
      center: { logo: false },
      customClass: "blue-bkg-sm-down",
    })
  })

  /**
   * Reset the mobileToolbarConfig
   */
  onDestroy(() => {
    mobileToolbarConfig.set({})
  })

  const formatGoals = (resolvedGoals) =>
    resolvedGoals.map(({ value }) => {
      return value
    })

  /**
   * Sets loading to false when goalInfo resolves
   */
  $: if ($goalInfo) {
    isLoading = false
    results = formatGoals($goalInfo)
  }

  $: if ($notifications?.data) {
    resultNotifications =
      notifications.get("HEALTH_RECORD", "GOAL")?.notifications || []
  }

  $: if (resultNotifications.length && results.length) {
    const notificationQueue = new Set() // set to avoid duplicates
    results = results.map((result) => {
      const { id, fields } = result || {}
      const notificationId = resultNotifications.find(
        ({ referenceId }) => referenceId === id,
      )?.id

      if (notificationId && !$viewedNotifications.includes(notificationId))
        notificationQueue.add(notificationId)

      return {
        fields,
        notificationId,
      }
    })

    if (notificationQueue.size) {
      viewedNotifications.add([...notificationQueue])
    }
  }
</script>

<section class="health-records-wrapper">
  <Breadcrumb {breadcrumbLinks} />
  <HealthRecordsHeader title={$_("HR.GOALS")} />

  <section class="health-records-container">
    {#if isLoading}
      <div class="spinner-wrapper">
        <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
      </div>
    {:else if results.length > 0}
      {#each results as { fields, notificationId }}
        <article class="card">
          <section class="card-body">
            {#if fields?.length > 0}
              {#each fields as { field, value }}
                {#if field !== "provenance"}
                  <p
                    class="description mt-0 mb-0"
                    class:has-notification={notificationId}
                  >
                    {#if notificationId}
                      <span class="notification-wrapper">
                        <NotificationDot />
                      </span>
                    {/if}
                    {value}
                  </p>
                {:else}
                  <p class="description description--provenance mt-0 mb-0">
                    <span class="field">Added to EHR: </span>
                    <span>{value}</span>
                  </p>
                {/if}
              {/each}
            {/if}
          </section>
        </article>
      {/each}
    {:else}
      <div class="no-record">
        {$_("HR.NO_RECORD_FOUND_FOR_GOALS")}
      </div>
    {/if}
  </section>
</section>

<style lang="scss" src="./index.scss"></style>
