<script>
  import { _ } from "@/i18n"
  import CurvedBanner from "@/components/CurvedBanner/CurvedBanner.svelte"

  const values = {}
</script>

<div class="registration">
  <CurvedBanner
    config={{
      title: $_("AUTH.ALL_SET"),
      iconSrc: "../../assets/svg/auth-icons/check-circle.svg",
      circleBorderColor: "#91dd45",
      disableAuthButtonGroup: true,
    }}
  />
  <section class="auth-container">
    <div class="sub-heading">
      <p>
        {$_("REGISTRATION.SIGN_IN_SUCCESS_DESCRIPTION")}
      </p>
    </div>
    <form>
      <div class="form-text-primary d-flex mb-4">
        <input
          aria-label="keep_me_logged_in"
          type="checkbox"
          name="keep_me_logged_in"
          bind:checked={values.checkbox}
        />
        <div class="pl-12">
          <p class="mb-0">
            {$_("REGISTRATION.KEEP_ME_LOGGED_IN")}
          </p>
          <p class="mb-0">
            <strong>{$_("REGISTRATION.DO_NOT_CHECK")}</strong>
          </p>
        </div>
      </div>
    </form>
    <div class="btn-div">
      <button type="button" id="go-to-portal" class="btn btn-primary"
        >{$_("COMMON.GO_TO_PORTAL")}
        <span class="next-icon">
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5112 11.1239C14.6917 10.9771 14.8 10.7569 14.8 10.5C14.8 10.2798 14.6917 10.0596 14.5112 9.87615L7.50973 2.75688C7.32927 2.61009 7.11273 2.5 6.89619 2.5C6.64356 2.5 6.42702 2.61009 6.28266 2.75688L5.48867 3.56422C5.30822 3.74771 5.19995 3.96789 5.19995 4.18807C5.19995 4.44495 5.27213 4.66514 5.45258 4.81193L11.0466 10.5L5.45258 16.1881C5.27213 16.3716 5.19995 16.5917 5.19995 16.8119C5.19995 17.0688 5.30822 17.289 5.48867 17.4358L6.28266 18.2431C6.42702 18.4266 6.64356 18.5 6.89619 18.5C7.11273 18.5 7.32927 18.4266 7.50973 18.2431L14.5112 11.1239Z"
              fill="white"
            />
          </svg>
        </span>
      </button>
    </div>
  </section>
</div>

<style src="./index.scss">
</style>
