import { isEmpty, filter } from "lodash"
import { convertComponentValueArrToObj } from "@/utils/convertComponentValueArrToObj"

/**
 * Parse and destructure the lab results
 * @param {Array} details Lab details
 * @returns {Array} Parse and destructured data for name, date and components
 */
export const formatLabsDataArr = (details) => {
  return (
    details
      // format data into UI ready structure
      .map(({ value }) => {
        return {
          name: filter(value, { field: "name" })[0]?.value,
          components: filter(value, { field: "components" }).map((component) =>
            convertComponentValueArrToObj(component.value),
          ),
        }
      })
  )
}

/**
 * Parse and destructure the lab results
 * @param {Array} details Lab details
 * @returns {Array} Parse and destructured data for name, date and components
 */
export const formatLabsHistory = (details) => {
  return details.map(({ value }) => convertComponentValueArrToObj(value))
}

/**
 * Get last segment of the URL and filter the lab result id from lab results
 * @returns {Array} Parsed and destructured array of lab details
 */
export const getLabDetails = (labInfo, labResultId) => {
  const labDetail = filter(labInfo.results, {
    value: [{ value: labResultId }],
  })

  return formatLabsDataArr(labDetail)
}

/**
 * get the value of the lab result
 * @param {String|Null} valueString lab result in string form
 * @param {Array|Null} valueQuantity lab result within an array
 * @returns {String} value of lab result
 */
export const getLabResultValue = (valueString, valueQuantity) => {
  if (valueString) return valueString
  let value = ""
  if (!isEmpty(valueQuantity)) {
    value = valueQuantity[0].replace(/{.*}/, "")
  }
  return value
}
