<script>
  import { createEventDispatcher } from "svelte"
  import { handleKeyboardEvents } from "@/utils/keyboardNavigation"
  import ThreadCard from "../ThreadCard/ThreadCard.svelte"

  export let threads
  // Declare the dispatch
  const dispatch = createEventDispatcher()

  /**
   * Dispatch event with thread details to navigate to the thread detail page
   * @param {Object} thread
   */
  const navigateToThreadDetail = (thread) => {
    dispatch("navigateToThreadDetail", thread)
  }
</script>

{#if threads && threads.length > 0}
  <div class="sent-messages">
    {#each threads as thread}
      <div
        class="clickable-card"
        use:handleKeyboardEvents
        on:keyboardSelect={() => navigateToThreadDetail(thread)}
        on:click={navigateToThreadDetail(thread)}
      >
        <ThreadCard messageThread={thread} type="read" />
      </div>
    {/each}
  </div>
{/if}
