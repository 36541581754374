const publicRoutes = [
  "/auth/sign-in-help",
  "/auth/send-verification-link",
  "/auth/password-reset",
  "/about-us",
  "/about-us/terms-of-use",
  "/about-us/privacy-policy",
  "/auth/check-email-link/",
  "/auth/check-email/",
  "/auth/sign-in-success",
  "/auth/create-password",
  "/auth/registration/validate-dob",
  "/auth/registration/secure-account",
  "/auth/registration/consent",
  "/auth/registration/all-set",
  "/error",
]

/**
 * Iterate the whitelisted routes to match them casue if has a param is not valid, need to match exactliy
 */
const isPublicRoute = (url = window.location.pathname) => {
  let inPublic = false
  // eslint-disable-next-line no-restricted-syntax
  for (const allowedRoute of publicRoutes) {
    if (url.includes(allowedRoute)) {
      inPublic = true
    }
  }
  return inPublic
}

export default isPublicRoute
