import { hasIn, get } from "lodash"
import applyResolvers from "@/utils/applyResolvers"
import createProvenanceResolver from "./provenanceResolvers"
import { isNotFutureDate } from "@/utils/dateTime"

const hasHealthConcernNameField = (entryObj) =>
  hasIn(entryObj, "resource.code.text")

const healthConcernsInfoResolvers = [
  {
    field: "healthConcerns",
    path: (data) =>
      data?.entry
        ?.filter(hasHealthConcernNameField)
        .filter((entry) => isNotFutureDate(entry?.resource?.onsetDateTime))
        .map((obj) => ({
          text: get(obj?.resource, "code.text"),
          clinicalFacts: obj?.resource?.evidence?.map((fact) => ({
            fact: get(fact, "code[0].text"),
          })),
          id: get(obj?.resource, "id"),
          statusCode: get(
            obj?.resource,
            "verificationStatus.coding[0].display",
          ),
          provenance: applyResolvers(
            obj,
            createProvenanceResolver(
              "resource.",
              "Added to EHR",
              "M/D/YYYY",
              ", hh:mma z",
            ),
          )[0]?.value,
        })),
  },
]

export default healthConcernsInfoResolvers
