<script>
  import { sessionData, isUserClinicianOrAdmin } from "@/stores/stores"
  import Logo from "@/components/AuthNav/logo.svelte"

  // Default welcome section height is 100 pixel.
  let clientHeight,
    welcomeSectionHeight = 100

  // Welcome header section is always 50 points larger than the header containing the welcome text
  $: welcomeSectionHeight = clientHeight + 50
</script>

<section style="height: {welcomeSectionHeight}px">
  <img src="../../assets/svg/curve.svg" class="curve" alt="Curve Icon" />
  <header bind:clientHeight>
    {#if $sessionData?.account_types?.includes("patient") && !$isUserClinicianOrAdmin && $sessionData.first_name}
      <h1>Welcome, {$sessionData.first_name}</h1>
    {:else}
      <div class="admin-or-not-logged-in">
        <Logo />
        <p class="admin-or-not-logged-in-text">Your Digital Healthcare Companion</p>
      </div>
    {/if}
  </header>
  <div id="cover">
    <svg
      width="100%"
      height="23"
      viewBox="0 0 797 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M797 21.8307V23H0V22.6677C113.273 8.40995 252.402 0 402.564 0C549.763
        0 685.845 8.08138 797 21.8307Z"
        fill="#F0F2F5"
      />
    </svg>
  </div>
  <img src="../../assets/svg/arrows.svg" class="arrows" alt="Arrows Icon" />
</section>

<style lang="scss" src="./HomeHeader.scss">
</style>
