<script>
  import { goto } from "@roxi/routify"
  import { getExpiringS3FileAndOpen } from "@/utils/getExpiringS3FileAndOpen"
  import { patientId } from "@/stores/healthRecordStores"
  import { viewedNotifications } from "@/stores/stores"
  // TODO: This could change based on the final API and the resolver.
  /**
   * @typedef Icon
   * @type {object}
   * @property {String} src - image source path
   * @property {String} alt - image alt text
   * @property {String} position - ['left' | 'right'] position of icon relative to `text`
   */

  /**
   * @typedef ActionProps
   * @type {object}
   * @property {String} id - health document identifier
   * @property {String} text - action link text
   * @property {String} category - category of the health document
   * @property {String} href - path to link
   * @property {Boolean} isDownload - determine if link is for downloading
   * @property {Boolean} openInNewTab - Opens href in new tab
   * @property {Icon} [icon] - icon props
   * @property {Boolean} [isTitleCTA] - makes the title an actionable link
   * @property {String} notificationId - notification Id if exist
   */

  /**
   * @type {string} - card title
   */
  export let title

  /**
   * @type {ActionProps}
   */
  export let actionProps

  const handleDocument = async (
    healthDocumentID,
    category = "HealthDocument",
  ) => {
    const apiUrl = `patients/${$patientId}/health-documents?category=${category}&health_document_identifier=${healthDocumentID}`
    const fileResolver = [
      {
        field: "url",
        path: "entry[0].resource.content[0].attachment.url",
      },
    ]

    await getExpiringS3FileAndOpen(apiUrl, fileResolver)
  }

  const handleLink = () => {
    if (actionProps.isDownload) {
      handleDocument(actionProps.id, actionProps.category)
      if (actionProps.notificationId) {
        viewedNotifications.markAsRead([actionProps.notificationId])
      }
    } else if (actionProps.openInNewTab) {
      window.open(actionProps.href, "_blank")
      if (actionProps.notificationId) {
        viewedNotifications.markAsRead([actionProps.notificationId])
      }
    } else {
      $goto(actionProps.href)
    }
  }
</script>

<div class="hr-card-container">
  <div class="description-container">
    <h1 class="title" class:has-notification={actionProps.notificationId}>
      {#if actionProps.notificationId}
        <span class="notification-indicator" />
      {/if}
      {#if actionProps?.isTitleCTA}
        <!-- svelte-ignore a11y-invalid-attribute -->
        <a
          href="Javascript:void(0);"
          on:click={handleLink}
          class:has-notification={actionProps.notificationId}
        >
          {title}
        </a>
      {:else}
        {title}
      {/if}
    </h1>
    <slot />
  </div>
  <div class="actions-container">
    <!-- svelte-ignore a11y-invalid-attribute -->
    <a class="action-link" href="JavaScript:void(0);" on:click={handleLink}>
      {#if actionProps.icon && actionProps.icon.position === "left"}
        <img
          width="16"
          height="16"
          src={actionProps.icon.src}
          alt={actionProps.icon.alt}
        />
      {/if}
      {actionProps.text}
      {#if actionProps.icon && actionProps.icon.position === "right"}
        <img
          width="16"
          height="16"
          src={actionProps.icon.src}
          alt={actionProps.icon.alt}
        />
      {/if}
    </a>
  </div>
</div>

<style lang="scss" src="./HRCard.scss">
</style>
