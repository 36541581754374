import { datadogRum } from "@datadog/browser-rum"
import { get } from "svelte/store"
import { sessionData, patientContext } from "@/stores/stores"

/**
 * Store error information to datadog.
 * @param {Object} errorObj Error information object
 */
export const addCustomError = (
  errorObj,
  errorTitle = "Something went wrong.",
) => {
  datadogRum.addError(new Error(errorTitle), {
    error: {
      ...errorObj,
    },
  })
}

// TODO : update datadog to <=v4.17.0 as as methods used here are deprecated and new versions allow for setting of individual user attributes
/**
 * Store User, Patient and Clinic information to the datadog
 * Set localStorage = false in datadog context if there is any issue while setItem in localStorage
 */
export const setupDataDog = (isLocalStorageAvailable = true) => {
  const sessionDetails = get(sessionData)
  const patientObj = get(patientContext)

  const {
    first_name: firstName,
    last_name: lastName,
    nc_account_id: ncAccountId,
  } = sessionDetails || {}
  const { ncPatientId, clinicName, clinicId } = patientObj || {}

  if (!isLocalStorageAvailable)
    datadogRum.addRumGlobalContext("localStorage", false)

  // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#identify-user-sessions
  datadogRum.setUser({
    id: ncAccountId,
    name: `${firstName} ${lastName}`,
  })

  if (patientObj) {
    datadogRum.addRumGlobalContext("patient", {
      id: ncPatientId,
    })

    datadogRum.addRumGlobalContext("clinic", {
      id: clinicId,
      name: clinicName,
    })
  }
}
