import { getData } from "@/utils/getData"
import applyResolvers from "@/utils/applyResolvers"
import { isSafariBrowser } from "./isSafariBrowser"

/**
 * Get expiring s3 file and open it to download.
 *
 * @param {String} apiUrl endpoint url to get the response including expiring s3 file.
 * @param {String} fileResolver format of resolver to get file url from the response.
 */
export const getExpiringS3FileAndOpen = (apiUrl, fileResolver) => {
  // eslint-disable-next-line consistent-return
  getData(apiUrl).then((data) => {
    if (!data) return null

    const { value: fileUrl } = applyResolvers(data, fileResolver)?.[0]

    window.open(fileUrl, isSafariBrowser() ? "_self" : "_blank")
  })
}
