<script>
  /* eslint-disable */
  import { isEmpty } from "lodash"
  import {
    globalNavConfig,
    mobileToolbarConfig,
    isUserClinicianOrAdmin,
    isLoggedIn,
    currentPath,
  } from "@/stores/stores"
  import TitleBar from "@/components/TitleBar/TitleBar.svelte"

  const clicianURL = portalApp.env.PORTAL_1_URL
  const patientURL = `${portalApp.env.AUTH_API_URL}redirect_login`

  export let handleLogout = () => {}

  export let handleLogin = (event) => {
    const { target } = event.detail
    if (target === "clinician") {
      window.location.replace(clicianURL)
    } else {
      window.location.replace(patientURL)
    }
  }

  let viewPortWidth

  /**
   * Notification Handler.
   * If any updates there such as new message, new calendar invitation, It should appear on user's browsers.
   */
  const notify = []

  /**
   * Grab configuration of menus using helper function
   */
  $: ({ main_tabs, more_menu_links, topbar_menus, base_portal_urls } =
    $globalNavConfig)
</script>

<svelte:window bind:innerWidth={viewPortWidth} />
{#if !isEmpty($globalNavConfig)}
  {#if viewPortWidth < 768}
    {#if !$mobileToolbarConfig || isEmpty($mobileToolbarConfig)}
      <mobile-nav
        on:logout={handleLogout}
        on:login={handleLogin}
        is_logged_in={$isLoggedIn}
        main_tabs={JSON.stringify(main_tabs)}
        more_menu_links={JSON.stringify(more_menu_links)}
        topbar_menus={JSON.stringify(topbar_menus)}
        notify={JSON.stringify(notify)}
        base_portal_urls={JSON.stringify(base_portal_urls)}
        is_clinician_or_admin={$isUserClinicianOrAdmin}
        current_path={$currentPath}
      />
    {:else}
      <TitleBar mobileToolbarConfig={$mobileToolbarConfig} />
    {/if}
  {:else}
    <desktop-nav
      on:logout={handleLogout}
      on:login={handleLogin}
      is_logged_in={$isLoggedIn}
      main_tabs={JSON.stringify(main_tabs)}
      more_menu_links={JSON.stringify(more_menu_links)}
      topbar_menus={JSON.stringify(topbar_menus)}
      notify={JSON.stringify(notify)}
      base_portal_urls={JSON.stringify(base_portal_urls)}
      is_clinician_or_admin={$isUserClinicianOrAdmin}
      current_path={$currentPath}
    />
  {/if}
{/if}
