import dayjs from "dayjs"

/**
 * Checks if there's a timezoneless midnight timestamp in the date
 * @param {String} dateStr - date string
 * @returns {Boolean} - true if there's a timezoneless midnight timestamp in the date
 */
export const isTimeZonelessMidnight = (dateStr) => {
  if (!dateStr) return false

  const timestampIdx = dateStr.indexOf("T")
  if (timestampIdx === -1) return true

  const timestamp = dateStr.substring(timestampIdx + 1)
  return timestamp === "00:00:00"
}

/**
 * Format date as per the requsted format
 * @param {String} date
 * @param {Object} [options={}] - options object
 * @param {String} [options.dateFormat="M/D/YYYY"] - dayjs date format
 * @param {String} [options.timeFormat=""] - dayjs time format
 * @param {String} [options.strSeparator=""] - splits dateStr into array using this separator
 * @returns {String} formatted date as per the format passed in the params
 */
export const returnFormattedDate = (date, options = {}) => {
  if (!date) return undefined

  let newDate = date

  const timestampIdx = date.indexOf("T")
  // don't replace anything if there's a timestamp
  if (timestampIdx === -1) {
    // Replaced separator from - to /, because dayjs does not support - separator in mozilla firefox, it returns invalid date
    newDate = date.replace(/-/g, "/")
  }

  const defaultOptions = {
    dateFormat: "M/D/YYYY",
    timeFormat: "",
  }

  const { dateFormat, timeFormat } = {
    ...defaultOptions,
    ...options,
  }

  const format = isTimeZonelessMidnight(date)
    ? dateFormat
    : `${dateFormat}${timeFormat}`

  return dayjs(newDate).format(format)
}

/**
 * Convert the message received date into specific format based on current date fall into different criteria
 * @param {String} messageReceivedDate date on which message was received
 * @returns {String} convert the date and returned in specific format
 */
export const convertDateTime = (messageReceivedDate) => {
  const currentDate = new Date()

  // If previous year
  if (!dayjs(currentDate).isSame(messageReceivedDate, "year")) {
    return dayjs(messageReceivedDate).format("M/D/YY")
  } else {
    // If same day
    if (dayjs(currentDate).isSame(messageReceivedDate, "day")) {
      return dayjs(messageReceivedDate).format("h:mm A")
    }

    // If same year but past day
    return dayjs(messageReceivedDate).format("MMM D")
  }
}

/**
 * Pads a number with up to 2 leading zeros
 * @param {Number} n - number to pad
 * @param {Number} z - how many digits to slice starting from right of padded number
 * @returns {String} of a padded number
 */
function pad(n, z = 2) {
  return `00${n}`.slice(-z)
}

/**
 *
 * @param {*} ms
 * @returns
 */
export const msToHMS = (ms) => {
  let seconds = Math.round(ms / 1000)

  const hours = parseInt(seconds / 3600, 10)

  seconds %= 3600 // seconds remaining after extracting hours

  const minutes = parseInt(seconds / 60, 10)
  // 4- Keep only seconds not extracted to minutes:
  seconds %= 60

  return `${hours > 0 ? `${hours}:` : ""}${minutes}:${pad(seconds)}`
}

/**
 * Sorts an array of data by timestamp
 * @param {Object} data
 * @param {String} order by default is DESC
 * @returns {Object} sorted data
 */

export const sortByTimeStamp = (
  data,
  order = "DESC",
  timestampKey = "eventTimeStamp",
) => {
  const sortedData = [...data]
  return sortedData.sort((x, y) => {
    if (order === "DESC") {
      return new Date(y[timestampKey]) - new Date(x[timestampKey])
    } else {
      return new Date(x[timestampKey]) - new Date(y[timestampKey])
    }
  })
}

/**
 * Gets number of seconds from now to end of day, 14 days from now
 * @returns {Number} of seconds from now until end of day 14 days from now
 */
export const secondsTill14daysEOD = () => {
  const now = dayjs()
  const endDay = now.endOf("day")
  const expiryDate = endDay.add(14, "day")
  return expiryDate.diff(now, "s")
}

/**
  Forces the timestamp to be in UTC format.
  @param {string} timestamp - The timestamp to be forced into UTC format.
  @returns {string} The timestamp in UTC format.
*/
export const forceUTCTime = (timestamp) => {
  // no timestamp or time associated, return
  if (!timestamp || !dayjs(timestamp).isValid() || !timestamp.includes("T")) {
    return timestamp
  }
  // timezone already provided, don't add
  if (timestamp.includes("Z") || timestamp.includes("+")) {
    return timestamp
  }

  return `${timestamp}Z`
}

/**
 * Validate if the date is in the future
 * @returns {Boolean} if a date is in the future
 */
export const isNotFutureDate = (value) => {
  if (!value) return true
  const UTCTimestamp = forceUTCTime(value)
  return dayjs(UTCTimestamp).isBefore(dayjs())
}
