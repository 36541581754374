<script>
  import { onMount, createEventDispatcher } from "svelte"
  import { truncate } from "lodash"
  import ProgressIndicator from "../ProgressIndicator/ProgressIndicator.svelte"
  import Modal from "../Modal/Modal.svelte"
  import FileUploaderClass from "../../utils/fileUploader"
  import { convertFilesize } from "../../utils/convertFilesize"

  const dispatch = createEventDispatcher()

  export let fileMetadata = {}
  export let uuid = ""

  let isUploaded = false
  let fileExtension = ""
  let fileName = ""
  let fileSize = 0
  let progress = 0
  let isModalOpen = false
  let modalProps = null
  let abort
  let retry

  $: progressPercent = progress * 100

  const reset = () => {
    progress = 0
    abort = undefined
    retry = undefined
  }

  const onProgress = (progressResponse) => {
    abort = progressResponse.abort
    retry = () => {
      reset()
      progressResponse.retry()
    }
    progress = progressResponse.progress
  }

  const cancelUpload = () => {
    if (!isUploaded && abort) {
      abort()
    }
    dispatch("cancelUpload", { uuid })
  }

  const createModalWithProps = (modalType, modalTitle, modalBody) => {
    const newProps = {
      modalTitle,
      modalBody,
    }
    switch (modalType) {
      case "retry":
        newProps.btnDefs = [
          {
            label: "Stop",
            escapeFunction: true,
            buttonClass: "secondary",
            callback: cancelUpload,
          },
          {
            label: "Retry",
            buttonClass: "primary",
            callback: retry,
          },
        ]
        break

      case "remove":
        newProps.btnDefs = [
          {
            label: "Remove",
            buttonClass: "secondary",
            callback: cancelUpload,
          },
          {
            label: "Cancel",
            escapeFunction: true,
            buttonClass: "primary",
          },
        ]
        break

      case "cancel":
        newProps.btnDefs = [
          {
            label: "Cancel upload",
            buttonClass: "secondary",
            callback: cancelUpload,
          },
          {
            label: "Resume",
            escapeFunction: true,
            buttonClass: "primary",
          },
        ]
        break

      case "validation":
        newProps.btnDefs = [
          {
            label: "Okay",
            buttonClass: "secondary",
            escapeFunction: true,
            callback: cancelUpload,
          },
        ]
        break

      default:
        break
    }
    modalProps = newProps
    isModalOpen = true
  }

  const onCompleted = (response) => {
    let data = {}

    if (response.xhrResponse) {
      data = JSON.parse(response.xhrResponse)
      data.uuid = uuid
    }

    progress = response.progress
    isUploaded = true

    dispatch("uploadSuccess", data)
  }

  const onError = (response) => {
    // check if error is validation or request based
    if (response.retry) {
      retry = () => {
        reset()
        response.retry()
      }
      createModalWithProps("retry", response.title, response.body)
    } else {
      createModalWithProps("validation", response.title, response.body)
    }
  }

  onMount(() => {
    // instantiate class here
    const fileUploaderInstance = new FileUploaderClass({
      fileMetadata,
      onProgress,
      onCompleted,
      onError,
    })
    fileExtension = fileUploaderInstance.getFileExtension()
    fileName = fileMetadata.name
    fileSize = fileMetadata.size
    // Trigger file upload event
    fileUploaderInstance.fileUpload()
  })

  const handleKeydown = (event) => {
    if (event.code === "Enter") {
      event.preventDefault()
      createModalWithProps("remove", "Remove attachment?")
    }
  }
</script>

<div on:keydown={handleKeydown} class="attachment-container" tabindex="0">
  <div class="file-details">
    {#if isUploaded}
      <div class="position-relative">
        <div class="filetype">
          {fileExtension}
        </div>

        <svg
          width="37"
          height="50"
          viewBox="0 0 37 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.5473 1.05513L35.9499 10.5848C36.2902 11.0223 36.5003 11.4923 36.5003 11.9141V12H25.667C25.3908 12 25.167 11.7761 25.167 11.5V0.5H25.2451C25.6549 0.5 26.1155 0.710116 26.5473 1.05513Z"
            fill="#F9FBFC"
            stroke="#C3C7CC"
          />
          <path
            d="M36.5 16.125V47.6562C36.5 48.1972 36.2709 48.6599 35.9696 48.9652C35.591 49.349 35.1455 49.5 34.6875 49.5H2.3125C1.73233 49.5 1.32631 49.3352 1.06695 49.0066L1.03253 48.963L0.98938 48.928C0.664921 48.665 0.5 48.2499 0.5 47.6562V2.34375C0.5 1.87382 0.651249 1.41904 1.0304 1.03477C1.33172 0.729368 1.78521 0.5 2.3125 0.5H21.0833V13.2812C21.0833 14.0373 21.294 14.7774 21.9002 15.2943C22.4106 15.9092 23.1443 16.125 23.8958 16.125H36.5Z"
            fill="#F0F2F5"
            stroke="#C3C7CC"
          />
        </svg>
      </div>
    {:else}
      <div class="progress-wrapper">
        <ProgressIndicator {progress} />
      </div>
    {/if}
    <div class="d-block attachment-info">
      <div>
        <p class="uploading-text attachment-title mb-0">
          {#if fileName.length <= 24}
            {fileName}
          {:else}
            {truncate(fileName, {
              length: 22,
            })}{`.${fileExtension}`}
          {/if}
        </p>
      </div>
      {#if isUploaded}
        <p class="attachment-details mb-0">
          {`${fileExtension.toUpperCase()} | ${convertFilesize(fileSize)}`}
        </p>
      {:else}
        <p
          class="attachment-details progress-text mb-0"
          on:click|self={() =>
            createModalWithProps(
              "cancel",
              "Cancel upload?",
              "File will be removed from message",
            )}
        >
          Uploading... {Math.trunc(progressPercent)}%
        </p>
      {/if}
    </div>
  </div>

  {#if isUploaded}
    <div class="attachment-remove-wrapper">
      <div
        class="remove-attachment"
        on:click={() => createModalWithProps("remove", "Remove attachment?")}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7955 10.2727L9.52273 8L11.7955 5.72727C11.9318 5.59091 12 5.43182 12 5.22727C12 5.04545 11.9318 4.86364 11.7955 4.70455L11.2955 4.20455C11.1364 4.06818 10.9545 4 10.7727 4C10.5682 4 10.4091 4.06818 10.2727 4.20455L8 6.47727L5.72727 4.20455C5.59091 4.06818 5.40909 4 5.22727 4C5.02273 4 4.84091 4.06818 4.70455 4.20455L4.20455 4.70455C4.06818 4.86364 4 5.04545 4 5.22727C4 5.43182 4.06818 5.59091 4.20455 5.72727L6.47727 8L4.20455 10.2727C4.06818 10.4091 4 10.5909 4 10.7727C4 10.9773 4.06818 11.1591 4.20455 11.2955L4.70455 11.7955C4.84091 11.9318 5.02273 12 5.22727 12C5.40909 12 5.59091 11.9318 5.72727 11.7955L8 9.52273L10.2727 11.7955C10.4091 11.9318 10.5682 12 10.7727 12C10.9545 12 11.1364 11.9318 11.2955 11.7955L11.7955 11.2955C11.9318 11.1591 12 10.9773 12 10.7727C12 10.5909 11.9318 10.4091 11.7955 10.2727Z"
            fill="#1150A2"
          />
        </svg>
        <p class="mb-0">Remove attachment</p>
      </div>
    </div>
  {/if}
</div>
{#if isModalOpen}
  <Modal bind:isOpen={isModalOpen} {...modalProps} />
{/if}

<style src="./FileUploader.scss">
</style>
