<script>
  import { _, locale, setLocale} from "@/i18n"

  const handleTranslation = () => {
    const newLocale = ($locale === "en") ? "es" : "en"
    setLocale(newLocale)
  }
</script>

<button class="btn-link" on:click={handleTranslation}>
    {$_("COMMON.LANGUAGE")}</button>

<style lang="scss" src="./LanguageButton.scss"></style>