import { map } from "lodash"
import applyResolvers from "@/utils/applyResolvers"
import { sortByDate } from "@/utils/sortByDate"
import { dateTransformer } from "./transformers"
import createProvenanceResolver from "./provenanceResolvers"

const treatmentResolver = [
  {
    field: "treatment",
    path: [
      {
        join: ["detail.code.coding[0].display", "$: ", "detail.description"],
      },
    ],
  },
  {
    field: "treatmentDate",
    path: "detail.scheduledTiming.event[0]",
    transform: dateTransformer,
  },
]

/**
 * Isolate treatment data from the assessment data
 * @param {Object} entry
 * @param {Object} entry.resource - - Health records data
 * @returns {Array<Object>} - of treatment data
 */
const mapTreatmentData = ({ resource }) => {
  if (!resource.activity) return []
  // TODO: remove this filter when medication resolver created
  const withoutMedicationRequests = (treatment) =>
    treatment.detail.kind !== "MedicationRequest"

  return resource?.activity
    ?.filter(withoutMedicationRequests)
    ?.sort((a, b) =>
      sortByDate(
        a.detail?.scheduledTiming?.event[0],
        b.detail?.scheduledTiming?.event[0],
      ),
    )
    ?.map((treatment) => applyResolvers(treatment, treatmentResolver))
}
/**
 * Checks if the content between the initial <div> tag in an HTML string is empty.
 * @param {string} htmlString - The HTML string to be checked.
 * @returns {boolean} Returns true if the content between the <div> tags is empty, otherwise false.
 */
function isStringDivContentEmpty(htmlString) {
  if (!htmlString) return true
  // check for content between div string
  const divContentRegex = /<div[^>]*>([\s\S]*?)<\/div>/
  const match = divContentRegex.exec(htmlString)

  if (match) {
    const content = match[1].trim()
    return content === ""
  }

  return false
}

/**
 * Filter the empty assessment data
 * @param {String} data Assessment data
 * @returns {String|Null} return string if it exists or null
 */
const filterEmptyAssessmentData = (data) => data || ""

const assessmentInfoResolvers = [
  {
    field: "Assessments",
    path: (data) =>
      data?.entry
        ?.map((obj) => ({
          html: isStringDivContentEmpty(obj?.resource?.text?.div)
            ? null
            : obj?.resource?.text?.div, // Flatiron specific. Used in place of assessments + treatments
          assessments: map(obj.resource.note, "text").filter(
            filterEmptyAssessmentData,
          ),
          treatments: mapTreatmentData(obj),
          provenance: applyResolvers(
            obj,
            createProvenanceResolver("resource."),
          ),
        }))
        // filter out records that don't have assessments, treatments, or html data
        .filter(({ html, treatments, assessments }) => {
          return html || treatments.length || assessments.length
        }),
  },
]

export default assessmentInfoResolvers
