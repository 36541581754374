/* eslint-disable quotes */
import { partialRight } from "lodash"
import applyResolvers from "@/utils/applyResolvers"
import { sortByDate } from "@/utils/sortByDate"
import { dateTransformer } from "./transformers"

/**
 * Used to filter lab components that have value of "See attached"
 * @param {Array<Object>} entry - lab records entry
 * @returns {Boolean} - indicating if the value isnt "See attached"
 */
const isNotSeeAttachedValue = ({ resource }) =>
  resource?.valueString !== "See attached"

const componentResolvers = [
  {
    field: "text",
    path: "code.text",
  },
  {
    field: "valueQuantity",
    path: [
      {
        join: ["valueQuantity.value", "$ ", "valueQuantity.code"],
      },
    ],
  },
  {
    field: "referenceRange",
    path: [
      {
        join: [
          "referenceRange[0].low.value",
          "$ - ",
          "referenceRange[0].high.value",
        ],
      },
    ],
  },
  {
    field: "valueUnit",
    path: "valueQuantity.unit",
  },
  {
    field: "referenceRangeLowUnit",
    path: "referenceRange[0].low.unit",
  },
  {
    field: "referenceRangeHighUnit",
    path: "referenceRange[0].high.unit",
  },
  {
    field: "referenceRangeUnits",
    path: "referenceRange[0].low.code",
  },
  {
    field: "valueUnits",
    path: "valueQuantity.code",
  },
  {
    field: "valueNumber",
    path: "valueQuantity.value",
  },
  {
    field: "valueString",
    path: "valueString",
  },
  {
    field: "low",
    path: "referenceRange[0].low.value",
  },
  {
    field: "high",
    path: "referenceRange[0].high.value",
  },
  {
    field: "interpretationConcept",
    path: 'interpretation.|{"coding": [{ "system": "http://hl7.org/fhir/ValueSet/observation-interpretation" }]}|.text',
  },
  {
    field: "displayDate",
    path: "effectiveDateTime",
    transform: partialRight(dateTransformer, { dateFormat: "M/D/YY" }),
  },
  {
    field: "sortDate",
    path: "effectiveDateTime",
  },
]

const labHistoryResolvers = [
  {
    field: "Labs",
    path: ({ entry = [] }) =>
      entry
        .filter(isNotSeeAttachedValue)
        .sort((a, b) =>
          sortByDate(
            a.resource?.effectiveDateTime,
            b.resource?.effectiveDateTime,
          ),
        )
        .map(({ resource = {} }) =>
          applyResolvers(resource, componentResolvers),
        ),
  },
]

export default labHistoryResolvers
