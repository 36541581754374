<script>
  import { isArray, isEmpty } from "lodash"
  import NotificationDot from "@/components/Notifications/NotificationDot.svelte"

  export let medicationName
  export let dosage
  export let frequency
  export let notificationId
</script>

<section class="expandable-card__header">
  <header>
    {#if notificationId}
      <span>
        <NotificationDot />
      </span>
    {/if}
    <h3
      class:has-notification={notificationId}
      class="expandable-card__title mb-0"
    >
      {medicationName}
    </h3>
  </header>
  {#if isArray(dosage) && !isEmpty(dosage.filter(Boolean)) && frequency}
    <h4 class="expandable-card__subTitle mb-0 mt-2">
      {isArray(dosage) ? dosage.join(" ") : ""}{", "}{frequency.toLowerCase()}
    </h4>
  {/if}
</section>
