<script>
  import { goto } from "@roxi/routify"
  import { _ } from "@/i18n"
  import AuthButtonGroup from "@/components/AuthButtonGroup/AuthButtonGroup.svelte"
</script>

<section class="auth-container">
  <AuthButtonGroup
    breadcrumbBtn={{ text: $_("AUTH.BACK"), href: "/auth/login" }}
  />
  <header class="heading">
    <h1 class="title">{$_("AUTH.TRY_SOMETHING")}</h1>
  </header>
  <div class="sub-heading">
    <p>
      {$_("AUTH.SIGN_IN_HELP_DESCRIPTION")}
    </p>
  </div>
  <div class="actions-group">
    <button
      class="btn btn-secondary"
      on:click={$goto("/auth/send-verification-link")}
      >{$_("AUTH.SEND_VERIFICATION_LINK")}</button
    >
    <div class="separator">{$_("AUTH.OR")}</div>
    <button class="btn btn-secondary" on:click={$goto("/auth/password-reset")}
      >{$_("AUTH.RESET_MY_PASSWORD")}</button
    >
  </div>
  <div class="hr" />
  <div class="contactus-text">
    <p>
      {$_("AUTH.TROUBLES")}
      <a
        class="link"
        href="https://www.navigatingcare.com/patient/contact-us.html"
        target="_blank"
      >
        {$_("AUTH.CONTACT_US")}
      </a>
    </p>
  </div>
</section>

<style lang="scss">
  .actions-group {
    display: flex;
    flex-flow: column;
    margin-bottom: 30px;
  }
  .separator {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #555860;
    margin: 25px 0;
  }
  .separator:after,
  .separator:before {
    content: "";
    display: block;
    background: #c3c7cc;
    width: 50%;
    height: 1px;
    margin: 0 10px;
  }
</style>
