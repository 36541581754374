<script>
  import GlobalAlert from "@/components/Alert/GlobalAlert/GlobalAlert.svelte"
  import AuthNav from "@/components/AuthNav/AuthNav.svelte"
</script>

<main class="auth-wrapper">
  <AuthNav />
  <GlobalAlert />

  <slot />
</main>

<style lang="scss">
  .auth-wrapper {
    display: block;
    width: 100%;
  }
</style>
