<script>
  // TODO: Add tab logic back at a later date
  import { onMount } from "svelte"
  import { orderBy } from "lodash"
  import ExpandableCard from "@/components/ExpandableCard/ExpandableCard.svelte"
  import { _ } from "@/i18n"
  import { viewedNotifications } from "@/stores/stores"
  import { hasData, formatRecordsDate } from "./medicationUtils"
  // import Tabs from "./_tabs.svelte"
  import CardBody from "./_cardBody.svelte"
  import CardHeader from "./_cardHeader.svelte"
  import { clientSidePagination } from "@/utils/clientSidePagination.js"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"

  export let medicationInfo
  let noficationsIds = []
  const initialRowCount = 100
  let isLoading = true
  let filterCounts
  let activeTab

  /* eslint no-use-before-define: ["error", { "variables": false }] */
  const updateTabProps = (newTabProps) => {
    const active = { ...tabProps.active, ...newTabProps.active }
    const inactive = { ...tabProps.inactive, ...newTabProps.inactive }
    const all = { ...tabProps.all, ...newTabProps.all }
    tabProps = { active, inactive, all }
  }

  const updateRowCount = (tab) => (chunkSize) => {
    const { visibleRowCount } = tabProps[tab]
    updateTabProps({ [tab]: { visibleRowCount: visibleRowCount + chunkSize } })
  }

  let tabProps = {
    active: {
      data: [],
      display: "none",
      id: "medications-active",
      visibleRowCount: initialRowCount,
      updater: updateRowCount("active"),
    },
    inactive: {
      data: [],
      display: "none",
      id: "medications-inactive",
      visibleRowCount: initialRowCount,
      updater: updateRowCount("inactive"),
    },
    all: {
      data: [],
      display: "none",
      id: "medications-all",
      visibleRowCount: initialRowCount,
      updater: updateRowCount("all"),
    },
  }

  // TODO: Need to add more status to filter records into Active and Inactive category
  /**
   * Filters and sort the data of records
   */
  const filterAndSortData = () => {
    const activeRecordsFiltered = medicationInfo.filter(
      (medication) =>
        medication.value.status === "in-progress" || !medication.value.status,
    )

    const activeRecords = orderBy(
      activeRecordsFiltered,
      ["value.started", "value.medicationName"],
      ["desc", "asc"],
    ).map(formatRecordsDate)

    const inActiveRecordsFiltered = medicationInfo.filter(
      (medication) => medication.value.status === "completed",
    )

    const inActiveRecords = orderBy(
      inActiveRecordsFiltered,
      ["value.started", "value.medicationName"],
      ["desc", "asc"],
    ).map(formatRecordsDate)

    // TODO: Switch allRecords logic once tab filtering is readded
    // const allRecords = [...activeRecords, ...inActiveRecords]
    const allRecords = orderBy(
      medicationInfo,
      ["value.started", "value.medicationName"],
      ["desc", "asc"],
    ).map(formatRecordsDate)

    updateTabProps({
      all: { data: allRecords },
      active: { data: activeRecords },
      inactive: { data: inActiveRecords },
    })
  }

  /**
   * Sets active tab and set data for that active tab
   */
  const setActiveTab = ({ detail }) => {
    activeTab = detail
    const newTabProps = {
      active: { display: "none" },
      inactive: { display: "none" },
      all: { display: "none" },
    }

    newTabProps[activeTab].display = ""
    updateTabProps(newTabProps)
  }

  /**
   * Gets the count of active and inactive records
   */
  const getCounts = () => {
    filterCounts = {
      activeCount: tabProps.active.data.length || 0,
      inActiveCount: tabProps.inactive.data.length || 0,
    }
  }

  $: if (medicationInfo) {
    filterAndSortData()
    setActiveTab({ detail: "all" })
    getCounts()

    isLoading = false
  }

  /**
   * Add notification to the viewedNotifications Store
   */
  onMount(() => {
    noficationsIds = medicationInfo
      .filter((medication) => medication.value.notificationId)
      .map((medication) => medication.value.notificationId)
    viewedNotifications.add([...noficationsIds])
  })
</script>

{#if isLoading}
  <div class="spinner-wrapper">
    <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
  </div>
{:else if tabProps[activeTab].data.length > 0}
  <div
    data-testid="all-list"
    use:clientSidePagination={{
      totalRows: tabProps.all.data.length,
      updater: tabProps.all.updater,
      listId: tabProps.all.id,
    }}
    style={`display: ${tabProps.all.display};`}
  >
    <div id={tabProps.all.id}>
      {#each tabProps.all.data.slice(0, tabProps.all.visibleRowCount) as { value: { medicationName, dosage, frequency, id, isPatientAdministered, notificationId, ...medicationProperties } } (id)}
        <div>
          <div class="card medication-card">
            <ExpandableCard isBodyExist={hasData(medicationProperties)}>
              <span slot="header">
                <CardHeader
                  {medicationName}
                  {dosage}
                  {frequency}
                  {notificationId}
                />
              </span>
              <span slot="body">
                <CardBody {medicationProperties} />
              </span>
            </ExpandableCard>
          </div>
        </div>
      {/each}
    </div>
  </div>
{:else}
  <div class="no-record">
    {#if medicationInfo.length === 0}
      {$_("HR.NO_RECORD_FOUND_FOR_MEDICATIONS")}
    {:else if activeTab === "active"}
      {$_("HR.NO_RECORD_FOR_ACTIVE_MEDICATIONS")}
    {:else if activeTab === "inactive"}
      {$_("HR.NO_RECORD_FOR_INACTIVE_MEDICATIONS")}
    {/if}
  </div>
{/if}

<style lang="scss">
  .category {
    color: #555860;
    margin-top: 20px;
    display: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }

  .showCategory {
    display: block;
  }

  .medication-card {
    padding: 0;
    margin-top: 12px;
    flex-direction: column;
  }

  @media (max-width: 575.98px) {
    .card {
      border-radius: 8px;
    }
  }
</style>
