<script>
  import { createEventDispatcher, onMount } from "svelte"
  import { goto } from "@roxi/routify"
  import anchorme from "anchorme"
  import DOMPurify from "dompurify"
  import { _ } from "@/i18n"
  import { patientContext } from "@/stores/stores"
  import { returnFormattedDate } from "@/utils/dateTime"
  import Attachment from "../Attachments/Attachment.svelte"
  import SwitchAccountsModal from "../../../_components/SwitchAccountsModal/SwitchAccountsModal.svelte"

  export let threadDetails
  export let replyFlag
  let isSwitchAccountsModalOpen = false
  /**
   * allow target="_blank" and add rel="noopener" for extra security
   */
  onMount(() => {
    DOMPurify.addHook("afterSanitizeAttributes", (node) => {
      if ("target" in node) {
        node.setAttribute("target", "_blank")
        node.setAttribute("rel", "noopener")
      }
    })
  })

  // Declare the dispatch
  const dispatch = createEventDispatcher()

  /**
   * Show reply screen
   */
  const showReply = () => {
    if ($patientContext?.mergedInto) {
      isSwitchAccountsModalOpen = true
    } else {
      dispatch("showReply", true)
    }
  }

  /**
   * Prepare message author details from the thread
   * @param {Object} message message details
   * @returns {String} message author name and on behalf of details if exists
   */
  const getThreadAuthor = (message) => {
    if (!message.author) {
      return ""
    }

    const onBehalfOf = message.author.on_behalf_of
      ? ` ${$_("SM.ON_BEHALF_OF")} ${message.author.on_behalf_of}`
      : ""
    return message.author.name + onBehalfOf
  }

  /**
   * Navigate to the compose message screen
   */
  const navigateToCompose = () => {
    if ($patientContext?.mergedInto) {
      isSwitchAccountsModalOpen = true
    } else {
      $goto("/messages/compose")
    }
  }
</script>

{#if !threadDetails?.readonly && !replyFlag}
  <section class="message-reply desktop-only">
    <button
      type="button"
      class="btn btn-primary compose-btn"
      on:click={showReply}
      id="btn-reply"
    >
      <i class="fas fa-reply" />
      {$_("SM.REPLY")}
    </button>
  </section>
{:else if threadDetails?.readonly && (!threadDetails?.category || threadDetails?.category?.name !== "Home Care Instructions")}
  <section class="message-notice">
    <div class="notice-text">
      {$_("SM.RESOVED_CONVERSATION_NOTICE")}
    </div>
    <div class="new-message-btn">
      <button
        type="button"
        class="btn btn-secondary"
        on:click={navigateToCompose}>{$_("SM.NEW_MESSAGE_BTN")}</button
      >
    </div>
  </section>
{/if}

{#if threadDetails?.messages}
  {#if threadDetails.messages.length === 1 && threadDetails.participants?.length === 2}
    <section
      class="message-body-container"
      class:no-footer={threadDetails.readonly || replyFlag}
    >
      <div class="message-detail">
        <div class="message-body">
          {@html DOMPurify.sanitize(anchorme(threadDetails.messages[0].body))}
        </div>

        {#if threadDetails.messages[0].attachments?.length > 0}
          <div class="message-attachments">
            {#each threadDetails.messages[0].attachments as attachment}
              <Attachment {attachment} />
            {/each}
          </div>
        {/if}
      </div>
    </section>
  {:else}
    <section
      class="message-body-container"
      class:no-footer={threadDetails.readonly || replyFlag}
    >
      {#each threadDetails.messages as message}
        <div class="message-detail">
          <h5 class="message-metadata">
            On <time class="message-timestamp"
              >{returnFormattedDate(message.created_at, {
                dateFormat: "M/DD/YYYY",
                timeFormat: " [at] h:mm A",
              })}</time
            >, <span class="message-author">{getThreadAuthor(message)}</span> wrote:
          </h5>
          <div class="message-body">
            {@html DOMPurify.sanitize(anchorme(message.body))}
          </div>
          {#if message?.attachments?.length > 0}
            <div class="message-attachments">
              {#each message.attachments as attachment}
                <Attachment {attachment} />
              {/each}
            </div>
          {/if}
        </div>
      {/each}
    </section>
  {/if}
{/if}

{#if !threadDetails?.readonly && !replyFlag}
  <section class="message-reply mobile-only">
    <button
      type="button"
      class="btn btn-primary compose-btn"
      on:click={showReply}
      id="btn-reply"
    >
      <i class="fas fa-reply" />
      {$_("SM.REPLY")}
    </button>
  </section>
{/if}
<SwitchAccountsModal bind:isModalOpen={isSwitchAccountsModalOpen} />

<style lang="scss" src="./ThreadBody.scss">
</style>
