/* eslint-disable camelcase */
/* global portalApp */
import { writable, derived, readable, get as storeGet } from "svelte/store"
import { isEmpty } from "lodash"

import { isChangingPage } from "@roxi/routify"
import { getData } from "@/utils/getData"
import { getP1Host } from "@/utils/getP1Host"
import { removeUrlParamByName, callAPI } from "@/utils/httpService"
import {
  getStoredJsonAsObject,
  serializeObjectToStorage,
} from "@/utils/localStorage"
import isPublicRoute from "@/utils/isPublicRoute"
import { getSessionData } from "@/getSessionData"

/**
 * @typedef AlertConfigObject
 * @type {object}
 * @property {String} title - alert title
 * @property {(String|Undefined)} message - alert message
 * @property {String} contentType - alert content type if empty is inform by default
 * @property {String} actionLink - alert link
 * @property {String} actionText - alert text
 * @property {Boolean} disableCloseButton - disables the close button
 * @property {String} iconName - icon name if empty is defined by the contentType
 * @property {String} iconColor - icon color if empty is defined by the contentType
 * @property {Boolean} stacked - stacks content on a column
 */

function createBodyClassName() {
  const { subscribe, update, set } = writable("")
  return {
    subscribe,
    add: (newClass) => {
      update((className) =>
        !className.match(new RegExp(`\\b${newClass}\\b`, "g"))
          ? `${className} ${newClass}`.trim()
          : className,
      )
    },
    remove: (className) => {
      update((classList) =>
        classList.replace(new RegExp(`\\b${className}\\b`, "g"), "").trim(),
      )
    },
    set,
  }
}

export const bodyClassName = createBodyClassName()

/* export const globalNavConfig = writable(null) */
export const mobileToolbarConfig = writable(null)
export const pageBannerConfig = writable(null)

/**
 * @type {AlertConfigObject}
 */
export const globalAlertConfig = writable(null)
/**
 * @type {Object<AlertConfigObject>}
 */
export const sectionalAlertConfig = writable({})
export const error = writable(null)
export const isPageFullWidth = writable(false)
export const loginViaNewAuth = writable(null)
export const authTest = writable(false) // TODO: PE-4081: Once we have new auth enabled for all users on prod, then wen can remove this code.
const params = new URLSearchParams(window.location.search)
export const accountIdUrlContext = writable(params.get("accountId"))
export const patientIdUrlContext = writable(params.get("patientId"))

/**
 * General function to redirect to P1
 */
const redirectToP1 = (accountTypes) => {
  const { PORTAL_1_URL } = portalApp.env
  const p1Host = getP1Host()
  if (accountTypes?.includes("nc_admin")) {
    window.location.replace(`${p1Host}/admin`)
  } else {
    window.location.replace(PORTAL_1_URL)
  }
}

export const currentPath = derived(isChangingPage, ($isChangingPage, set) => {
  if (!$isChangingPage) {
    set(window.location.pathname)
  }
})

const createSessionDataStore = () => {
  const { set, ...rest } = writable({})
  return {
    ...rest,
    set,
    async init() {
      // whitelisted routes does not require session check, it is accessible whitout authentication
      if (!isPublicRoute()) {
        const session = await getSessionData()
        if (session) set(session)
      }
    },
  }
}

export const sessionData = createSessionDataStore()

export const isUserClinicianOrAdmin = derived(
  sessionData,
  ($sessionData, set) => {
    const { account_types } = $sessionData
    if (!account_types) set(false)
    else if (
      account_types?.includes("clinician") ||
      account_types?.includes("nc_admin")
    ) {
      set(true)
    } else set(false)
  },
)

export const isElevatedUser = derived(sessionData, ($sessionData, set) => {
  const { account_types } = $sessionData
  if (!account_types) set(false)
  else if (
    account_types?.includes("clinician") ||
    account_types?.includes("nc_admin") ||
    account_types?.includes("authorized_rep")
  ) {
    set(true)
  } else set(false)
})

export const isLoggedIn = derived(sessionData, ($sessionData, set) => {
  if (isEmpty($sessionData)) set(false)
  else set(true)
})

/**
 * Store for patient lists for a specific account
 * If no account ID, display custom error message
 */
export const accountPatientsInfo = derived(
  [sessionData, isUserClinicianOrAdmin, accountIdUrlContext],
  ([$sessionData, $isUserClinicianOrAdmin, $accountIdUrlContext], set) => {
    if (isEmpty($sessionData)) return

    const {
      first_name: firstName,
      last_name: lastName,
      nc_account_id: ncAccountId,
      account_types: accountTypes,
    } = $sessionData

    const sessionStorageAcctId = sessionStorage.getItem(ncAccountId)
    const accountId =
      $accountIdUrlContext || sessionStorageAcctId || ncAccountId

    const addNameAndAcctIdToPatientArr = (account) => {
      const { patients = [], authorizedPatients = [] } = { ...account }

      if (!patients?.length) return account

      // add session data name fields to patients objects
      const patientsWithName = patients.map((patient) => ({
        ...patient,
        firstName,
        lastName,
      }))

      return { patients: patientsWithName, authorizedPatients, accountId }
    }

    // check for non-patient account and redirect if so
    // accountId is always set to either sessionData.accountID OR the given URL account ID
    // which happens in patient link.  If they're the same, then we are not in a patient link session
    if ($isUserClinicianOrAdmin && accountId === ncAccountId) {
      redirectToP1(accountTypes)
      return
    }

    getData(`accounts/${accountId}/patients`, true)
      .then(addNameAndAcctIdToPatientArr)
      .then(set)
      .then(() => {
        if ($accountIdUrlContext) {
          sessionStorage.setItem(ncAccountId, $accountIdUrlContext)
          removeUrlParamByName("accountId")
        }
      })
      .catch(() => {
        sessionStorage.removeItem(ncAccountId)
        redirectToP1(accountTypes)
      })
  },
)

export const patientContext = derived(
  [accountPatientsInfo, patientIdUrlContext, sessionData],
  ([$accountPatientsInfo, $patientIdUrlContext, $sessionData], set) => {
    if (!$accountPatientsInfo || !$sessionData) return

    const {
      patients = [],
      authorizedPatients = [],
      accountId,
    } = $accountPatientsInfo
    const combinedPatientsArr = [...patients, ...authorizedPatients]

    const localStoragePatientIdsByAcct =
      getStoredJsonAsObject("patientIdsByAcct")
    const localStoragePatientId = localStoragePatientIdsByAcct?.[accountId]

    /**
     * sets the patient id to localstorage as the value of the account id
     * @param {String} patientId - id of patient
     * @returns {Undefined}
     */
    const addPatientIdToLocalStorage = (patientId) => {
      const updatedLocalStorePatientId = {
        ...localStoragePatientIdsByAcct,
        [accountId]: patientId,
      }

      serializeObjectToStorage("patientIdsByAcct", updatedLocalStorePatientId)
    }

    /**
     * removes a patient id from localStorage
     * @returns {Undefined}
     */
    const removePatientIdFromLocalStorage = () => {
      // remove account id from local storage since stored value wasn't found
      const { [accountId]: acctIdToRemove, ...remainingPatientIds } =
        localStoragePatientIdsByAcct
      serializeObjectToStorage("patientIdsByAcct", remainingPatientIds)
    }

    /**
     * default if neither url param or localstorage lookup is successful
     * @returns {undefined}
     */
    const setDefaultPatientContext = () => {
      // default is first in combined patients array(order patients then authorized reps)
      const patientObj = { ...combinedPatientsArr[0] }
      const { ncPatientId } = patientObj
      addPatientIdToLocalStorage(ncPatientId)
      set(patientObj)
    }

    /**
     * Gets a patient Obj from combinedPatientsArr by patient id
     * @param {String} id - patient Id
     * @returns {Object|Null} with patient context
     */
    const getPatientObjByPatientId = (id) => {
      const patientObjIdx = combinedPatientsArr.findIndex(
        ({ ncPatientId }) => ncPatientId === id,
      )

      if (patientObjIdx < 0) return null

      const patientInfoObj = { ...combinedPatientsArr[patientObjIdx] }

      return patientInfoObj
    }

    // initial patient lookup by url param
    if ($patientIdUrlContext) {
      const patientObj = getPatientObjByPatientId($patientIdUrlContext)

      // there's a patient match
      if (patientObj) {
        const { ncPatientId } = patientObj
        addPatientIdToLocalStorage(ncPatientId)
        set(patientObj)
        removeUrlParamByName("patientId")
        return
      } else {
        removeUrlParamByName("patientId")
      }
    }

    // secondary lookup by local storage if url param isn't present
    if (localStoragePatientId) {
      const patientObj = getPatientObjByPatientId(localStoragePatientId)

      // there's a patient match
      if (patientObj) {
        set(patientObj)
        return
      } else {
        removePatientIdFromLocalStorage()
      }
    }

    setDefaultPatientContext()
  },
)

const createNotificationStore = () => {
  const storeObj = writable({})
  const { set, subscribe, ...rest } = storeObj

  /**
   * Filters down notification data by category and optional sub category
   * @param {String} categoryFilter - category filter
   * @param {String} [subCategoryFilter] - optional subcategory filter
   * @returns {Object} containing filtered counts and notifications arrays
   */
  const get = (categoryFilter, subCategoryFilter) => {
    const { data = {} } = storeGet(storeObj)
    const { counts = [], notifications = [] } = data

    const filterResults = ({ category, subCategory }) => {
      if (subCategoryFilter) {
        return category === categoryFilter && subCategory === subCategoryFilter
      }
      return category === categoryFilter
    }
    return {
      counts: counts.filter(filterResults),
      notifications: notifications.filter(filterResults),
    }
  }
  /**
   * refreshes the store with new, patient context specific notification data
   * @param {String} patientId - active patient ID
   * @returns {Undefined}
   */
  const refresh = (patientId) => {
    set({
      isLoading: true,
      data: null,
    })

    return callAPI({
      url: `notifications?ncPatientId=${patientId}`, // "../sample-notifications.json"
      method: "GET",
      suppressErrorPage: true,
    }).then((data) => {
      set({
        isLoading: false,
        data,
      })
    })
  }

  return {
    ...rest,
    set,
    subscribe,
    refresh,
    get,
  }
}

const createViewedNotificationsStore = () => {
  const { set, update, subscribe } = writable([])

  // add new notification ids to be marked as read if user is exlusively a patient
  const add = (notificationsArray) => {
    if (storeGet(isElevatedUser)) {
      return false
    }
    return update((currentStoreVal) => {
      return [...currentStoreVal, ...notificationsArray]
    })
  }

  /**
   * mark notifications as read if user has patient role only
   * @param {Array<Strings>}} readNotificationsArr - of notification IDs
   * @returns {Promise} calling proxy notification delete endpoint
   */
  const markAsRead = (readNotificationsArr) => {
    if (storeGet(isElevatedUser)) {
      return false
    }
    const notificationsSet = new Set(readNotificationsArr) // remove duplicates
    set([])
    return callAPI({
      url: "notifications",
      method: "DELETE",
      payload: {
        ids: [...notificationsSet],
      },
      suppressErrorPage: true,
      keepAlive: true,
    })
  }

  return {
    add,
    markAsRead,
    subscribe,
  }
}
export const notifications = createNotificationStore()
export const viewedNotifications = createViewedNotificationsStore()

/**
 * import the globalNavConfig function and web components
 */
export const generateGlobalNavConfig = readable(null, async (set) => {
  try {
    // string replaced in the bundler with the real url as rollup doesn't support dynamic import path strings
    const globalNavConfigFunc = await import("__globalNavURL__")
    set(globalNavConfigFunc.default)
  } catch (e) {
    set(null)
  }
})

export const clinicFeatures = derived(
  [patientContext],
  ([$patientContext], set) => {
    if (!$patientContext) return
    const { clinicId } = $patientContext

    callAPI({
      url: `clinic/${clinicId}/features`,
      method: "GET",
      suppressErrorPage: true,
    }).then((data) => {
      if (data) set(data)
    })
  },
  {},
)

/**
 * generate global nav config object from patient context
 */
export const globalNavConfig = derived(
  [
    sessionData,
    patientContext,
    generateGlobalNavConfig,
    accountPatientsInfo,
    isUserClinicianOrAdmin,

    clinicFeatures,
    notifications,
  ],
  (
    [
      $sessionData,
      $patientContext,
      $generateGlobalNavConfig,
      $accountPatientsInfo,
      $isUserClinicianOrAdmin,

      $clinicFeatures,
      $notifications,
    ],
    set,
  ) => {
    if (!$patientContext || !$generateGlobalNavConfig || !$accountPatientsInfo)
      return
    const { account_types = [] } = $sessionData || {}

    const {
      patients = [],
      authorizedPatients = [],
      accountId,
    } = $accountPatientsInfo

    const { ncPatientId: patientId, customPath } = $patientContext

    const {
      patient_link: { show_forms_to_patient: showFormsToPatient = false } = {},
    } = $clinicFeatures
    // eslint-disable-next-line no-undef
    const updatedNavConfig = $generateGlobalNavConfig(portalApp.env.ENV_NAME, {
      portal1Context: false,
      accountId,
      patientId,
      patients,
      authorizedPatients,
      isClinicianOrAdmin: $isUserClinicianOrAdmin,
      isAdmin: account_types.includes("nc_admin"),
      isClinician: account_types.includes("clinician"),

      showFormsToPatient,
      notifications: $notifications?.data || {},
    })
    set({ ...updatedNavConfig, customPath })
  },
  {
    base_portal_urls: {},
    customPath: "",
    main_tabs: [],
    more_menu_links: [],
    topbar_menus: [],
  },
)

/**
 * generate global nav config object from patient context
 */
export const clinicCategories = derived(
  [patientContext],
  ([$patientContext], set) => {
    if (!$patientContext) return
    const { clinicId, ncPatientId } = $patientContext

    callAPI({
      url: `patients/${ncPatientId}/secure-messages/clinic/${clinicId}/categories`,
      method: "GET",
      suppressErrorPage: true,
    }).then((data) => {
      if (data) set(data)
    })
  },
  [],
)

// url including the patient specific customPath
export const p1PatientUrl = derived(
  [patientContext, globalNavConfig],
  ([$patientContext], set) => {
    if (!$patientContext) return
    const { customPath } = $patientContext
    set(`${getP1Host()}/members/${customPath}`)
  },
)
