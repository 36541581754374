<script>
  export let size = 50
  export let strokeWidth = 8
  export let progress = 0
  export let progressOutlineWidth = 2
  export let ringColor = "#BEEFF4"
  export let progressColor = "#2999A3"
  export let progressOutlineColor = "#F0F2F5"

  $: center = size / 2
  $: radius = center - strokeWidth / 2 - progressOutlineWidth
  $: circum = 2 * Math.PI * radius
  $: offset = circum - progress * circum
</script>

<svg
  class="progress-ring"
  width={size}
  height={size}
  viewBox="0 0 {size} {size}"
>
  <defs>
    <filter id="outline" x="-50%" y="-50%" width="200%" height="200%">
      <!--outside-stroke-->
      <feFlood flood-color={progressOutlineColor} result="outside-color" />
      <feMorphology
        in="SourceAlpha"
        operator="dilate"
        radius={progressOutlineWidth}
      />
      <feComposite in="outside-color" operator="in" result="outside-stroke" />

      <!--fill-area-->
      <feMorphology
        in="SourceAlpha"
        operator="dilate"
        radius={progressOutlineWidth}
      />
      <feComposite in="SourceGraphic" operator="in" result="fill-area" />

      <!--merge graphics-->
      <feMerge>
        <feMergeNode in="outside-stroke" />
        <feMergeNode in="fill-area" />
      </feMerge>
    </filter>
  </defs>
  {#if progress !== 1}
    <circle
      class="progress-ring__bg"
      stroke={ringColor}
      stroke-width={strokeWidth}
      fill="transparent"
      r={radius}
      cx={center}
      cy={center}
    />
  {/if}
  <circle
    class="progress-ring__progress"
    stroke-width={strokeWidth}
    stroke={progressColor}
    fill="transparent"
    r={radius}
    cx={center}
    cy={center}
    stroke-dasharray="{circum} {circum}"
    stroke-dashoffset={offset}
    filter="url(#outline)"
  />
</svg>

<style>
  .progress-ring__progress {
    transition: 0.35s stroke-dashoffset;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
</style>
