/**
 *
 * @returns {Boolean} if the user agent browser is Safari
 */
export const isSafariBrowser = () => {
  // Get the user-agent string
  const userAgentString = navigator.userAgent
  // Detect Safari
  let isSafariAgent = userAgentString.indexOf("Safari") > -1
  // Detect Chrome
  const isChromeAgent = userAgentString.indexOf("Chrome") > -1
  // Discard Safari since it also matches Chrome
  if (isChromeAgent && isSafariAgent) isSafariAgent = false

  return isSafariAgent
}
