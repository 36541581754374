<script>
  import { isArray, isEmpty } from "lodash"
  import { _ } from "@/i18n"
  import {
    patientInfo,
    smokingStatus,
    deviceInfo,
  } from "@/stores/healthRecordStores"
  import CareTeamPatientInfo from "../care-team-patient-info/index.svelte"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"

  let isLoading = true
  let filteredPatientInfo

  /**
   * Filters the resolver response from patient info
   * @param {Array} resolverResponse response received from resolver
   */
  const filterPatientInfo = (resolverResponse) => {
    filteredPatientInfo = resolverResponse.filter((value) => {
      if (!value || value.hide) return false
      if (isArray(value)) {
        return !!value.length
      } else {
        return !!value
      }
    })
  }

  $: if ($patientInfo && $smokingStatus && $deviceInfo) {
    isLoading = false

    filterPatientInfo($patientInfo)
  }
</script>

<CareTeamPatientInfo activeClassLabel="Patient Information">
  {#if isLoading}
    <div class="spinner-wrapper">
      <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
    </div>
  {:else if !!filteredPatientInfo.length}
    <div class="card card-full-width">
      <div class="card-body">
        <ul class="patient-info">
          {#each filteredPatientInfo as { field, value }}
            {#if field !== "Addresses" && field !== "Name"}
              <li>
                <div class="label">
                  {field}:
                </div>
                <div
                  class="value"
                  class:multiline={isArray(value)}
                  class:email={field === "Email address"}
                >
                  {#if field === "Phone number"}
                    <a class="tel" href="tel:{value}">{value}</a>
                  {:else}
                    {isArray(value) ? value.join("\n") : value}
                  {/if}
                </div>
              </li>
            {:else}
              {#each value as { field, value }}
                <li>
                  <div class="label">{field}:</div>
                  <div class="value" class:multiline={isArray(value)}>
                    {isArray(value) ? value.join("\n") : value}
                  </div>
                </li>
              {/each}
            {/if}
          {/each}
          {#if $smokingStatus.length > 0}
            {#each $smokingStatus as { field, value: [value, provenance] }}
              {#if value}
                <li>
                  <div class="label">
                    {field}:
                  </div>
                  <div class="value">
                    {value}
                    {#if !isEmpty(provenance)}
                      <p class="value-secondary">
                        Added to EHR on {provenance}
                      </p>
                    {/if}
                  </div>
                </li>
              {/if}
            {/each}
          {/if}
          {#if $deviceInfo.length > 0}
            {#each $deviceInfo as { field, value: [value, provenance] }}
              {#if value}
                <li>
                  <div class="label">
                    {field}:
                  </div>
                  <div class="value">
                    {value}
                    {#if !isEmpty(provenance)}
                      <p class="value-secondary">
                        Added to EHR on {provenance}
                      </p>
                    {/if}
                  </div>
                </li>
              {/if}
            {/each}
          {/if}
        </ul>
      </div>
    </div>
  {:else}
    <div class="no-record">
      {$_("HR.NO_RECORD_FOUND_FOR_CARE_TEAM_&_PATIENT_INFORMATION")}
    </div>
  {/if}
</CareTeamPatientInfo>

<style lang="scss" src="./index.scss">
</style>
