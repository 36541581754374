<script>
  import { onMount, onDestroy } from "svelte"
  import { _ } from "@/i18n"
  import {
    mobileToolbarConfig,
    notifications,
    viewedNotifications,
  } from "@/stores/stores"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"
  import HealthRecordsHeader from "@/components/HealthRecordsHeader/HealthRecordsHeader.svelte"
  import SelfAdministered from "./_selfAdministered.svelte"
  import ClinicAdministered from "./_clinicAdministered.svelte"
  import { medicationInfo, patientId } from "@/stores/healthRecordStores"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"

  let isSelfAdministered = true
  let isLoading = true
  let groupedMeds = {}
  let navTabs = []
  let notificationsIds
  const SELF_ADMINISTERED_MEDICATIONS = new Set()
  const CLINIC_ADMINISTERED_MEDICATIONS = new Set()
  /**
   * Set the mobileToolbarConfig options for HR
   */
  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: true, text: $_("HR.HEALTH_RECORDS") },
      center: { logo: false },
      customClass: "blue-bkg-sm-down",
      redirectPath: "/health-records",
    })
  })

  /**
   * Reset the mobileToolbarConfig
   */
  onDestroy(() => {
    mobileToolbarConfig.set({})
  })

  const breadcrumbLinks = [
    {
      title: $_("HR.HEALTH_RECORDS"),
      link: "/health-records",
      id: "health-records",
    },
    {
      title: $_("HR.MEDICATIONS"),
      active: true,
    },
  ]

  const filterMedsByAdministration = (medInfo) => {
    notificationsIds = notifications
      .get("HEALTH_RECORD", "MEDICATION")
      ?.notifications?.map((notification) => {
        return {
          referenceId: notification.referenceId,
          notificationId: notification.id,
        }
      })
    return medInfo.reduce(
      (medicationGroupObj, medication) => {
        const { value } = medication
        let notificationId = ""
        const matchingNotification = notificationsIds.find(
          (notification) => notification.referenceId === value.id,
        )
        notificationId = matchingNotification
          ? matchingNotification.notificationId
          : ""
        if (value?.isPatientAdministered) {
          if (notificationId) SELF_ADMINISTERED_MEDICATIONS.add(notificationId)
          medicationGroupObj.selfAdministered.push({
            ...medication,
            value: { ...medication.value, notificationId },
          })
        } else {
          if (notificationId)
            CLINIC_ADMINISTERED_MEDICATIONS.add(notificationId)
          medicationGroupObj.clinicAdministered.push({
            ...medication,
            value: { ...medication.value, notificationId },
          })
        }
        return medicationGroupObj
      },
      {
        selfAdministered: [],
        clinicAdministered: [],
      },
    )
  }
  const createMedHeaderTabs = (groupedMedications) => {
    const headerTabs = []
    const { selfAdministered, clinicAdministered } = groupedMedications
    if (selfAdministered.length) {
      headerTabs.push({
        callback: () => {
          if ($viewedNotifications.length) {
            viewedNotifications
              .markAsRead([...CLINIC_ADMINISTERED_MEDICATIONS])
              .then(() => {
                return notifications.refresh($patientId)
              })
              .then(() => {
                isSelfAdministered = true
              })
          } else {
            isSelfAdministered = true
          }
        },
        label: $_("HR.SELF_ADMINISTERED"),
        notificationCount: SELF_ADMINISTERED_MEDICATIONS.size,
      })
    }
    if (clinicAdministered.length) {
      headerTabs.push({
        callback: () => {
          if ($viewedNotifications.length) {
            viewedNotifications
              .markAsRead([...SELF_ADMINISTERED_MEDICATIONS])
              .then(() => {
                return notifications.refresh($patientId)
              })
              .then(() => {
                isSelfAdministered = false
              })
          } else {
            isSelfAdministered = false
          }
        },
        label: $_("HR.CLINIC_ADMINISTERED"),
        notificationCount: CLINIC_ADMINISTERED_MEDICATIONS.size,
      })
    }
    return headerTabs
  }

  $: if ($medicationInfo && $notifications?.data) {
    groupedMeds = filterMedsByAdministration($medicationInfo)
    // default selfAdmin column if such a column exists in the data
    isSelfAdministered = !!groupedMeds?.selfAdministered?.length
    navTabs = createMedHeaderTabs(groupedMeds)
    isLoading = false
  }
</script>

<section class="health-records-wrapper">
  <Breadcrumb {breadcrumbLinks} />
  <HealthRecordsHeader
    title={$_("HR.MEDICATIONS")}
    {navTabs}
    activeClassLabel={isSelfAdministered
      ? $_("HR.SELF_ADMINISTERED")
      : $_("HR.CLINIC_ADMINISTERED")}
  />
  <section class="health-records-container">
    {#if isLoading}
      <div class="spinner-wrapper">
        <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
      </div>
    {:else if $medicationInfo.length > 0}
      {#if isSelfAdministered}
        <SelfAdministered medicationInfo={groupedMeds.selfAdministered} />
      {:else}
        <ClinicAdministered medicationInfo={groupedMeds.clinicAdministered} />
      {/if}
    {:else}
      <div class="no-record">
        {#if $medicationInfo.length === 0}
          {$_("HR.NO_RECORD_FOUND_FOR_MEDICATIONS")}
        {/if}
      </div>
    {/if}
  </section>
</section>

<style lang="scss">
  @import "../index.scss";
</style>
