<section class="footer-links">
  <ul class="links">
    <li>
      <a href="/about-us/terms-of-use" target="_blank"> Terms of Use </a>
    </li>
    <li>
      <a href="/about-us/privacy-policy" target="_blank"> Privacy Policy </a>
    </li>
    <li>
      <a
        href="https://navigatingcancer.com/requirements-incentives"
        target="_blank"
      >
        Certification
      </a>
    </li>
    <li>
      <a
        href="https://www.navigatingcare.com/patient/contact-us.html"
        target="_blank"
      >
        Help
      </a>
    </li>
  </ul>
  <p class="copyright">© 2021 Navigating Cancer, Inc.</p>
</section>

<style lang="scss" src="./Footer.scss"></style>
