<script>
  import { immunizationInfo } from "@/stores/healthRecordStores"
  import { notifications, viewedNotifications } from "@/stores/stores"
  import { _ } from "@/i18n"
  import ProceduresImmunizations from "../procedures-immunizations/index.svelte"
  import { clientSidePagination } from "@/utils/clientSidePagination"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"
  import NotificationDot from "@/components/Notifications/NotificationDot.svelte"

  let isLoading = true
  let immunizations = []
  let immunizationsNotifications = []
  let visibleRowCount = 25
  const updateRowCount = (chunkSize) => {
    visibleRowCount += chunkSize
  }

  /**
   * Formats the immunization resolver response
   * @param {Array<Object>} resolverResponse response received from resolver
   * @returns {Array<Object>} return formatted data
   */
  const formatImmunizationData = (resolverResponse) => {
    return resolverResponse.map(({ value }) => {
      const { id, title, description, fields } = value
      return {
        id,
        title,
        description,
        fields,
      }
    })
  }

  $: if ($immunizationInfo) {
    isLoading = false
    immunizations = formatImmunizationData($immunizationInfo)
  }

  $: if ($notifications?.data) {
    immunizationsNotifications =
      notifications.get("HEALTH_RECORD", "IMMUNIZATION")?.notifications || []
  }

  $: if (immunizationsNotifications.length && immunizations.length) {
    const notificationQueue = new Set() // set to avoid duplicates
    immunizations = immunizations.map(({ id, ...result }) => {
      const notificationId = immunizationsNotifications.find(
        ({ referenceId }) => referenceId === id,
      )?.id

      if (notificationId && !$viewedNotifications.includes(notificationId))
        notificationQueue.add(notificationId)

      return {
        ...result,
        notificationId,
      }
    })

    if (notificationQueue.size) {
      viewedNotifications.add([...notificationQueue])
    }
  }
</script>

<ProceduresImmunizations activeClassLabel="Immunizations">
  {#if isLoading}
    <div class="spinner-wrapper">
      <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
    </div>
  {:else if immunizations.length > 0}
    <div
      data-testid="immunizations"
      use:clientSidePagination={{
        totalRows: immunizations.length,
        updater: updateRowCount,
        listId: "immunizations",
      }}
    >
      <div id="immunizations">
        {#each immunizations.slice(0, visibleRowCount) as { title, description, notificationId, fields }}
          <article class="card">
            <section class="card-header">
              <header>
                {#if notificationId}
                  <NotificationDot />
                {/if}
                <h3 class="title mb-0" class:has-notification={notificationId}>
                  {title}
                </h3>
              </header>
            </section>
            <section class="card-body">
              {#if description}
                <div class="description mb-0">{description}</div>
              {/if}
              {#if fields.length > 0}
                <ul class="card-text">
                  {#each fields as { field, value }}
                    {#if value}
                      <li>
                        <div
                          class="label"
                          class:multiline={field === "Added to\nEHR"}
                        >
                          {field}:
                        </div>
                        <div class="value">{value}</div>
                      </li>
                    {/if}
                  {/each}
                </ul>
              {/if}
            </section>
          </article>
        {/each}
      </div>
    </div>
  {:else}
    <div class="no-record">
      {$_("HR.NO_RECORD_FOUND_FOR_PROCEDURES_&_IMMUNIZATIONS")}
    </div>
  {/if}
</ProceduresImmunizations>

<style lang="scss">
  @import "../index.scss";
</style>
