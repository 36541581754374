import applyResolvers from "@/utils/applyResolvers"
import { isNotFutureDate } from "@/utils/dateTime"

/* eslint-disable no-useless-escape */
/* eslint-disable quotes */

/**
 *
 * @param {Object} resourceObj - Fihr vitals resource obj
 * @param {String} columnName - name of vitals field for grouping
 * @param {String|Undefined} isValueString - string treated as boolean if valueString value is passed, isValueString is treated as true
 * @returns {Array|Undefined} of strings [value, date] if data can be resolved
 */
const resolveVitalValue = (resourceObj, columnName, isValueString) => {
  let path
  if (isValueString) {
    path = [`valueString`, `effectiveDateTime`]
  } else {
    path = [
      {
        join: [`valueQuantity.value`, "$ ", `valueQuantity.code`],
      },
      `effectiveDateTime`,
      `id`, // id necessary for notifications
    ]
  }
  const resolvedData = applyResolvers(resourceObj, [
    { field: columnName, path },
  ])
  // resolver returns array of field, value objects, we just want value
  return resolvedData?.[0]?.value
}

/**
 * @param entry {Array<Object>} - Vital signs entry data
 * @returns {Array<Object>} - of resolved vitals values grouped by field names (columns) [{field: name, value: [[cell value, date],...]},...]
 */
const resolveVitalsDataIntoColumns = (entry) => {
  if (!entry) return []
  // maps the column index to the array of values
  const columns = {}
  // 2d array of vitals headers and values
  const valueArrayByColumn = []

  entry.forEach(({ resource }) => {
    const columnName = resource?.code?.text
    if (!columnName) return

    const isValueString = resource?.valueString

    // gets array idx for column specific values
    const columnIdx = columns[columnName]

    const resolvedValue = resolveVitalValue(resource, columnName, isValueString)

    // check strictly for undefined as columnIdx can be 0
    if (columnIdx === undefined) {
      // replicate resolver return structure
      valueArrayByColumn.push({
        field: columnName,
        value: [resolvedValue],
      })
      // store the idx of the column in the valueArray
      columns[columnName] = valueArrayByColumn.length - 1
    } else {
      valueArrayByColumn[columnIdx].value.push(resolvedValue)
    }
  })
  return valueArrayByColumn
}

const vitalSignsResolvers = [
  {
    field: "vitals",
    path: (data) => {
      return resolveVitalsDataIntoColumns(
        data?.entry?.filter((entry) =>
          isNotFutureDate(entry?.resource?.effectiveDateTime),
        ),
      )
    },
  },
]

export default vitalSignsResolvers
