<script>
  import { _ } from "@/i18n"
  import LanguageButton from "../LanguageButton/LanguageButton.svelte"

  export let breadcrumbBtn = {
    text: $_("AUTH.BACK"),
    href: "#/",
    callback: () => {
      window.history.back()
    },
  }
</script>

<div class="btn-group">
  <a class="link" on:click={breadcrumbBtn.callback} href={breadcrumbBtn.href}>
    <i class="fas fa-chevron-left" />{breadcrumbBtn.text || "Back"}</a
  >
  <LanguageButton/>
</div>

<style lang="scss" src="./AuthButtonGroup.scss"></style>
