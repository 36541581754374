import { datadogRum } from "@datadog/browser-rum"
import App from "@/App.svelte"

/// https://docs.datadoghq.com/real_user_monitoring/browser/
datadogRum.init({
  applicationId: "a61294b1-d8b6-477d-b0dd-a830e1d047e3",
  clientToken: "pub55c350b7186e6bc6aa518b26f8fd4c4b",
  site: "datadoghq.com",
  service: "__appName__",
  env: "__envName__",
  version: "__appVersion__",
  /* eslint-disable no-undef */
  sampleRate: __datadogRumSampleRate__,
  sessionReplaySampleRate: __datadogRumSampleRate__,
  /* eslint-enable no-undef */
  allowedTracingOrigins: [
    /https:\/\/.*\.nc-acceptance\.com/,
    /https:\/\/.*navigatingcare\.com/,
  ],
  trackResources: true,
  trackLongTasks: true,
  trackInteractions: true,
})

datadogRum.startSessionReplayRecording()

const app = new App({
  target: document.body,
})

export default app
