<script>
  import { isEmpty } from "lodash"
  import { sessionData } from "@/stores/stores"
  // eslint-disable-next-line no-undef
  let displayHealthRecords = false
  $: if (!isEmpty($sessionData)) {
    displayHealthRecords = true
  }
</script>

{#if displayHealthRecords && !isEmpty($sessionData)}
  <slot />
{/if}
