<script>
  import { goto } from "@roxi/routify"
  import { onMount, onDestroy } from "svelte"
  import { bodyClassName } from "@/stores/stores"
  import { _ } from "@/i18n"

  import CurvedBanner from "@/components/CurvedBanner/CurvedBanner.svelte"

  let email

  /**
   * Get query string params
   * Set white bodyClassName for xs-down view ports
   */
  onMount(() => {
    bodyClassName.add("white-bkg-xs-down")

    const params = new URLSearchParams(window.location.search)
    if (params.get("email")) {
      email = params.get("email")
    } else {
      $goto("/auth/password-reset")
    }
  })

  /**
   * Reset the bodyClassName
   */
  onDestroy(() => {
    bodyClassName.remove("white-bkg-xs-down")
  })
</script>

<div>
  <CurvedBanner config={{ title: $_("AUTH.CHECK_EMAIL") }} />
  <section class="auth-container">
    <div class="sub-heading">
      <p>
        {$_("AUTH.EMAIL_PROVIDED")}<br />
        <strong>{email}</strong>
      </p>
    </div>
    <div class="sub-heading">
      <p>
        {$_("AUTH.CHECK_EMAIL_DESCRIPTION")}
      </p>
    </div>
    <div class="sub-heading">
      <p>
        {$_("AUTH.CHECK_SPAM")}
      </p>
    </div>
    <div class="hr" />
    <div class="contactus-text">
      <p>
        {@html $_("AUTH.HAVING_TROUBLE_IN_SIGNING_IN")}
      </p>
    </div>
  </section>
</div>
