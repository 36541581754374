/**
 * Sorts field defined in sortField param to the top of the array
 * @param {Object} fieldA - field object
 * @param {Object} fieldB - comparison field object
 * @param {String} sortField - name of the field to be sorted to top
 * @returns fieldA -1 >= 0 <= 1
 */

export const sortFieldToTop = (fieldA, fieldB, sortField) => {
  const fieldTypeA = fieldA.use === sortField ? 0 : 1
  const fieldTypeB = fieldB.use === sortField ? 0 : 1
  if (fieldTypeA < fieldTypeB) {
    return -1
  }
  if (fieldTypeA > fieldTypeB) {
    return 1
  }
  return 0
}
