<script>
  import { onMount, onDestroy } from "svelte"
  import { url, params, goto } from "@roxi/routify"
  import { _ } from "@/i18n"
  import SectionalToast from "@/components/Alert/SectionalToast/SectionalToast.svelte"
  import { pageBannerConfig, notifications } from "@/stores/stores"

  let notificationsSubCategories = []
  let healthRecordsPageValues = [
    {
      label: "Test Results",
      iconFileName: "lab-results.svg",
      urlFragment: "/test-results",
      subCategories: ["TEST_RESULT"],
    },
    {
      label: "Vitals",
      iconFileName: "vitals.svg",
      urlFragment: "/vitals",
      subCategories: ["VITAL_SIGN"],
    },
    {
      label: "Medications",
      iconFileName: "meds-allergies.svg",
      urlFragment: "/medications",
      subCategories: ["MEDICATION"],
    },
    {
      label: "Allergies",
      iconFileName: "allergies.svg",
      urlFragment: "/allergies",
      subCategories: ["ALLERGY"],
    },
    {
      label: "Health Documents",
      iconFileName: "health-documents.svg",
      urlFragment: "/health-documents",
      subCategories: ["HEALTH_DOCUMENT"],
    },
    {
      label: "Clinical Notes",
      iconFileName: "clinical-notes.svg",
      urlFragment: "/clinical-notes",
      subCategories: ["CLINICAL_NOTE"],
    },
    {
      label: "Health Concerns & Diagnoses",
      iconFileName: "problems.svg",
      urlFragment: "/health-concerns",
      subCategories: ["HEALTH_CONCERN", "DIAGNOSIS"],
    },
    {
      label: "Assessments & Treatment Plans",
      iconFileName: "assessments.svg",
      urlFragment: "/assessments-treatment-plans",
      subCategories: ["ASSESSMENT_TREATMENT_PLAN"],
    },
    {
      label: "Goals",
      iconFileName: "goals.svg",
      urlFragment: "/goals",
      subCategories: ["GOAL"],
    },
    {
      label: "Procedures & Immunizations",
      iconFileName: "procedures.svg",
      urlFragment: "/procedures",
      subCategories: ["PROCEDURE", "IMMUNIZATION"],
    },
    {
      label: "Care Team & Patient Information",
      iconFileName: "care-team.svg",
      urlFragment: "/care-team",
      subCategories: [],
    },
  ]

  const { isDemoPage } = $params

  $: isEmptyRecords = healthRecordsPageValues.some(
    (record) => record.isData === false,
  )

  /**
   * Temporary implementation, replace when api data is present
   */
  // eslint-disable-next-line no-shadow
  const getRecordsData = async (notificationsSubCategories) => {
    healthRecordsPageValues = healthRecordsPageValues.map((record) => {
      return {
        ...record,
        isData: true,
        hasNotifications: notificationsSubCategories?.some((notification) =>
          record?.subCategories?.includes(notification),
        ),
      }
    })
  }

  /**
   * Randomize records data for demo purposes
   */
  const demoSetRandomizeRecordsData = () => {
    healthRecordsPageValues = healthRecordsPageValues.map((record) => {
      const randomBool = Math.random() < 0.5
      return {
        ...record,
        isData: randomBool,
      }
    })
  }

  /**
   * Set the pageBanner options for HR
   */
  onMount(() => {
    pageBannerConfig.set({
      title: "Health Records",
      iconSrc: "../assets/svg/health-records-icon.svg",
      circleBorderColor: "#91DD44",
    })
  })

  /**
   * Reset the pageBannerConfig
   */
  onDestroy(() => {
    pageBannerConfig.set(null)
  })

  $: {
    if ($notifications?.data) {
      notificationsSubCategories = $notifications.data.counts
        .filter(
          (notification) =>
            notification.category === "HEALTH_RECORD" && notification.count > 0,
        )
        .map((notification) => notification.subCategory)
      getRecordsData(notificationsSubCategories)
    }
  }
</script>

<div>
  {#if isDemoPage}
    <!-- TODO: Should we remove this? -->
    <section>
      <button on:click={demoSetRandomizeRecordsData} class="button btn-primary"
        >Set Random Data For Records</button
      >
    </section>
  {/if}
  <div class="alert-padding">
    <SectionalToast key="sentRecordsSuccess" />
  </div>
  <nav class="records">
    {#each healthRecordsPageValues as { label, iconFileName, urlFragment, isData, hasNotifications }}
      {#if isData}
        <a
          class="record-anchor-wrap"
          id={urlFragment.slice(1)}
          href="{$url()}{urlFragment}"
        >
          <div class="record">
            <img
              width="20"
              height="20"
              src="../../../assets/svg/records-icons/{iconFileName}"
              alt="{label} icon"
            />
            <p class="record-label">{label}</p>
          </div>

          <div class="notification-container">
            {#if hasNotifications}
              <div class="notification">New</div>
            {/if}
            <i class="pl-3 fas fa-chevron-right" aria-hidden="true" />
          </div>
        </a>
      {/if}
    {/each}
  </nav>
  <section class="vds-btn">
    <button class="btn btn-secondary" on:click={$goto("/health-records/vds")}
      >View, Download, or Share</button
    >
  </section>
  <section class="access-log-section">
    <p class="access-log-title">{$_("HR.ACCESS_LOG_SECTION_TITLE")}</p>
    <p class="access-log-description mb-0">
      {$_("HR.ACCESS_LOG_PRE_LINK_TEXT")}
      <span
        ><a class="access-log-description-link" href="{$url()}/access-log"
          >{$_("HR.ACCESS_LOG_LINK_TEXT")}</a
        ></span
      >
      {$_("HR.ACCESS_LOG_POST_LINK_TEXT")}
    </p>
  </section>
  <section class="empty-records">
    {#if isEmptyRecords}
      <p class="mb-0">
        We don't have health records for the following categories:
      </p>
      <ul>
        {#each healthRecordsPageValues as { label, isData }}
          {#if isData === false}
            <li>{label}</li>
          {/if}
        {/each}
      </ul>
    {/if}
  </section>
</div>

<style lang="scss" src="./index.scss">
</style>
