<script>
  import { onDestroy, onMount } from "svelte"
  import DOMPurify from "dompurify"
  import { fade, fly } from "svelte/transition"
  import { quintOut } from "svelte/easing"
  import { find, isUndefined } from "lodash"
  import { bodyClassName } from "../../stores/stores"
  import { _ } from "@/i18n"
  import { trapKeyboardFocus } from "@/utils/keyboardNavigation"

  export let isOpen = false
  export let maxWidth = 400
  export let modalTitle = ""
  export let modalBody = ""
  export let closeIcon = false
  export let closeModalOnBackDropClick = true || undefined
  export let btnDefs = [
    {
      name: "OK",
      disableButton: false,
      escapeFunction: true,
      keepModalOpen: false,
      isBtnHidden: false,
    },
  ]
  let keyboardEvt

  /**
   * Attach keyboard event listener to the targeted DOM element
   * @param {HTMLElement} target HTMLElement
   * @param {Object} args event type
   */
  const attachEvent = (target, ...args) => {
    target.addEventListener(...args)
    return {
      remove: () => target.removeEventListener(...args),
    }
  }

  const modalOpen = () => {
    bodyClassName.add("modal-open")
  }

  const modalClose = () => {
    bodyClassName.remove("modal-open")
  }

  /**
   * Close modal on click of closeIon OR btn has escapeFunction and invoke callback function if btn has escapeFunction
   */
  const callEscapeCallbackIfDefined = () => {
    // Find object which has escapeFunction=true
    const btnDefsForEscape = find(btnDefs, { escapeFunction: true })

    // If escape is binded with btnDefs then only invoke callback
    if (btnDefsForEscape) btnDefsForEscape.callback?.()

    // Close the modal
    isOpen = false
  }

  /**
   * Close the modal while on button click
   * Invoke the callback if btn has callback props
   * @param {Object} btn Button definition objects which includes label and callback properties
   */
  const onButtonClick = (btn) => {
    // Find object which has keepModalOpen=true
    const btnDefsToKeepModalOpen = find(btnDefs, { keepModalOpen: true })
    isOpen = !!btnDefsToKeepModalOpen

    if (btn.callback) {
      btn.callback()
    }
  }

  /**
   * Attach the keyboard event listener while modal is opened
   * Invoke Escape callback function if event key is Escape
   */
  const onBackdropClick = (event) => {
    event.stopPropagation()
    if (closeModalOnBackDropClick || isUndefined(closeModalOnBackDropClick))
      callEscapeCallbackIfDefined()
  }

  /**
   * Attach the keyboard event listener while modal is opened
   * Invoke Escape callback function if event key is Escape
   */
  const onModalOpened = () => {
    keyboardEvt = attachEvent(document, "keydown", (e) => {
      if (e.key === "Escape" && e.code === "Escape") {
        callEscapeCallbackIfDefined()
      }
    })
  }

  /**
   * Remove keyboard event listener from DOM while modal is closed
   */
  const onModalClosed = () => {
    if (keyboardEvt) {
      keyboardEvt.remove()
    }
  }

  onMount(async () => {
    if (isOpen) {
      onModalOpened()
    }
  })

  onDestroy(() => {
    modalClose()
  })

  $: {
    if (isOpen) {
      modalOpen()
    } else {
      modalClose()
    }
  }
</script>

{#if isOpen}
  <div
    use:trapKeyboardFocus={{ querySelectorString: "button" }}
    class="modal show d-block"
    tabindex="-1"
    data-backdrop="static"
    data-keyboard="false"
    role="dialog"
    aria-labelledby={modalTitle ? "dialogTitle" : undefined}
    aria-describedby={modalBody && modalBody.length > 1
      ? "dialogDesc"
      : undefined}
    aria-modal="true"
    data-testid="modal"
    on:click|self={onBackdropClick}
    on:introend={onModalOpened}
    on:outroend={onModalClosed}
    transition:fade
  >
    <div
      class="modal-dialog modal-dialog-centered "
      role="document"
      style="--modal-size: {maxWidth}px"
      in:fly={{ y: -50, duration: 300 }}
      out:fly={{ y: -50, duration: 300, easing: quintOut }}
    >
      <div class="modal-content">
        <div class="modal-content-wrapper mb-4">
          {#if modalTitle}
            <div class="modal-header">
              <h5 class="modal-title" id="dialogTitle">{modalTitle}</h5>
            </div>
          {/if}
          {#if $$slots.body}
            <div class="modal-body" id="dialogDesc">
              <slot name="body" />
            </div>
          {:else if modalBody}
            <div class="modal-body" id="dialogDesc">
              {@html DOMPurify.sanitize(modalBody)}
            </div>
          {/if}
        </div>
        <div class="modal-footer">
          {#each btnDefs as btn}
            {#if !btn.isBtnHidden}
              <button
                type="button"
                class="btn btn-{btn.buttonClass || 'primary'}"
                disabled={btn.disableButton}
                on:click={onButtonClick(btn)}>{btn.label}</button
              >
            {/if}
          {/each}
        </div>
        {#if closeIcon}
          <div class="close-modal" on:click={() => (isOpen = false)}>
            <button id="close-btn" class="close-btn">
              <span class="sr-only">{$_("GLOBAL_NAV.CLOSE")}</span>
            </button>
          </div>
        {/if}
      </div>
    </div>
  </div>
  {#if isOpen}
    <div class="modal-backdrop show" transition:fade={{ duration: 150 }} />
  {/if}
{/if}

<style type="scss">
  :global(.modal-dialog) {
    max-width: var(--modal-size);
  }

  .close-btn {
    border: 0;
    background-image: url("/assets/svg/close.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-color: white;
    width: 12px;
    height: 12px;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 13px;
  }
</style>
