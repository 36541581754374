<script>
  import { onMount, afterUpdate } from "svelte"

  import { _ } from "@/i18n"
  import { convertComponentValueArrToObj } from "@/utils/convertComponentValueArrToObj"
  import SimpleModal from "@/components/Modal/SimpleModal.svelte"

  export let interpretations
  /**
   * enables truncation at a set char length
   * @property {Number} - of chars until truncation
   */
  export let truncateLength

  let truncateContainer
  let formattedInterpretations
  let isLong = false
  let isModalOpen = false

  $: {
    if (interpretations && interpretations.length > 0) {
      formattedInterpretations = convertComponentValueArrToObj(interpretations)
    }
  }

  /**
Truncates HTML tags if the total character length exceeds the specified limit.
@param {number} charLength - The maximum character length before truncation.
@returns {void}
*/
  function truncateHTMLTags(charLength) {
    const elements = truncateContainer.getElementsByClassName("truncate")
    const elementArray = Array.from(elements) // Convert HTMLCollection to array
    const totalLength = elementArray.reduce(
      (length, element) => length + element.textContent.length,
      0,
    )
    isLong = false
    if (totalLength > charLength) {
      let truncatedLength = 0

      elementArray.some((element) => {
        const content = element.textContent
        const remainingLength = charLength - truncatedLength

        if (content.length > remainingLength) {
          // modify the element in place
          // eslint-disable-next-line no-param-reassign
          element.textContent = `${content.substr(0, remainingLength)}...`
          isLong = true
          return true // Exit the loop
        } else {
          truncatedLength += content.length
        }

        return false // Continue the loop
      })

      elementArray.slice(truncatedLength).forEach((element) => element.remove())
    }
  }

  onMount(() => {
    if (truncateContainer && truncateLength) truncateHTMLTags(truncateLength)
  })
  afterUpdate(() => {
    if (truncateContainer && truncateLength) truncateHTMLTags(truncateLength)
  })
</script>

{#if formattedInterpretations}
  <div class="interpretations-container" bind:this={truncateContainer}>
    {#if formattedInterpretations.findings}
      <h3 class="interpretations-title truncate">
        {$_("HR.TEST_RESULTS.FINDINGS")}:
      </h3>
      <p class="interpretations-text truncate">
        {formattedInterpretations.findings}
      </p>
    {/if}
    {#if formattedInterpretations.comments}
      <h3 class="interpretations-title truncate">
        {$_("HR.TEST_RESULTS.COMMENTS")}:
      </h3>
      <p class="interpretations-text truncate">
        {formattedInterpretations.comments}
      </p>
    {/if}
    {#if formattedInterpretations.labComments}
      <h3 class="interpretations-title truncate">
        {$_("HR.TEST_RESULTS.LAB_COMMENTS")}:
      </h3>
      <p class="interpretations-text truncate">
        {formattedInterpretations.labComments}
      </p>
    {/if}
  </div>
  {#if isLong}
    <button
      class="modal-btn"
      type="button"
      on:click={() => (isModalOpen = true)}>See full comment</button
    >
  {/if}
  {#if isModalOpen}
    <SimpleModal on:close={() => (isModalOpen = false)}>
      <div slot="content" class="interpretations-container">
        <p class="modal-title">
          {$_("HR.TEST_RESULTS.COMMENTS_TITLE")}
        </p>
        {#if formattedInterpretations.findings}
          <h3 class="interpretations-title ">
            {$_("HR.TEST_RESULTS.FINDINGS")}:
          </h3>
          <p class="interpretations-text ">
            {formattedInterpretations.findings}
          </p>
        {/if}
        {#if formattedInterpretations.comments}
          <h3 class="interpretations-title ">
            {$_("HR.TEST_RESULTS.COMMENTS")}:
          </h3>
          <p class="interpretations-text ">
            {formattedInterpretations.comments}
          </p>
        {/if}
        {#if formattedInterpretations.labComments}
          <h3 class="interpretations-title ">
            {$_("HR.TEST_RESULTS.LAB_COMMENTS")}:
          </h3>
          <p class="interpretations-text ">
            {formattedInterpretations.labComments}
          </p>
        {/if}
      </div>
    </SimpleModal>
  {/if}
{/if}

<style lang="scss" src="./LabInterpretations.scss"></style>
