<script>
  import { onMount, onDestroy } from "svelte"
  import DOMPurify from "dompurify"
  import { filter, isEmpty } from "lodash"
  import { _ } from "@/i18n"
  import { mobileToolbarConfig } from "@/stores/stores"
  import { assessmentInfo } from "@/stores/healthRecordStores"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"
  import HealthRecordsHeader from "@/components/HealthRecordsHeader/HealthRecordsHeader.svelte"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"

  let isLoading = true

  const breadcrumbLinks = [
    {
      title: $_("HR.HEALTH_RECORDS"),
      link: "/health-records",
      id: "health-records",
    },
    {
      title: $_("HR.ASSESSMENTS_&_TREATMENT_PLANS"),
      active: true,
    },
  ]

  $: if ($assessmentInfo) {
    isLoading = false
  }

  /**
   * Set the mobileToolbarConfig options for HR
   */
  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: true, text: $_("HR.HEALTH_RECORDS") },
      center: { logo: false },
      customClass: "blue-bkg-sm-down",
      redirectPath: "/health-records",
    })
  })

  /**
   * Reset the mobileToolbarConfig
   */
  onDestroy(() => {
    mobileToolbarConfig.set({})
  })

  /**
   * Format the treatments data array and return the object with key value pair
   * @param {Array} treatmentsData
   * @returns {Object} formatted treatment object
   */
  const formatTreatmentsData = (treatmentsData) => {
    return treatmentsData.map((treatments) => {
      const filteredObjByDate = filter(treatments, { field: "treatmentDate" })
      const filteredObjByTitle = filter(treatments, { field: "treatment" })

      return {
        date: filteredObjByDate.length > 0 ? filteredObjByDate[0].value : "",
        title: filteredObjByTitle.length > 0 ? filteredObjByTitle[0].value : "",
      }
    })
  }
</script>

<section class="health-records-wrapper">
  <Breadcrumb {breadcrumbLinks} />
  <HealthRecordsHeader title={$_("HR.ASSESSMENTS_&_TREATMENT_PLANS")} />
  {#if isLoading}
    <div class="spinner-wrapper">
      <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
    </div>
  {:else if $assessmentInfo.length > 0}
    <section class="health-records-container">
      {#each $assessmentInfo as { value: { assessments, treatments, provenance, html } }}
        <article class="card">
          <section class="card-body">
            {#if html}
              <div class="treatment-container">
                {@html DOMPurify.sanitize(html)}
              </div>
            {/if}
            {#if treatments.length > 0}
              <div class="treatment-container">
                {#each formatTreatmentsData(treatments) as { title, date }}
                  <div class="treatments">
                    {#if title.length > 0}
                      <p class="description mb-0">
                        {title[0]}
                      </p>
                    {/if}
                    {#if date}
                      <p class="description mb-0">
                        <span class="field">Date:</span>
                        <span>{date}</span>
                      </p>
                    {/if}
                  </div>
                {/each}
              </div>
            {/if}
            {#if !isEmpty(assessments) && !isEmpty(treatments)}
              <div class="hr" />
            {/if}
            {#if !isEmpty(assessments)}
              <div class="assessment-container">
                {#each assessments as assessment}
                  <div class="assessment">
                    <h3 class="title mb-0">Assessment</h3>
                    <p class="description mb-0">
                      {assessment}
                    </p>
                  </div>
                {/each}
              </div>
            {/if}
            {#if !isEmpty(provenance) && !isEmpty(assessments) && !isEmpty(treatments)}
              <div class="hr" />
            {/if}
            {#if !isEmpty(provenance)}
              {#each provenance as { value }}
                <div class="provenance-container">
                  <p class="description description--provenance mt-0 mb-0">
                    <span class="field">Added to EHR: </span>
                    <span>{value}</span>
                  </p>
                </div>
              {/each}
            {/if}
          </section>
        </article>
      {/each}
    </section>
  {:else}
    <div class="no-record">
      {$_("HR.NO_RECORD_FOUND_FOR_ASSESSMENTS_&_TREATMENT_PLANS")}
    </div>
  {/if}
</section>

<style lang="scss" src="./index.scss"></style>
