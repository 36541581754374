<script>
  import DOMPurify from "dompurify"
  import NotificationCount from "../Notifications/NotificationCount.svelte"

  /**
   * navTab type def
   * @typedef {Object} navTab
   * @property {String} href - link
   * @property {String} label - text display for link
   * @property {Number} [notificationCount] - number of notifications
   * @callback [callback] - will execute instead of the href if passed
   */
  /**
   * @type {Array.<navTab>}
   * @default []
   */
  export let navTabs = []
  export let title = ""
  export let subTitle = ""

  // Label that will have active class applied to it
  export let activeClassLabel = ""

  // Title alignment class would be text-left, text-center, text-right
  export let textAlignClass = "text-center"

  export let customClass = ""
</script>

<header class="header">
  <h1 class="title {textAlignClass} {customClass}">{title}</h1>
  {#if subTitle}
    <h2 class="sub-title">{subTitle}</h2>
  {/if}
  <slot />
  <nav>
    {#if navTabs.length > 0}
      <ul class="nav nav-tabs">
        {#each navTabs as { href, label, callback, notificationCount }}
          <li class="nav-item" class:active={activeClassLabel === label}>
            {#if callback}
              <button type="button" on:click={callback} class="nav-link"
                >{@html DOMPurify.sanitize(label)}</button
              >
            {:else}
              <a {href} class="nav-link">{@html DOMPurify.sanitize(label)}</a>
            {/if}
            {#if notificationCount}
              <NotificationCount count={notificationCount} />
            {/if}
          </li>
        {/each}
      </ul>
    {/if}
  </nav>
</header>

<style lang="scss" src="./HealthRecordsHeader.scss">
</style>
