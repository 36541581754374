<script>
  export let mobileToolbarConfig = {}

  function navigateTo(redirectPath) {
    return redirectPath
      ? window.history.pushState({}, document.title, redirectPath)
      : window.history.back()
  }
</script>

  <nav
    class="navbar navbar-expand-lg navbar-light bg-color {mobileToolbarConfig &&
    mobileToolbarConfig.customClass
      ? mobileToolbarConfig.customClass
      : ''}"
  >
    <div class="mobile-only">
      {#if mobileToolbarConfig && mobileToolbarConfig.left}
        <button
          type="button"
          class="btn-link"
          on:click|preventDefault={navigateTo(
            mobileToolbarConfig.redirectPath,
          )}
        >
          {#if mobileToolbarConfig.left.arrow}
            <i class="far fa-angle-left" aria-hidden="true" />
          {/if}
          {mobileToolbarConfig.left.text || "Back"}
        </button>
      {/if}
    </div>
    <div class="mobile-only">
      {#if mobileToolbarConfig && mobileToolbarConfig.center}
        {#if mobileToolbarConfig.center.text}
          <h5 class="mb-0">{mobileToolbarConfig.center.text}</h5>
        {:else if mobileToolbarConfig.center.logo === false}
          <span />
        {/if}
      {/if}
    </div>

    <div class="right-section mobile-only" />
  </nav>

<style lang="scss" src="./TitleBar.scss">
</style>