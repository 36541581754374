<script>
  import { onMount } from "svelte"
  import { _ } from "@/i18n"
  import AuthButtonGroup from "@/components/AuthButtonGroup/AuthButtonGroup.svelte"

  export let config = {}
  let email

  let title,
    bannerColor,
    circleBorderColor,
    backgroundColor,
    iconSrc,
    coverColor,
    disableAuthButtonGroup
  $: {
    title = config.title || "Title"
    bannerColor = config.bannerColor || "#F0F2F5"
    circleBorderColor = config.circleBorderColor || "#7C317B"
    backgroundColor = config.backgroundColor || "#FFFFFF"
    coverColor = config.coverColor || "white"
    iconSrc = config.iconSrc || "../../assets/svg/auth-icons/messages.svg"
    disableAuthButtonGroup = config.disableAuthButtonGroup
  }

  onMount(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.has("email")) {
      email = params.get("email")
    }
  })
</script>

<svelte:head>
  <style>
    body {
      background-color: white;
    }
  </style>
</svelte:head>

<div>
  <div id="auth-banner" style="--bannerColor: {bannerColor}">
    {#if !disableAuthButtonGroup}
      <AuthButtonGroup
        breadcrumbBtn={{
          text: $_("AUTH.BACK"),
          href: `/auth/password-reset/?email=${email}`,
        }}
      />
    {/if}
    <div id="circle">
      <svg
        width="130"
        height="130"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50C100 77.6142 77.6142 100 50 100C22.3858 100 0 77.6142 0 50C0
        22.3858 22.3858 0 50 0C77.6142 0 100 22.3858 100 50Z"
          fill={circleBorderColor}
        />
        <circle cx="50" cy="50" r="32" fill={backgroundColor} />
      </svg>
    </div>
    <div id="cover">
      <svg
        width="100%"
        height="23"
        viewBox="0 0 797 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path
          d="M797 21.8307V23H0V22.6677C113.273 8.40995 252.402 0 402.564 0C549.763
        0 685.845 8.08138 797 21.8307Z"
          fill={coverColor}
        />
      </svg>
    </div>
    <div id="icon">
      <img src={iconSrc} alt="Banner icon" />
    </div>
  </div>

  <header class="heading">
    <h1 class="title">{title}</h1>
  </header>
</div>

<style lang="scss">
  @import "../../css/mixins.scss";

  #auth-banner {
    background-color: var(--bannerColor);
    height: 130px;
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 10px 25px 0;
  }
  .title {
    @include feature-title;
    color: #242627;
    font-family: "Mukta";
    text-align: center;
    margin-top: 10px;
    font-size: 24px;
  }
  #circle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 55px;
  }
  #cover {
    bottom: -1px;
    position: absolute;
    left: 0;
    width: 100%;
  }
  #icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: center;
    top: 80px;
    width: 60px;
  }
</style>
