<script>
  /* eslint-disable */
  // eslint-disable-next-line no-unused-vars
  /* global portalApp */
  import { onMount, onDestroy } from "svelte"
  import { redirect, params } from "@roxi/routify"
  import { _ } from "@/i18n"
  import {
    globalNavConfig,
    isPageFullWidth,
    patientContext,
    sectionalAlertConfig,
    clinicCategories,
    p1PatientUrl,
  } from "@/stores/stores"
  import HomeHeader from "@/components/HomeHeader/HomeHeader.svelte"
  import SimpleModal from "@/components/Modal/SimpleModal.svelte"
  import Pill from "@/components/Pill/Pill.svelte"
  import Card from "@/components/Card/Card.svelte"
  import SectionalAlert from "@/components/Alert/SectionalAlert/SectionalAlert.svelte"
  import { getCookie, setCookie } from "@/utils/cookie"
  import { removeUrlParamByName } from "@/utils/httpService"
  import {
    isPWAEnabled,
    isAndroidChrome,
    isiPhoneSafari,
  } from "@/utils/isPWAEnabled"
  import SwitchAccountsModal from "../messages/_components/SwitchAccountsModal/SwitchAccountsModal.svelte"

  let isModalOpen = false
  let isPWABannerEnabled = isPWAEnabled() && !getCookie("isPWABannerDismissed")
  let cardsData = []
  let bannersData = {}
  let dismissedBanners = []
  let isSwitchAccountsModalOpen = false

  let pillsData = [
    {
      title: $_("HOME.QUICK_ACTIONS.ASK_CARE"),
      url: "/messages/compose",
      imageSrc: "../../assets/svg/pills/ask.svg",
      imageAlt: $_("HOME.QUICK_ACTIONS.ASK_CARE"),
      callback: () => {
        if ($patientContext?.mergedInto) {
          isSwitchAccountsModalOpen = true
        } else {
          window.location.href = "/messages/compose"
        }
      },
    },
    {
      title: $_("HOME.QUICK_ACTIONS.CHECK_TEST_RESULTS"),
      url: "/health-records/test-results",
      imageSrc: "../../assets/svg/pills/labs.svg",
      imageAlt: $_("HOME.QUICK_ACTIONS.CHECK_TEST_RESULTS"),
    },
  ]

  $: cardsData = [
    {
      id: "education",
      title: $_("HOME.CARDS.EDUCATION.TITLE"),
      description: $_("HOME.CARDS.EDUCATION.DESCRIPTION", {
        values: { clinic: $patientContext?.clinicName },
      }),
      actionText: $_("HOME.CARDS.EDUCATION.ACTION"),
      url: "",
      image: {
        src: "../../assets/svg/home-cards/education.svg",
        alt: $_("HOME.CARDS.EDUCATION.ALT"),
      },
    },
    {
      id: "aboutUs",
      title: $_("HOME.CARDS.ABOUT_US.TITLE"),
      subTitle: $patientContext?.clinicName
        ? $_("HOME.CARDS.ABOUT_US.SUBTITLE") + ` ${$patientContext?.clinicName}`
        : "",
      description: $patientContext?.clinicName
        ? $_("REGISTRATION.WELCOME_NAV_CARE") +
          `, ${$patientContext?.clinicName}'s ` +
          $_("HOME.CARDS.ABOUT_US.DESCRIPTION")
        : $_("REGISTRATION.WELCOME_NAV_CARE") +
          "'s " +
          $_("HOME.CARDS.ABOUT_US.DESCRIPTION"),
      actionText: $_("HOME.CARDS.ABOUT_US.ACTION"),
      url: "/about-us",
    },
  ]

  const dismissBanner = (key) => {
    // Get banners in blacklist.
    if (getCookie("DISMISSED_BANNERS")) {
      dismissedBanners = JSON.parse(getCookie("DISMISSED_BANNERS"))
    }
    // Add banner to blacklist.
    dismissedBanners = [...dismissedBanners, key]
    setCookie("DISMISSED_BANNERS", JSON.stringify(dismissedBanners))
  }

  bannersData = {
    onboardingResource: {
      title: $_("HOME.ONBOARDING_BANNER.ONBOARDING_RESOURCE.TITLE"),
      actionText: $_("HOME.ONBOARDING_BANNER.ONBOARDING_RESOURCE.ACTION"),
      actionLink: "https://www.navigatingcancer.com/navigatingcare",
      actionTarget: "_blank",
      disableCloseButton: false,
      stacked: true,
      onDismiss: dismissBanner,
    },
    // Add more banners...
  }

  $: if (bannersData) {
    // Get banners in blacklist to delete in original bannersData.
    if (getCookie("DISMISSED_BANNERS")) {
      dismissedBanners = JSON.parse(getCookie("DISMISSED_BANNERS"))
      if (dismissedBanners && dismissedBanners.length > 0) {
        dismissedBanners.forEach((bannerKey) => {
          delete bannersData[bannerKey]
        })
      }
    }

    // Set banners data which is not in blacklist into the store.
    sectionalAlertConfig.set({
      ...$sectionalAlertConfig,
      ...bannersData,
    })
  }

  /**
   * Set the mobileToolbarConfig options for HR
   */
  onMount(async () => {
    isPageFullWidth.set(true)

    if (portalApp.env.PREVIEW_RELEASE === "true") {
      $redirect("/messages/inbox")
    }
  })

  /**
   * Reset the mobileToolbarConfig
   */
  onDestroy(() => {
    isPageFullWidth.set(false)
  })

  $: {
    const {
      customPath,
      base_portal_urls: { p1 },
      main_tabs: mainTabs,
    } = $globalNavConfig

    if (customPath && p1) {
      if (
        // check for duplicates
        !pillsData.find(({ id }) => id === "upcomingAppointments")
      ) {
        pillsData = [
          ...pillsData,
          {
            id: "upcomingAppointments",
            title: $_("HOME.QUICK_ACTIONS.UPCOMING_APPOINTMENTS"),
            url: `${p1}/members/${customPath}/events`,
            imageSrc: "../../assets/svg/pills/appoinments.svg",
            imageAlt: $_("HOME.QUICK_ACTIONS.UPCOMING_APPOINTMENTS"),
          },
        ]
      }
    }

    if (mainTabs?.length) {
      const educationUrl = mainTabs.find((t) => t.title === "Education")?.url
      cardsData = cardsData.map((cd) => {
        if (cd.id === "education") {
          return { ...cd, url: educationUrl }
        }
        return cd
      })
    }
  }

  $: hasPrescriptionCategory = $clinicCategories?.some(
    // TODO: use id property instead of name if possible
    ({ name }) => name === "Prescription",
  )
  $: if (hasPrescriptionCategory) {
    if (
      // check for duplicates
      !pillsData.find(({ id }) => id === "medicationRefill")
    ) {
      pillsData = [
        ...pillsData,
        {
          id: "medicationRefill",
          title: $_("HOME.QUICK_ACTIONS.MEDICATION_REFILL"),
          url: "/messages/compose",
          imageSrc: "../../assets/svg/pills/refill.svg",
          imageAlt: $_("HOME.QUICK_ACTIONS.MEDICATION_REFILL"),
          callback: () => {
            if ($patientContext?.mergedInto) {
              isSwitchAccountsModalOpen = true
            } else {
              window.location.href = "/messages/compose"
            }
          },
        },
      ]
    }
  }

  // eslint-disable-next-line no-unused-expressions
  $: if ($params?.fc?.toLowerCase() === "true" && $p1PatientUrl) {
    if (!$sectionalAlertConfig.formsSuccess) {
      sectionalAlertConfig.set({
        ...$sectionalAlertConfig,
        formsSuccess: {
          title: $_("BANNERS.FORM_SUCCESS_TITLE"),
          disableCloseButton: false,
          stacked: true,
          contentType: "success",
          iconName: "check",
          isToast: true,
        },
      })
    }
    removeUrlParamByName("fc")
  }

  $: if (isPWABannerEnabled) {
    sectionalAlertConfig.set({
      ...$sectionalAlertConfig,
      installationPrompt: {
        stacked: true,
        onDismiss: () => {
          isPWABannerEnabled = false
          setCookie("isPWABannerDismissed", "true")
        },
      },
    })
  }

  const closeInstallationModal = () => {
    isModalOpen = false
  }

  const openInstallationModal = () => {
    isModalOpen = true
  }
</script>

<section class="homepage">
  <HomeHeader />
  <div class="onboarding-banners-container">
    {#if isPWABannerEnabled}
      <SectionalAlert key="installationPrompt">
        <div slot="message" class="installation-prompt">
          <img
            src="../../assets/svg/nc-shortcut.svg"
            class="icon"
            alt="nc-shortcut"
          />
          <div class="container">
            <p class="description-text">
              Add Navigating Care to your home screen.
            </p>
            <button class="btn btn-tertiary" on:click={openInstallationModal}
              >Add</button
            >
          </div>
        </div>
      </SectionalAlert>
    {/if}
    {#if bannersData}
      <SectionalAlert key="formsSuccess">
        <div slot="message">
          <p class="description-text form-success-body">
            {@html $_("BANNERS.FORM_SUCCESS_BODY", {
              values: { formsUrl: `${$p1PatientUrl}/prepare_forms` },
            })}
          </p>
        </div>
      </SectionalAlert>
      <SectionalAlert key="onboardingResource">
        <div slot="message">
          <p class="description-text">
            {$_("HOME.ONBOARDING_BANNER.ONBOARDING_RESOURCE.DESCRIPTION")}
          </p>
        </div>
      </SectionalAlert>
    {/if}
  </div>
  <div class="layout">
    <div class="quick-actions">
      <h3 class="title">{$_("HOME.QUICK_ACTIONS.TITLE")}</h3>
      {#each pillsData as pillData}
        <Pill config={pillData} />
      {/each}
    </div>
    <hr />
    <div class="cards-container">
      {#each cardsData as cardData}
        <Card config={cardData} />
      {/each}
    </div>
  </div>
  {#if isModalOpen}
    <SimpleModal on:close={closeInstallationModal}>
      <div slot="content" class="installation-modal">
        <img
          src="../../assets/svg/nc-shortcut.svg"
          class="icon"
          alt="nc-shortcut"
        />
        <h1>Add Navigating Care to your home screen</h1>
        {#if isiPhoneSafari()}
          <ul>
            <li>
              Tap <img
                src="../../assets/svg/ios-share.svg"
                class="share-icon"
                alt="ios-share"
              /> at the bottom of the screen
            </li>
            <li>Tap <strong>Add to Home Screen</strong></li>
            <li>
              Confirm and tap <strong>Add</strong> in the top-right corner
            </li>
          </ul>
        {/if}
        {#if isAndroidChrome()}
          <ul>
            <li>
              Tap <i class="fa fa-ellipsis-v" /> in the top-right corner
            </li>
            <li>Tap <strong>Add to Home Screen</strong></li>
            <li>Choose a name and tap <strong>Add</strong></li>
          </ul>
        {/if}
      </div>
    </SimpleModal>
  {/if}
  <SwitchAccountsModal bind:isModalOpen={isSwitchAccountsModalOpen} />
</section>

<style lang="scss" src="./index.scss"></style>
