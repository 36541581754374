import { get } from "lodash"
import applyResolvers from "@/utils/applyResolvers"
import createProvenanceResolver from "./provenanceResolvers"
/* eslint-disable quotes */
/* eslint-disable no-useless-escape */

const smokingStatusResolvers = [
  {
    field: "Smoking status",
    path: (data) => {
      const smokingStatus = get(
        data,
        "entry[0].resource.valueCodeableConcept.coding[0].display",
      )

      const provenanceData = applyResolvers(
        data,
        createProvenanceResolver("entry[0].resource.", "Added to EHR"),
      )[0]?.value

      // necessary to wrap the returned array in another array to avoid applyResolvers breaking up the data into to multiple resolvers
      return smokingStatus ? [[smokingStatus, provenanceData]] : undefined
    },
  },
]
export default smokingStatusResolvers
