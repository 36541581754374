export const isiPhoneSafari = () => {
  const { userAgent } = window.navigator

  const isiPhone = /iPhone/i.test(userAgent)
  const isSafari =
    /Safari/i.test(userAgent) &&
    !/Chrome/i.test(userAgent) &&
    !/CriOS/i.test(userAgent) &&
    !/Firefox/i.test(userAgent) &&
    !/FxiOS/i.test(userAgent)
  return isiPhone && isSafari
}

export const isAndroidChrome = () => {
  const { userAgent } = window.navigator

  const isAndroid = /Android/i.test(userAgent)
  const isChrome =
    /Chrome/i.test(userAgent) && /Google Inc/i.test(navigator.vendor)

  return isAndroid && isChrome
}

export const isPWAEnabled = () => {
  return isiPhoneSafari() || isAndroidChrome()
}
