export const enableInteractive = (node, callback) => {
  node.setAttribute("tabIndex", "0")

  const handleKeydown = (e) => {
    if (e.key === "Enter" || e.key === "Space") {
      e.preventDefault()
      callback()
    }
  }

  node.addEventListener("keydown", handleKeydown)
  node.addEventListener("click", callback)

  return {
    destroy() {
      node.removeEventListener("keydown", handleKeydown)
      node.removeEventListener("click", callback)
    },
  }
}
