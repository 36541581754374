<script>
  export let indicatorTitle = ""
</script>

<div class="sk-container">
  <div class="sk-circle-fade">
    <div class="sk-circle-fade-dot"></div>
    <div class="sk-circle-fade-dot"></div>
    <div class="sk-circle-fade-dot"></div>
    <div class="sk-circle-fade-dot"></div>
    <div class="sk-circle-fade-dot"></div>
    <div class="sk-circle-fade-dot"></div>
    <div class="sk-circle-fade-dot"></div>
    <div class="sk-circle-fade-dot"></div>
  </div>
  <span class="sk-title">{indicatorTitle}</span>
</div>
<style lang="scss" src="./LoadingIndicator.scss"></style>