<script>
  import { onMount, onDestroy } from "svelte"
  import { route } from "@roxi/routify"
  import { _ } from "@/i18n"
  import {
    mobileToolbarConfig,
    notifications,
    viewedNotifications,
  } from "@/stores/stores"
  import {
    labInfo,
    labsPagination,
    loadMoreLabs,
  } from "@/stores/healthRecordStores"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"
  import HealthRecordsHeader from "@/components/HealthRecordsHeader/HealthRecordsHeader.svelte"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"
  // or operator for jest unit testing
  const currPageURL = $route?.shortPath || "/"

  const breadcrumbLinks = [
    {
      title: $_("HR.HEALTH_RECORDS"),
      link: "/health-records",
      id: "health-records",
    },
    {
      title: $_("HR.TEST_RESULTS.TITLE"),
      active: true,
    },
  ]

  let isLoading = true
  let currentPage = 0
  let accumulatedResults = []
  let labRecordsPanels
  let testResultNotifications

  const handlePageChange = () => {
    currentPage += 1
    loadMoreLabs(accumulatedResults, currentPage)
  }

  /**
   * Set the mobileToolbarConfig options for HR
   */
  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: true, text: $_("HR.HEALTH_RECORDS") },
      center: { logo: false },
      customClass: "blue-bkg-sm-down",
      redirectPath: "/health-records",
    })
  })

  /**
   * Reset the mobileToolbarConfig
   */
  onDestroy(() => {
    mobileToolbarConfig.set({})
  })

  /**
   * Formats array of lab resolver results into an object without components
   * @param labsArr {Array} -  The array of labs to format.
   * @returns {Array} - The array of formatted labs.
   */

  const formatLabsAsPanels = (labsArr) => {
    return labsArr.map(({ value }) => {
      let hasNotifications = false
      value.forEach(({ field, value: labValue }) => {
        if (field === "components" && value.length > 0) {
          const componentId = labValue.find(
            ({ field: componentField }) => componentField === "id",
          )
          const notificationId = testResultNotifications.find(
            ({ referenceId }) => referenceId === componentId.value,
          )?.id

          if (
            notificationId &&
            !$viewedNotifications.includes(notificationId)
          ) {
            hasNotifications = true
          }
        }
      })

      const removeLabCompontsFromPanel = value.filter(
        ({ field }) => field !== "components",
      )

      const filteredPanel = removeLabCompontsFromPanel.map((labField) => ({
        [labField.field]: labField.value,
      }))

      return Object.assign({}, ...filteredPanel, { hasNotifications })
    })
  }

  $: {
    if ($notifications?.data) {
      testResultNotifications =
        notifications.get("HEALTH_RECORD", "TEST_RESULT")?.notifications || []
    }
  }

  $: if ($labInfo && testResultNotifications) {
    accumulatedResults = $labInfo.results
    labRecordsPanels = formatLabsAsPanels($labInfo.results)
    currentPage = $labsPagination.page
    isLoading = $labInfo.isLoading
  }
</script>

<section class="health-records-wrapper">
  <Breadcrumb {breadcrumbLinks} />
  <HealthRecordsHeader title={$_("HR.TEST_RESULTS.TITLE")}>
    <p class="test-result-description">
      {$_("HR.TEST_RESULTS.DESCRIPTION")}
    </p>
  </HealthRecordsHeader>
  {#if isLoading}
    <div class="spinner-wrapper">
      <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
    </div>
  {:else if labRecordsPanels.length > 0}
    <section class="records">
      {#each labRecordsPanels as { name, date, id, hasNotifications }}
        <a class="record-anchor-wrap" href="{currPageURL}/{id}">
          <div class="record">
            <div>
              <p class="record-label">{name}</p>
              {#if date}
                <p class="record-date">{date}</p>
              {/if}
            </div>
            <div class="notification-container">
              {#if hasNotifications}
                <div class="notification">New</div>
              {/if}
              <i class="pl-3 fas fa-chevron-right" aria-hidden="true" />
            </div>
          </div>
        </a>
      {/each}
    </section>
    {#if $labInfo.totalResults < $labInfo.totalCount}
      <div class="load-more-container">
        {#if isLoading}
          <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
        {:else}
          <button class="btn btn-secondary" on:click={handlePageChange}
            >{$_("HR.LOAD_MORE")}</button
          >
        {/if}
      </div>
    {/if}
  {:else}
    <div class="no-record">
      {$_("HR.TEST_RESULTS.NO_RECORD_FOUND")}
    </div>
  {/if}
</section>

<style src="./index.scss">
</style>
