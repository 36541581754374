<script>
  import { createEventDispatcher, onDestroy, onMount } from "svelte"
  import { _ } from "@/i18n"
  import alertStyleMapping from "@/utils/alertStyleMapping"
  import Icon from "@/components/Icon/Icon.svelte"

  /**
   * @typedef AlertConfigObject
   * @type {object}
   * @property {String} title - alert title
   * @property {(String|Undefined)} message - alert message
   * @property {String} contentType - alert content type if empty is inform by default
   * @property {String} actionLink - alert link
   * @property {String} actionText - alert text
   * @property {String} actionTarget - open new tab or same tab
   * @property {Boolean} disableCloseButton - disables the close button
   * @property {String} iconName - icon name if empty is defined by the contentType
   * @property {String} iconColor - icon color if empty is defined by the contentType
   * @property {Boolean} stacked - stacks content on a column
   * @property {Function} onDismiss - customized dismiss handler
   */

  /**
   * @type {AlertConfigObject}
   */
  export let config = {}
  /**
   * @type {Boolean} removes the side margins and borders from the alert
   */
  export let global = false

  let element
  let messageWidth
  let contentWidth
  let actionWidth
  let stackContent

  const defaultConfig = {
    message: "",
    contentType: "inform",
  }

  const {
    title,
    message = "Message ....",
    contentType = "inform",
    actionLink,
    actionText,
    actionTarget = "_self",
    disableCloseButton,
    iconName,
    stacked,
    isToast,
    closeCallback = () => {},
  } = {
    ...defaultConfig,
    ...(config || {}),
  }

  const dispatch = createEventDispatcher()

  const dismissAlert = () => {
    closeCallback()
    dispatch("dismissAlert", {})
  }

  onMount(() => {
    // scrolls to show alert in center of screen on show
    setTimeout(() => {
      element.scrollIntoView({
        block: "center",
        inline: "nearest",
        behavior: "smooth",
      })
    }, 1)
  })

  onDestroy(() => {
    if (isToast) {
      dismissAlert()
    }
  })
  $: stackContent = stacked || actionWidth + messageWidth + 200 >= contentWidth

  $: ({ iconColor, backgroundColor } = alertStyleMapping[contentType])
</script>

<section
  bind:this={element}
  class:global
  class="alert"
  style="background-color: {backgroundColor}"
  bind:clientWidth={contentWidth}
>
  {#if iconName}
    <div class="icon-container">
      <Icon {iconName} {iconColor} />
    </div>
  {/if}
  <div class:stacked={stackContent} class="content">
    {#if title}
      <h3 class="alert-title">{title}</h3>
    {/if}
    <div class:stacked={stackContent} class="inner-content">
      <div bind:clientWidth={messageWidth} class="alert-message">
        {#if $$slots.message}
          <slot name="message" />
        {:else}
          <p>{message}</p>
        {/if}
      </div>
      {#if actionLink}
        <div
          bind:clientWidth={actionWidth}
          class="action"
          class:pushed={!disableCloseButton}
        >
          <a href={actionLink} class="link" target={actionTarget}>
            {actionText}
          </a>
        </div>
      {/if}
    </div>
  </div>
  {#if !disableCloseButton}
    <div class="close-btn">
      <button on:click={dismissAlert} class="btn btn-close">
        <Icon iconName="close" />
        <span class="sr-only">{$_("GLOBAL_NAV.CLOSE")}</span>
      </button>
    </div>
  {/if}
</section>

<style lang="scss" src="./Alert.scss"></style>
