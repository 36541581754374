/**
 * Date compare function for use in JS sort function
 * @param {String} dateA - date string
 * @param {String} dateB - comparison date string
 * @returns a number -1 >= 0 <= 1
 */
export const sortByDate = (dateA, dateB) => {
  // if either property is missing a date field, sort down
  if (!dateA) return 1
  if (!dateB) return -1

  return new Date(dateB).getTime() - new Date(dateA).getTime()
}
