<script>
  import { slide } from "svelte/transition"

  export let isBodyExist = true

  let isExpanded = false

  const toggleExpansion = () => {
    isExpanded = !isExpanded
  }
</script>

<article class="expandable-card">
  <slot name="header" />

  {#if isBodyExist}
    <div class:border-top={isBodyExist}>
      <button
        class="toggle-expand {isExpanded ? 'toggle-expand__expanded' : ''}"
        role="button"
        tabindex="0"
        on:click={toggleExpansion}
      >
        <i
          class="fas fa-chevron-down expanded-indicator {isExpanded
            ? 'expanded-indicator__expanded'
            : ''}"
        />
        <span>{isExpanded ? "Hide" : "Show"} details</span>
      </button>
      {#if isExpanded}
        <div class="expandable-card__body" transition:slide|local>
          <slot name="body" />
        </div>
      {/if}
    </div>
  {/if}
</article>

<style lang="scss">
  .border-top {
    border-top: 1px solid #c3c7cc;
  }
</style>
