<script>
  import { fade } from "svelte/transition"
  import { onMount, onDestroy } from "svelte"
  import dayjs from "dayjs"
  import { isEqual } from "lodash"
  import { goto } from "@roxi/routify"
  import { _ } from "@/i18n"
  import {
    mobileToolbarConfig,
    error,
    isUserClinicianOrAdmin,
  } from "@/stores/stores"
  import { patientId } from "@/stores/healthRecordStores"
  import { callAPI } from "@/utils/httpService"
  import { returnFormattedDate } from "@/utils/dateTime"
  import Modal from "@/components/Modal/Modal.svelte"
  import DateRangePicker from "@/components/DatePickers/DateRangePicker/DateRangePicker.svelte"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"
  import HealthRecordsHeader from "@/components/HealthRecordsHeader/HealthRecordsHeader.svelte"

  let healthRecordsOptions = "ALL_HR"
  let showBackdrop = false

  const today = dayjs()
  const dateRangeBtns = [
    {
      label: $_("VDS.BUTTON_DATE_LAST_TEN_DAYS"),
      range: [
        today.subtract(10, "day").format("MM/DD/YYYY"),
        today.format("MM/DD/YYYY"),
      ],
    },
    {
      label: $_("VDS.BUTTON_DATE_LAST_THIRTY_DAYS"),
      range: [
        today.subtract(30, "day").format("MM/DD/YYYY"),
        today.format("MM/DD/YYYY"),
      ],
    },
    {
      label: $_("VDS.BUTTON_DATE_LAST_NINETY_DAYS"),
      range: [
        today.subtract(90, "day").format("MM/DD/YYYY"),
        today.format("MM/DD/YYYY"),
      ],
    },
    {
      label: $_("VDS.BUTTON_DATE_LAST_YEAR"),
      range: [
        today.startOf("year").format("MM/DD/YYYY"),
        today.format("MM/DD/YYYY"),
      ],
    },
  ]

  let selectedDateRange = dateRangeBtns[1].range

  /**
   * This function will replace the url state based on HR timeframe option selection
   * @param {String} selectedTimeframe selected HR timeframe option
   * @param {Array} selectedDate selected date reange
   */
  const replaceUrlState = (selectedTimeframe, selectedDate) => {
    if (selectedTimeframe === "ALL_HR") {
      $goto(window.location.origin + window.location.pathname)
    } else if (selectedTimeframe === "DATE_RANGE_HR") {
      const url = new URL(window.location.href)
      url.searchParams.set("from", selectedDate[0]?.replace(/\//g, "-"))
      url.searchParams.set("to", selectedDate[1]?.replace(/\//g, "-"))

      $goto(url.toString())
    }
  }

  /**
   * This function will update the timeframe selection option based on updated date range
   * @param {Array} newDateRange newly selected date range
   */
  const updateDateRange = (newDateRange) => {
    selectedDateRange = newDateRange
    replaceUrlState("DATE_RANGE_HR", selectedDateRange)
  }

  /**
   * This function will redirect user to the share health records page. If user has selected date range option, then it will append the query params
   */
  const shareHealthRecords = () => {
    let queryParams = ""
    if (healthRecordsOptions === "DATE_RANGE_HR") {
      queryParams = `?from=${selectedDateRange[0].replace(
        /\//g,
        "-",
      )}&to=${selectedDateRange[1].replace(/\//g, "-")}`
    }

    $goto(`/health-records/share${queryParams}`)
  }

  const breadcrumbLinks = [
    {
      title: $_("HR.HEALTH_RECORDS"),
      link: "/health-records",
      id: "health-records",
    },
    {
      title: $_("VDS.VIEW_DOWNLOAD_OR_SHARE"),
      active: true,
    },
  ]

  /**
   * This function will pre-filled the the options based on last selection
   */
  const selectTimeframe = () => {
    const params = new URLSearchParams(window.location.search)
    if (params.has("from") && params.has("to")) {
      document.getElementById("dateRange").checked = true
      healthRecordsOptions = "DATE_RANGE_HR"

      const fromDate = params.get("from").replace(/-/g, "/")
      const toDate = params.get("to").replace(/-/g, "/")
      updateDateRange([fromDate, toDate])
    }
  }

  let isOpen = false
  let controller
  let modalProps

  const getModalProps = (type, fileOptions = null) => {
    const { fileType, fileUrl, fileReadyLabel, callbacks } = fileOptions
    switch (type) {
      case "CHOOSE_FORMAT":
        return {
          modalBody: $_("VDS.CHOOSE_FORMAT"),
          closeModalOnBackDropClick: true,
          btnDefs: [
            {
              label: $_("VDS.PRINTABLE_PDF"),
              buttonClass: "secondary",
              disableButton: $isUserClinicianOrAdmin,
              callback: callbacks[0],
            },
            {
              label: $_("VDS.DATA_FILE"),
              buttonClass: "secondary",
              disableButton: $isUserClinicianOrAdmin,
              callback: callbacks[1],
            },
          ],
        }

      case "GENERATING_FILE":
        return {
          modalBody: `${$_(
            `VDS.GENERATING_${fileType}`,
          )}<br /><br /><img src=../assets/spinner.gif alt="spinner" /><br />`,
          btnDefs: [
            {
              label: "Cancel",
              escapeFunction: true,
              buttonClass: "secondary",
              callback: () => {
                if (controller) controller.abort()
              },
            },
          ],
        }
      case "FILE_READY":
        return {
          modalTitle: $_("VDS.YOUR_FILE_IS_READY"),
          modalBody: $_("VDS.LINK_WILL_EXPIRE"),
          closeIcon: true,
          closeModalOnBackDropClick: false,
          btnDefs: [
            {
              label: $_(fileReadyLabel),
              buttonClass: "primary",
              keepModalOpen: true,
              callback: () => window.open(fileUrl),
            },
          ],
        }

      default:
        return null
    }
  }

  /**
   * This function will manage the http error response
   * @param {Object} response http response object
   */
  const handleHttpError = (response) => {
    if (response.aborted === true) {
      // Close modal if request aborted by user
      isOpen = false
    } else if (
      response.jobStatus === "failed" ||
      response.jobStatus === "unauthorized"
    ) {
      // TODO: Need to update error handling in the future tickets. Currently, we will only display connetion issues
      error.set({
        errorCode: "connection_issues",
      })
    } else {
      isOpen = false
      error.set({
        errorCode: "connection_issues",
      })
    }
  }

  /**
   * This function will open the modal and display the button to view or download document
   * @param {String} fileUrl Download File Url
   */
  const openViewOrDownloadModal = (fileReadyLabel, fileUrl) => {
    const options = {
      fileReadyLabel,
      fileUrl,
    }

    modalProps = getModalProps("FILE_READY", options)
  }

  /**
   * Fetch downloaded file status
   */
  const setModalDownloadStatus = async ({ action, jobId }) => {
    const getResponse = await callAPI({
      url: `health-records/document-requests/${jobId}`,
      method: "GET",
    })

    if (getResponse.jobStatus === "submitted") {
      setTimeout(() => {
        setModalDownloadStatus({ action, jobId })
      }, 1000)
    } else if (getResponse.jobStatus === "completed") {
      openViewOrDownloadModal(action, getResponse.pdfUrl || getResponse.zipUrl)
    } else {
      handleHttpError(getResponse)
    }
  }

  /**
   * Post the request to view or download the health records
   */
  const viewOrDownloadHealthRecords = async (action = null) => {
    isOpen = true
    const options = {}
    if (action === "VIEW_PDF" || action === "DOWNLOAD_PDF") {
      options.fileType = "PDF"
    } else if (action === "DOWNLOAD_CCD") {
      options.fileType = "CCD"
    }

    modalProps = getModalProps("GENERATING_FILE", options)

    let dataFilters = {
      patientId: $patientId,
    }

    if (healthRecordsOptions === "DATE_RANGE_HR") {
      dataFilters = Object.assign(dataFilters, {
        startDate: returnFormattedDate(selectedDateRange[0], {
          dateFormat: "YYYY-MM-DD",
        }),
        endDate: returnFormattedDate(selectedDateRange[1], {
          dateFormat: "YYYY-MM-DD",
        }),
      })
    }

    const fileFormat = action === "DOWNLOAD_CCD" ? "xml" : "pdf"
    const payload = {
      usage: "download",
      dataFilters,
      documents: {
        linkValiditySeconds: 3 * 60, // 3 minutes for view & download
        formats: [fileFormat],
      },
    }

    // Abort controller will be used to cancel the inprogress download request.
    controller = new AbortController()
    const { signal } = controller

    const postResponse = await callAPI({
      url: "health-records/document-requests",
      method: "POST",
      payload,
      signal,
    })

    if (postResponse.jobStatus === "submitted") {
      setModalDownloadStatus({
        action: `VDS.${action}`,
        jobId: postResponse.jobId,
      })
    } else {
      handleHttpError(postResponse)
    }
  }

  const downloadHealthRecords = async () => {
    isOpen = true
    const options = {
      fileType: "CCD",
      callbacks: [
        () => viewOrDownloadHealthRecords("DOWNLOAD_PDF"),
        () => viewOrDownloadHealthRecords("DOWNLOAD_CCD"),
      ],
    }
    modalProps = getModalProps("CHOOSE_FORMAT", options)
  }

  /**
   * Set the mobileToolbarConfig options for HR
   */
  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: true, text: $_("HR.HEALTH_RECORDS") },
      center: { logo: false },
      customClass: "blue-bkg-sm-down",
      redirectPath: "/health-records",
    })
    selectTimeframe()
  })

  /**
   * Reset the mobileToolbarConfig
   */
  onDestroy(() => {
    mobileToolbarConfig.set({})
  })
</script>

<section class="health-records-wrapper">
  <Breadcrumb {breadcrumbLinks} />
  <HealthRecordsHeader title={$_("HR.VDS")} textAlignClass="text-left" />
  <section class="health-records-container">
    <p class="description">
      {$_("VDS.DESCRIPTION")}
    </p>
    <div class="options">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="healthRecordsOptions"
          id="all"
          on:change={() => replaceUrlState("ALL_HR", selectedDateRange)}
          bind:group={healthRecordsOptions}
          value="ALL_HR"
        />
        <label class="form-check-label" for="all">
          {$_("VDS.ALL_HR_OPTION")}
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="healthRecordsOptions"
          id="dateRange"
          on:change={() => replaceUrlState("DATE_RANGE_HR", selectedDateRange)}
          bind:group={healthRecordsOptions}
          value="DATE_RANGE_HR"
        />
        <label class="form-check-label" for="dateRange">
          {$_("VDS.DATE_RANGE_HR_OPTION")}
        </label>
      </div>
    </div>

    {#if healthRecordsOptions === "DATE_RANGE_HR"}
      <div>
        <div class="records-btn-group">
          {#each dateRangeBtns as { label, range }}
            <button
              role="button"
              class="btn btn-outline-primary"
              class:active={isEqual(selectedDateRange, range)}
              on:click={() => updateDateRange(range)}>{label}</button
            >
          {/each}
        </div>
        <DateRangePicker
          startInputLabel="From"
          endInputLabel="To"
          on:click={() => {
            showBackdrop = true
          }}
          on:updateDateRange={({ detail }) => {
            updateDateRange(detail)
          }}
          on:blur={() => {
            showBackdrop = false
          }}
          {selectedDateRange}
        />
        {#if showBackdrop}
          <div out:fade={{ duration: 800 }} class="backdrop" />
        {/if}
      </div>
    {/if}

    <section class="records-btn-group">
      <button
        class="btn btn-secondary"
        disabled={$isUserClinicianOrAdmin}
        on:click={() => viewOrDownloadHealthRecords("VIEW_PDF")}
        >{$_("VDS.BUTTON_GROUP_PDF")} <i class="fas fa-eye" /></button
      >
      <button class="btn btn-secondary" on:click={downloadHealthRecords}
        >{$_("VDS.BUTTON_GROUP_DOWNLOAD")} <i class="fas fa-download" /></button
      >
      <button class="btn btn-secondary" on:click={shareHealthRecords}
        >{$_("VDS.BUTTON_GROUP_SHARE")} <i class="fas fa-share" /></button
      >
    </section>
  </section>
  <Modal bind:isOpen {...modalProps} />
</section>

<style lang="scss" src="./index.scss"></style>
