import { get } from "lodash"
import applyResolvers from "@/utils/applyResolvers"
import createProvenanceResolver from "./provenanceResolvers"

const deviceInfoResolvers = [
  {
    field: "Implantable device ID",
    path: (data) => {
      const device = get(
        data,
        "entry[0].resource.udiCarrier[0].deviceIdentifier",
      )

      const provenanceData = applyResolvers(
        data,
        createProvenanceResolver("entry[0].resource.", "Added to EHR"),
      )[0]?.value

      return device ? [[device, provenanceData]] : undefined
    },
  },
]

export default deviceInfoResolvers
