/**
 * Convert the file size to MB.
 * @param {Number} filesize size of file in bytes
 * @returns {String} Converted file size to MBs
 */
export function convertFilesize(filesize) {
    let suffix = " KB"

    // Convert bytes to KB
    let convertedFilesize = filesize / 1024

    // If KB > 500 then convert it to MB
    if (convertedFilesize > 500) {
        convertedFilesize /= 1024
        suffix = " MB"
    }

    return convertedFilesize.toFixed(1) + suffix
}
