import { partialRight } from "lodash"
import applyResolvers from "@/utils/applyResolvers"
import { dateTransformer } from "../transformers"

const labAttachmentMetadataResolvers = [
  {
    field: "attachmentMetadata",
    path: (data) =>
      data?.entry?.map((obj) =>
        // apply resolvers on sub-objects to allow for complex resolver methods(transform, find, join, ...)
        applyResolvers(obj, [
          {
            field: "title",
            path: "resource.content[0].attachment.title",
          },
          {
            field: "date",
            path: "resource.date",
            transform: partialRight(dateTransformer, {
              dateFormat: "M/D/YY",
            }),
          },
          {
            field: "documentId",
            path: "resource.id",
          },
        ]),
      ),
  },
]

export default labAttachmentMetadataResolvers
