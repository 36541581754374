<script>
  import { onMount, onDestroy } from "svelte"
  import { _ } from "@/i18n"
  import { returnFormattedDate } from "@/utils/dateTime"
  import { vitalSigns } from "@/stores/healthRecordStores"
  import Table from "@/components/Table/Table.svelte"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"
  import {
    isPageFullWidth,
    mobileToolbarConfig,
    notifications,
    viewedNotifications,
  } from "@/stores/stores"
  import HealthRecordsHeader from "@/components/HealthRecordsHeader/HealthRecordsHeader.svelte"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"
  import { dateOrganizedTableInfo, formatCells } from "./parseHelpers"

  let isLoading = true
  let results = []
  let notificationResults = []
  let notificationDates = []

  const breadcrumbLinks = [
    {
      title: $_("HR.HEALTH_RECORDS"),
      link: "/health-records",
      id: "health-records",
    },
    {
      title: $_("HR.VITALS"),
      active: true,
    },
  ]

  /**
   * Parses the data to the format required by the table component
   * @param {Array<Object>} data array of resolver data response
   * @returns {Array} return formated data
   */
  const parseDataToTableFormat = (data) => {
    if (data.length === 0) return data
    const { table, uniqueTableHeaders } = dateOrganizedTableInfo(data)
    const cells = formatCells(table, uniqueTableHeaders)
    return [[...uniqueTableHeaders], ...cells]
  }

  /**
   * Set the mobileToolbarConfig options for HR
   */
  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: true, text: $_("HR.HEALTH_RECORDS") },
      center: { logo: false },
      customClass: "blue-bkg-sm-down",
    })
  })

  /**
   * Reset the mobileToolbarConfig
   */
  onDestroy(() => {
    isPageFullWidth.set(false)
    mobileToolbarConfig.set({})
  })
  $: if ($vitalSigns) {
    isLoading = false
    results = $vitalSigns
  }
  $: if ($notifications?.data) {
    notificationResults =
      notifications.get("HEALTH_RECORD", "VITAL_SIGN")?.notifications || []
  }

  $: if (notificationResults.length && results.length) {
    const notificationQueue = new Set() // set to avoid duplicates

    /** Handles queueing a notification to be marked as read and creating an array of dates that have notifications.
     * @param {Date} date - The date to be added to the notification dates array in the format M/D/YY
     * @param {String} recordId - The id of the health record.
     * @returns {Undefined}
     */
    const handleNotifications = (date, recordId) => {
      const notificationId = notificationResults.find(
        ({ referenceId }) => referenceId === recordId,
      )?.id
      if (notificationId) {
        notificationDates = [
          ...notificationDates,
          returnFormattedDate(date, {
            dateFormat: "M/D/YY",
          }),
        ]
        notificationQueue.add(notificationId)
      }
    }

    results.forEach((result) => {
      const cell = result?.value?.value || []
      // cell can be an array of arrays or array of strings
      if (Array.isArray(cell)) {
        cell.forEach(([, date, id]) => handleNotifications(date, id))
      } else {
        const [, date, id] = cell
        handleNotifications(date, id)
      }
    })

    if (notificationQueue.size) {
      viewedNotifications.add([...notificationQueue])
    }
  }
</script>

<section class="health-records-wrapper">
  <Breadcrumb {breadcrumbLinks} />
  <HealthRecordsHeader title={$_("HR.VITALS")} />
  {#if isLoading}
    <div class="spinner-wrapper">
      <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
    </div>
  {:else if results.length > 0}
    <div class="record-table">
      <Table data={parseDataToTableFormat(results)} {notificationDates} />
    </div>
  {:else}
    <div class="no-record">
      {$_("HR.NO_RECORD_FOUND_FOR_VITALS")}
    </div>
  {/if}
</section>

<style src="./index.scss">
</style>
