/* eslint-disable no-use-before-define */
import _ from "lodash"
import { resolverErrors } from "@/stores/healthRecordStores"

// extend lodash with notArray
_.mixin({ notArray: (arg) => !_.isArray(arg) })

/**
 * Helper function of `processPathAsSteps` method to create the "get" or "find" lodash steps from the given path string
 * @param {String} path is the string that has "get" & "find" parts describing where the valueis
 * @returns {Array} of { method, path } to iterate over in `processPathAsSteps` method
 */
export const createSteps = (path) => {
  const OPEN = ".|"
  const CLOSE = "|."
  if (path.includes(`.${OPEN}`) || path.includes(`${CLOSE}.`))
    throw new Error(
      `Search tags ${OPEN} and ${CLOSE} should not be preceded or followed by periods: ${path}`,
    )

  const steps = []
  let remainingStr = path

  // loop to capture all search parts
  while (remainingStr.includes(OPEN)) {
    // get search part indexes and validate
    const start = remainingStr.indexOf(OPEN) + OPEN.length
    const end = remainingStr.indexOf(CLOSE, start)
    if (end === -1)
      throw new Error(`No closing ${CLOSE} tag for ${OPEN}: ${remainingStr}`)

    // if non-search content before start, add that as a "get" entry
    if (start > 2)
      steps.push({
        method: "get",
        path: remainingStr.substring(0, start - OPEN.length),
      })

    // now add search part as search entry (find requires path to be an object)
    steps.push({
      method: "find",
      path: JSON.parse(remainingStr.substring(start, end)),
    })

    // update with what's left after search and loop again
    remainingStr = remainingStr.substring(end + 2)
  }

  // if there's remaining content after search processing, add as get
  if (remainingStr.length) steps.push({ method: "get", path: remainingStr })
  return steps
}

/**
 * Filters an array of complex objects by stringifying each object and filtering
 * if it contains all of the search strings. Typically, each search string is
 * a stringified prop/value pair -- e.g. JSON.stringify([`"code": "39156-5"`]) results in
 * [\"\\\"code\\\":\\\"39156-5\\\"\"] and that's what's given in the searchStrings array
 * @param {Array} array with complex objects to search
 * @param {Array} searchStrings strings to search objects for
 * @returns {Object || undefined} the object that has all strings in it or undefined
 */
export const deepFilter = (array, searchStrings) =>
  _.filter(array, (obj) => {
    const strData = JSON.stringify(obj)
    return _.every(searchStrings, (str) => strData.includes(str))
  })

/**
 * Process the resolver as a path of steps derived from the path string
 * @param {Object} data is the raw JSON data to be transformed/resolved
 * @param {String} path is a string that has "get" & "find" parts describing where the valueis
 * @returns {Array} of field, value and optional groupWith props
 */
export const processPathAsSteps = (data, path) => {
  const values = []
  const steps = createSteps(path)

  // use a clone of data as we will mutate it in case there's multiple "find" results
  const workData = _.cloneDeep(data)

  // "find" can have more multiple results so loop while moreResults flag is true
  let moreResults = true
  while (moreResults) {
    moreResults = false
    // eslint-disable-next-line no-loop-func
    const value = steps.reduce((accumulator, step) => {
      let result
      if (step.method === "get") {
        result = _.get(accumulator, step.path)
      } else if (step.method === "find") {
        // path as array signifies deepFilter of strings, otherwise just use lodash find
        const allResults = _.isArray(step.path)
          ? deepFilter(accumulator, step.path)
          : _.filter(accumulator, step.path)

        // to collect multiple find results, moreResults flag will loop current resolver
        // and if more, then removing found result allows next result to be found
        moreResults = allResults.length > 1
        result = _.head(allResults)
        if (moreResults) _.remove(accumulator, result)
      }
      return result
    }, workData)

    // if there is any value after step processing, add entry in values
    if (value !== undefined) values.push(value)
  }
  return values
}

/**
 * Process a nested array of arrays + strings into a new nested array grouped by indices of sub arrays
 * String values are added to each new array in their original order
 * @param {Array} Array of possible arrays + strings
 * @returns {Array.<Array>|<String>}
 */
const zipArraysByIndex = (array) => {
  // if no array elements, then no need to zip
  if (array.every(_.notArray)) return array

  // used if there are descrepancies in nested array length
  const arrayOfSubArrayLengths = array.reduce(
    (acc, curr) => (_.isArray(curr) ? [...acc, curr.length] : acc),
    [],
  )
  const maxNestedArrLength = arrayOfSubArrayLengths.length
    ? Math.max(...arrayOfSubArrayLengths)
    : 0

  // replaces strings with arrays of the string value with lengths = to the max length of the other sub arrays
  const formatArrayForZipping = array.map((el) => {
    return _.isString(el) ? _.fill(Array(maxNestedArrLength), el) : el
  })

  // remove undefined values due to unequal sub array lengths and convert types to strings when zipping
  return _.zip(...formatArrayForZipping)
}

/**
 * Determines if str is a "separator" as possibly used for joining srings
 * @param {String} str
 * @returns {Boolean}
 */
const isSeparator = (str) =>
  str.length > 0 &&
  ((str.length <= 2 && (str.includes(" ") || str.includes(","))) ||
    (str.length <= 3 && str.includes("-")))

/**
 * Remove separators if at beginning or end of array or when preceded or followed by an empty string
 * @param {Array} arr
 * @returns {Array}
 */
export const removeUnusedSeparators = (arr) => {
  const array = _.cloneDeep(arr)
  for (let i = 0; i < array.length; ) {
    // if separator and empty before or after, then pull it (which advances i)
    if (
      isSeparator(array[i]) &&
      (_.trim(_.get(array, i - 1)) === "" || _.trim(_.get(array, i + 1)) === "")
    ) {
      _.pullAt(array, i)
    } else {
      // otherwise just advance i
      i += 1
    }
  }
  return array
}

/**
 * Join the array, either a flat array or arrays within the array
 * @param {Array.<Array>|<String>} of strings or mixed sub arrays and strings
 * @returns {Array<String>} of joined array values
 */
const joinResults = (array) => {
  if (array.every(_.notArray))
    return removeUnusedSeparators(array).join("").trim()
  return array.map((subArr) => removeUnusedSeparators(subArr).join("").trim())
}

/**
 * Process the resolver as String.  Supports literal entries (strings that start with $)
 * @param {Object} data is the raw JSON data to be transformed/resolved
 * @param {Object} path is the object that dictates how the engine resolves the value
 * @returns {Array} of field, value and optional groupWith props
 */
export const processPathAsString = (data, path) =>
  // if literal entry, then just add that as value
  _.startsWith(path, "$") ? [path.slice(1)] : processPathAsSteps(data, path)

/**
 * Process the resolver using the function given in the path
 * @param {Object} data is the raw JSON data to be transformed/resolved
 * @param {Object} path is the object that dictates how the engine resolves the value
 * @returns {Array} of field, value and optional groupWith props
 */
export const processPathAsFunction = (data, path) => {
  const value = path(data)
  if (value === undefined) return []
  if (_.isArray(value)) {
    return value.map((val) => {
      return _.isPlainObject(val) ? _.omitBy(val, _.isUndefined) : val
    })
  }
  return [value]
}

/**
 * Process as an object, usually for concantenated results of compound resolver
 * @param {Object} data is the raw JSON data to be transformed/resolved
 * @param {Object} path is the object that dictates how the engine resolves the value
 * @returns {String}
 */
export const processPathAsObject = (data, pathObject) => {
  let value = []
  // if join prop then process its value as array but join inner arrays strings
  if (pathObject.join) {
    const arrayResults = processPathAsArray(data, pathObject.join)
    value = joinResults(arrayResults[0])
  }
  return value
}

/**
 * Process array of paths, returning array of results within an array (assumes inner result is an array)
 * In the case where one or more paths have multiple results, zip it so it's grouped by indices of sub arrays
 * e.g. path results [[500, 1], ["MB", "TB"]] gets zipped to [["500MB", "1TB"]]
 * @param {Object} data is the raw JSON data to be transformed/resolved
 * @param {Object} path is the object that dictates how the engine resolves the value
 * @returns {Array} of an Array of path results
 */
export const processPathAsArray = (data, pathArray) => {
  const values = []
  pathArray.forEach((path) => {
    let value = processPath(data, path)
    if (value) {
      // if value has multiple results retain as array otherwise flatten as single value
      value = _.isArray(value) && value.length <= 1 ? value[0] : value
      if (value !== undefined) values.push(value)
    }
  })
  return [zipArraysByIndex(values)]
}

/**
 * Main method for handling resolver paths of multiple types
 * @param {Object} data is the raw JSON data to be transformed/resolved
 * @param {Object} path is the object that dictates how the engine resolves the value
 * @returns {Array} of field, value and optional groupWith props
 */
export const processPath = (data, path) => {
  let values = []
  if (_.isFunction(path)) values = processPathAsFunction(data, path)
  else if (_.isString(path)) values = processPathAsString(data, path)
  else if (_.isArray(path)) values = processPathAsArray(data, path)
  else if (_.isPlainObject(path)) values = processPathAsObject(data, path)
  return values
}

/**
 * Group the results according to optional "groupWith" fields.  If a result has that
 * field defined, pull the field named by groupWith to right after this result
 * @param {Array} results
 * @returns {Array} grouped array of { field, value, groupWith }
 */
export const groupResults = (results) => {
  const grouped = _.clone(results)
  grouped.forEach((result, index) => {
    if (_.isString(result.groupWith)) {
      const targetRec = _.find(grouped, { field: result.groupWith }, index)
      if (targetRec) _.pull(grouped, targetRec).splice(index + 1, 0, targetRec)
    }
  })
  return grouped
}

/**
 * Prepares the result to an array of objects each { field, value } plus potentially other props
 * `value` contains the original value in the array, and `field` and other props are in the obj
 * @param {Array} array of values to be each assigned to `value` in an object and extended with given obj
 * @param {Object} obj to wrap each values with.  All props with undefined values are omitted
 * @returns {Array} of objects
 */
const wrapEachValueWith = (array, obj) => {
  const objNoUndefined = _.omitBy(obj, _.isUndefined)
  return array.map((value) => _.assign({ value }, objNoUndefined))
}

/**
 * Converts resolved objects array of field and values into a single object with the fields as keys
 * @param {Array<Object>} valueArr -  of {field: "", value: ""}
 * returns {Object} of field, value
 */
export const convertResolvedValuesArrToObj = (valueArr) =>
  valueArr?.reduce((acc, { field, value }) => {
    acc[field] = value
    return acc
  }, {})

/**
 * Piping functions on transforms
 * @param {Functions} functions list of functions to be piped
 */
const reducer = (f, g) => (arg) => g(f(arg))
const pipe = (...functions) => functions.reduce(reducer)

/* ============================================================ */

/**
 * Main client resolver engine to resolve fields from raw data
 * @param {Object} data is the original data (e.g. FHIR data)
 * @param {Array} resolvers is the list of resolver entries describing each field and how to transform data to it
 * @returns {Array} of { field: String of label, value: String of value } representing transformed data
 */
function applyResolvers(data, resolvers) {
  let results = []
  resolvers.forEach(({ field, path, hide, groupWith, transform }) => {
    try {
      let valuesArray = processPath(data, path)
      if (transform) {
        if (_.isArray(transform)) {
          valuesArray = valuesArray.map(pipe(...transform))
        } else {
          valuesArray = valuesArray.map(transform)
        }
      }
      results = [
        ...results,
        ...wrapEachValueWith(valuesArray, { field, hide, groupWith }),
      ]
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)

      resolverErrors.add({
        message: e?.message,
        field,
        path,
      })
    }
  })

  return groupResults(results)
}

export default applyResolvers
