<script>
  import { onMount, onDestroy } from "svelte"
  import DOMPurify from "dompurify"
  import { params } from "@roxi/routify"
  import { _ } from "@/i18n"
  import {
    activeMcKessonClinicalNoteId,
    mcKessonClinicalNoteInfo,
  } from "@/stores/healthRecordStores"
  import { removeUrlParamByName } from "@/utils/httpService"
  import { convertResolvedValuesArrToObj } from "@/utils/applyResolvers"
  import { mobileToolbarConfig, viewedNotifications } from "@/stores/stores"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"
  import HealthRecordsHeader from "@/components/HealthRecordsHeader/HealthRecordsHeader.svelte"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"

  let breadcrumbLinks
  let isLoading = true

  let clinicalNote = null

  // not concerned about anything after the day in the comparison
  function isSameDate(d1, d2) {
    return d1?.slice(0, 10) === d2?.slice(0, 10)
  }

  /**
   * Prepare breadcrumb link with dynamic lab result name
   * @returns {Array} Breadcrumblinks
   */
  const generateBreadcrumbLinks = (note) => [
    {
      title: $_("HR.HEALTH_RECORDS"),
      link: "/health-records",
      id: "health-records",
    },
    {
      title: $_("HR.CLINICAL_NOTES.TITLE"),
      link: "/health-records/clinical-notes",
      id: "clinical-notes",
    },
    {
      title: note.title,
      active: true,
    },
  ]

  /**
   * Set the mobileToolbarConfig options for HR
   */
  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: true, text: $_("HR.CLINICAL_NOTES.TITLE") },
      center: { logo: false },
      customClass: "blue-bkg-sm-down",
      redirectPath: "/health-records/clinical-notes",
    })

    // notificationId is recieved via url param
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const notificationId = urlParams.get("nid")
    if (notificationId) {
      removeUrlParamByName("nid")
      viewedNotifications.add([notificationId])
    }
  })

  $: if ($params.id) {
    activeMcKessonClinicalNoteId.set($params.id)
  }

  $: if ($mcKessonClinicalNoteInfo) {
    clinicalNote = convertResolvedValuesArrToObj($mcKessonClinicalNoteInfo)
    breadcrumbLinks = generateBreadcrumbLinks(clinicalNote)
    isLoading = false
  }

  /**
   * Reset the mobileToolbarConfig
   */
  onDestroy(() => {
    mobileToolbarConfig.set({})
    activeMcKessonClinicalNoteId.set("")
  })
</script>

<section class="health-records-wrapper">
  <Breadcrumb {breadcrumbLinks} />
  {#if isLoading}
    <div class="spinner-wrapper">
      <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
    </div>
  {:else if clinicalNote}
    <HealthRecordsHeader title={clinicalNote.title} textAlignClass="text-left">
      <h2 class="sub-title created-on">
        Created on {clinicalNote.createdAt} by {clinicalNote.createdBy}
      </h2>
      {#if clinicalNote.updatedAt && !isSameDate(clinicalNote.createdAt, clinicalNote.updatedAt)}
        <h2 class="sub-title last-updated">
          Last updated {clinicalNote.updatedAt}
          {clinicalNote.updatedBy ? `by ${clinicalNote.updatedBy}` : ""}
        </h2>
      {/if}
    </HealthRecordsHeader>
    <section class="health-records-container">
      <div class="note-container">
        {@html DOMPurify.sanitize(window.atob(clinicalNote.attachment))}
      </div>
    </section>
  {:else}
    <div class="no-record">
      {$_("HR.CLINICAL_NOTES.DETAIL.ERROR.DEFAULT")}
      <p>
        {$_("HR.CLINICAL_NOTES.DETAIL.ERROR.DEFAULT_BODY")}
      </p>
    </div>
  {/if}
</section>

<style lang="scss" src="./[id].scss">
</style>
