const bannerTypeMapping = {
  inform: {
    icon: "inform",
    iconColor: "#0b6db2",
    backgroundColor: "#dbf3ff",
  },
  success: {
    icon: "check",
    iconColor: "#1c7525",
    backgroundColor: "#E5F6E1",
  },
  caution: {
    icon: "alert",
    iconColor: "#b2511a",
    backgroundColor: "#FFF1CC",
  },
  fail: {
    icon: "alert",
    iconColor: "#8A0022",
    backgroundColor: "#FBE6EB",
  },
}

export default bannerTypeMapping
