
/**
 * @roxi/routify 2.18.3
 * File generated Thu Sep 28 2023 14:26:47 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.18.3"
export const __timestamp = "2023-09-28T14:26:47.884Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports
import __fallback from '../src/pages/_fallback.svelte'
import _aboutUs_index from '../src/pages/about-us/index.svelte'
import _aboutUs_privacyPolicy_index from '../src/pages/about-us/privacy-policy/index.svelte'
import _aboutUs_termsOfUse_index from '../src/pages/about-us/terms-of-use/index.svelte'
import _auth_checkEmail_index from '../src/pages/auth/check-email/index.svelte'
import _auth_checkEmailLink_index from '../src/pages/auth/check-email-link/index.svelte'
import _auth_createPassword_index from '../src/pages/auth/create-password/index.svelte'
import _auth_login_index from '../src/pages/auth/login/index.svelte'
import _auth_passwordReset_index from '../src/pages/auth/password-reset/index.svelte'
import _auth_registration_allSet_index from '../src/pages/auth/registration/all-set/index.svelte'
import _auth_registration_consent_index from '../src/pages/auth/registration/consent/index.svelte'
import _auth_registration_secureAccount_index from '../src/pages/auth/registration/secure-account/index.svelte'
import _auth_registration_validateDob_index from '../src/pages/auth/registration/validate-dob/index.svelte'
import _auth_sendVerificationLink_index from '../src/pages/auth/send-verification-link/index.svelte'
import _auth_signInHelp_index from '../src/pages/auth/sign-in-help/index.svelte'
import _auth_signInSuccess_index from '../src/pages/auth/sign-in-success/index.svelte'
import _auth__layout from '../src/pages/auth/_layout.svelte'
import _error_index from '../src/pages/error/index.svelte'
import _healthRecords_accessLog_index from '../src/pages/health-records/access-log/index.svelte'
import _healthRecords_allergies_index from '../src/pages/health-records/allergies/index.svelte'
import _healthRecords_assessmentsTreatmentPlans_index from '../src/pages/health-records/assessments-treatment-plans/index.svelte'
import _healthRecords_careTeam_index from '../src/pages/health-records/care-team/index.svelte'
import _healthRecords_careTeamPatientInfo_index from '../src/pages/health-records/care-team-patient-info/index.svelte'
import _healthRecords_clinicalNotes__id from '../src/pages/health-records/clinical-notes/[id].svelte'
import _healthRecords_clinicalNotes_index from '../src/pages/health-records/clinical-notes/index.svelte'
import _healthRecords_diagnoses_index from '../src/pages/health-records/diagnoses/index.svelte'
import _healthRecords_goals_index from '../src/pages/health-records/goals/index.svelte'
import _healthRecords_healthConcerns_index from '../src/pages/health-records/health-concerns/index.svelte'
import _healthRecords_healthConcernsDiagnoses_index from '../src/pages/health-records/health-concerns-diagnoses/index.svelte'
import _healthRecords_healthDocuments_index from '../src/pages/health-records/health-documents/index.svelte'
import _healthRecords_immunizations_index from '../src/pages/health-records/immunizations/index.svelte'
import _healthRecords_index from '../src/pages/health-records/index.svelte'
import _healthRecords_medications_index from '../src/pages/health-records/medications/index.svelte'
import _healthRecords_patientInfo_index from '../src/pages/health-records/patient-info/index.svelte'
import _healthRecords_procedures_index from '../src/pages/health-records/procedures/index.svelte'
import _healthRecords_proceduresImmunizations_index from '../src/pages/health-records/procedures-immunizations/index.svelte'
import _healthRecords_share_index from '../src/pages/health-records/share/index.svelte'
import _healthRecords_testResults__id_detail__index from '../src/pages/health-records/test-results/[id]/detail/[index].svelte'
import _healthRecords_testResults__id_history__index from '../src/pages/health-records/test-results/[id]/history/[index].svelte'
import _healthRecords_testResults__id_index from '../src/pages/health-records/test-results/[id]/index.svelte'
import _healthRecords_testResults_index from '../src/pages/health-records/test-results/index.svelte'
import _healthRecords_vds_index from '../src/pages/health-records/vds/index.svelte'
import _healthRecords_vitals_index from '../src/pages/health-records/vitals/index.svelte'
import _healthRecords__layout from '../src/pages/health-records/_layout.svelte'
import _home_index from '../src/pages/home/index.svelte'
import _index from '../src/pages/index.svelte'
import _messages_compose_index from '../src/pages/messages/compose/index.svelte'
import _messages_inbox_index from '../src/pages/messages/inbox/index.svelte'
import _messages_index from '../src/pages/messages/index.svelte'
import _messages_sent_index from '../src/pages/messages/sent/index.svelte'
import _messages_thankyou_index from '../src/pages/messages/thankyou/index.svelte'
import _messages_thread__id from '../src/pages/messages/thread/[id].svelte'
import __layout from '../src/pages/_layout.svelte'

//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => __fallback
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/about-us/index",
          "id": "_aboutUs_index",
          "component": () => _aboutUs_index
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/about-us/privacy-policy/index",
              "id": "_aboutUs_privacyPolicy_index",
              "component": () => _aboutUs_privacyPolicy_index
            }
          ],
          "path": "/about-us/privacy-policy"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/about-us/terms-of-use/index",
              "id": "_aboutUs_termsOfUse_index",
              "component": () => _aboutUs_termsOfUse_index
            }
          ],
          "path": "/about-us/terms-of-use"
        }
      ],
      "path": "/about-us"
    },
    {
      "isDir": true,
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/auth/check-email/index",
              "id": "_auth_checkEmail_index",
              "component": () => _auth_checkEmail_index
            }
          ],
          "path": "/auth/check-email"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/auth/check-email-link/index",
              "id": "_auth_checkEmailLink_index",
              "component": () => _auth_checkEmailLink_index
            }
          ],
          "path": "/auth/check-email-link"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/auth/create-password/index",
              "id": "_auth_createPassword_index",
              "component": () => _auth_createPassword_index
            }
          ],
          "path": "/auth/create-password"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/auth/login/index",
              "id": "_auth_login_index",
              "component": () => _auth_login_index
            }
          ],
          "path": "/auth/login"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/auth/password-reset/index",
              "id": "_auth_passwordReset_index",
              "component": () => _auth_passwordReset_index
            }
          ],
          "path": "/auth/password-reset"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isIndex": true,
                  "isPage": true,
                  "path": "/auth/registration/all-set/index",
                  "id": "_auth_registration_allSet_index",
                  "component": () => _auth_registration_allSet_index
                }
              ],
              "path": "/auth/registration/all-set"
            },
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isIndex": true,
                  "isPage": true,
                  "path": "/auth/registration/consent/index",
                  "id": "_auth_registration_consent_index",
                  "component": () => _auth_registration_consent_index
                }
              ],
              "path": "/auth/registration/consent"
            },
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isIndex": true,
                  "isPage": true,
                  "path": "/auth/registration/secure-account/index",
                  "id": "_auth_registration_secureAccount_index",
                  "component": () => _auth_registration_secureAccount_index
                }
              ],
              "path": "/auth/registration/secure-account"
            },
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isIndex": true,
                  "isPage": true,
                  "path": "/auth/registration/validate-dob/index",
                  "id": "_auth_registration_validateDob_index",
                  "component": () => _auth_registration_validateDob_index
                }
              ],
              "path": "/auth/registration/validate-dob"
            }
          ],
          "path": "/auth/registration"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/auth/send-verification-link/index",
              "id": "_auth_sendVerificationLink_index",
              "component": () => _auth_sendVerificationLink_index
            }
          ],
          "path": "/auth/send-verification-link"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/auth/sign-in-help/index",
              "id": "_auth_signInHelp_index",
              "component": () => _auth_signInHelp_index
            }
          ],
          "path": "/auth/sign-in-help"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/auth/sign-in-success/index",
              "id": "_auth_signInSuccess_index",
              "component": () => _auth_signInSuccess_index
            }
          ],
          "path": "/auth/sign-in-success"
        }
      ],
      "isLayout": true,
      "path": "/auth",
      "id": "_auth__layout",
      "component": () => _auth__layout
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/error/index",
          "id": "_error_index",
          "component": () => _error_index
        }
      ],
      "path": "/error"
    },
    {
      "isDir": true,
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/access-log/index",
              "id": "_healthRecords_accessLog_index",
              "component": () => _healthRecords_accessLog_index
            }
          ],
          "path": "/health-records/access-log"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/allergies/index",
              "id": "_healthRecords_allergies_index",
              "component": () => _healthRecords_allergies_index
            }
          ],
          "path": "/health-records/allergies"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/assessments-treatment-plans/index",
              "id": "_healthRecords_assessmentsTreatmentPlans_index",
              "component": () => _healthRecords_assessmentsTreatmentPlans_index
            }
          ],
          "path": "/health-records/assessments-treatment-plans"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/care-team/index",
              "id": "_healthRecords_careTeam_index",
              "component": () => _healthRecords_careTeam_index
            }
          ],
          "path": "/health-records/care-team"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/care-team-patient-info/index",
              "id": "_healthRecords_careTeamPatientInfo_index",
              "component": () => _healthRecords_careTeamPatientInfo_index
            }
          ],
          "path": "/health-records/care-team-patient-info"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isPage": true,
              "path": "/health-records/clinical-notes/:id",
              "id": "_healthRecords_clinicalNotes__id",
              "component": () => _healthRecords_clinicalNotes__id
            },
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/clinical-notes/index",
              "id": "_healthRecords_clinicalNotes_index",
              "component": () => _healthRecords_clinicalNotes_index
            }
          ],
          "path": "/health-records/clinical-notes"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/diagnoses/index",
              "id": "_healthRecords_diagnoses_index",
              "component": () => _healthRecords_diagnoses_index
            }
          ],
          "path": "/health-records/diagnoses"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/goals/index",
              "id": "_healthRecords_goals_index",
              "component": () => _healthRecords_goals_index
            }
          ],
          "path": "/health-records/goals"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/health-concerns/index",
              "id": "_healthRecords_healthConcerns_index",
              "component": () => _healthRecords_healthConcerns_index
            }
          ],
          "path": "/health-records/health-concerns"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/health-concerns-diagnoses/index",
              "id": "_healthRecords_healthConcernsDiagnoses_index",
              "component": () => _healthRecords_healthConcernsDiagnoses_index
            }
          ],
          "path": "/health-records/health-concerns-diagnoses"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/health-documents/index",
              "id": "_healthRecords_healthDocuments_index",
              "component": () => _healthRecords_healthDocuments_index
            }
          ],
          "path": "/health-records/health-documents"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/immunizations/index",
              "id": "_healthRecords_immunizations_index",
              "component": () => _healthRecords_immunizations_index
            }
          ],
          "path": "/health-records/immunizations"
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/health-records/index",
          "id": "_healthRecords_index",
          "component": () => _healthRecords_index
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/medications/index",
              "id": "_healthRecords_medications_index",
              "component": () => _healthRecords_medications_index
            }
          ],
          "path": "/health-records/medications"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/patient-info/index",
              "id": "_healthRecords_patientInfo_index",
              "component": () => _healthRecords_patientInfo_index
            }
          ],
          "path": "/health-records/patient-info"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/procedures/index",
              "id": "_healthRecords_procedures_index",
              "component": () => _healthRecords_procedures_index
            }
          ],
          "path": "/health-records/procedures"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/procedures-immunizations/index",
              "id": "_healthRecords_proceduresImmunizations_index",
              "component": () => _healthRecords_proceduresImmunizations_index
            }
          ],
          "path": "/health-records/procedures-immunizations"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/share/index",
              "id": "_healthRecords_share_index",
              "component": () => _healthRecords_share_index
            }
          ],
          "path": "/health-records/share"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "path": "/health-records/test-results/:id/detail/:index",
                      "id": "_healthRecords_testResults__id_detail__index",
                      "component": () => _healthRecords_testResults__id_detail__index
                    }
                  ],
                  "path": "/health-records/test-results/:id/detail"
                },
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "path": "/health-records/test-results/:id/history/:index",
                      "id": "_healthRecords_testResults__id_history__index",
                      "component": () => _healthRecords_testResults__id_history__index
                    }
                  ],
                  "path": "/health-records/test-results/:id/history"
                },
                {
                  "isIndex": true,
                  "isPage": true,
                  "path": "/health-records/test-results/:id/index",
                  "id": "_healthRecords_testResults__id_index",
                  "component": () => _healthRecords_testResults__id_index
                }
              ],
              "path": "/health-records/test-results/:id"
            },
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/test-results/index",
              "id": "_healthRecords_testResults_index",
              "component": () => _healthRecords_testResults_index
            }
          ],
          "path": "/health-records/test-results"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/vds/index",
              "id": "_healthRecords_vds_index",
              "component": () => _healthRecords_vds_index
            }
          ],
          "path": "/health-records/vds"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/health-records/vitals/index",
              "id": "_healthRecords_vitals_index",
              "component": () => _healthRecords_vitals_index
            }
          ],
          "path": "/health-records/vitals"
        }
      ],
      "isLayout": true,
      "path": "/health-records",
      "id": "_healthRecords__layout",
      "component": () => _healthRecords__layout
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/home/index",
          "id": "_home_index",
          "component": () => _home_index
        }
      ],
      "path": "/home"
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => _index
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/messages/compose/index",
              "id": "_messages_compose_index",
              "component": () => _messages_compose_index
            }
          ],
          "path": "/messages/compose"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/messages/inbox/index",
              "id": "_messages_inbox_index",
              "component": () => _messages_inbox_index
            }
          ],
          "path": "/messages/inbox"
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/messages/index",
          "id": "_messages_index",
          "component": () => _messages_index
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/messages/sent/index",
              "id": "_messages_sent_index",
              "component": () => _messages_sent_index
            }
          ],
          "path": "/messages/sent"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/messages/thankyou/index",
              "id": "_messages_thankyou_index",
              "component": () => _messages_thankyou_index
            }
          ],
          "path": "/messages/thankyou"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isPage": true,
              "path": "/messages/thread/:id",
              "id": "_messages_thread__id",
              "component": () => _messages_thread__id
            }
          ],
          "path": "/messages/thread"
        }
      ],
      "path": "/messages"
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => __layout
}


export const {tree, routes} = buildClientTree(_tree)

