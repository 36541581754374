<script>
  import { onMount, onDestroy } from "svelte"
  import { _ } from "@/i18n"
  import { mobileToolbarConfig } from "@/stores/stores"
  import { accessLogsInfo } from "@/stores/healthRecordStores"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"
  import HealthRecordsHeader from "@/components/HealthRecordsHeader/HealthRecordsHeader.svelte"
  import { returnFormattedDate } from "@/utils/dateTime"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"

  let isLoading = true

  /**
   * Mocking pagination
   */
  let currentPage = 1
  let allAccessLogsData
  let currentAccessLogs = []
  /**
   * Mocks the pagination using log data
   * @param {Array} data array of data to be paginated
   * @returns {Object} return paginated data
   */
  const ITEMS_PER_PAGE = 25
  const mockPagination = (allData) => {
    const paginated = {
      count: allData.length,
      pages: Math.ceil(allData.length / ITEMS_PER_PAGE),
      results: allData,
    }
    allAccessLogsData = paginated
    currentAccessLogs = allData.slice(0, ITEMS_PER_PAGE)
  }

  /**
   * Loads more items using the mock logic
   */
  const loadMore = () => {
    // TODO: Real logic goes here.
    if (currentPage < allAccessLogsData.pages) {
      currentAccessLogs = [
        ...currentAccessLogs,
        ...allAccessLogsData.results.slice(
          currentPage * ITEMS_PER_PAGE,
          currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
        ),
      ]

      currentPage += 1
    }
  }

  const breadcrumbLinks = [
    {
      title: $_("HR.HEALTH_RECORDS"),
      link: "/health-records",
      id: "health-records",
    },
    {
      title: $_("HR.ACCESS_LOG"),
      active: true,
    },
  ]

  $: if ($accessLogsInfo) {
    if (!$accessLogsInfo.error && $accessLogsInfo.length > 0) {
      mockPagination($accessLogsInfo)
    }

    isLoading = false
  }

  /**
   * Returns the the relationship value formatted.
   * @param {String} relationship - Access log relationship
   * @returns {String} formatted relationship string
   */
  const formatCredentials = (relationship) => {
    switch (relationship) {
      case "ACCOUNT_OWNER":
        return ""
      case "AUTHORIZED_REP":
        return ""
      case "NC_SUPPORT_STAFF":
        return $_("HR.ACCESS_LOG_CREDENTIALS_NC_SUPPORT_STAFF")
      case "CLINIC_STAFF":
        return $_("HR.ACCESS_LOG_CREDENTIALS_CLINIC_STAFF")
      default:
        return ""
    }
  }

  /**
   * Returns the the action value formatted.
   * @param {Object} action - Access log action
   * @returns {String} formatted action string
   */
  const formatAction = (action) => {
    switch (action.accessType) {
      case "VIEW":
        return $_("HR.ACCESS_LOG_ACTION_VIEW")
      case "DOWNLOAD":
        return $_("HR.ACCESS_LOG_ACTION_DOWNLOAD")
      case "TRANSMIT":
        return `${$_("HR.ACCESS_LOG_ACTION_TRANSMIT")} \n${
          action.emailRecipient
        }`
      default:
        return ""
    }
  }

  /**
   * Set the mobileToolbarConfig options for HR
   */
  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: true, text: $_("HR.HEALTH_RECORDS") },
      center: { logo: false },
      customClass: "blue-bkg-sm-down",
      redirectPath: "/health-records",
    })
  })

  /**
   * Reset the mobileToolbarConfig
   */
  onDestroy(() => {
    mobileToolbarConfig.set({})
  })
</script>

<section class="health-records-wrapper">
  <Breadcrumb {breadcrumbLinks} />
  <HealthRecordsHeader title={$_("HR.ACCESS_LOG")} />
  <section class="health-records-container">
    <p class="description">{$_("HR.ACCESS_LOG_DESCRIPTION")}</p>
    {#if isLoading}
      <div class="spinner-wrapper">
        <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
      </div>
    {:else}
      <div>
        {#if $accessLogsInfo?.error}
          <div class="no-record-error">
            <p><strong>{$_("HR.ERROR_LOADING_ACCESS_LOG")}</strong></p>
            <p>
              {@html $_("HR.ACCESS_LOG_REFRESH")}
            </p>
          </div>
        {:else if currentAccessLogs.length > 0}
          <div>
            <div class="table-container">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">{$_("HR.ACCESS_LOG_TABLE_DATE")}</th>
                    <th scope="col">{$_("HR.ACCESS_LOG_TABLE_ACCESSED")}</th>
                    <th scope="col">{$_("HR.ACCESS_LOG_TABLE_ACTION")}</th>
                  </tr>
                </thead>
                <tbody>
                  {#each currentAccessLogs as { eventTimeStamp, individualWhoAccessedData, action }}
                    <tr>
                      <td
                        >{returnFormattedDate(eventTimeStamp, {
                          dateFormat: "M/DD/YYYY",
                          timeFormat: "[, \n] h:mma z",
                        })}</td
                      >
                      <td>
                        {`${individualWhoAccessedData.firstName} ${
                          individualWhoAccessedData.lastName
                        } ${formatCredentials(
                          individualWhoAccessedData.relationshipToPatient,
                        )}`}
                      </td>
                      <td>{formatAction(action)}</td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            </div>

            <div class="cards-container">
              {#each currentAccessLogs as { eventTimeStamp, individualWhoAccessedData, action }}
                <article class="card">
                  <section class="card-body">
                    <div class="card-text mt-0">
                      <p class="date">
                        {returnFormattedDate(eventTimeStamp, {
                          dateFormat: "M/DD/YYYY",
                          timeFormat: "[, \n] h:mma z",
                        })}
                      </p>
                      <ul>
                        <li>
                          <div class="label">
                            {$_("HR.ACCESS_LOG_CARD_ACCESSED")}
                          </div>
                          <div class="value">
                            {`${individualWhoAccessedData.firstName} ${
                              individualWhoAccessedData.lastName
                            } ${formatCredentials(
                              individualWhoAccessedData.relationshipToPatient,
                            )}`}
                          </div>
                        </li>
                        <li>
                          <div class="label">
                            {$_("HR.ACCESS_LOG_CARD_ACTION")}
                          </div>
                          <div class="value">{formatAction(action)}</div>
                        </li>
                      </ul>
                    </div>
                  </section>
                </article>
              {/each}
            </div>
            {#if currentAccessLogs.length < allAccessLogsData?.count}
              <div class="load-more-container">
                <button class="btn btn-secondary" on:click={loadMore}
                  >{$_("HR.LOAD_MORE")}</button
                >
              </div>
            {/if}
          </div>
        {:else}
          <div class="no-record-error">
            <p><strong>{$_("HR.NO_ACTIVITY_FOR_ACCESS_LOG")}</strong></p>
          </div>
        {/if}
      </div>
    {/if}
  </section>
</section>

<style lang="scss" src="./index.scss">
</style>
