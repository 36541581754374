<script>
  import { goto } from "@roxi/routify"

  /**
   * breadcrumbLink type definition
   * @typedef {Object} breadcrumbLink
   * @property {String} link - link
   * @property {String} title - text display for link
   * @property {Boolean} active - active/current displayed page
   * @property {String} id - id
   */

  /**
   * @type {Array.<breadcrumbLink>}
   * @default []
   */
  export let breadcrumbLinks = []
</script>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    {#each breadcrumbLinks as { title, link, active, id }}
      <li class="breadcrumb-item" class:active>
        {#if link}
          <button type="button" {id} class="btn-link" on:click={$goto(link)}
            >{title}</button
          >
        {:else}
          {title}
        {/if}
      </li>
    {/each}
  </ol>
</nav>

<style lang="scss" src="./Breadcrumb.scss"></style>
