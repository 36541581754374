<script>
  import { _ } from "@/i18n"
  import CurvedBanner from "@/components/CurvedBanner/CurvedBanner.svelte"

  const values = {}
</script>

<div>
  <CurvedBanner
    config={{
      title: $_("AUTH.ALL_SET"),
      iconSrc: "../assets/svg/auth-icons/unlocked.svg",
      circleBorderColor: "#91dd45",
      disableAuthButtonGroup: true,
    }}
  />
  <section class="auth-container">
    <div class="sub-heading">
      <p>
        {$_("AUTH.SIGN_IN_SUCCESS_DESCRIPTION")}
      </p>
    </div>
    <form>
      <div class="form-text-primary d-flex">
        <input
          aria-label="keep_me_logged_in"
          type="checkbox"
          name="keep_me_logged_in"
          bind:checked={values.checkbox}
        />
        <p for="keep_me_logged_in" class="pl-12">
          {$_("AUTH.KEEP_ME_LOGGED_IN")}
        </p>
      </div>
    </form>
    <div class="btn-div">
      <button id="sign-in-btn" type="submit" class="btn btn-primary w-100"
        >{$_("AUTH.CONTINUE")}</button
      >
    </div>
  </section>
</div>
