<script>
  import { sortedUniqBy, find, orderBy, filter } from "lodash"
  import { _ } from "@/i18n"
  import { careTeamInfo } from "@/stores/healthRecordStores"
  import CareTeamPatientInfo from "../care-team-patient-info/index.svelte"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"

  let isLoading = true
  let provenance
  const arrCareTeam = []
  const arrClinics = []

  /**
   * Sort the data by specific field and order
   * @param {Array} data array of data to be sorted
   * @param {String} field field by which we need to sort the data
   * @param {Array} order array of order
   * @returns {Array} return sorted data
   */
  const sortData = (data, field, order) => {
    return orderBy(data, [(item) => find(item, { field })?.value], order)
  }

  /**
   * Group the data between CareTeam and Clinics and sort the data in specific order
   * @param {Array} resolverResponse response returned from the resolver
   */
  const groupByAndSortData = (resolverResponse) => {
    const { CareTeam, Clinics } = resolverResponse.reduce(
      (careTeams, careTeamMember) => {
        careTeamMember.value.forEach(({ field, value }) => {
          if (careTeams[field]) {
            careTeams[field].push(value)
          } else {
            // eslint-disable-next-line no-param-reassign
            careTeams[field] = [value]
          }
        })
        return careTeams
      },
      {},
    )

    // Sort care team data in alphabetical order
    const sortedCareTeamData = sortData(CareTeam, "Name", ["asc"])

    // Filter Primary care provider from sortedCareTeamData
    const primaryCareProvider = filter(sortedCareTeamData, (item) =>
      find(item, { value: "PCP" }),
    )

    // To give the preference for PCP in the order, combined the primary care provider and sorted care team data
    // then Remove duplicate data from the combined array
    arrCareTeam.push(
      ...new Set([...primaryCareProvider, ...sortedCareTeamData]),
    )

    // Sort clinics data in alphabetical order
    const sortedClinicsData = sortData(Clinics, "Name", ["asc"])

    // remove duplicate clinics from the sorted clinics data via the name property
    const uniqueClinicsData = sortedUniqBy(sortedClinicsData, (clinicArr) => {
      return find(clinicArr, { field: "Name" })?.value
    })

    arrClinics.push(...uniqueClinicsData)
  }

  $: if ($careTeamInfo) {
    if ($careTeamInfo.length > 0) {
      groupByAndSortData($careTeamInfo)
      provenance = find($careTeamInfo[0]?.value, { field: "Provenance" })?.value
    }
    isLoading = false
  }
</script>

<CareTeamPatientInfo activeClassLabel="Care Team">
  {#if isLoading}
    <div class="spinner-wrapper">
      <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
    </div>
  {:else if arrCareTeam.length > 0 || arrClinics.length > 0}
    {#if arrCareTeam.length > 0}
      <div class="card">
        <section class="card-header">
          <header>
            <h3 class="title mb-0">Care Team</h3>
          </header>
        </section>
        <div class="card-body-container">
          {#each arrCareTeam as careTeamMember}
            <section class="card-body">
              <div class="card-details">
                {#if find(careTeamMember, { field: "Name" })}
                  <div class="title">
                    {find(careTeamMember, { field: "Name" }).value}
                  </div>
                {/if}
                {#if find(careTeamMember, { field: "Role" })}
                  <div class="sub-title">
                    {find(careTeamMember, { field: "Role" }).value}
                  </div>
                {/if}
                {#if find(careTeamMember, { field: "Phone" })?.value}
                  <ul>
                    <li class="mb-0">
                      <a
                        class="tel"
                        href="tel:{find(careTeamMember, { field: 'Phone' })
                          .value}"
                        >{find(careTeamMember, { field: "Phone" }).value}</a
                      >
                    </li>
                  </ul>
                {/if}
              </div>
            </section>
          {/each}
        </div>
      </div>
    {/if}
    {#if arrClinics.length > 0}
      <div class="card">
        <section class="card-header">
          <header>
            <h3 class="title mb-0">Clinics</h3>
          </header>
        </section>
        <div class="card-body-container">
          {#each arrClinics as clinic}
            <section class="card-body">
              <div class="card-details">
                <div class="title">
                  {find(clinic, { field: "Name" }).value}
                </div>
                <div class="contact-details">
                  {#if find(clinic, { field: "Address" }).value.length > 0}
                    <ul>
                      {#each find(clinic, { field: "Address" }).value as row}
                        <li>{row}</li>
                      {/each}
                    </ul>
                  {/if}
                  {#if find(clinic, { field: "Phone" })?.value}
                    <ul>
                      <li>
                        <a
                          class="tel"
                          href="tel:{find(clinic, { field: 'Phone' }).value}"
                          >{find(clinic, { field: "Phone" }).value}</a
                        >
                      </li>
                    </ul>
                  {/if}
                </div>
              </div>
            </section>
          {/each}
        </div>
      </div>
    {/if}
    {#if provenance}
      <p class="provenance-info">
        Added to EHR on {provenance}
      </p>
    {/if}
  {:else}
    <div class="no-record">
      {$_("HR.NO_RECORD_FOUND_FOR_CARE_TEAM_&_PATIENT_INFORMATION")}
    </div>
  {/if}
</CareTeamPatientInfo>

<style lang="scss" src="./index.scss">
</style>
