/**
 * Converts lab detail objects array of field and values into a single object with the fields as keys
 * @param {Array<Object>} valueArr -  of lab detail components
 * returns {Object} of lab detail properties
 */
export const convertComponentValueArrToObj = (valueArr) =>
  valueArr.reduce((acc, { field, value }) => {
    acc[field] = value
    return acc
  }, {})
