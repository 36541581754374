import { derived, writable, get as storeGet } from "svelte/store"
import { datadogRum } from "@datadog/browser-rum"
import { partialRight, get, find, isEmpty } from "lodash"
import { patientContext, error } from "@/stores/stores"
import { addCustomError } from "@/utils/datadog"
import applyResolvers from "@/utils/applyResolvers"
import patientInfoResolvers from "./resolvers/patientInfoResolvers"
import vitalSignsResolvers from "./resolvers/vitalSignsResolvers"
import allergyInfoResolvers from "./resolvers/allergyInfoResolvers"
import medicationInfoResolvers from "./resolvers/medicationInfoResolvers"
import procedureInfoResolvers from "./resolvers/procedureInfoResolvers"
import immunizationInfoResolvers from "./resolvers/immunizationInfoResolvers"
import labInfoResolvers from "./resolvers/labInfoResolvers"
import careTeamInfoResolvers from "./resolvers/careTeamInfoResolvers"
import healthConcernsInfoResolvers from "./resolvers/healthConcernsInfoResolvers"
import problemsInfoResolvers from "./resolvers/problemsInfoResolvers"
import goalInfoResolvers from "./resolvers/goalInfoResolvers"
import assessmentInfoResolvers from "./resolvers/assessmentInfoResolvers"
import smokingStatusResolvers from "./resolvers/smokingStatusResolvers"
import deviceInfoResolvers from "./resolvers/deviceInfoResolvers"
import healthDocumentsResolvers from "./resolvers/healthDocumentsResolvers"
import clinicalNotesResolvers from "./resolvers/McKesson/clinicalNotesResolvers"
import clinicalNoteResolvers from "./resolvers/McKesson/clinicalNoteResolvers"
import encounterDiagnosisResolver from "./resolvers/encounterDiagnosisResolver"
import labHistoryResolvers from "./resolvers/labHistoryResolvers"
import { sortByTimeStamp } from "@/utils/dateTime"
import { getData } from "@/utils/getData"
import { callAPI } from "@/utils/httpService"
import { sortByDate } from "@/utils/sortByDate"

// eslint-disable-next-line no-console
const fetchError = (err) => console.error("Failed to fetch", err)

const setPatientInfoDatadogContext = (patientId, patientInfo) => {
  const dateOfBirth = get(
    find(patientInfo, ["field", "Date of birth"]),
    "value",
  )
  // Set patient info in datadog global context
  datadogRum.addRumGlobalContext("patient", {
    cdm_patient_id: patientId,
    cdm_patient_dob: dateOfBirth,
  })
}

/**
 * Set error code into store variable to display error page.
 * @param {(Number|String)} errorCode http status code OR custom error code
 * @returns {Boolean} false
 */
const showErrorPage = (errorCode) => {
  error.set({
    errorCode,
  })

  return false
}

/**
 * Creates a store for errors created while applying resolvers
 * @returns {Object} with store methods
 */
function createResolverErrStore() {
  const { subscribe, update, set } = writable([])
  return {
    subscribe,
    add: (resErr) => {
      update((errors) => {
        const updatedErrors = [...errors]
        updatedErrors.push(resErr)
        return updatedErrors
      })
    },
    reset: () => set([]),
    set,
  }
}

export const resolverErrors = createResolverErrStore()

/**
 * Reports any errors found when resolving FIHR data to DD and resets the resolverErrors store
 * @param {String} resolverName - name of resolver currently being exectuted
 * @returns {Undefined}
 */
const reportAnyResolverErrors = (resolverName) => {
  const errors = storeGet(resolverErrors)
  if (errors.length) {
    // bulk report error per resolver, as applyResolvers may generate many errors at once
    addCustomError(
      {
        message: `${resolverName} Resolver Error`,
        resolver: resolverName,
        errors,
      },
      "Resolver Error",
    )
    resolverErrors.reset()
  }
}

/**
 * Pagination Stores
 */

export const labsPagination = writable({
  page: 1,
  accumulatedResults: [],
  itemsPerPage: 500,
})

/**
 * Patient Id Store
 * If no cdm patient id then display no patient id custom error page
 */
export const patientId = derived(patientContext, ($patientContext, set) => {
  if (isEmpty($patientContext)) return
  const { ncPatientId: patientIdValue } = $patientContext

  if (!patientIdValue) {
    showErrorPage("no CDM patient ID")
  }
  setPatientInfoDatadogContext(patientIdValue, $patientContext)
  set(patientIdValue)
})

/**
 * Patient Info Store
 * If no CPL ID then display custom error page
 */
// eslint-disable-next-line consistent-return
export const patientInfo = derived(patientId, ($patientId, set) => {
  if (!$patientId) return

  const resolveData = partialRight(applyResolvers, patientInfoResolvers)
  getData(`patients/${$patientId}`) // "../sample-patient-info.json"
    .then(resolveData)
    .then(set)
    .then(() => reportAnyResolverErrors("Patient Info"))
    .catch(fetchError)
})

/**
 * Smoking Status Store
 */
export const smokingStatus = derived(patientId, ($patientId, set) => {
  if (!$patientId) return
  const resolveData = partialRight(applyResolvers, smokingStatusResolvers)
  getData(`patients/${$patientId}/social-history`) // "../sample-smoking-status.json"
    .then(resolveData)
    .then(set)
    .then(() => reportAnyResolverErrors("Smoking Status"))
    .catch(fetchError)
})

/**
 * Device Info Store
 */
export const deviceInfo = derived(patientId, ($patientId, set) => {
  if (!$patientId) return
  const resolveData = partialRight(applyResolvers, deviceInfoResolvers)
  getData(`patients/${$patientId}/devices`) // "../sample-device-info.json"
    .then(resolveData)
    .then(set)
    .then(() => reportAnyResolverErrors("Device Info"))
    .catch(fetchError)
})

/**
 * Vital Signs Store
 */
// TODO: Pagination is going to be added once server side is fixed, is the same method as labs and the commit to get the previous code is: 3ec0604
export const vitalSigns = derived([patientId], ([$patientId], set) => {
  if (!$patientId) return
  const resolveData = partialRight(applyResolvers, vitalSignsResolvers)
  getData(`patients/${$patientId}/vitals`) // "../sample-vital-signs.json"
    .then(resolveData)
    .then(set)
    .then(() => reportAnyResolverErrors("Vitals"))
    .catch(fetchError)
})

/**
 * Allergy Info Store
 */
export const allergyInfo = derived(patientId, ($patientId, set) => {
  if (!$patientId) return
  const resolveData = partialRight(applyResolvers, allergyInfoResolvers)
  getData(`patients/${$patientId}/allergies`) // "../sample-allergy-info.json"
    .then(resolveData)
    .then(set)
    .then(() => reportAnyResolverErrors("Allergies"))
    .catch(fetchError)
})

/**
 * Medication Info Store
 */
export const medicationInfo = derived(patientId, ($patientId, set) => {
  if (!$patientId) return

  const resolveData = partialRight(applyResolvers, medicationInfoResolvers)

  getData(`patients/${$patientId}/medications`) // "../sample-medication-info.json"
    .then(resolveData)
    .then(set)
    .then(() => reportAnyResolverErrors("Medications"))
    .catch(fetchError)
})

/**
 * Procedure Info Store
 */
export const procedureInfo = derived(patientId, ($patientId, set) => {
  if (!$patientId) return
  const resolveData = partialRight(applyResolvers, procedureInfoResolvers)
  getData(`patients/${$patientId}/procedures`) // "../sample-procedures-info.json"
    .then(resolveData)
    .then(set)
    .then(() => reportAnyResolverErrors("Procedures"))
    .catch(fetchError)
})

/**
 * Immunization Info Store
 */
export const immunizationInfo = derived(patientId, ($patientId, set) => {
  if (!$patientId) return
  const resolveData = partialRight(applyResolvers, immunizationInfoResolvers)
  getData(`patients/${$patientId}/immunizations`) // "../sample-immunization-info.json"
    .then(resolveData)
    .then(set)
    .then(() => reportAnyResolverErrors("Immunizations"))
    .catch(fetchError)
})

/**
 * Lab Info Store
 */

export const labInfo = derived(
  [patientId, labsPagination],
  ([$patientId, $labsPagination], set) => {
    if (!$patientId) return
    const { page, itemsPerPage, accumulatedResults } = $labsPagination
    let totalCount
    let totalResults
    // TODO refactor is loading into it's own store - PE-5583
    set({
      totalCount: accumulatedResults.length + 1,
      totalResults: accumulatedResults.length,
      results: accumulatedResults,
      isLoading: true,
    })

    const resolveData = partialRight(applyResolvers, labInfoResolvers)

    const setTotalCount = (data) => {
      if (!data?.entry) {
        totalResults = 0
        totalCount = 0
        return data
      }
      totalResults = page > 0 ? page * itemsPerPage : itemsPerPage
      totalCount = data.total
      return data
    }
    const setStore = (data) => {
      set({
        totalResults,
        totalCount,
        results: [...accumulatedResults, ...data],
        isLoading: false,
      })
    }
    getData(`patients/${$patientId}/labs?page=${page}&per_page=${itemsPerPage}`) // "../sample-labs-info.json"
      .then(setTotalCount)
      .then(resolveData)
      .then(setStore)
      .then(() => reportAnyResolverErrors("Labs"))
      .catch(fetchError)
  },
)

export const activeComponentLoincCode = writable("")

export const labComponentHistoryInfo = derived(
  [activeComponentLoincCode, patientId],
  ([$activeComponentLoincCode, $patientId], set) => {
    if (!$patientId) return
    // eslint-disable-next-line consistent-return
    if (!$activeComponentLoincCode) return set(null)
    const resolveData = partialRight(applyResolvers, labHistoryResolvers)
    getData(`patients/${$patientId}/labs?code=${$activeComponentLoincCode}`) // "../sample-loinc-filtered-labs.json"
      .then(resolveData)
      .then(set)
      .then(() => reportAnyResolverErrors("Labs (Loinc Filtered)"))
      .catch(fetchError)
  },
)

/**
 * Care Team Store
 */
export const careTeamInfo = derived(patientId, ($patientId, set) => {
  if (!$patientId) return
  const resolveData = partialRight(applyResolvers, careTeamInfoResolvers)
  getData(`patients/${$patientId}/careteam`) // "../sample-care-team.json"
    .then(resolveData)
    .then(set)
    .then(() => reportAnyResolverErrors("Care Team"))
    .catch(fetchError)
})

/**
 * Health Concerns Store
 */
export const healthConcernsInfo = derived(
  [patientId],
  // eslint-disable-next-line no-unused-vars
  ([$patientId], set) => {
    if (!$patientId) return
    const resolveData = partialRight(
      applyResolvers,
      healthConcernsInfoResolvers,
    )
    getData(`patients/${$patientId}/health-concerns`) // "../sample-health-concerns-info.json"
      .then(resolveData)
      .then(set)
      .then(() => reportAnyResolverErrors("Health Concerns"))
      .catch(fetchError)
  },
)

/**
 * Encounter Diagnosis - Will merge with problems info.
 */
export const encounterDiagnosisInfo = derived(
  [patientId],
  // eslint-disable-next-line no-unused-vars
  ([$patientId], set) => {
    if (!$patientId) return
    const resolveData = partialRight(applyResolvers, encounterDiagnosisResolver)
    getData(`patients/${$patientId}/encounter-diagnoses`) // "../sample-encounter-diagnosis-info.json"
      .then(resolveData)
      .then(set)
      .then(() => reportAnyResolverErrors("Encounter Diagnosis"))
      .catch(fetchError)
  },
)

/**
 * Problems Store
 *
 * Merge encounter diagnosis info into problems since problems has no parity data.
 */
export const problemsInfo = derived(
  [patientId, encounterDiagnosisInfo],
  // eslint-disable-next-line no-unused-vars
  ([$patientId, $encounterDiagnosisInfo], set) => {
    if (!$patientId) return
    if (!$encounterDiagnosisInfo) return

    const mergeArray = (data) => {
      return [...(data || []), ...($encounterDiagnosisInfo || [])]
    }

    const getValueByFieldFromArr = (data, field) =>
      get(data, "value.fields")?.filter(
        (item) => item?.field?.toLowerCase() === field,
      )[0] || {}

    const sortArray = (data) => {
      return data.sort((a, b) =>
        sortByDate(
          getValueByFieldFromArr(a, "started")?.value,
          getValueByFieldFromArr(b, "started")?.value,
        ),
      )
    }

    const resolveData = partialRight(applyResolvers, problemsInfoResolvers)
    getData(`patients/${$patientId}/problems`) // "../sample-problems-info.json"
      .then(resolveData)
      .then(mergeArray)
      .then(sortArray)
      .then(set)
      .then(() => reportAnyResolverErrors("Problems"))
      .catch(fetchError)
  },
)

/**
 * Goal Info Store
 */
export const goalInfo = derived(patientId, ($patientId, set) => {
  if (!$patientId) return
  const resolveData = partialRight(applyResolvers, goalInfoResolvers)
  getData(`patients/${$patientId}/goals`) // "../sample-goal-info.json"
    .then(resolveData)
    .then(set)
    .then(() => reportAnyResolverErrors("Goals"))
    .catch(fetchError)
})

/**
 * Assessment Info Store
 */
export const assessmentInfo = derived(patientId, ($patientId, set) => {
  if (!$patientId) return
  const resolveData = partialRight(applyResolvers, assessmentInfoResolvers)
  getData(`patients/${$patientId}/assessment-and-treatment-plans`) // "../sample-assessments-info.json"
    .then(resolveData)
    .then(set)
    .then(() => reportAnyResolverErrors("Assessments"))
    .catch(fetchError)
})

/**
 * Access logs Store
 */
export const accessLogsInfo = derived(patientId, ($patientId, set) => {
  if (!$patientId) return
  getData(`phi-access-events?ncPatientId=${$patientId}`, true) // "../sample-access-logs.info.json"
    .then(sortByTimeStamp)
    .then(set)
    .then(() => reportAnyResolverErrors("Access Logs"))
    .catch(() => {
      set({
        error: true,
      })
    })
})

/**
 * Change Labs Page Update
 */
export const loadMoreLabs = (accumulatedResults, page, itemsPerPage = 500) => {
  const updateStore = () => {
    labsPagination.update(() => {
      return { accumulatedResults, page, itemsPerPage }
    })
  }
  updateStore()
}

/*
 * Health Documents Store
 */
export const healthDocumentsInfo = derived(patientId, ($patientId, set) => {
  if (!$patientId) return

  const resolveData = partialRight(applyResolvers, healthDocumentsResolvers)
  getData(`patients/${$patientId}/health-documents?category=HealthDocument`) // "../sample-health-documents.json" //
    .then(resolveData)
    .then(set)
    .then(() => reportAnyResolverErrors("Health Documents"))
    .catch(fetchError)
})

/**
 * McKesson Clinical Notes Store
 */
export const mcKessonClinicalNotesInfo = derived(
  patientId,
  ($patientId, set) => {
    if (!$patientId) return

    const resolveData = partialRight(applyResolvers, clinicalNotesResolvers)
    const url = `patients/${$patientId}/health-documents?category=ClinicalNotesMetadata`

    callAPI({ url, method: "GET" })
      .then(resolveData)
      .then(set)
      .then(() => reportAnyResolverErrors("McKesson Clinical Notes"))
      .catch(fetchError)
  },
)

export const activeMcKessonClinicalNoteId = writable("")

/**
 * McKesson Clinical Note Store
 */
export const mcKessonClinicalNoteInfo = derived(
  [mcKessonClinicalNotesInfo, activeMcKessonClinicalNoteId, patientId],
  (
    [$mcKessonClinicalNotesInfo, $activeMcKessonClinicalNoteId, $patientId],
    set,
  ) => {
    if (!$mcKessonClinicalNotesInfo || !$activeMcKessonClinicalNoteId) return
    const resolveData = partialRight(applyResolvers, clinicalNoteResolvers)
    const url = `patients/${$patientId}/health-documents?category=ClinicalNote&health_document_identifier=${$activeMcKessonClinicalNoteId}`

    callAPI({ url, method: "GET" })
      .then(resolveData)
      .then(set)
      .then(() => reportAnyResolverErrors("McKesson Clinical Note"))
      .catch(fetchError)
  },
)
