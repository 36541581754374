import { get, hasIn, isEmpty, partialRight } from "lodash"
import applyResolvers from "@/utils/applyResolvers"
import { sortByDate } from "@/utils/sortByDate"
import { dateTransformer } from "./transformers"
import createProvenanceResolver from "./provenanceResolvers"
import { isNotFutureDate } from "@/utils/dateTime"

/**
 * Filter empty immunization notes
 * @param {Object} obj Resource object
 * @returns {Array} Immunization notes
 */
const fetchNotes = (obj) => {
  if (!isEmpty(obj?.resource?.note) && obj?.resource?.note[0]?.text === "N/A")
    return []
  return [
    {
      field: "Notes",
      path: "resource.note[0].text",
    },
  ]
}

// used to filter out entries that have no display name and can't be displayed in UI
const hasDisplayName = (entryObj) =>
  hasIn(entryObj, "resource.vaccineCode.coding[0].display")

const immunizationInfoResolvers = [
  {
    field: "Immunizations",
    path: (data) =>
      data?.entry
        ?.filter(hasDisplayName)
        .filter((entry) => isNotFutureDate(entry?.resource?.occurrenceDateTime))
        .sort((a, b) =>
          sortByDate(
            a.resource?.occurrenceDateTime,
            b.resource?.occurrenceDateTime,
          ),
        )
        .map((obj) => ({
          title: get(obj, "resource.vaccineCode.coding[0].display"),
          id: get(obj, "resource.id"),
          description: get(obj, "resource.vaccineCode.text"),
          fields: applyResolvers(obj, [
            {
              field: "Date",
              path: "resource.occurrenceDateTime",
              transform: partialRight(dateTransformer, {
                dateFormat: "M/D/YYYY",
                strSeparator: "T",
              }),
            },
            {
              field: "Status",
              path: "resource.status",
            },
            ...createProvenanceResolver(
              "resource.",
              "Added to\nEHR",
              "M/D/YYYY",
              ", hh:mma z",
            ),
            ...fetchNotes(obj),
          ]),
        })),
  },
]

export default immunizationInfoResolvers
