<script>
  export const size = 12
</script>

<span
  class="dot"
  style={`width: ${size}px; height: ${size}px; min-width: ${size}px; min-height: ${size}px;`}
/>

<style>
  .dot {
    display: inline-block;
    border-radius: 50%;
    background-color: #b836b5;
  }
</style>
