<script>
  import { imask } from "svelte-imask"
  import { _ } from "@/i18n"
  import Dropdown from "@/components/Dropdown/Dropdown.svelte"

  export let ariaLabel
  export let id
  export let name
  export let error
  export let value
  export let options = []
  export let maskOptions
  let isDrodownOpened = false

  const handleMenuOpened = ({ detail }) => {
    isDrodownOpened = detail.isOpen
  }
</script>

<div>
  <div class="masked-input-dropdown-container">
    <input
      type="text"
      aria-label={ariaLabel}
      {id}
      {name}
      class="form-text-primary"
      class:error
      class:menuOpened={isDrodownOpened}
      placeholder="(XXX) XXX-XXXX"
      use:imask={maskOptions}
      bind:value
      on:input
      on:blur
    />
    <Dropdown
      {options}
      id="phone-dropdown"
      grouped
      selectedOption={options[0]}
      iconSrc="../../assets/svg/caret.svg"
      placeholder="Phone"
      on:optionSelected
      on:menuOpened={handleMenuOpened}
    />
  </div>
  {#if error}
    <span class="error-text">{$_(error)}</span>
  {/if}
</div>

<style src="./MaskedInputWithDropdown.scss"></style>
