<script>
  import { onMount, onDestroy } from "svelte"
  import { map, assign, isEmpty } from "lodash"
  import { v4 as uuidv4 } from "uuid"
  import { goto, route, url } from "@roxi/routify"
  import FileUploader from "@/components/FileUploader/FileUploader.svelte"
  import TextArea from "@/components/FormComponents/TextArea/TextArea.svelte"
  import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.svelte"
  import { _ } from "@/i18n"
  import { validateForm } from "@/utils/formValidation"
  import {
    enableKeyboardNavigation,
    setNodeFocusOnKeyboardSelect,
    focusBackHandler,
  } from "@/utils/keyboardNavigation"
  import {
    bodyClassName,
    mobileToolbarConfig,
    patientContext,
    isUserClinicianOrAdmin,
  } from "@/stores/stores"
  import Recipient from "@/components/Recipient/Recipient.svelte"
  import { clickOutside } from "@/utils/clickOutside"
  import { callAPI } from "@/utils/httpService"
  import { attachmentFF } from "@/utils/featureFlag"
  import LoadingIndicator from "@/components/LoadingIndicator/LoadingIndicator.svelte"

  let subject = ""
  let message = ""
  let selectedRecipients = ""
  let selectedCategory = ""
  let isFormDirty = false
  let showLoader = true
  let isOpen = false
  let providerSelected = false

  let myClinicProviders = []
  let myConnections = []
  let clinicCategories = []
  let clinicId
  let fileAttachments = []
  let areAttachments = false
  let pendingAttachments = false
  let isSendButtonDisabled = true
  let isAddAttachmentsButtonDisabled = false

  // set goBackUrl intelligently to inbox or sent tab
  const goBackUrl = $route?.last?.shortPath
    ? $url($route.last.shortPath, $route.last.params)
    : "/messages/inbox"

  // Disable Send if ther are pending attachments or message is empty
  $: areAttachments = fileAttachments.length > 0
  $: pendingAttachments =
    areAttachments &&
    fileAttachments.some(({ isUploaded }) => isUploaded === false)
  $: {
    if ($isUserClinicianOrAdmin) {
      isSendButtonDisabled = true
      isAddAttachmentsButtonDisabled = true
    }
    // if there are pending attachments, disabled
    // if the message is empty and there is no completed attachments, disabled
    else if (areAttachments) {
      if (pendingAttachments) {
        isSendButtonDisabled = true
        isAddAttachmentsButtonDisabled = true
      } else {
        if (message.trim().length > 0) {
          isSendButtonDisabled = false
        } else {
          isSendButtonDisabled = true
        }
        isAddAttachmentsButtonDisabled = false
      }
    } else if (message.trim().length > 0) {
      isSendButtonDisabled = false
      isAddAttachmentsButtonDisabled = false
    } else {
      isSendButtonDisabled = true
      isAddAttachmentsButtonDisabled = false
    }
  }

  /**
   * toggle dropdown
   */
  const toggleDropdown = () => {
    isOpen = !isOpen
  }

  /**
   * Get clinic providers and connections from API
   */
  const getRecipients = async () => {
    showLoader = true
    const { ncPatientId, clinicId: clinicIdValue } = $patientContext
    clinicId = clinicIdValue
    // Fetch clinic providers
    const providerResponse = await callAPI({
      url: `patients/${ncPatientId}/secure-messages/clinic/${clinicId}/providers`,
      method: "GET",
    })

    if (providerResponse) {
      myClinicProviders = map(providerResponse, (provider) =>
        assign(provider, {
          name: `${provider.name} and staff`,
        }),
      )
    }
    // Fetch clinic categories
    clinicCategories = await callAPI({
      url: `patients/${ncPatientId}/secure-messages/clinic/${clinicId}/categories`,
      method: "GET",
    })

    // Fetch my connections
    myConnections = await callAPI({
      url: `patients/${ncPatientId}/secure-messages/users`,
      method: "GET",
    })

    showLoader = false
  }

  // Get Recipients when patientContext is available
  $: if (isEmpty($patientContext) === false) getRecipients()

  /**
   * Toggle clinic categories based on Recipient selection.
   * If Recipient is provider then clinic categories will be visible and if Recipient is connections then it will be hidden.
   * @param {Object} event event emitted from child (Recipient) component
   */
  const toggleClinicCategories = (event) => {
    // Get clinic selection values from child component
    selectedRecipients = event?.detail?.selectedRecipients
    providerSelected = event?.detail?.providerSelected

    // Reset category selection
    if (!providerSelected) selectedCategory = ""

    // Hide message area until dropdown is open while removing recipient from chip selection
    if (
      event?.detail?.action === "recipient-removed" &&
      event?.detail?.isOpen
    ) {
      toggleDropdown()
    }
    // Close topic dropdown while removing chip selection
    if (document.getElementById("topic-dropdown")) {
      document.getElementById("topic-dropdown").classList.remove("show")
    }
  }

  /**
   * Discard messagge and go to the previous URL
   */
  const handleDiscardMessage = () => $goto(`${goBackUrl}`)

  /**
   * Filter cancelled attachment detail and remove it from the DOM
   * @param {Object} fileAttachment file attachment object
   * @param {Object} detail details of attachment
   */
  const cancelUpload = ({ detail: attachment }) =>
    (fileAttachments = fileAttachments.filter(
      (file) => file.uuid !== attachment.uuid,
    ))

  /**
   * Update the isUploaded flag after successful attachment uploads and re-render the DOM
   * @param {Object} fileAttachment file attachment object
   * @param {Object} detail details of attachment
   */
  const uploadSuccess = ({ detail: attachment }) => {
    fileAttachments = fileAttachments.map((file) =>
      attachment.uuid === file.uuid
        ? { ...file, uploadResponse: attachment, isUploaded: true }
        : { ...file },
    )
  }

  /**
   * Set mobileToolbarConfig to set the position for Cancel and New Message button for mobile view port.
   * Set white body class name for sm-down view port.
   */
  onMount(() => {
    mobileToolbarConfig.set({
      left: { arrow: false, text: $_("GLOBAL_NAV.CANCEL") },
      center: { logo: false, text: $_("GLOBAL_NAV.NEW_MESSAGE") },
    })

    bodyClassName.add("white-bkg-sm-down")
  })

  /**
   * Reset the mobileToolbarConfig and bodyClassName values.
   */
  onDestroy(() => {
    mobileToolbarConfig.set({})
    bodyClassName.remove("white-bkg-sm-down")
  })

  /**
   * Select clinic categories
   * @param {String} name category name
   * @param {KeyboardEvent} e Keyboard event
   */
  const selectCategory = (name, e) => {
    // check for keyboard enter and space events as well as click
    if (e && e instanceof KeyboardEvent) {
      if (e.code !== "Space" && e.code !== "Enter") return
    }

    ;[selectedCategory] = clinicCategories.filter(
      (category) => category.name === name,
    )
  }

  /**
   * Validate the compose form and send a message
   */
  const sendMessage = async () => {
    // no submitting message as admin or clinician
    if ($isUserClinicianOrAdmin) return
    const { ncPatientId } = $patientContext
    isFormDirty = true
    const validationResponse = validateForm("compose-message")

    if (validationResponse.length > 0) {
      showLoader = true
      let URL
      const payload = { message, subject }

      if (providerSelected) {
        URL = `patients/${ncPatientId}/secure-messages/clinic/${clinicId}/threads`
        payload.providers = [...selectedRecipients]
        payload.category = selectedCategory
      } else {
        URL = `patients/${ncPatientId}/secure-messages/threads`
        payload.users = [...selectedRecipients]
      }

      // Add file attachment ids in thread payload
      if (fileAttachments.length > 0) {
        payload.attachment_ids = [
          ...new Set(map(fileAttachments, "uploadResponse.id")),
        ]
      }
      const response = await callAPI({
        url: URL,
        method: "POST",
        payload,
      })
      if (response) {
        fileAttachments = []
        $goto("/messages/thankyou")
      }

      showLoader = false
    }
  }

  /**
   * Trigger upload event for any attachment
   */
  const uploadEvent = () => {
    if ($isUserClinicianOrAdmin) return
    const inputFileElement = document.querySelector("#customInput")
    const newFileAttachments = [...inputFileElement.files].map((file) => {
      return {
        // use unique id for keyed each loop on components
        uuid: uuidv4(),
        fileMetadata: file,
        isUploaded: false,
      }
    })

    fileAttachments = [...fileAttachments, ...newFileAttachments]
    inputFileElement.value = ""
  }

  const breadcrumbLinks = [
    {
      title: $_("SM.MESSAGES"),
      link: "/messages/inbox",
      id: "messages-inbox",
    },
    {
      title: $_("SM.NEW_MESSAGE"),
      active: true,
    },
  ]

  // TODO: change this to an action once svelte version is updated
  /**
   * Sets focus when tabbing for the first time to the recipient dropdown
   * @param {document#event:keydown} e - keydown event on document
   * @returns {Undefined}
   */
  const setInitialTabFocusToRecipient = (e) => {
    const recipientDropdown = document.querySelector("#recipient-dropdown")
    if (
      e.keyCode === 9 &&
      recipientDropdown &&
      document.activeElement === document.body
    ) {
      e.preventDefault()
      recipientDropdown.focus()
    }
  }
</script>

<svelte:body on:keydown={setInitialTabFocusToRecipient} />

{#if showLoader}
  <div class="spinner-wrapper">
    <LoadingIndicator indicatorTitle={$_("COMMON.LOADING")} />
  </div>
{:else}
  <section class="message-header">
    <Breadcrumb {breadcrumbLinks} />
  </section>

  <section class="compose-container">
    <form class="compose-message" novalidate>
      <header>
        <h5 class="desktop-only">{$_("SM.NEW_MESSAGE")}</h5>
      </header>
      <div
        class="compose-form"
        class:invalid={isFormDirty && selectedRecipients.length === 0}
      >
        <Recipient
          {myClinicProviders}
          {myConnections}
          on:recipientSelected={toggleClinicCategories}
          invalid={isFormDirty && selectedRecipients.length === 0}
        />
        <input
          type="text"
          class="d-none"
          name="recipientSelected"
          value={selectedRecipients.length || ""}
          required
        />
        <div class="invalid-feedback px-16">
          {$_("SM.CHOOSE_AT_LEAST_ONE_RECIPIENT")}
        </div>
      </div>

      {#if providerSelected === true}
        <div
          class="compose-form {selectedCategory && selectedCategory.notice.en
            ? 'border-bottom-0'
            : ''}"
          use:enableKeyboardNavigation={"#topic-dropdown .mainmenu"}
          on:keyboardNavigate={isOpen ? "" : toggleDropdown}
          on:keyboardEscape={isOpen ? toggleDropdown : ""}
          on:keyboardUnfocus={isOpen ? toggleDropdown : ""}
          class:invalid={isFormDirty && selectedCategory.length === 0}
        >
          <div class="dropdown">
            <label for="topic" class="pl-16"
              >{$_("SM.TOPIC")}<span class="mobile-only">:</span></label
            >
            <button
              class="btn dropdown-toggle topic-dropdown {isOpen
                ? 'square-btm-borders'
                : ''}"
              data-display="static"
              type="button"
              data-toggle="dropdown"
              class:invalid={isFormDirty && selectedCategory.length === 0}
              id="topic-dropdown"
              use:clickOutside
              use:focusBackHandler
              on:focusBack={isOpen ? toggleDropdown : ""}
              on:clickOutside={isOpen ? toggleDropdown : ""}
              on:click={toggleDropdown}
            >
              {#if selectedCategory}
                <span class="category-title">{selectedCategory.name}</span>
              {:else}
                <span class="placeholder">{$_("SM.SELECT_ONE")}</span>
              {/if}
              <span class="chevron-icon caret pr-16">
                <i
                  class="fas {isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}"
                  class:invalid={isFormDirty && selectedCategory.length === 0}
                  aria-hidden="true"
                />
              </span>
            </button>
            <ul
              id="topic-dropdown"
              class="dropdown-menu mobile-full-height"
              class:show={isOpen}
              class:invalid={isFormDirty && selectedCategory.length === 0}
            >
              {#each clinicCategories as category}
                <li
                  use:setNodeFocusOnKeyboardSelect={".topic-dropdown"}
                  on:click={() => selectCategory(category.name)}
                  on:keydown={(e) => selectCategory(category.name, e)}
                  class="mainmenu"
                >
                  {category.name}
                </li>
              {/each}
            </ul>
          </div>
          <input
            type="text"
            class="d-none"
            name="topicSelected"
            bind:value={selectedCategory.name}
            required
          />
          <div class="invalid-feedback px-16">{$_("SM.SELECT_A_TOPIC")}</div>
        </div>
        {#if selectedCategory && selectedCategory.notice.en}
          <div class="notice px-16" id="topic-notice">
            {selectedCategory.notice.en}
          </div>
        {/if}
      {/if}

      <div
        class="compose-form compose-form--padded px-16"
        class:invalid={isFormDirty && !subject.trim()}
      >
        <label for="subject"
          >{$_("SM.SUBJECT")}<span class="mobile-only">:</span></label
        >
        <input
          type="text"
          name="subject"
          bind:value={subject}
          required
          pattern=".*\S+.*"
          autocomplete="off"
          aria-label="subject"
          id="subject"
        />
        <div class="invalid-feedback">{$_("SM.ENTER_A_SUBJECT")}</div>
      </div>
      <div
        class="compose-form compose-form--padded border-bottom-0 px-16 py-12"
      >
        <TextArea
          label={$_("SM.MESSAGE")}
          placeholder={$_("SM.COMPOSE_MESSAGE_PLACEHOLDER")}
          bind:value={message}
        />
      </div>
      {#if fileAttachments.length > 0}
        <div class="pl-16 attachments-container">
          {#each fileAttachments as { uuid, fileMetadata } (uuid)}
            <FileUploader
              {uuid}
              {fileMetadata}
              on:uploadSuccess={uploadSuccess}
              on:cancelUpload={cancelUpload}
            />
          {/each}
        </div>
      {/if}
      <div class="message-footer pl-16">
        <button
          type="button"
          class="btn btn-link discard-btn desktop-only px-0"
          on:click={handleDiscardMessage}
        >
          <i class="fas fa-trash-alt" />
          Discard
        </button>
        <div class="sub-btns">
          {#if attachmentFF(clinicId)}
            <div class="custom-file">
              <input
                type="file"
                name="document"
                class="custom-file-input"
                class:disabled={isAddAttachmentsButtonDisabled}
                disabled={isAddAttachmentsButtonDisabled}
                id="customInput"
                on:change={uploadEvent}
              />
              <label
                class="custom-file-label"
                for="customInput"
                class:disabled={isAddAttachmentsButtonDisabled}
                id="file-input"
              >
                <i class="fas fa-paperclip" />
                Add a photo or file
              </label>
            </div>
          {/if}
          <div>
            <button
              type="button"
              class="btn btn-primary compose-btn"
              id={message.trim().length === 0
                ? "btn-send-disabled"
                : "btn-send-enabled"}
              class:disabled={isSendButtonDisabled}
              on:click={sendMessage}
            >
              <i class="fas fa-paper-plane" />
              Send
            </button>
          </div>
        </div>
      </div>
    </form>
  </section>
{/if}

<style lang="scss" src="./Compose.scss">
</style>
