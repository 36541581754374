import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import advancedFormat from "dayjs/plugin/advancedFormat"
import parsePhoneNumber from "libphonenumber-js"
import { isTimeZonelessMidnight } from "@/utils/dateTime"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

export const languageTransformer = (value) => {
  const lookup = {
    en: "English",
    sp: "Spanish",
    zh: "Chinese",
    fr: "French",
    tg: "Tagalog",
    vi: "Vietnamese",
  }
  return lookup[value] ?? value
}

export const phoneTransformer = (valueStr) =>
  valueStr ? parsePhoneNumber(valueStr, "US").formatNational() : ""

/**
 * As an array map method callback, dateTransformer recieves 3 initial arguments (map method passes 3 params and
 * only first arg is utilized) and format can be bound via a partialRight
 * @param {String} dateStr - representing dateTime
 * @param {Object} [options={}] - options object
 * @param {String} [options.dateFormat="M/D/YYYY"] - dayjs date format
 * @param {String} [options.timeFormat=""] - dayjs time format
 * @param {String} [options.strSeparator] - splits dateStr into array using this separator
 * @returns {String} - of formatted date
 */
export function dateTransformer(...[dateStr, , , options = {}]) {
  const defaultOptions = {
    dateFormat: "M/D/YYYY",
    timeFormat: "",
  }

  const { dateFormat, timeFormat, strSeparator } = {
    ...defaultOptions,
    ...options,
  }

  let date = dateStr
  if (strSeparator) {
    ;[date] = dateStr.split(strSeparator)
  }

  const format = isTimeZonelessMidnight(dateStr)
    ? dateFormat
    : `${dateFormat}${timeFormat}`

  const parsedDate = dayjs(date)
  return parsedDate.isValid() ? parsedDate.format(format) : date
}

/**
 * Transforms integer bytes value into string with correct size suffix, i.e 100kb
 * @param {Number} bytes - bytes value
 * @returns {String} - of size formatted to readable display of unit size
 */
export const formatBytes = (bytes) => {
  if (!bytes || typeof bytes !== "number") return bytes
  if (bytes === 0) return "0 Bytes"

  const k = 1024
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))}${sizes[i]}`
}
