<script>
  export let config = {}

  let title,
    bannerColor,
    circleBorderColor,
    backgroundColor,
    iconSrc,
    coverColor,
    rightValue
  $: {
    title = config.title || "Title"
    bannerColor = config.bannerColor || "#0D446B"
    circleBorderColor = config.circleBorderColor || "#FFBB0B"
    backgroundColor = config.backgroundColor || "#F0F2F5"
    coverColor = config.coverColor || "#F0F2F5"
    iconSrc = config.iconSrc || "assets/svg/messages-icon.svg"
    rightValue = config.rightValue || "27px"
  }
</script>

<div id="page-banner" style="--bannerColor: {bannerColor}">
  <h1 id="title">{title}</h1>
  <div id="circle">
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50C100 77.6142 77.6142 100 50 100C22.3858 100 0 77.6142 0 50C0
        22.3858 22.3858 0 50 0C77.6142 0 100 22.3858 100 50Z"
        fill={circleBorderColor}
      />
      <circle cx="50" cy="50" r="37" fill={backgroundColor} />
    </svg>
  </div>
  <div id="cover">
    <svg
      width="100%"
      height="23"
      viewBox="0 0 797 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M797 21.8307V23H0V22.6677C113.273 8.40995 252.402 0 402.564 0C549.763
        0 685.845 8.08138 797 21.8307Z"
        fill={coverColor}
      />
    </svg>
  </div>
  <div id="icon" style="right: {rightValue}">
    <img src={iconSrc} alt="Banner icon" />
  </div>
</div>

<style lang="scss">
  @import "../../css/mixins.scss";

  #page-banner {
    background-color: var(--bannerColor);
    height: 99px;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  #title {
    color: white;
    font-family: "Mukta";
    margin-left: 23px;
    margin-top: 17px;
    @include feature-title;
  }
  #circle {
    position: absolute;
    right: 7px;
    top: 25px;
  }
  #cover {
    bottom: -1px;
    position: absolute;
    width: 100%;
  }
  #icon {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: center;
    position: absolute;
    top: 43px;
    width: 60px;
  }
</style>
